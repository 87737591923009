
import { defineComponent, defineAsyncComponent, inject, computed, onMounted, watch } from "vue";
import { IStore } from "./Store";
import { IScreen } from "./Screen";
import { SvcAliases } from "./SvcAliases";
import { IScene3D } from "./engine/Scene3D";
import { IEngine } from "./engine/Engine";
import BaseModal from "./ui/BaseModal.vue";
import { Api } from "@mobibamjs/api";

export default defineComponent({
    components: {
        Desktop: defineAsyncComponent(() => import("./ui/Desktop.vue")),
        Mobile: defineAsyncComponent(() => import("./ui/Mobile.vue")),
        BadOrientation: defineAsyncComponent(() => import("./ui/BadOrientation.vue")),
        BaseModal
    },

    setup() {
        const screen = inject(SvcAliases.Screen) as IScreen;
        const scene3D = inject(SvcAliases.Scene3D) as IScene3D;
        const engine = inject(SvcAliases.Engine) as IEngine;
        const store = inject(SvcAliases.Store) as IStore;

        watch(() => engine.project.isReady, (isReady: boolean) => { 
          if (isReady && !engine.project.isUIEasy) {
            store.currentModal = "Desktop";
          }
        });

        // Load current user session if any
        Api.user.extendSession().then((user) => store.currentUser = user);

        // Bind the screen service to detect orientation change and window size change
        if (window.screen.orientation) // Warn ! Does not exists on iOS
            window.screen.orientation.onchange = (e) => { screen.orientationChange() };

        window.onresize = (e) => { screen.sizeChange() }
        
        const dispType = computed(() => "Mobile" /* TODO : screen.displayType */ );
        const dispHeight = computed(() => { return `${screen.configuratorTotalHeight}px` });
        console.log(`screen.configurator3DHeight => ${screen.configuratorTotalHeight}`);

        // Activate 3D user selection detection
        scene3D.activateUserSelection(engine.project);

        // Check project saving before exit
        window.onbeforeunload = (event) => {
          if (engine.project.isReady && !engine.project.isSaved && engine.project.isModified) {
            const msg = "Si vous fermer cette page vous allez perdre toute vos modifications !";
            if (event) event.returnValue = msg;
            return msg;
          }
        }
        
        return {
            dispType,
            dispHeight
        }
    }
})
