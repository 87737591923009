import ezInjector from "./core/ezInjector";

import $ez from "./core/$ez";
import ezObs from "./core/ezObs";
// import ezRepo from "./core/ezRepo";
// import ezApp from "./core/ezApp";
import ezBOM from "./core/ezBOM";
// import ez3D from "./core/ez3DPlus";
import ezSchema from "./core/ezSchema";
import ezThick from "./core/ezThick";
import ezCTree from "./core/ezCTree";
import ezSwingDoor from "./core/ezSwingDoor";
import ezValidator from "./core/ezValidator";
// import ezClosetHelper from "./helper/ezClosetHelper";
import ezMinMaxResolver from "./core/ezMinMaxResolver";
import ezZoneHelper from "./helper/ezZoneHelper";
import ezCTreeHelper from "./helper/ezCTreeHelper";
import ezGPos from "./core/ezGPos";
import ezRW from "./core/ezRW";
import ezSplit from "./core/ezSplit";
import ezZone from "./core/ezZone";
import ezPanel from "./core/ezPanel";
// import ezPatternHelper from "./helper/ezPatternHelper";

import ezPrice from "./core/ezPrice";
import ezCloset from "./core/ezCloset";
import ezPlan from "./core/ezPlanPlus";

import ezDrawer from "./core/ezDrawer";
import ezHangingBar from "./core/ezHangingBar";
import ezAccessory from "./core/ezAccessory";
// import ezProfile from "./core/ezProfile";
// import ezSlidingDoor from "./core/ezSlidingDoor";


/**
 * Setup the depencies of EZ Closet engine V4 and return the injector
 * @param ez3D The ez3D service used by engine (use ez3DPlus.js or no3D.js)
 */
export function EZCompositionRoot(ez3D: object): void {
  if (ezInjector.has("$ez")) return; // Already done

  // Composition root begin
  ezInjector.register("$ez", $ez);
  ezInjector.register("ezObs", ezObs, ["$ez"]);
  // ezInjector.register("ezRepo", ezRepo, ["$ez", /* "ezWebSvc", */ "ezObs"]);
  // ezInjector.register("ezApp", ezApp, ["$ez", "ezObs"]);
  ezInjector.register("ez3D", ez3D, ["$ez", "ezObs"]);
  ezInjector.register("ezBOM", ezBOM, ["$ez"]);
  ezInjector.register("ezPrice", ezPrice, ["$ez"]);
  ezInjector.register("ezThick", ezThick, ["$ez"]);
  ezInjector.register("ezSchema", ezSchema, ["$ez"]);
  ezInjector.register("ezValidator", ezValidator, ["$ez", "ezObs", "ez3D"]);
  ezInjector.register("ezCTreeHelper", ezCTreeHelper, ["$ez"]);
  ezInjector.register("ezMinMaxResolver", ezMinMaxResolver, ["$ez", "ezCTreeHelper"]);
  ezInjector.register("ezZoneHelper", ezZoneHelper, ["$ez", "ezCTreeHelper", "ezMinMaxResolver"]);
  ezInjector.register("ezGPos", ezGPos, ["$ez"]);
  ezInjector.register("ezSplit", ezSplit, ["$ez"]);
  ezInjector.register("ezZone", ezZone, ["$ez", "ez3D", "ezGPos"]);
  ezInjector.register("ezPanel", ezPanel, ["$ez", "ez3D", "ezPrice", "ezGPos"]);

  ezInjector.register("ezSwingDoor", ezSwingDoor, ["$ez", "ez3D", "ezPrice", "ezBOM", "ezPanel", "ezGPos"]);
  ezInjector.register("ezCTree", ezCTree, [
    "$ez",
    "ezSplit",
    "ezZone",
    "ezPanel",
    "ezSwingDoor",
    "ezSchema",
    "ezMinMaxResolver",
    "ezPrice",
  ]);
  ezInjector.register("ezDrawer", ezDrawer, ["$ez", "ez3D", "ezPrice", "ezSchema", "ezCTree", "ezThick", "ezBOM"]);
  ezInjector.register("ezHangingBar", ezHangingBar, ["$ez", "ez3D", "ezPrice", "ezBOM", "ezGPos"]);
  ezInjector.register("ezAccessory", ezAccessory, ["$ez", "ez3D", "ezPrice", "ezBOM", "ezGPos"]);
  ezInjector.register("ezCloset", ezCloset, ["$ez", "ez3D", "ezSchema", "ezThick", "ezCTree", "ezSwingDoor", "ezValidator"]);
  ezInjector.register("ezRW", ezRW, [
    "$ez",
    "ez3D",
    "ezPrice",
    "ezBOM",
    "ezCloset",
    "ezPanel",
    "ezDrawer",
    "ezHangingBar",
    "ezAccessory",
    "ezGPos",
  ]);
  // ezInjector.register("ezPatternHelper", ezPatternHelper, ["$ez", "ezRW", "ezSchema"]);
  // ezInjector.register("ezClosetHelper", ezClosetHelper, ["$ez", "ezPatternHelper"]);
  ezInjector.register("ezPlan", ezPlan, [
    "$ez",
    // "ezObs",
    "ezCloset",
    // "ezClosetHelper",
    "ezMinMaxResolver",
    "ezZoneHelper",
    "ezCTreeHelper",
    "ezGPos",
    "ezRW",
  ]);
}
