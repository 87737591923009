import { ICloset } from "./Closet";
import { IClosetNode } from "./ClosetNode";
import { ISmallModule } from "./DataModels/ISmartPattern";
import ezInjector from "./ezV4/core/ezInjector";

class DimAnalyzer {

    readonly dimName: "width" | "height"; // The dimension name
    readonly anaNode: IClosetNode; // (CNode) The analysed node

    azDim = 0; // (Number) The dimension of sub auto-zones to detect max limit : auto-zone dimension average. Use to add separator only. 
    azSum = 0; // (Number) The sum of sub auto-zones dimensions.
    sum = 0; // (Number) The sum of all sub zone dimensions.
    oppDim = 0; // (Number) The opposite dimension of analyzed node, according to dimName (ex. for 'width' get ydim )
    n: number; // (Number) The count of sub nodes.
    azn = 0; // (Number) The count of sub auto-zone.
    lasn: IClosetNode | null = null; // (CNode) The last sub-node.
    lasni = 0; // (Number) The index of last sub-node. 

    constructor(dimName: "width" | "height", node: IClosetNode) {
        this.dimName = dimName;
        this.anaNode = node;
        this.n = (node as any).subNodes.getLength(); // (Number) The count of sub nodes.
    }

    // Get the node dimension according current dimension name
    private _getNodeDim(node: IClosetNode): number { return (this.dimName === "width") ? node.innerZone.xdim : node.innerZone.ydim }

    // Get the min dimension of node according current dimension name
    // private _getMinDim(node: IClosetNode): number {
    //     var bnds = (node as any).computedBounds;
    //     if (this.dimName === "width")
    //         return node.innerZone.hasXDimAuto ? bnds.w : node.innerZone.xdim;
    //     else
    //         return node.innerZone.hasYDimAuto ? bnds.h : node.innerZone.ydim;
    // }

    // Return true if dimension is auto according current dimension name
    private _hasAutoDim(node: IClosetNode): boolean { return (this.dimName === "width") ? node.innerZone.hasXDimAuto : node.innerZone.hasYDimAuto }

    // Analyze node along dimension name.
    analyse() {
        var me = this;

        me.oppDim = me.dimName === 'width' ? me.anaNode.innerZone.ydim : me.anaNode.innerZone.xdim;

        if (me.n === 0) { // Empty node
            me.azDim = me._getNodeDim(me.anaNode);
            me.azSum = me.azDim;
            me.lasni = -1;
            me.azn = 1;
        }
        else { // Has sub-nodes
            for (var i = 0; i < me.n; i++) {
                var sn = (me.anaNode as any).subNodes.get(i);
                var dim = me._getNodeDim(sn);
                if (me._hasAutoDim(sn)) {
                    me.azSum += dim;
                    me.azn++;
                }

                me.lasn = sn;
                me.lasni = i;

                me.sum += dim;
            }

            me.azDim = me.azSum / me.azn; // The width or height of auto-zone. Used to split when dimension increase : auto-zone dimension average
        }
    }


}

class DimSplitter {

    private closet: any;

    private splitAxis: string = "X_AXIS";

    private splitNode: any;

    private isFirstSplit: boolean = true;

    private addeds: IClosetNode[] = [];



    // Prepare splitting and set this.splitNode with the real split node.
    // closet: (Closet) The current closet
    // splitAxis: (String) The split axis : 'X_AXIS' or 'Y_AXIS'.
    // node : (CNode) The split node. Note than this node could not be the real split node (in case of split root node for example). 
    prepare(closet: any, splitAxis: string, node: any) {
        const me = this;

        me.closet = closet;
        me.splitAxis = splitAxis;
        me.addeds.splice(0);

        var n = node.subNodes.getLength();
        
        if (n === 0) { // Node has no sub-nodes
            //var subTreeBackup = node.subTree; // Backup subtree before split-node preparation
            //node.subTree = null;
            me.splitNode = node.prepareSeparatorAdd(splitAxis);
            //me.splitNode.subTree = subTreeBackup; // Restore subtree
            me.addeds.push(me.splitNode);
            me.isFirstSplit = true;
        }
        else {
            me.isFirstSplit = false;
            me.splitNode = node;
        }
    }

    // Do splitting. Call prepare() methode before.
    // nSplit : (Number) The count of separator.
    split(nSplit: number, addedBefore: boolean) {
        var me = this;
        var i, j;

        var splitAxis = me.splitAxis;
        var splitNode = me.splitNode;

        // Now, split node has parent
        var splitParent = splitNode.parent;

        var savedSplitDim = splitAxis === "X_AXIS" ? splitNode.outerZone.xdim : splitNode.outerZone.ydim;
        for (i = 0; i < nSplit; i++) {
            var added = splitParent.addSeparator(splitNode, addedBefore);
            me.addeds.push(added);

            // To pass fixed dimension of splitNode to added node if dimension is fixed
            if (splitAxis === "X_AXIS") {
                if (!added.outerZone.hasXDimAuto) { // Is fixed
                    added.outerZone.xdim = savedSplitDim;
                    splitNode.outerZone.hasXDimAuto = true;
                }
            }
            else {
                if (!added.outerZone.hasYDimAuto) { // Is fixed
                    added.outerZone.ydim = savedSplitDim;
                    splitNode.outerZone.hasYDimAuto = true;
                }
            }

            splitNode = added; // split last added. To have ordered addeds array
        }

        return me.addeds;
    }
}


export interface ISeparatorInjector {

    getDeltaDim(oldDim: number, newDim: number): number;

    analyseRootDim(node: IClosetNode, dimName: "width" | "height"): void;

    proccessRootDim(closet: ICloset, deltaDim: number): void;

    addSepOnDim: number;

    remSepOnDim: number;

    smallModule: ISmallModule | null;

}


export class SeparatorInjector {

    private readonly _ezRW: any;

    private dimSplitter = new DimSplitter();
    private closet: any;
    // private sepDim = 0; // Separator thickness
    private rootAna!: DimAnalyzer; // DimAnalyzer instance

    constructor() { 
        this._ezRW = ezInjector.get("ezRW");
    }

    addSepOnDim: number = 9999;

    remSepOnDim: number = 0;

    smallModule: ISmallModule | null = null;

    // Get delta dimension, i.e the difference between new dimension and old dimension
    // delta dimension is positive when dimension increase, and negative otherwise.
    getDeltaDim(oldDim: number, newDim: number) { return newDim - oldDim }

    // Analyze root node dimension. Must be used before closet root dimension change.
    // node: (CNode) The analyzed node.
    // dimName: (String) The analyzed dimension : 'width' or 'height'.
    analyseRootDim(node: IClosetNode, dimName: "width" | "height") {
        const me = this;
        me.rootAna = new DimAnalyzer(dimName, node);
        me.rootAna.analyse();
    }

    // Process algo after closet root dimension was changed (add or remove separators, inject template).
    // closet : (Closet) The current closet.
    // deltadim : (Number) The difference between old-dimension and new-dimension
    proccessRootDim(closet: ICloset, deltaDim: number) {
        const me = this;
        me.closet = closet;
        if (me.addSepOnDim <= 0) throw new Error(`Separator injector must have addSepOnDim > 0`);

        if (deltaDim > 0) { // dimension was increased
            me._whenRootDimIncrease(deltaDim);
        }
        else if (deltaDim < 0) { // dimension was decreased
            var driver = me.closet.getDriver();
            me._whenDimDecrease(me.rootAna, deltaDim, !driver.hasReversedPattern(), driver.patternKeepEndContent());
        }
    }

    private _setNodeDim(node: any, axis: string, dim: number) {
        if (axis === "X_AXIS") {
            if (dim > 0) node.outerZone.xdim = dim;
            node.outerZone.hasXDimAuto = dim <= 0;
        }
        else {
            if (dim > 0) node.outerZone.ydim = dim;
            node.outerZone.hasYDimAuto = dim <= 0;
        }
    }

    // Process root dimension increasing
    // deltadim : (Number) The difference between old-dimension and new-dimension
    private _whenRootDimIncrease(deltaDim: number) {
        const me = this;
        var i;

        var ana = me.rootAna;
        
        const onRootDim = me.addSepOnDim;
        var rootAxis;
        if (me.closet.root.axis === "X_AXIS") {
            rootAxis = "X_AXIS";
        } else {
            rootAxis = "Y_AXIS";
        }

        // New auto-zones dimension is computed by distributing deltaDim on each auto-zone
        var newazDim = (deltaDim / ana.azn) + ana.azDim;

        if (newazDim > onRootDim) { // split required
            var nRootSplit = Math.ceil((newazDim * ana.azn) / onRootDim) - ana.azn;

            var splitNode = (ana.lasn || ana.anaNode);
            
            if (nRootSplit > 0) {

                var DS = me.dimSplitter; // Get splitter

                // Split along root dimension
                DS.prepare(me.closet, rootAxis, splitNode);
                var L1nodes = DS.split(nRootSplit, me.closet.getDriver().hasReversedPattern());

                // if (nSubSplit > 0 || me.applyFHBackPanel) {
                    
                //     for (i = 0; i < L1nodes.length; i++) { // For each root node (column or row)
                //         if (me.applyFHBackPanel)
                //             me.pattern.applyBackPanel(L1nodes[i]);

                //         if (nSubSplit > 0) {
                //             // Split along sub dimension
                //             DS.prepare(me.closet, subAxis, L1nodes[i], me.pattern);
                //             var L2Nodes = DS.split(nSubSplit, 0, false);

                //             // apply pattern in each sub dimension nodes
                //             me._applyPattern(9999, L2Nodes);

                //             // Set first and last sub dimension node if any
                //             // me._setFistAndLastDim(ana.lasni + i + 1, subAxis, L2Nodes);

                            
                //         }
                //     }
                // }

                // Smart pattern small-module
                if (me.smallModule) {
                    const moduleParent = L1nodes[0].parent as any;
                    if (moduleParent) {
                        const indexesByModuleCount = me.smallModule.indexesByModuleCount;
                        const moduleCount = moduleParent.subNodes.getLength();
                        const colCount = moduleCount <= indexesByModuleCount.length ? moduleCount - 1 : indexesByModuleCount.length - 1;
                        const idx = indexesByModuleCount[colCount];
                        for (i = 0; i < moduleCount; i++) {
                            if (idx === i) {
                                me._setNodeDim(moduleParent.subNodes.get(i), "X_AXIS", me.smallModule.dim);
                            }
                            else {
                                me._setNodeDim(moduleParent.subNodes.get(i), "X_AXIS", 0);
                            }
                        }
                    }
                }
            }
        }
    }

    // function to get min dimension in sum
    private _getMinDim(sn: any, analyzer: Readonly<DimAnalyzer>) {
        var me = this;
        let minDim = 0;

        // Get min dim
        var bnds = sn.computedBounds;
        if (bnds) { // In case of Y(1)->X(2)[n|] or X(1)->Y(2)[n|], computedBounds of node(2) is null
            if (analyzer.dimName === "width")
                minDim = sn.innerZone.hasXDimAuto ? (bnds.w > me.remSepOnDim ? bnds.w : me.remSepOnDim) : sn.innerZone.xdim;
            else
                minDim = sn.innerZone.hasYDimAuto ? (bnds.h > me.remSepOnDim ? bnds.h : me.remSepOnDim) : sn.innerZone.ydim;

        }

        return minDim;
    }

    // Process dimension decreasing
    // analyzer: (DimAnalyzer) The dimension analyzer associate to dimension decreasing.
    // deltadim : (Number) The difference between old-dimension and new-dimension
    // removeFromLast: (Boolean) If true, removal will start from the last zone. This is the standard way.
    private _whenDimDecrease(analyzer: Readonly<DimAnalyzer>, deltaDim: number, removeFromLast: boolean, keepEndContent: boolean) {
        const me = this;
        var i, n, sn, sumOfMin = 0;

        const ana = analyzer;

        var newSum = ana.sum + deltaDim;
        var startRemIndex = -1; // The first node index that will be removed

        // Compute the sum of min dimension
        const anaNode = ana.anaNode as any;
        n = anaNode.subNodes.getLength();

        if (removeFromLast) {
            for (i = 0; i < n; i++) {
                sn = anaNode.subNodes.get(i);
                sumOfMin += me._getMinDim(sn, ana);

                if (startRemIndex === -1 && sumOfMin > newSum) { startRemIndex = i; }
            }
        }
        else { // reversed
            for (i = n - 1; i >= 0; i--) { 
                sn = anaNode.subNodes.get(i);
                sumOfMin += me._getMinDim(sn, ana);

                if (startRemIndex === -1 && sumOfMin > newSum) { startRemIndex = i; }
            }
        }            

        // Determine if newSum < sumOfMin
        if (startRemIndex >= 0) { // Removing required
            me._removeSeparators(ana, startRemIndex, removeFromLast, keepEndContent);
        }
    }

    // Remove node
    private _removeNode(ana: Readonly<DimAnalyzer>, nearbyIdx: number, removedIdx: number) {
        const me = this;

        const anaNode = ana.anaNode as any;

        // The nearby sub-node
        var nearbySn = anaNode.subNodes.get(nearbyIdx);

        // removed separator
        var sep = anaNode.separators.removeAt(nearbyIdx);

        // Removed subnode
        var sn = anaNode.subNodes.removeAt(removedIdx);

        // Manage size of remaining zone if required
        if (anaNode.axis === "X_AXIS") {
            if (!sn.outerZone.hasXDimAuto && !nearbySn.outerZone.hasXDimAuto) // Adjacent zone and removed zone are fixed
                nearbySn.outerZone.xdim = sn.outerZone.xdim + nearbySn.outerZone.xdim + me.closet.getMainThickness();
            else if (!sn.outerZone.hasXDimAuto) { // Removed zone is fixed
                nearbySn.outerZone.xdim = sn.outerZone.xdim;
                nearbySn.outerZone.hasXDimAuto = false;
            }
            else // it becomes auto
                nearbySn.outerZone.hasXDimAuto = true;
        }
        else { // $ez.Y_AXIS
            if (!sn.outerZone.hasYDimAuto && !nearbySn.outerZone.hasYDimAuto) // Adjacent zone and removed zone are fixed
                nearbySn.outerZone.ydim = sn.outerZone.ydim + nearbySn.outerZone.ydim + me.closet.getMainThickness();
            else if (!sn.outerZone.hasYDimAuto) { // Removed zone is fixed
                nearbySn.outerZone.ydim = sn.outerZone.ydim;
                nearbySn.outerZone.hasYDimAuto = false;
            }
            else // it becomes auto
                nearbySn.outerZone.hasYDimAuto = true;
        }

        // Clear sub-node
        sn.removeSubNodes();
        sn.removeItems();

        sep.hide();
        sep.dispose();

        sn.hide();
        sn.dispose();
    }

    // Remove separators from specified node index. Use when dimension decrease.
    // analyzer: (DimAnalyzer) The dimension analyzer associate to dimension decreasing.
    // iRem : (Number) Index of node from start removing (iRem is included in removing).
    // removeFromLast: (Boolean) If true, nodes will be removed from last node (the usual way), otherwise they will be removed from first node.
    private _removeSeparators(analyzer: Readonly<DimAnalyzer>, iRem: number, removeFromLast: boolean, keepEndContent: boolean) {
        const me = this;
        var i, n, sn;
        const ana = analyzer;
        const anaNode = ana.anaNode as any;
        var lastNodeIdx = anaNode.subNodes.getLength() - 1;
        var copyBuffer = null;
        
        var root = (ana.anaNode.parent ? ana.anaNode.parent : ana.anaNode) as any;

        // To clear single module if its content does not respect dimension constraint
        if ((iRem === 0 && removeFromLast) || (iRem === lastNodeIdx && !removeFromLast)) { // first or last node is removed
            sn = anaNode.subNodes.get(0);

            var bnds = sn.computedBounds;
            if (bnds) { // Check if content can be preserved in node
                var contentTooLarge;

                if (analyzer.dimName === 'width')
                    contentTooLarge = sn.innerZone.xdim < bnds.w;
                else
                    contentTooLarge = sn.innerZone.ydim < bnds.y;

                if (contentTooLarge) {
                    
                    sn.removeSubNodes();
                    sn.removeItems();
                }
            }
        }
        else if (keepEndContent && ((iRem > 1 && removeFromLast) || (iRem < lastNodeIdx - 1 && !removeFromLast))) {
            var endIdx = removeFromLast ? lastNodeIdx : 0;
            var nodeToKeep = root.subNodes.get(endIdx);
            copyBuffer = me._ezRW.copy(nodeToKeep, false);
        }

        if (removeFromLast) {
            i = anaNode.subNodes.getLength() - 1; // Start from last node
            while (i >= iRem && i > 0) { // Do not delete first node
                var nearbySN = anaNode.subNodes.get(i - 1);
                if (nearbySN.parts) nearbySN.parts.removeConsolidators(); // Remove consolidator from new last node
                me._removeNode(ana, i - 1, i); 
                i--;
            }
        }
        else { // reversed
            i = 0; // Start from first node
            while (i <= iRem && i < lastNodeIdx) { // Do not delete last node
                me._removeNode(ana, 0, 0);
                i++;
            }
        }

        if (copyBuffer !== null) {
            var pasteIdx = removeFromLast ? iRem - 1 : 0;
            var pasteNode = root.subNodes.get(pasteIdx);
            pasteNode.empty();
            me._ezRW.paste(pasteNode, copyBuffer);
        }

        root.simplifyTree();
    }

    
}