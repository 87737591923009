﻿///// Init method for injection
function _init(dcies, $ez) {

    var $log = $ez.getLogger();

    /////// Class CNThickProvider
    // Brief : Responsible of panel thickness providing to CNode.
    // Constructor
    // thick : (Number) Thickess value for all type of parts.
    var CNThickProvider = function (thick) {
        var me = this;
        me.left = thick;
        me.right = thick;
        me.bottom = thick;
        me.top = thick;
        me.back = thick;
        me.front = thick;
        me.separator = thick;
        me.topBand = thick;
        me.bottomBand = thick;
        me.topBandMatModel = null;
        me.bottomBandMatModel = null;
    };
    // Methods
    (function (_P) {

        _P.setLeft = function (t) { this.left = t }
        _P.setRight = function (t) { this.right = t }
        _P.setBottom = function (t) { this.bottom = t }
        _P.setTop = function (t) { this.top = t }
        _P.setBack = function (t) { this.back = t }
        _P.setFront = function (t) { this.front = t }
        _P.setSeparator = function (t) { this.separator = t }
        _P.setTopBand = function (t) { this.topBand = t }
        _P.setBottomBand = function (t) { this.bottomBand = t }
        _P.setTopBandMatModel = function (m) { this.topBandMatModel = m }
        _P.setBottomBandMatModel = function (m) { this.bottomBandMatModel = m }

        _P.getLeft = function () { return this.left }
        _P.getRight = function () { return this.right }
        _P.getBottom = function () { return this.bottom }
        _P.getTop = function () { return this.top }
        _P.getBack = function () { return this.back }
        _P.getFront = function () { return this.front }
        _P.getSeparator = function () { return this.separator }
        _P.getTopBand = function () { return this.topBand; }
        _P.getBottomBand = function () { return this.bottomBand; }
        _P.getTopBandMatModel = function () { return this.topBandMatModel; }
        _P.getBottomBandMatModel = function () { return this.bottomBandMatModel; }

    })(CNThickProvider.prototype);


    ////// ezThick service
    return {
        $name: 'ezThick',

        $new: function (thick) { return new CNThickProvider(thick); }
    };
}


////// EXPORT
export default {
    $init: _init
}