﻿import ezAccessory from "./ezAccessory";

///// Init method for injection
function _init(dcies, $ez, ezObs, ez3D) {

    var $log = $ez.getLogger();

    /////// Class GenericIssue, implement IIssue { status: 'WARN' | 'ERR', msg: (String) }
    // Brief : Class to define a generic not fixable issue.
    // Constructor
    var GenericIssue = function (status, msg) {
        var me = this;
        me.status = status;
        me.msg = msg;
    };

    /////// Class Validator
    // Brief : Class to validate closet conception
    // Constructor
    var Validator = function (bestHandleHeight) {
        var me = this;
        me.closetModel = null;
        me.collisionTol = 1.e-03;
        //me.fixableIssues = []; // Issues that can be fixed.
        me.issues = []; // Not fixable issue.
        me.swdDoorCount = 0;
        me.userIssues = {
            moduleOffWidth: {WARN: 0, ERR: 0}, // Count of module off-width issue
            backOffWidth: { WARN: 0, ERR: 0 }, // Count of back off-width issue
            bottomOffWidth: { WARN: 0, ERR: 0 }, // Count of bottom off-width issue
            topOffWidth: { WARN: 0, ERR: 0 }, // Count of top off-width issue
            consolidatorWithContent: { WARN: 0, ERR: 0 }, // Count of consolidator with content conflict
            accessoryNotFitted: { WARN: 0, ERR: 0 }, // Count of accessories not fitted to a zone
            accessoryCollision: { WARN: 0, ERR: 0 }, // Count of accessories with collision
            accessoryDoorCollision: { WARN: 0, ERR: 0 },
            topHingeDist: { WARN: 0, ERR: 0 },
            doorOffWidth: { WARN: 0, ERR: 0},
            doorOffHeight: { WARN: 0, ERR: 0},
            doorWidthTooSmall: { WARN: 0, ERR: 0},
            doorHeightTooSmall: { WARN: 0, ERR: 0}
        }
        me._3DInvalidateds = {}; // Map of item that need a 3D computation at the end of validation
        me._bestHandleHeight = bestHandleHeight;
    };
    // Methods
    (function (_P) {

        _P._invalidate3D = function (item) {
            if (item.CUID === undefined) $ez.THROW("Invalidate 3D of item required the CUID property.");
            if (item.compute3D === undefined) $ez.THROW("Invalidate 3D of item required the compute3D() method.");
            this._3DInvalidateds["ID" + item.CUID] = item;
        }

        // Set closet model
        _P.setClosetModel = function (m) { this.closetModel = m; }

        // Returns true if segment1 overlap segment2
        // u1: (Number) the coordinate of center of segment1
        // dim1: (Number) the dimension of segment1
        // u2: (Number) the coordinate of center of segment2
        // dim2: (Number) the dimension of segment2
        _P.overlap = function (u1, dim1, u2, dim2) {
            var tol = this.collisionTol;
            var delta_u = Math.abs(u1 - u2);
            var delta_dim = (dim1 / 2) + (dim2 / 2);
            return delta_u - tol < delta_dim + tol;
        }

        // Return true if box1 has collision with box2
        // box1: (Box) The first box. Box is defined by its position and dimensions : {x: 0, y: 0: z: 0, xdim: 0, ydim: 0, zdim: 0 }.
        // box2: (Box) The second box.
        _P.hasCollision = function (box1, box2) {
            var me = this;
            return  me.overlap(box1.x, box1.xdim, box2.x, box2.xdim) &&
                    me.overlap(box1.y, box1.ydim, box2.y, box2.ydim) &&
                    me.overlap(box1.z, box1.zdim, box2.z, box2.zdim);
        }


        ////// New validation achitecture with full down exploration

        // Create exploration object, to give informations at several step of exploration (for example, are behind swing-door ?)
        _P._initExpInfos = function (upperExpInfos) {
            return { ancestorDoor: upperExpInfos ? upperExpInfos.ancestorDoor : null };
        }

        // 
        _P._processDoorAndAccessoryConflict = function (swd, accZon, acc, hingeGrps, tipons) {
            var me = this;
            if (!acc.canCollideDoor()) return;

            var swdModel = swd.getModel();
            var accModel = acc.getModel();
            var swdZone = swd.owner.innerZone;
            var hasDoorColl = false;

            // Check hinges
            if (hingeGrps.length > 0) {

                // The Y offset of hinge if collide an accessory
                var YHing_offs = accModel.positioning.hingeCollisionOffs !== undefined ? accModel.positioning.hingeCollisionOffs : 0;

                // Make hinge collision box
                var accBox = acc.getDoorCollisionBox();

                // For each hinge group
                for (var iHingGrp = 0; iHingGrp < hingeGrps.length; iHingGrp++) {
                    var hingGrp = hingeGrps[iHingGrp];
                    var ruleModel = hingGrp.ruleModel;

                    // The Y coord limit for the hinge moving (hinge.y cannot be under this value to stay in zone) 
                    var hingH = hingGrp.ruleModel.HingeHeight;
                    var YHing_limit = accZon.y - accZon.ydim / 2 + hingH / 2 + swdModel.SepCollOffs;

                    var hingLocs = hingGrp.getPositions();
                    for (var iHing = 0; iHing < hingLocs.length; iHing++) {
                        var hingPos = hingLocs[iHing];
                        // make hinge box
                        var hingBox = {
                            x: hingPos.x,
                            y: hingPos.y,
                            z: hingPos.z,
                            xdim: hingGrp.ruleModel.HingeWidth,
                            ydim: hingGrp.ruleModel.HingeHeight,
                            zdim: hingGrp.ruleModel.HingeDepth
                        }

                        if (me.hasCollision(accBox, hingBox)) {

                            // The new position of hinge
                            var newY = accBox.y + (accBox.ydim / 2) - (hingH / 2) - YHing_offs;

                            // Try to move the hinge
                            if (newY >= YHing_limit && YHing_offs > 0) { // Offset must be greater than 0
                                hingGrp.setYPos(iHing, newY);
                                me._invalidate3D(swd);

                                // Distance of highest hinge from bottom of door-zone
                                // var ratio = ruleModel.AllowedDoorHeightRatio / 100;
                                // var allowedDist = Math.round(swdZone.ydim * ratio * 100) / 100; // round to 1.e-02
                                var newDistFromBottom = (newY + hingH / 2) - (swdZone.y - swdZone.ydim / 2);
                                //var newDistFromTop = (swdZone.y + swdZone.ydim / 2) - (newY + hingH / 2);
                                if (iHing === hingLocs.length - 1 && newDistFromBottom < ruleModel.MinDistFromBottom) {
                                    hingGrp.setIssueStatus(iHing, "ERR");
                                    acc.setIssueStatus('ERR');
                                    me.userIssues.topHingeDist['ERR'] += 1;
                                }
                            }
                            else {
                                hingGrp.setIssueStatus(iHing, "ERR");
                                acc.setIssueStatus('ERR');
                                var msg = "L'accessoire '" + acc.getModel().Title + "' est en conflit avec une charnière.";
                                me.issues.push(new GenericIssue('ERR', msg));
                                hasDoorColl = true;
                            }
                        }
                    } // End of for hingLocs
                } // End of for hingeGrps

            } // End if hingeGrps

            // Check tip-on
            if (tipons) {

                if (me.hasCollision(accBox, tipons.getBndBox())) {
 
                    var YTipon_offs = accModel.positioning.tiponCollisionOffs !== undefined ? accModel.positioning.tiponCollisionOffs : 0; // The Y offset of tip-ons if collide an accessory
                    var tiponH = tipons.ruleModel.TipOnHeight;
                    var YTipon_limit = accZon.y - accZon.ydim / 2 + tiponH / 2 + swdModel.SepCollOffs;

                    // The new position of hinge
                    var newY = accBox.y + (accBox.ydim / 2) - (tiponH / 2) - YTipon_offs;

                    // Try to move the hinge
                    if (newY >= YTipon_limit && YTipon_offs > 0 && swdModel.DoorType < 3) { // Offset must be greater than 0, and must be a single door
                        tipons.setYPos(newY);
                        me._invalidate3D(swd);
                    }
                    else {
                        tipons.setIssueStatus("ERR");
                        acc.setIssueStatus('ERR');
                        var msg = "L'accessoire '" + acc.getModel().Title + "' est en conflit avec un tip-on.";
                        me.issues.push(new GenericIssue('ERR', msg));
                        hasDoorColl = true;
                    }
                }

            } // End if tipons

            if (hasDoorColl) { // To count the accessory only
                me.userIssues.accessoryDoorCollision['ERR'] += 1;
            }

        }

        // Check a node according exploration info
        _P.checkNode = function (node, expInfos) {
            var me = this;

            // Check swing-door ?
            if (expInfos.ancestorDoor) {

                var swd = expInfos.ancestorDoor;
                var swModel = swd.getModel();
                var hingeGrps = swd.getHingeGroups();
                var tipOns = swd.getTipOns();

                if (hingeGrps.length > 0 || tipOns) { // Has hinges or tip-ons

                    if (node.separators && node.separators.getLength() > 0) { // Is behind door and node has separators

                        var n = node.separators.getLength();
                        for (var i = 0; i < n; i++) { // For each separator behind door
                            var sep = node.separators.get(i);
                            
                            //#region Check collision between separator and hinges
                            for (var j = 0; j < hingeGrps.length; j++) {
                                var hingeGrp = hingeGrps[j];
                                var posOfHinges = hingeGrp.getPositions();
                                var hingeBox = swd.getHingeDims(); // Get { xdim, ydim, zdim }
                                for (var k = 0; k < posOfHinges.length; k++) {
                                    hingeBox.x = posOfHinges[k].x;
                                    hingeBox.y = posOfHinges[k].y;
                                    hingeBox.z = posOfHinges[k].z;

                                    if (me.hasCollision(hingeBox, sep)) { // Has Collision with separator
                                        // var msg = "Porte '" + swModel.Title + "' avec charnière en collision avec le séparateur '" + sep.title + "'.";
                                        // var issue = new GenericIssue('WARN', msg);
                                        // me.issues.push(issue);
                                        // Fix the position
                                        hingeGrp.setYPos(k, sep.y - (sep.ydim / 2) - (hingeBox.ydim / 2) - hingeGrp.heightOffs - swModel.SepCollOffs);
                                        me._invalidate3D(expInfos.ancestorDoor);
                                    }
                                }
                            }
                            //#endregion

                            //#region Check collision between separator and tip-ons
                            if (tipOns) {
                                var tipOnsBox = tipOns.getBndBox();
                                if ((node.axis === $ez.Y_AXIS && swModel.DoorType < 3) || // Single door
                                    (node.axis === $ez.X_AXIS && swModel.DoorType === 3)) { // Double doors
                                    if (me.hasCollision(tipOnsBox, sep)) { // Collision found
                                        // var msg = "Porte '" + swModel.Title + "' avec tip-on en collision avec le panneau '" + sep.title + "'.";
                                        // var issue = new GenericIssue('WARN', msg);
                                        // me.issues.push(issue);
                                        if (swModel.DoorType < 3) // Move the HSep
                                            tipOns.setYPos(sep.y - (sep.ydim / 2) - (tipOns.ruleModel.TipOnHeight / 2) - swModel.SepCollOffs);
                                        else { // Move the VSep
                                            var x, limit;
                                            var posZon = tipOns.getPosZone();

                                            x = sep.x - (sep.xdim / 2) - (tipOns.ruleModel.TipOnHeight / 2);
                                            limit = tipOns.getXPos(posZon, 0);
                                            if (x < limit)
                                                tipOns.setXPos(x, 0);
                                            else
                                                tipOns.setXPos(limit, 0);

                                            x = sep.x + (sep.xdim / 2) + (tipOns.ruleModel.TipOnHeight / 2);
                                            limit = tipOns.getXPos(posZon, 1);
                                            if (x > limit)
                                                tipOns.setXPos(x, 1);
                                            else
                                                tipOns.setXPos(limit, 1);
                                        }
                                        me._invalidate3D(expInfos.ancestorDoor);
                                    }
                                }
                            }
                            //#endregion

                        } // End of separators

                    } 
                    else if (node.parts && node.parts.frontConsolidator) { // Has consolidator

                        //#region check collision between articles and front consolidator if any
                        var collisionFound = false;
                        var consBox = node.parts.frontConsolidator.getBox()

                        // Check collision with tip-ons
                        if (tipOns) {
                            var tipOnsBox = tipOns.getBndBox();
                            collisionFound = me.hasCollision(tipOnsBox, consBox);
                        }

                        // Try to check collision with hinges if no collision found yet
                        if (!collisionFound) {
                            for (var iHG = 0; iHG < hingeGrps.length && !collisionFound; iHG++) {
                                var hingeGrp = hingeGrps[iHG];
                                var posOfHinges = hingeGrp.getPositions();
                                var hingeBox = swd.getHingeDims(); // Get { xdim, ydim, zdim }
                                for (var iHP = 0; iHP < posOfHinges.length && !collisionFound; iHP++) {
                                    hingeBox.x = posOfHinges[iHP].x;
                                    hingeBox.y = posOfHinges[iHP].y;
                                    hingeBox.z = posOfHinges[iHP].z;

                                    collisionFound = me.hasCollision(hingeBox, consBox);
                                }
                            }
                        }

                        if (collisionFound) { // Move the consolidator
                            var closetModel = node.closet.getClosetModel();
                            var cons = node.parts.frontConsolidator;
                            cons.setZPos(cons.z - closetModel.Consolidator.HingeCollisionOffs);
                            me._invalidate3D(cons);
                        }
                        //#endregion

                    }

                    //#region Check collision between articles and accessories
                    if (node.hasItems()) {
                        var itemCount = node.items.getLength();
                        for (var itemIdx = 0; itemIdx < itemCount; itemIdx++) {
                            var item = node.items.get(itemIdx);
                            if (item.getEzType() === "Accessory")
                                me._processDoorAndAccessoryConflict(swd, node.innerZone, item, hingeGrps, tipOns);
                        } // End of for items
                    }
                    //#endregion

                } // End of hingeGrp || tipOns

            } // End of swing-door

            // Check if has hangingbar with consolidator
            if (node.parts && node.parts.frontConsolidator) { 
                var hgb = node.findItem(function (item) { return item.getEzType() === "HangingBar" });
                if (hgb !== null && node.parts.frontConsolidator.ydim > hgb.model.TopOffset) {
                    hgb.setYOffs(node.parts.frontConsolidator.ydim); // With height of consolidator (not confirmed yet ...)
                    if (ez3D.has3D()) hgb.compute3D();
                }
            }

        }

        // Set best Y position of tip-on in case of double-door
        _P.setBestTipOnY = function (tipOns, doorNode, bestHandleHeight) {
            var me = this;
            var n, i,
                bestNode = null, bestEpsillon = 999999, bestSide = null,
                epsillon,
                sn,
                Y = null, y, gy;

            // To the best zone, we have to seach in the good node. doorNode can be X->Y
            var parsedNode = doorNode.subNodes.getLength() === 1 ? doorNode.subNodes.get(0) : doorNode;

            n = parsedNode.subNodes.getLength();
            for (i = 0; i < n; i++) { // For each sub-nodes behind door
                sn = parsedNode.subNodes.get(i);
                if (!sn.hasItems('DrawerGroup')) {

                    // Does tip-on on zone bottom is best position ?
                    /*y = tipOns.getYPos(sn.innerZone, 'bottom');
                    gy = (parsedNode.getCloset().getHeight() / 2) + y;
                    epsillon = Math.abs(gy - bestHandleHeight);
                    if (epsillon < bestEpsillon) {
                        Y = y;
                        bestEpsillon = epsillon;
                        bestNode = sn;
                        bestSide = 'bottom';
                    }*/

                    // Does tip-on on top of zone is best position ?
                    y = tipOns.getYPos(sn.innerZone, 'top');
                    gy = (parsedNode.getCloset().getHeight() / 2) + y;
                    epsillon = Math.abs(gy - bestHandleHeight);
                    if (epsillon < bestEpsillon) {
                        Y = y;
                        bestEpsillon = epsillon;
                        bestNode = sn;
                        bestSide = 'top';
                    }
                }
                
            }

            if (Y !== null) { // Position must be setted
                // Fix tip-on Y position
                tipOns.setYPos(Y);
                me._invalidate3D(tipOns);
            }
        }

        // Check all tree from node.
        _P.checkTree = function (node, upperExpInfos) {
            var me = this;
            var e = 0.01; // cm precision
            var expInfos = me._initExpInfos(upperExpInfos);

            // Determine the exploration infos from current node
            var isAncestorDoor = false;
            if (node.parts) { // Current node has parts

                // Swing door ?
                if (node.parts.front && node.schOpts.frontDef && node.schOpts.frontDef.EZT === 'SWD') {
                    var swd = node.parts.front;
                    expInfos.ancestorDoor = swd;
                    isAncestorDoor = true;
                    
                    me.swdDoorCount++; // Count of doors

                    // To be sure to have refreshed hinge positions (after separator removal for example)
                    var hingeGrps = swd.getHingeGroups();
                    var tipOns = swd.getTipOns();
                    if (hingeGrps.length > 0) {
                        for (var i = 0; i < hingeGrps.length; i++)
                            hingeGrps[i].update("OK"); // With issue status initialization
                    }
                    if (tipOns) {
                        tipOns.update("OK"); // With issue status initialization
                        if (swd.getModel().DoorType === 3) 
                            me.setBestTipOnY(tipOns, node, me._bestHandleHeight);
                    }

                    // Check door dimensions
                    var swdXDim = 0, swdYDim = 0;

                    for (var i = 0; i < swd.getPanels().length; i++) {
                        var doorPanel = swd.getPanels()[i];
                        swdXDim += doorPanel.xdim;
                        if (swdYDim < doorPanel.ydim) swdYDim = doorPanel.ydim;
                    }

                    if (node.innerZone.xdim - swdXDim > e) {
                        var issue = new GenericIssue('ERR', "La porte '" + swd.getModel().Title + "' est dans une zone trop large.");
                        me.issues.push(issue);
                        me.userIssues.doorOffWidth.ERR += 1;
                    }
                    else if (swdXDim < swd.getModel().MinWidth) {
                        var issue = new GenericIssue('ERR', "La porte '" + swd.getModel().Title + "' est dans une zone petite en largeur.");
                        me.issues.push(issue);
                        me.userIssues.doorWidthTooSmall.ERR += 1;
                    }

                    if (node.innerZone.ydim - swdYDim > e) {
                        var issue = new GenericIssue('ERR', "La porte '" + swd.getModel().Title + "' est dans une zone trop haute.");
                        me.issues.push(issue);
                        me.userIssues.doorOffHeight.ERR += 1;
                    }
                    else if (swdYDim < swd.getModel().MinHeight) { //
                        var issue = new GenericIssue('ERR', "La porte '" + swd.getModel().Title + "' est dans une zone petite en hauteur.");
                        me.issues.push(issue);
                        me.userIssues.doorHeightTooSmall.ERR += 1;
                    }
                }

                var issueStatus;

                // Module
                issueStatus = node.getIssues().getModuleOffWidth();
                if (issueStatus !== 'OK') {
                    var issue = new GenericIssue(issueStatus, "Le module est trop large.");
                    me.issues.push(issue);
                    me.userIssues.moduleOffWidth[issueStatus] += 1;
                }

                // Back-panel
                if (node.getIssues().hasBackOffWidth() && node.parts && node.parts.back) {
                    var issue = new GenericIssue('ERR', "Le fond '" + node.parts.back.getMaterialModel().Title + "' est dans une zone trop large.");
                    me.issues.push(issue);
                    me.userIssues.backOffWidth['ERR'] += 1;
                }

                // Consolidator
                if (node.getIssues().hasConsolidatorWithContent()) {
                    var issue = new GenericIssue('ERR', "Raidisseur en conflit avec un tiroir.");
                    me.issues.push(issue);
                    me.userIssues.consolidatorWithContent['ERR'] += 1;
                }

            }
            
            // Check current node
            me.checkNode(node, expInfos);

            // Check items
            me._checkItems(node, expInfos);

            // Check sub-node : go deeper in CTree
            var n = node.subNodes.getLength();
            for (var i = 0; i < n; i++)
                me.checkTree(node.subNodes.get(i), expInfos);

            // Restore exploration infos
            if (isAncestorDoor) expInfos.ancestorDoor = null;
        }

        // Check items contained in node
        _P._checkItems = function (node, expInfos) {
            var me = this;

            if (node.hasItems()) {
                var itemCount = node.items.getLength();
                for (var itemIdx = 0; itemIdx < itemCount; itemIdx++) {
                    var item = node.items.get(itemIdx);

                    switch (item.getEzType()) {
                        case "DrawerGroup": // Check drawer behind door
                            if (expInfos.ancestorDoor && !expInfos.ancestorDoor.getModel().IsOverlay) {
                                var issue = new GenericIssue('ERR', "Détection de tiroir derrière une porte encastrée.");
                                me.issues.push(issue);
                            }
                            break;

                        case "Accessory":
                            
                            if (item.zoneIsTooSmall(node.innerZone) || item.zoneIsTooLarge(node.innerZone)) {
                                item.setIssueStatus('ERR');
                                var issue = new GenericIssue('ERR', "Accessoire ne respectant pas les dimensions de la zone.");
                                me.issues.push(issue);
                                me.userIssues.accessoryNotFitted['ERR'] += 1;
                            }

                            break;
                    }

                    // To find collisions with other accessories of the same zone
                    var collHisto = {};
                    for (var itemIdx2 = 0; itemIdx2 < itemCount; itemIdx2++) {
                        if (itemIdx !== itemIdx2 && !collHisto["coll" + itemIdx + "_" + itemIdx2] && !collHisto["coll" + itemIdx2 + "_" + itemIdx]) { // To avoid collision with itself
                            var item2 = node.items.get(itemIdx2);
                            if (me.hasCollision(item.getBox(), item2.getBox())) {
                                collHisto["coll" + itemIdx + "_" + itemIdx2] = true;
                                collHisto["coll" + itemIdx2 + "_" + itemIdx] = true;
                                if (item.setIssueStatus) item.setIssueStatus('ERR');
                                if (item2.setIssueStatus) item2.setIssueStatus('ERR');
                                me.userIssues.accessoryCollision['ERR'] += 1;
                            }
                        }
                    } 

                }
            }
        }

        ////// End of New validation achitecture

        // Get all issues. Call check() method before.
        _P.getIssues = function () {
            return this.issues;
        }
        
        // Check tree and fix in single operation
        _P.checkAndFix = function (node) {
            this._3DInvalidateds = {};
            // Initalize cout of user issuses
            this.userIssues.moduleOffWidth.WARN = 0; this.userIssues.moduleOffWidth.ERR = 0;
            this.userIssues.backOffWidth.WARN = 0; this.userIssues.backOffWidth.ERR = 0;
            this.userIssues.bottomOffWidth.WARN = 0; this.userIssues.bottomOffWidth.ERR = 0;
            this.userIssues.topOffWidth.WARN = 0; this.userIssues.topOffWidth.ERR = 0;
            this.userIssues.consolidatorWithContent.WARN = 0; this.userIssues.consolidatorWithContent.ERR = 0;
            this.userIssues.accessoryNotFitted.WARN = 0; this.userIssues.accessoryNotFitted.ERR = 0;
            this.userIssues.accessoryCollision.WARN = 0; this.userIssues.accessoryCollision.ERR = 0;
            this.userIssues.accessoryDoorCollision.WARN = 0; this.userIssues.accessoryDoorCollision.ERR = 0;
            this.userIssues.topHingeDist.WARN = 0; this.userIssues.topHingeDist.ERR = 0;
            this.userIssues.doorOffWidth.WARN = 0; this.userIssues.doorOffWidth.ERR = 0;
            this.userIssues.doorOffHeight.WARN = 0; this.userIssues.doorOffHeight.ERR = 0;
            this.userIssues.doorWidthTooSmall.WARN = 0; this.userIssues.doorWidthTooSmall.ERR = 0;
            this.userIssues.doorHeightTooSmall.WARN = 0; this.userIssues.doorHeightTooSmall.ERR = 0;
            
            this.swdDoorCount = 0;
            this.issues = [];

            this.checkTree(node, this._initExpInfos());
            //this.fix();
            if (ez3D.has3D()) {
                for (var key in this._3DInvalidateds)
                    this._3DInvalidateds[key].compute3D();
            }

            // make issue messages for user
            var userMsgs = { warns: [], errs: [] };

            if (this.userIssues.moduleOffWidth.WARN > 0)
                userMsgs.warns.push(node.getIssues().getOffWidthMsg('WARN', this.userIssues.moduleOffWidth.WARN));

            if (this.userIssues.moduleOffWidth.ERR > 0)
                userMsgs.errs.push(node.getIssues().getOffWidthMsg('ERR', this.userIssues.moduleOffWidth.ERR));

            if (this.userIssues.backOffWidth.ERR > 0)
                userMsgs.errs.push(node.getIssues().getOffWidthBackMsg('ERR', this.userIssues.backOffWidth.ERR));

            if (this.userIssues.consolidatorWithContent.ERR > 0)
                userMsgs.errs.push(node.getIssues().getConsolidatorWithContentMsg('ERR', this.userIssues.consolidatorWithContent.ERR));

            if (this.userIssues.accessoryNotFitted.ERR > 0) {
                var accErrCount = this.userIssues.accessoryNotFitted.ERR;
                userMsgs.errs.push(accErrCount === 1 ? "1 accessoire ne respecte pas les dimensions de la zone." : accErrCount + " accessoires ne respectent pas les dimensions de la zone.");
            }

            if (this.userIssues.accessoryCollision.ERR > 0) {
                var accErrCount = this.userIssues.accessoryCollision.ERR;
                userMsgs.errs.push(accErrCount + " accessoires sont en collision.");
            }

            if (this.userIssues.accessoryDoorCollision.ERR > 0) {
                var accErrCount = this.userIssues.accessoryDoorCollision.ERR;
                if (accErrCount === 1)
                    userMsgs.errs.push("1 accessoire ne peut pas coulisser à cause de la porte.");
                else
                    userMsgs.errs.push(accErrCount + " accessoires ne peuvent pas coulisser à cause de la porte.");
            }

            if (this.userIssues.topHingeDist.ERR > 0) {
                var accErrCount = this.userIssues.topHingeDist.ERR;
                if (accErrCount === 1)
                    userMsgs.errs.push("1 charnière est trop éloignée du haut de la porte.");
                else
                    userMsgs.errs.push(accErrCount + " charnières sont trop éloignées du haut des portes.");
            }

            var closetModel = node.getCloset().getClosetModel();
            if (closetModel.HasSwingDoorOverlay && this.swdDoorCount === 0) {
                userMsgs.warns.push("Votre meuble en applique sans porte aura une profondeur < à " + node.getCloset().getDepth() + " cm");
                this.issues.push(new GenericIssue('WARN', "Meuble avec porte en applique sans porte. Profondeur réelle plus faible que celle désirée."));
            }

            if (this.userIssues.doorOffWidth.ERR > 0) {
                if (this.userIssues.doorOffWidth.ERR === 1)
                    userMsgs.errs.push(this.userIssues.doorOffWidth.ERR + " porte est dans une zone trop large.");
                else 
                    userMsgs.errs.push(this.userIssues.doorOffWidth.ERR + " portes sont dans une zone trop large.");
            }

            if (this.userIssues.doorOffHeight.ERR > 0) {
                if (this.userIssues.doorOffHeight.ERR === 1)
                    userMsgs.errs.push(this.userIssues.doorOffHeight.ERR + " porte est dans une zone trop haute.");
                else 
                    userMsgs.errs.push(this.userIssues.doorOffHeight.ERR + " portes sont dans une zone trop haute.");
            }

            if (this.userIssues.doorWidthTooSmall.ERR > 0) {
                if (this.userIssues.doorWidthTooSmall.ERR === 1)
                    userMsgs.errs.push(this.userIssues.doorWidthTooSmall.ERR + " porte est trop petite en largeur.");
                else 
                    userMsgs.errs.push(this.userIssues.doorWidthTooSmall.ERR + " portes sont trop petites en largeur.");
            }

            if (this.userIssues.doorHeightTooSmall.ERR > 0) {
                if (this.userIssues.doorHeightTooSmall.ERR === 1)
                    userMsgs.errs.push(this.userIssues.doorHeightTooSmall.ERR + " porte est trop petite en hauteur.");
                else 
                    userMsgs.errs.push(this.userIssues.doorHeightTooSmall.ERR + " portes sont trop petites en hauteur.");
            }
            
            ezObs.run('doUserIssueUpdate', userMsgs);
        }

    })(Validator.prototype);

    ////// Singleton
    return {
        $name: 'ezValidator',

        // Create a Validator instance
        create: function (bestHandleHeight) { return new Validator(bestHandleHeight); }

    }
}


////// EXPORT
export default {
    $init: _init
}