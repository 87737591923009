﻿///// Init method for injection
function _init(dcies, $ez, ezCloset, ezMinMaxResolver, ezZoneHelper, ezCTreeHelper, ezGPos, ezRW) {

    var $log = $ez.getLogger();

    ///// Plan helper
    // Brief : Provide basic tools to manage plan
    var PlanHelper = {

        // Check if zone is own by closet
        checkZoneOwnByCloset: function (Z, C) {
            if (C !== Z.closet) $ez.THROW("Inconsistent zone detected for this plan closet.");
        },

        // Get magnetized node in shared corner configuration
        // Zref: (Zone) The reference zone. This is the zone selected in shared corner closet.
        // Cmagn: (Closet) The magnetized closet. This is the closet that share corner witn Zref
        // moduleMagnRank : (String) The magnetized module rank (i.e the rank of module in Cmagn that share corner with Zref : 'first' or 'last')
        getMagnetNode: function (Zref, Cmagn, moduleMagnRank) {
            var magnNode = Cmagn.root.subNodes.getLength() === 0 ? Cmagn.root : (Cmagn.root.subNodes.get(moduleMagnRank === 'first' ? 0 : Cmagn.root.subNodes.getLastIndex()));
            if (magnNode.subNodes.isEmpty()) { // First split of zone
                return magnNode;
            }
            else {
                var refIdx = Zref.relNode.getNodeIndex(); // Get index of reference zone
                return magnNode.subNodes.get(refIdx);
            }
        },

        // Convenient method to get Get magnetized inner-zone. See getMagnetNode() method.
        getMagnetZone: function (Zref, Cmagn, moduleMagnRank) {
            var magnNode = this.getMagnetNode(Zref, Cmagn, moduleMagnRank);
            return magnNode.innerZone;
        },

        // Return true if module belong to shared corner module
        isInSharedCorner: function (module, moduleRank) {
            if (moduleRank === 'first' && module.isFirst()) return true;
            if (moduleRank === 'last' && module.isLast()) return true;
            
            return false;
        },

        // Compute the closet bounds in single-closet environment
        computeClosetBounds: function (C) {

            var globalB = C.getGlobalBounds(); // Model, baseboard and sliding-door bounds

            var NB = C.root.getNodeBnds();

            var sch = C.schemaProvider.getSchema01();
            var thickProv = C.getThickProvider();

            if (sch.HasLeft) NB.width.min += thickProv.getLeft();
            if (sch.HasRight) NB.width.min += thickProv.getRight();
            if (sch.HasTop) NB.height.min += thickProv.getTop();
            if (sch.HasBottom) NB.height.min += thickProv.getBottom();


            if (globalB.width.min < NB.width.min) globalB.width.min = NB.width.min;
            if (globalB.height.min < NB.height.min) globalB.height.min = NB.height.min;
            if (globalB.depth.min < NB.depth.min) globalB.depth.min = NB.depth.min;

            if (globalB.width.max > NB.width.max) globalB.width.max = NB.width.max;
            if (globalB.height.max > NB.height.max) globalB.height.max = NB.height.max;
            if (globalB.depth.max > NB.depth.max) globalB.depth.max = NB.depth.max;

            var n = C.root.subNodes.getLength();
            if (n > 0) {
                var minDepth = ezMinMaxResolver.findMinDepth(C.root);

                var depthMin = minDepth + sch.BackMargin + sch.FrontMargin + C.model.FrontSeparatorMargin;
                var depthMax = globalB.depth.max;
                if (depthMin > globalB.depth.min) globalB.depth.min = depthMin;
                if (depthMax < globalB.depth.max) globalB.depth.max = depthMax;

                // find max padding (case of column with different heights )
                var maxTopPadding = 0;
                for (var i = 0; i < n; i++) {
                    var sn = C.root.subNodes.get(i);
                    if (sn.paddings.top > maxTopPadding)
                        maxTopPadding = sn.paddings.top;
                }
                globalB.height.min += maxTopPadding;
                
            }

            return globalB;
        }

    };



    ///// IClosetDriver
    // setDepth(dim)
    // computePos(dim)
    // canAddSeparator(Z, sepOrient)
    // addSeparator(Z, sepOrient)
    // canRemoveSeparator(parentNode)
    // removeSeparator(Z, sepSide)
    // setZoneHeight(Z, dim)
    // getZoneBounds(node)
    // hasReversedPattern()
    // patternKeepEndContent()
    // canAddDrawer(Z)
    // canAddSwingDoor(Z)
    // setColumnHeight(Z)
    // getWidthPaddings()
    // getPartMargins(node)
    // canSetBackPanel(Z)
    // canHaveBackPanel(node)
    // hasBack(node)
    // isSharedModule(node)

    ///// MainClosetDriver -> IClosetDriver
    // Constructor
    var MainClosetDriver = function (P, i, model) {
        var me = this;
        me.plan = P;
        me.idx = i; // Index of main closet in Plan._mains
        me.model = model;
        me.closet = null;
    };
    // Methods
    (function (_P) {

        // Return true if module is in right shared corner
        _P._isRightSharedModule = function (module) { return this.plan.hasRightSharedCorner() && PlanHelper.isInSharedCorner(module, 'last'); }

        // Return true if module is in left shared corner
        _P._isLeftSharedModule = function (module) { return this.plan.hasLeftSharedCorner() && PlanHelper.isInSharedCorner(module, 'first'); }

        // Compute position of this closet
        _P.computePos = function () { }

        // Set depth of this closet. Can impact other closets dimensions
        _P.setDepth = function (dim) {
            var me = this;

            var deltaDim = dim - me.closet.getDepth();
            me.closet.setDepth(dim);

            var R = me.plan.getRightCloset();
            if (R) {
                if (me.plan.hasRightSharedCorner()) {
                    var module0 = ezCTreeHelper.getFirstModule(R);
                    if (module0)
                        module0.outerZone.xdim += deltaDim;
                }
                else
                    me.plan.setClosetWidth(R, R.getWidth() - deltaDim); // When main-closet depth increase, right width decrease
            }

            var L = me.plan.getLeftCloset();
            if (L) {
                if (me.plan.hasLeftSharedCorner()) {
                    var module0 = ezCTreeHelper.getLastModule(L);
                    if (module0)
                        module0.outerZone.xdim += deltaDim;
                }
                else
                    me.plan.setClosetWidth(L, L.getWidth() - deltaDim); // When main-closet depth increase, right width decrease
            }
        }

        _P.canAddSeparator = function (Z, sepOrient) {
            var me = this;
            PlanHelper.checkZoneOwnByCloset(Z, me.closet);

            if (sepOrient === 'HORIZONTAL') return true;

            var module = ezCTreeHelper.getModule(Z.relNode);
            if (module.subNodes.getLength() === 0) return true;
            if (me._isRightSharedModule(module) || me._isLeftSharedModule(module)) return false;

            return true;
        }

        // Add separator zone with the specified sepOrient. Try to add separator in shared corner if any.
        // Returns: (Zone) The zone that can be selected.
        _P.addSeparator = function (Z, sepOrient) {
            var me = this;
            PlanHelper.checkZoneOwnByCloset(Z, me.closet);

            var result = ezZoneHelper.addSeparator(Z, sepOrient, true);
            if (result.hasTooShortDimErr) return result;

            var rightCloset = null, leftCloset = null;
            if (sepOrient === 'HORIZONTAL') {

                var module = ezCTreeHelper.getModule(Z.relNode);

                if (me._isRightSharedModule(module)) {
                    var magnZone = PlanHelper.getMagnetZone(Z, me.plan.getRightCloset(), 'first');
                    var magnResult = ezZoneHelper.addSeparator(magnZone, sepOrient, true);

                    if (magnResult.hasNotEmptyErr) {
                        remResult.hasNotEmptyErr = true;
                        return remResult;
                    }

                    rightCloset = me.plan.getRightCloset();
                    rightCloset.hide();
                    ezZoneHelper.addSeparator(magnZone, sepOrient, false, true);
                    
                }

                if (me._isLeftSharedModule(module)) {
                    var magnZone = PlanHelper.getMagnetZone(Z, me.plan.getLeftCloset(), 'last');
                    var magnResult = ezZoneHelper.addSeparator(magnZone, sepOrient, true);

                    if (magnResult.hasNotEmptyErr) {
                        remResult.hasNotEmptyErr = true;
                        return remResult;
                    }

                    leftCloset = me.plan.getLeftCloset();
                    leftCloset.hide();
                    ezZoneHelper.addSeparator(magnZone, sepOrient, false, true);
                }
            }

            result = ezZoneHelper.addSeparator(Z, sepOrient, false, true);

            // Update
            me.closet.hide();
            me.plan.update();
            me.closet.getSwingDoorAssoc().invalidate();
            me.closet.getSwingDoorAssoc().updateDoors();
            me.closet.getValidator().checkAndFix(me.closet.root);
            if (rightCloset) {
                rightCloset.getSwingDoorAssoc().invalidate();
                rightCloset.getSwingDoorAssoc().updateDoors();
                rightCloset.getValidator().checkAndFix(rightCloset.root);
            }
            if (leftCloset) {
                leftCloset.getSwingDoorAssoc().invalidate();
                leftCloset.getSwingDoorAssoc().updateDoors();
                leftCloset.getValidator().checkAndFix(leftCloset.root);
            }
            me.plan.display();

            /// TEST : write module desc

            // Copy test
            /*var module0 = ezCTreeHelper.getModule(Z.relNode);
            var module1 = module0.parent.subNodes.get(1);

            ezRW.copy(module0);
            module1.hide();
            module1.empty();
            ezRW.paste(module1);
            module1.update();
            module1.display();*/

            /// TEST

            return result;
        }

        // Return true if can remove separator
        _P.canRemoveSeparator = function (Z, side) {
            var me = this;
            var remNode = /*Z.relNode.parentOfST ||*/ Z.relNode;
            var remNodeParent = remNode.parent;

            if (!remNodeParent.isRoot()) return true;

            if (me.plan.hasLeftSharedCorner() && me.plan.hasRightSharedCorner()) {
                if (remNodeParent.subNodes.getLength() <= 2) return false; // min module count
            }


            var iRem = remNodeParent.subNodes.indexOf(remNode);

            if (me.plan.hasLeftSharedCorner() && side === 'right' && iRem === 0) {
                if (!remNodeParent.subNodes.get(iRem + 1).isEmpty()) return false;
            }

            if (me.plan.hasRightSharedCorner() && side === 'left' && iRem === remNodeParent.subNodes.getLastIndex()) {
                if (!remNodeParent.subNodes.get(iRem - 1).isEmpty()) return false;
            }

            return true;
        }

        // Remove separator from zone according to the specified separator side. Try to remove separator from shared corner if any.
        _P.removeSeparator = function (Z, sepSide) {
            var me = this;
            PlanHelper.checkZoneOwnByCloset(Z, me.closet);

            var remResult = ezZoneHelper.removeSeparator(Z, sepSide, true);
            if (remResult.hasNotEmptyErr) return remResult;

            var rightCloset = null, leftCloset = null;
            if (sepSide === 'bottom' || sepSide === 'top') {

                var module = ezCTreeHelper.getModule(Z.relNode);
                
                if (me._isRightSharedModule(module)) { // if has right shared corner like AGEM angle closet 
                    var magnZone = PlanHelper.getMagnetZone(Z, me.plan.getRightCloset(), 'first');
                    var magnRemResult = ezZoneHelper.removeSeparator(magnZone, sepSide, true);
                    if (magnRemResult.hasNotEmptyErr) {
                        remResult.hasNotEmptyErr = true;
                        return remResult;
                    }

                    rightCloset = me.plan.getRightCloset();
                    rightCloset.hide();
                    ezZoneHelper.removeSeparator(magnZone, sepSide, false, true);
                }

                if (me._isLeftSharedModule(module)) { // if has left shared corner like AGEM angle closet 
                    var magnZone = PlanHelper.getMagnetZone(Z, me.plan.getLeftCloset(), 'last');
                    var magnRemResult = ezZoneHelper.removeSeparator(magnZone, sepSide, true);
                    if (magnRemResult.hasNotEmptyErr) {
                        remResult.hasNotEmptyErr = true;
                        return remResult;
                    }

                    leftCloset = me.plan.getLeftCloset();
                    leftCloset.hide();
                    ezZoneHelper.removeSeparator(magnZone, sepSide, false, true);
                }

            }

            remResult = ezZoneHelper.removeSeparator(Z, sepSide, false, true);

            // Update
            me.closet.hide();
            me.plan.update();
            me.closet.getSwingDoorAssoc().invalidate();
            me.closet.getSwingDoorAssoc().updateDoors();
            me.closet.getValidator().checkAndFix(me.closet.root);
            if (rightCloset) {
                rightCloset.getSwingDoorAssoc().invalidate();
                rightCloset.getSwingDoorAssoc().updateDoors();
                rightCloset.getValidator().checkAndFix(rightCloset.root);
            }
            if (leftCloset) {
                leftCloset.getSwingDoorAssoc().invalidate();
                leftCloset.getSwingDoorAssoc().updateDoors();
                leftCloset.getValidator().checkAndFix(leftCloset.root);
            }
            me.plan.display();

            return remResult;
        }

        // Set zone height. Try to set height in shared corner if any.
        // Returns: true if status of Z become fixed, false otherwise
        _P.setZoneHeight = function (Z, dim) {
            var me = this;
            PlanHelper.checkZoneOwnByCloset(Z, me.closet);

            var result = ezZoneHelper.setHeight(Z, dim);

            var module = ezCTreeHelper.getModule(Z.relNode);

            if (me._isRightSharedModule(module)) { // if has right shared corner like AGEM angle closet
                var magnZone = PlanHelper.getMagnetZone(Z, me.plan.getRightCloset(), 'first');
                ezZoneHelper.setHeight(magnZone, dim);
            }

            if (me._isLeftSharedModule(module)) { // if has left shared corner like AGEM angle closet
                var magnZone = PlanHelper.getMagnetZone(Z, me.plan.getLeftCloset(), 'last');
                ezZoneHelper.setHeight(magnZone, dim);
            }

            return result;
        }

        // Switch zone height status. Try to set height status in shared corner if any.
        _P.switchHeightStatus = function (Z) {
            var me = this;
            PlanHelper.checkZoneOwnByCloset(Z, me.closet);

            ezZoneHelper.switchDimStatus(Z, 'height');

            var module = ezCTreeHelper.getModule(Z.relNode);

            if (me._isRightSharedModule(module)) { // if has right shared corner like AGEM angle closet
                var magnZone = PlanHelper.getMagnetZone(Z, me.plan.getRightCloset(), 'first');
                ezZoneHelper.switchDimStatus(magnZone, 'height');
            }

            if (me._isLeftSharedModule(module)) { // if has left shared corner like AGEM angle closet
                var magnZone = PlanHelper.getMagnetZone(Z, me.plan.getLeftCloset(), 'last');
                ezZoneHelper.switchDimStatus(magnZone, 'height');
            }
        }

        // Get zone bounds according to specified relative zone node
        _P.getZoneBounds = function (node) {
            var me = this;
            var bnds = $ez.createBounds();
            bnds.width.min = me.model.MinZoneWidth;
            bnds.height.min = me.model.MinZoneHeight;

            return bnds;
        }

        // Compute the closet bounds
        _P.computeBounds = function () {
            var me = this;
            var closetBnds = PlanHelper.computeClosetBounds(me.closet);

            if (me.plan._right) {
                var R = me.plan._right.closet;

                if (me.plan.hasRightSharedCorner()) {
                    var module0 = ezCTreeHelper.getFirstModule(R);
                    // Warns ! module0.get1stSelectable() is not a good solution if vertical separator can be added in shared zone
                    //var deltaMax = module0 ? module0.get1stSelectable().getZoneBnds().width.max - module0.outerZone.xdim : R.getWidth();
                    var deltaMax = module0 ? ezMinMaxResolver.getMinMaxOfZone(module0.get1stSelectable()).width.max - module0.outerZone.xdim : R.getWidth();
                    var max = me.closet.getDepth() + deltaMax;
                    if (closetBnds.depth.max > max)
                        closetBnds.depth.max = max;
                }

                closetBnds.width.max -= R.getDepth();
            }

            if (me.plan._left) {
                var L = me.plan._left.closet;

                if (me.plan.hasLeftSharedCorner()) {
                    var module0 = ezCTreeHelper.getLastModule(L);
                    // Warns ! module0.get1stSelectable() is not a good solution if vertical separator can be added in shared zone
                    //var deltaMax = module0 ? module0.get1stSelectable().getZoneBnds().width.max - module0.outerZone.xdim : L.getWidth();
                    var deltaMax = module0 ? ezMinMaxResolver.getMinMaxOfZone(module0.get1stSelectable()).width.max - module0.outerZone.xdim : L.getWidth();
                    var max = me.closet.getDepth() + deltaMax;
                    if (closetBnds.depth.max > max)
                        closetBnds.depth.max = max;
                }

                closetBnds.width.max -= L.getDepth();
            }
            
            return closetBnds;
        }

        // Returns true if pattern application start from last module to first module.
        _P.hasReversedPattern = function () { return this.plan.hasRightSharedCorner(); }

        // Returns true if pattern application must keep end module content
        _P.patternKeepEndContent = function () { return this.plan.getRightCloset() && this.plan.getLeftCloset(); }

        // Return true if can add drawer in the specified zone
        _P.canAddDrawer = function (Z) {
            var me = this;
            PlanHelper.checkZoneOwnByCloset(Z, me.closet);

            var module = ezCTreeHelper.getModule(Z.relNode);
            return !me._isRightSharedModule(module) && !me._isLeftSharedModule(module);
        }

        // Return true if can add swing-door in the specified zone
        _P.canAddSwingDoor = function (Z) {
            return this.canAddDrawer(Z);
        }

        // Set column height
        _P.setColumnHeight = function (Z, h) {
            var me = this;
            PlanHelper.checkZoneOwnByCloset(Z, me.closet);

            var mainModule = ezCTreeHelper.getModule(Z.relNode);
            ezCTreeHelper.setModuleHeight(mainModule, h);

            if (me._isLeftSharedModule(mainModule)) {
                var magnNode = PlanHelper.getMagnetNode(Z, me.plan.getLeftCloset(), 'last');
                var module = ezCTreeHelper.getModule(magnNode);
                ezCTreeHelper.setModuleHeight(module, h);
            }

            if (me._isRightSharedModule(mainModule)) {
                var magnNode = PlanHelper.getMagnetNode(Z, me.plan.getRightCloset(), 'first');
                var module = ezCTreeHelper.getModule(magnNode);
                ezCTreeHelper.setModuleHeight(module, h);
            }
        }

        // Get left and right padding of closet
        // Returns : { left: (Numeric), right: (Numeric) }
        _P.getWidthPaddings = function () {
            return { left: this.model.LeftMargin, right: this.model.RightMargin };
        }

        // Get left and right margins of parts in corner case : Top, Bottom and HSeparator, according specified node
        // Returns: { Top: { left: (Number), right: (Number) }, HSeparator: { left: (Number), right: (Number) }, Bottom: { left: (Number), right: (Number) } } or null
        _P.getPartMargins = function (node) {
            var me = this;
            var model = me.closet.getClosetModel();

            var margs = {
                Top: { left: 0, right: 0 },
                HSeparator: { left: 0, right: 0 },
                Bottom: { left: 0, right: 0 }
            };

            var module = ezCTreeHelper.getModule(node);

            if (me._isLeftSharedModule(module) && me.plan.getLeftCloset()) {
                model = me.plan.getLeftCloset().getClosetModel();
                margs.Top.left = -model.FrontTopMargin;
                margs.HSeparator.left = -model.FrontShelfMargin;
                margs.Bottom.left = -model.FrontBottomMargin;
            }

            if (me._isRightSharedModule(module) && me.plan.getRightCloset()) {
                model = me.plan.getRightCloset().getClosetModel();
                margs.Top.right = -model.FrontTopMargin;
                margs.HSeparator.right = -model.FrontShelfMargin;
                margs.Bottom.right = -model.FrontBottomMargin;
            }

            return margs;
        }

        // Returns true if user can set back panel in the specified zone
        _P.canSetBackPanel = function (Z) {
            var me = this;
            PlanHelper.checkZoneOwnByCloset(Z, me.closet);

            var module = ezCTreeHelper.getModule(Z.relNode);
            return !me._isRightSharedModule(module) && !me._isLeftSharedModule(module);
        }

        // Returns true if node can have a back panel. If not, back part in never created.
        _P.canHaveBackPanel = function (node) {
            var me = this;
            var module = ezCTreeHelper.getModule(node);
            return !me._isRightSharedModule(module) && !me._isLeftSharedModule(module);
        }

        // Returns true if node is a corner shared module
        _P.isSharedModule = function (node) {
            var module = ezCTreeHelper.getModule(node);
            return this._isRightSharedModule(module) || this._isLeftSharedModule(module);
        }

    })(MainClosetDriver.prototype);

    ///// SideClosetDriver -> IClosetDriver
    // Constructor
    var SideClosetDriver = function (P, side, model) {
        var me = this;
        me.plan = P;
        me.side = side; // 'right' or 'left'
        me.model = model;
        me.closet = null;
    };
    // Methods
    (function (_P) {

        // Return true if module is shared with in right corner plan
        _P._isRightSharedModule = function (module) { return this.side === 'right' && this.plan.hasRightSharedCorner() && PlanHelper.isInSharedCorner(module, 'first'); }

        // Return true if module is shared with in left corner plan
        _P._isLeftSharedModule = function (module) { return this.side === 'left' && this.plan.hasLeftSharedCorner() && PlanHelper.isInSharedCorner(module, 'last'); }

        // Compute position of this closet
        _P.computePos = function (P) {
            var me = this;
            var C = me.closet;

            var Ws = C.getWidth();
            var Hs = C.getHeight();
            var Ds = C.getDepth();

            // For future use : if has an element in corner, between main and side closet
            var rce = me.side === 'left' ? me.plan.getLCElement() : me.plan.getRCElement();

            var Wrc = rce ? rce.getWidth() : me.plan.getMainWidth();
            var Hrc = rce ? rce.getHeight() : me.plan.getMainHeight();
            var Drc = rce ? rce.getDepth() : me.plan.getMainDepth();

            var xrce = rce ? rce.getGPos().x : (me.side === 'left' ? -Ds : Ds);
            var yrce = rce ? rce.getGPos().y : 0;
            var zrce = rce ? rce.getGPos().z : 0;

            if (me.side === 'right') {
                C.setGPos(
                    ezGPos.getGCoordValue(Wrc, xrce, +1, Ds, -1),
                    ezGPos.getGCoordValue(Hrc, yrce, -1, Hs, +1),
                    ezGPos.getGCoordValue(Drc, zrce, rce ? +1 : -1, Ws, +1) // if corner-element exists, side-closet is front of corner-element on ZDim plan axis
                );

                // Ground angle (-90°)
                C.setGAng(-Math.PI / 2);
            }
            else {
                C.setGPos(
                    ezGPos.getGCoordValue(Wrc, xrce, -1, Ds, +1),
                    ezGPos.getGCoordValue(Hrc, yrce, -1, Hs, +1),
                    ezGPos.getGCoordValue(Drc, zrce, rce ? +1 : -1, Ws, +1) // if corner-element exists, side-closet is front of corner-element on ZDim plan axis
                );

                // Ground angle (+90°)
                C.setGAng(+Math.PI / 2);
            }
        }

        // Set depth of this closet. Can impact other closets dimensions
        _P.setDepth = function (dim) {
            var me = this;

            var deltaDim = dim - me.closet.getDepth();
            me.closet.setDepth(dim);

            var mainCloset = me.plan.getMainCloset();
            me.plan.setClosetWidth(mainCloset, mainCloset.getWidth() - deltaDim); // When right-closet depth increase, main width closet decrease
        }

        // Return true if can add separator
        _P.canAddSeparator = function (Z, sepOrient) {
            var me = this;
            PlanHelper.checkZoneOwnByCloset(Z, me.closet);

            if (sepOrient === 'HORIZONTAL') return true;

            var module = ezCTreeHelper.getModule(Z.relNode);
            if (module.subNodes.getLength() === 0) return true;
            if (me._isRightSharedModule(module) || me._isLeftSharedModule(module)) return false;
            
            return true;
        }

        // Add separator zone with the specified sepOrient. Try to add separator in shared corner if any.
        // Returns: (Zone) The zone that can be selected.
        _P.addSeparator = function (Z, sepOrient) {
            var me = this;
            PlanHelper.checkZoneOwnByCloset(Z, me.closet);

            var addResult = ezZoneHelper.addSeparator(Z, sepOrient, true);
            if (addResult.hasTooShortDimErr) return addResult;

            me.closet.hide();
            addResult = ezZoneHelper.addSeparator(Z, sepOrient, false, true);

            var mainCloset = me.plan.getMainCloset();
            mainCloset.hide();
            if (sepOrient === 'HORIZONTAL') {

                var module = ezCTreeHelper.getModule(Z.relNode);

                if (me._isRightSharedModule(module)) {
                    var magnZone = PlanHelper.getMagnetZone(addResult.selectableZone, me.plan.getMainCloset(), 'last');
                    ezZoneHelper.addSeparator(magnZone, sepOrient, false, true);
                    
                }

                if (me._isLeftSharedModule(module)) {
                    var magnZone = PlanHelper.getMagnetZone(addResult.selectableZone, me.plan.getMainCloset(), 'first');
                    ezZoneHelper.addSeparator(magnZone, sepOrient, false, true);
                }

            }

            // Update
            me.plan.update();
            mainCloset.getSwingDoorAssoc().invalidate();
            mainCloset.getSwingDoorAssoc().updateDoors();
            mainCloset.getValidator().checkAndFix(mainCloset.root);
            me.closet.getSwingDoorAssoc().invalidate();
            me.closet.getSwingDoorAssoc().updateDoors();
            me.closet.getValidator().checkAndFix(me.closet.root);
            me.plan.display();

            return addResult;
        }

        // Return true if can remove separator
        _P.canRemoveSeparator = function (Z, side) {
            var me = this;
            var remNode = /*Z.relNode.parentOfST ||*/ Z.relNode;
            var remNodeParent = remNode.parent;

            if (!remNodeParent.isRoot()) return true;

            var iRem = remNodeParent.subNodes.indexOf(remNode);

            if (me.plan.hasLeftSharedCorner() && side === 'left' && iRem === remNodeParent.subNodes.getLastIndex()) {
                if (!remNodeParent.subNodes.get(iRem - 1).isEmpty()) return false;
            }

            if (me.plan.hasRightSharedCorner() && side === 'right' && iRem === 0) {
                if (!remNodeParent.subNodes.get(iRem + 1).isEmpty()) return false;
            }
            
            return true;
        }

        // Remove separator from zone according to the specified separator side. Try to remove separator from shared corner if any.
        _P.removeSeparator = function (Z, sepSide) {
            var me = this;
            PlanHelper.checkZoneOwnByCloset(Z, me.closet);

            var remResult = ezZoneHelper.removeSeparator(Z, sepSide, true);
            if (remResult.hasNotEmptyErr) return remResult;

            var mainCloset = me.plan.getMainCloset();
            mainCloset.hide();
            if (sepSide === 'bottom' || sepSide === 'top') {

                var module = ezCTreeHelper.getModule(Z.relNode);

                if (me._isRightSharedModule(module)) { // if has right shared corner like AGEM angle closet 
                    var magnZone = PlanHelper.getMagnetZone(Z, me.plan.getMainCloset(), 'last');
                    var magnRemResult = ezZoneHelper.removeSeparator(magnZone, sepSide, true);
                    if (magnRemResult.hasNotEmptyErr) { // Zone are not empty
                        remResult.hasNotEmptyErr = true;
                        return remResult;
                    }

                    ezZoneHelper.removeSeparator(magnZone, sepSide, false, true);
                }

                if (me._isLeftSharedModule(module)) { // if has left shared corner like AGEM angle closet 
                    var magnZone = PlanHelper.getMagnetZone(Z, me.plan.getMainCloset(), 'first');
                    var magnRemResult = ezZoneHelper.removeSeparator(magnZone, sepSide, true);
                    if (magnRemResult.hasNotEmptyErr) { // Zone are not empty
                        remResult.hasNotEmptyErr = true;
                        return remResult;
                    }

                    ezZoneHelper.removeSeparator(magnZone, sepSide, false, true);
                }

            }

            remResult = ezZoneHelper.removeSeparator(Z, sepSide, false, true); // Remove from current zone

            // Update
            me.closet.hide();
            me.plan.update();
            mainCloset.getSwingDoorAssoc().invalidate();
            mainCloset.getSwingDoorAssoc().updateDoors();
            mainCloset.getValidator().checkAndFix(mainCloset.root);
            me.closet.getSwingDoorAssoc().invalidate();
            me.closet.getSwingDoorAssoc().updateDoors();
            me.closet.getValidator().checkAndFix(me.closet.root);
            me.plan.display();

            return remResult;
        }

        // Set zone height. Try to set height in shared corner if any.
        // Returns: true if status of Z become fixed, false otherwise
        _P.setZoneHeight = function (Z, dim) {
            var me = this;
            PlanHelper.checkZoneOwnByCloset(Z, me.closet);

            var result = ezZoneHelper.setHeight(Z, dim);

            var module = ezCTreeHelper.getModule(Z.relNode);

            if (me._isRightSharedModule(module)) { // if has right shared corner like AGEM angle closet
                var magnZone = PlanHelper.getMagnetZone(Z, me.plan.getMainCloset(), 'last');
                ezZoneHelper.setHeight(magnZone, dim);
            }

            if (me._isLeftSharedModule(module)) { // if has right shared corner like AGEM angle closet
                var magnZone = PlanHelper.getMagnetZone(Z, me.plan.getMainCloset(), 'first');
                ezZoneHelper.setHeight(magnZone, dim);
            }

            return result;
        }

        // Switch zone height status. Try to set height status in shared corner if any.
        _P.switchHeightStatus = function (Z) {
            var me = this;
            PlanHelper.checkZoneOwnByCloset(Z, me.closet);

            ezZoneHelper.switchDimStatus(Z, 'height');

            var module = ezCTreeHelper.getModule(Z.relNode);

            if (me._isRightSharedModule(module)) { // if has right shared corner like AGEM angle closet
                var magnZone = PlanHelper.getMagnetZone(Z, me.plan.getMainCloset(), 'last');
                ezZoneHelper.switchDimStatus(magnZone, 'height');
            }

            if (me._isLeftSharedModule(module)) { // if has left shared corner like AGEM angle closet
                var magnZone = PlanHelper.getMagnetZone(Z, me.plan.getMainCloset(), 'first');
                ezZoneHelper.switchDimStatus(magnZone, 'height');
            }
        }

        // Get zone bounds according to specified relative zone node
        _P.getZoneBounds = function (node) {
            var me = this;
            var bnds = $ez.createBounds();
            bnds.width.min = me.model.MinZoneWidth;
            bnds.height.min = me.model.MinZoneHeight;

            var module = ezCTreeHelper.getModule(node);
            if (me._isRightSharedModule(module) || me._isLeftSharedModule(module)) {
                var sch = me.closet.schemaProvider.getSchema01();
                var thickProv = me.closet.getThickProvider();
                var thick = me.side === 'left' ? (sch.HasLeft ? thickProv.getLeft() : 0) : (sch.HasRight ? thickProv.getRight() : 0);
                var myMinWidth = me.plan.getMainCloset().getDepth() + me.plan.getDto().MinSharedCornerSpacing - thick;
                if (bnds.width.min < myMinWidth) bnds.width.min = myMinWidth; // Most restrictive
            }

            return bnds;
        }

        // Compute the closet bounds
        _P.computeBounds = function () {
            var me = this;
            var closetBnds = PlanHelper.computeClosetBounds(me.closet);


            // Width
            if (me.plan.getZDim() < closetBnds.width.max)
                closetBnds.width.max = me.plan.getZDim();

            // Depth
            var M = me.plan.getMainCloset();
            var mbnds = PlanHelper.computeClosetBounds(M);
            var maxd = me.closet.getDepth() + M.getWidth() - mbnds.width.min;
            if (maxd < closetBnds.depth.max)
                closetBnds.depth.max = maxd;

            return closetBnds;
        }

        //Returns true if pattern application start from last module to first module.
        _P.hasReversedPattern = function () { return this.side === 'left'; }

        // Returns true if pattern application must keep end module content
        _P.patternKeepEndContent = function () { return false; }

        // Retunrn true if can add drawer in the specified zone
        _P.canAddDrawer = function (Z) {
            var me = this;
            PlanHelper.checkZoneOwnByCloset(Z, me.closet);

            var module = ezCTreeHelper.getModule(Z.relNode);
            return !me._isRightSharedModule(module) && !me._isLeftSharedModule(module);
        }

        // Return true if can add swing-door in the specified zone
        _P.canAddSwingDoor = function (Z) {
            return this.canAddDrawer(Z);
        }

        // Set column height
        _P.setColumnHeight = function (Z, h) {
            var me = this;
            PlanHelper.checkZoneOwnByCloset(Z, me.closet);

            var sideModule = ezCTreeHelper.getModule(Z.relNode);
            ezCTreeHelper.setModuleHeight(sideModule, h);

            if (me._isLeftSharedModule(sideModule)) {
                var magnNode = PlanHelper.getMagnetNode(Z, me.plan.getMainCloset(), 'first');
                module = ezCTreeHelper.getModule(magnNode);
                ezCTreeHelper.setModuleHeight(module, h);
            }

            if (me._isRightSharedModule(sideModule)) {
                var magnNode = PlanHelper.getMagnetNode(Z, me.plan.getMainCloset(), 'last');
                module = ezCTreeHelper.getModule(magnNode);
                ezCTreeHelper.setModuleHeight(module, h);
            }
        }

        // Get left and right padding of closet
        // Returns : { left: (Numeric), right: (Numeric) }
        _P.getWidthPaddings = function () {
            var me = this;
            var mainC = me.plan._main.closet;
            var mainModel = mainC.getClosetModel();
            var pads = { left: me.model.LeftMargin, right: me.model.RightMargin };

            // Main closet back margin
            var mainBackMarg = mainC.getClosetModel().BackMargin + mainC.getClosetModel().BackPanelOffs;

            if (me.side === 'left' && me.plan.hasLeftSharedCorner())
                pads.right = mainBackMarg;
            else if (me.side === 'right' && me.plan.hasRightSharedCorner())
                pads.left = mainBackMarg;

            return pads;
        }

        // Get left and right margins of parts : Top, Bottom and HSeparator, according specified node
        // Returns: null
        _P.getPartMargins = function (node) {
            var module = ezCTreeHelper.getModule(node);
            var me = this;

            var mainCloset = me.plan.getMainCloset();
            var coef = 1;
            if (me.plan.isULayout() && mainCloset.root.subNodes.getLength() <= 1) { // U layout and Main closet has single column
                coef = 0.5; // Left and right part of side closet have half margin
            }

            if (me._isLeftSharedModule(module)) {
                var nodeOfMain = mainCloset.root.subNodes.getLength() === 0 ? mainCloset.root : mainCloset.root.subNodes.get(0);
                return { Right: { front: -nodeOfMain.innerZone.xdim * coef } };
            }

            if (me._isRightSharedModule(module)) {
                var nodeOfMain = mainCloset.root.subNodes.getLength() === 0 ? mainCloset.root : mainCloset.root.subNodes.get(mainCloset.root.subNodes.getLength() - 1);
                return { Left: { front: -nodeOfMain.innerZone.xdim * coef } };
            }

            return null;
        }

        // Returns true if user can set back panel in the specified zone
        _P.canSetBackPanel = function (Z) {
            var me = this;
            PlanHelper.checkZoneOwnByCloset(Z, me.closet);

            var module = ezCTreeHelper.getModule(Z.relNode);
            return !me._isRightSharedModule(module) && !me._isLeftSharedModule(module);
        }

        // Returns true if node can have a back panel. If not, back part in never created.
        _P.canHaveBackPanel = function (node) {
            return true;
        }

        // Returns true if node is a corner shared module
        _P.isSharedModule = function (node) {
            var module = ezCTreeHelper.getModule(node);
            if (this.side === 'right') {
                return this._isRightSharedModule(module);
            }
            else {
                return this._isLeftSharedModule(module);
            }
        }

    })(SideClosetDriver.prototype);
        

    /////// Class Plan
    // Brief : Describe the closet plan according to ClosetPlanDto.
    // Constructor
    var Plan = function () {
        const me = this;
        me._planType = null; // ClosetPlanTypes

        me._planDto = null; // ClosetPlanDto class

        me._main = null; // IClosetDriver
        me._right = null; // IClosetDriver
        me._left = null; // IClosetDriver

        me.sepInjector = null; // ISeparatorInjector
        me.isUIEasy = false;
    };
    // Methods
    (function (_P) {

        // Set width of closet. Apply current pattern accoding to new dimension.
        // You are responsible to hide closet before, and display and update closet after this call.
        // C: (Closet) The closet to set new width
        // dim: (Number) The new width to set
        _P.setClosetWidth = function (C, dim) {
            var oldDim = C.getWidth();
            if (oldDim === dim) return;

            var deltaDim = this.sepInjector.getDeltaDim(oldDim, dim);

            if (C.root.axis === 'X_AXIS')
                this.sepInjector.analyseRootDim(C.root, 'width');

            C.setWidth(dim);

            if (C.root.axis === 'X_AXIS')
                this.sepInjector.proccessRootDim(C, deltaDim);
        }

        // Set height of closet. Apply current pattern accoding to new dimension.
        // You are responsible to hide closet before, and display and update closet after this call.
        // C: (Closet) The closet to set new height
        // dim: (Number) The new height to set
        _P.setClosetHeight = function (C, dim) {
            var oldDim = C.getHeight();
            if (oldDim === dim) return;

            var deltaDim = this.sepInjector.getDeltaDim(oldDim, dim);

            if (C.root.axis === 'Y_AXIS')
                PP.analyseRootDim(C.root, 'height');

            C.setHeight(dim);

            if (C.root.axis === 'Y_AXIS')
                PP.proccessRootDim(C, deltaDim);
        }

        // Init closet-plan with the speicifed closet-layout
        // Returns : promise with this plan.
        _P.init = function (closetLayout) {
            var me = this;
            var model;

            // me._planId = layoutId;
            //me._planType = planType;
            me._planDto = null;
            me._main = null;
            me._right = null;
            me._left = null;

            // var prom = new Promise(function (resolve, reject) {

                // ezWebSvc.getClosetPlan(layoutId, presetId).then(function (response) {

                    me._planDto = closetLayout; //response.data;

                    // create main model of plan
                    model = me._planDto.MainModel; //new ClosetModel();

                    // The model options that depending on plan 
                    model.HasLeft = !me._planDto.HasLeftSharedCorner;
                    model.HasRight = !me._planDto.HasRightSharedCorner;

                    // $ez.extend(model, me._planDto.MainModel);
                    // me._loadAllowedItemsOf(model); // Async load of allowed items of models
                    me._main = new MainClosetDriver(me, 0, model);

                    // create right model of plan if any
                    if (me._planDto.RightModel) {
                        model = me._planDto.RightModel; // new ClosetModel();
                        // $ez.extend(model, me._planDto.RightModel);
                        // me._loadAllowedItemsOf(model); // Async load of allowed items of models
                        me._right = new SideClosetDriver(me, 'right', model);
                    }

                    // create left model of plan if any
                    if (me._planDto.LeftModel) {
                        model = me._planDto.LeftModel; //new ClosetModel();
                        // $ez.extend(model, me._planDto.LeftModel);
                        // me._loadAllowedItemsOf(model); // Async load of allowed items of models
                        me._left = new SideClosetDriver(me, 'left', model);
                    }

                    // $log.log("Closet plan '" + me._planDto.Title + "' #" + layoutId + " loading done.");
                    // resolve(me);

                // })/*.catch(function (err) {
                //     $log.log("Closet plan " + planType + " #" + layoutId + " fails.");
                //     reject(err);
                // })*/;

            // });

            // return prom;
        }

        // Return true if plan is composed with single closet : the main closet.
        _P.hasSingleCloset = function () {
            var me = this;
            return me._main && !me._right && !me._left;
        }

        // Return true if plan has U layout
        _P.isULayout = function () { return this._left && this._right; }

        // Generate closets according loaded plan. You have to call load() method before.
        // dims: (Object) If specified, override dimensions provided by plan DTO : { width: (Number), height: (Number), Depth: (Number) }. Optional.
        // colour: (IColour) Colour of closets.
        // Returns : promise with main-closet and project title ex: plan.generateClosets().then(function({ closet: (Closet) Generated closet, title: (String) The project title}) {...});
        _P.generateClosets = function (dims, colour, dataRepo) {
            var me = this;

            if (!me._main)
                $ez.THROW("Main model missing. Check if plan layout define a main model at least.");

            // Generate main
            me._main.closet = me._createCloset(me._main.model, colour, dataRepo);
            me._main.closet.setDriver(me._main);

            if (me._main.closet.schemaProvider.axis0 === "X_AXIS") {
                me.sepInjector.addSepOnDim = me._main.model.MaxZoneWidth;
                me.sepInjector.remSepOnDim = me._main.model.MinZoneWidth;
            }
            else {
                me.sepInjector.addSepOnDim = me._main.model.MaxZoneHeight;
                me.sepInjector.remSepOnDim = me._main.model.MinZoneHeight;
            }

            // Generate right if any
            if (me._right) {
                me._right.closet = me._createCloset(me._right.model, colour, dataRepo);
                me._right.closet.setDriver(me._right);
            }

            // Generate left if any
            if (me._left) {
                me._left.closet = me._createCloset(me._left.model, colour, dataRepo);
                me._left.closet.setDriver(me._left);
            }


            // Set default dimension
            const xdim = dims ? dims.width : me._planDto.XDim;
            const ydim = dims ? dims.height : me._planDto.YDim;
            const zdim = dims ? dims.depth : me._planDto.ZDim;

            if (xdim > 0) me.setXDim(xdim);
            if (ydim > 0) me.setYDim(ydim);
            if (zdim > 0) {
                if (me.hasSingleCloset())
                    me._main.closet.setDepth(zdim);
                else
                    me.setZDim(zdim);
            }

            // Align all height if required
            if (me._planDto.HasHeightAligned) {
                var H = me.getYDim();
                me.setClosetHeight(me._main.closet, H);
                if (me._right) me.setClosetHeight(me._right.closet, H);
                if (me._left) me.setClosetHeight(me._left.closet, H);
            }

            // return prom;
        }

        _P._createCloset = function(closetModel, colour, dataRepo) {
            // Create closet instance
            var closet = ezCloset.createCloset(
                closetModel,
                closetModel.MinClosetWidth, closetModel.MinClosetHeight, closetModel.MinClosetDepth,
                colour,
                dataRepo.getPanelModel(closetModel.PanelMaterialId),
                dataRepo.getPanelModel(closetModel.BackPanelMaterialId),
                dataRepo);

            return closet;
        }

        // Read closet plan from DATA_NODE in JSON format. You must call load() method before.
        //  json: (object) The DATA_NODE in JSON format.
        //  report: (ezRW.ReadReport) The read report (optional).
        // Returns : promise with main-closet ex: plan.readClosets(json, report).then(function(closet) {...});
        _P.readClosets = async function (json, dataRepo, report) {
            const me = this;
            let desc;
            const container = ezRW.createContainer();
            container.prepareToRead(me, json);

            let mainReader, leftReader, rightReader;
            // var readers = [];
            // var descs = [];

            // To read main closet
            mainReader = ezRW.createReader(container, me._main.model, dataRepo, report);
            await mainReader.readCloset(container.getClosetDesc('main'));
            
            // To read left closet
            if (me._left) {
                desc = container.getClosetDesc('left');
                if (desc) {
                    leftReader = ezRW.createReader(container, me._left.model, dataRepo, report);
                    await leftReader.readCloset(desc);
                }
                else
                    $ez.warn("No left closet desciption found, but left closet model exists. Project JSON is not consistent.");
            }

            // To read right closet
            if (me._right) {
                desc = container.getClosetDesc('right');
                if (desc) {
                    rightReader = ezRW.createReader(container, me._right.model, dataRepo, report);
                    await rightReader.readCloset(desc);
                }
                else
                    $ez.warn("No right closet desciption found, but right closet model exists. Project JSON is not consistent.");
            }

            me._main.closet = mainReader.getCloset();
            me._main.closet.setDriver(me._main);
            // me._main.closet.getValidator().checkAndFix(me._main.closet.root);

            if (leftReader) {
                me._left.closet = leftReader.getCloset();
                me._left.closet.setDriver(me._left);
                // me._left.closet.getValidator().checkAndFix(me._left.closet.root);
            }

            if (rightReader) {
                me._right.closet = rightReader.getCloset();
                me._right.closet.setDriver(me._right);
                // me._right.closet.getValidator().checkAndFix(me._right.closet.root);
            }
        }

        // Write current closet plan with specified write options
        // Returns: (Container) The container with written closets.
        _P.writeClosets = function (writeOpts) {
            var me = this;

            var container = ezRW.createContainer();
            container.prepareToWrite(me);

            var writer = ezRW.createWriter(container, writeOpts);

            // To write main closet
            writer.writeCloset(me._main.closet, 'main');

            // To write left closet
            if (me._left)
                writer.writeCloset(me._left.closet, 'left');

            // To write right closet
            if (me._right)
                writer.writeCloset(me._right.closet, 'right');

            return container;
        }

        // Gets the closet-plan DTO
        _P.getDto = function () { return this._planDto; }

        // Gets the main closet
        _P.getMainCloset = function () { return this._main.closet; }

        // Gets the right-corner closet or null if does not exists
        _P.getRightCloset = function () { return this._right ? this._right.closet : null; }

        // Gets the left-corner closet or null if does not exists
        _P.getLeftCloset = function () { return this._left ? this._left.closet : null; }

        // Gets the right corner element
        _P.getRCElement = function () { return null; }

        // Gets the left corner element dimension
        _P.getLCElement = function () { return null; }

        // Free all closets
        _P.clearClosets = function () {
            var me = this;
            me.hide();

            if (me._main) me._main.closet.empty();
            if (me._right) me._right.closet.empty();
            if (me._left) me._left.closet.empty();

            me._main = null;
            me._right = null;
            me._left = null;
        }

        // Hide closets according plan definition
        _P.hide = function () {
            var me = this;
            if (me._main) me._main.closet.hide();
            if (me._right) me._right.closet.hide();
            if (me._left) me._left.closet.hide();
        }

        // Update hanging-bars of shared corner to avoid conflict and to compute extension ok main closet bar.
        _P._updateSharedHangingBars = function () {
            var me = this;

            // function predicate to find hanging-bar
            var findHBPredicate = function (item) { return item.getEzType() === 'HangingBar' };

            // function to process hanging-bar in left corner
            var processLeftCorner = function (mainSN) {
                var mainHB = mainSN.findItem(findHBPredicate);
                if (mainHB) {
                    var LMagnetZone = PlanHelper.getMagnetZone(mainSN.innerZone, me._left.closet, 'last');
                    mainHB.setLeftExtension(LMagnetZone.zdim);
                    var leftHB = LMagnetZone.relNode.findItem(findHBPredicate);
                    mainHB.setTopOffset(leftHB ? mainHB.getModel().Diameter * 2 : 0);
                    mainHB.update();
                }
            };

            // function to process hanging-bar in right corner
            var processRightCorner = function (mainSN) {
                var mainHB = mainSN.findItem(findHBPredicate);
                if (mainHB) {
                    var RMagnetZone = PlanHelper.getMagnetZone(mainSN.innerZone, me._right.closet, 'first');
                    mainHB.setRightExtension(RMagnetZone.zdim);
                    var rightHB = RMagnetZone.relNode.findItem(findHBPredicate);
                    mainHB.setTopOffset(rightHB ? mainHB.getModel().Diameter * 2 : 0);
                    mainHB.update();
                }
            };

            // Process
            if (me._left) {
                var mainSharedModule = ezCTreeHelper.getFirstModule(me._main.closet);
                var leftSharedModule = ezCTreeHelper.getLastModule(me._left.closet);
                if (mainSharedModule.subNodes.getLength() > 0) {
                    for (var i = 0; i < mainSharedModule.subNodes.getLength(); i++) {
                        processLeftCorner(mainSharedModule.subNodes.get(i));
                    }
                }
                else
                    processLeftCorner(mainSharedModule);
            }

            if (me._right) {
                var mainSharedModule = ezCTreeHelper.getLastModule(me._main.closet);
                var rightSharedModule = ezCTreeHelper.getFirstModule(me._right.closet);
                if (mainSharedModule.subNodes.getLength() > 0) {
                    for (var i = 0; i < mainSharedModule.subNodes.getLength(); i++) {
                        processRightCorner(mainSharedModule.subNodes.get(i));
                    }
                }
                else
                    processRightCorner(mainSharedModule);
            }
        }


        // Fix and udpate the parts in shared corners
        _P._fixAndUpdateCorners = function () {
            var me = this;
            var mainUpdateRequired = false;

            // Fix right corner if any
            if (me._right) {
                var mainLastCol = me._main.closet.root.subNodes.getLength() === 0 ? me._main.closet.root : me._main.closet.root.subNodes.get(me._main.closet.root.subNodes.getLastIndex());

                if (mainLastCol.parts && mainLastCol.parts.back) { // No back in last main col since issue #343
                    mainLastCol.parts.back.hide();
                    mainLastCol.parts.back.dispose();
                    mainLastCol.parts.back = null;
                }
                
                if (mainLastCol.schema && !mainLastCol.schema.hasBack(mainLastCol.schOpts)) {
                    mainLastCol.schOpts.hasBackActive = true; // To force back margins of parts
                    mainUpdateRequired = true;
                }

                var rightFirstCol = me._right.closet.root.subNodes.getLength() === 0 ? me._right.closet.root : me._right.closet.root.subNodes.get(0);

                // To force back-part in corner if is missing (since issue #343)
                //if (me._right.closet.root.subNodes.getLength() > 0) {
                if (rightFirstCol.schema && !rightFirstCol.schema.hasBack(rightFirstCol.schOpts)) {
                    rightFirstCol.schOpts.hasBackActive = true;
                    me._right.closet.update();
                }
                //}

                // Clean-up shared shelf if shared column has not same count of shelf
                
                if (mainLastCol.subNodes.getLength() !== rightFirstCol.subNodes.getLength()) {

                    while (mainLastCol.subNodes.getLength() > 0)
                        mainLastCol.removeSeparator(mainLastCol.subNodes.get(0), "AFTER");

                    while (rightFirstCol.subNodes.getLength() > 0)
                        rightFirstCol.removeSeparator(rightFirstCol.subNodes.get(0), "AFTER");

                }

            }

            // Update left corner if any
            if (me._left) {
                var mainFirstCol = me._main.closet.root.subNodes.getLength() === 0 ? me._main.closet.root : me._main.closet.root.subNodes.get(0);

                if (mainFirstCol.parts && mainFirstCol.parts.back) { // No back in first main col since issue #343
                    mainFirstCol.parts.back.hide();
                    mainFirstCol.parts.back.dispose();
                    mainFirstCol.parts.back = null;
                }

                if (mainFirstCol.schema && !mainFirstCol.schema.hasBack(mainFirstCol.schOpts)) {
                    mainFirstCol.schOpts.hasBackActive = true; // To force back margins of parts
                    mainUpdateRequired = true;
                }

                var leftLastCol = me._left.closet.root.subNodes.getLength() === 0 ? me._left.closet.root : me._left.closet.root.subNodes.get(me._left.closet.root.subNodes.getLastIndex());

                // To force back-part in corner if is missing (since issue #343)
                //if (me._left.closet.root.subNodes.getLength() > 0) {
                    //var lastCol = me._left.closet.root.subNodes.get(me._left.closet.root.subNodes.getLastIndex());
                if (leftLastCol.schema && !leftLastCol.schema.hasBack(leftLastCol.schOpts)) {
                    leftLastCol.schOpts.hasBackActive = true;
                    me._left.closet.update();
                }
                //}

                // Clean-up shared shelf if shared column has not same count of shelf
                if (mainFirstCol.subNodes.getLength() !== leftLastCol.subNodes.getLength()) {
                    while (mainFirstCol.subNodes.getLength() > 0)
                        mainFirstCol.removeSeparator(mainFirstCol.subNodes.get(0), "AFTER");

                    while (leftLastCol.subNodes.getLength() > 0)
                        leftLastCol.removeSeparator(leftLastCol.subNodes.get(0), "AFTER");
                }
            }

            if (mainUpdateRequired)
                me._main.closet.update();
        }

        // Update closets according plan definition
        _P.update = function () {
            var me = this;

            // Update main closet
            me._main.closet.update();

            // Update right closet if any
            if (me._right) {
                me._right.computePos(me);
                me._right.closet.update();
            }

            // Update left closet if any
            if (me._left) {
                me._left.computePos(me);
                me._left.closet.update();
            }

            me._fixAndUpdateCorners();
            me._updateSharedHangingBars();
        }

        // Check and fix each closet of this plan
        _P.checkAndFix = function () {
            var me = this;

            // Update main closet
            me._main.closet.getValidator().checkAndFix(me._main.closet.root);

            // Update right closet if any
            if (me._right) me._right.closet.getValidator().checkAndFix(me._right.closet.root);

            // Update left closet if any
            if (me._left) me._left.closet.getValidator().checkAndFix(me._left.closet.root);
        }

        // Display closets according plan definition
        _P.display = function () {
            var me = this;
            if (me._main) me._main.closet.display();
            if (me._right) me._right.closet.display();
            if (me._left) me._left.closet.display();
        }

        // Gets sum of width of all main closet include in this plan
        _P.getMainWidth = function () { return this._main.closet.getWidth(); }

        // Gets the max height by comparing all main closet include in this plan
        _P.getMainHeight = function () { return this._main.closet.getHeight(); }

        // Gets the max depth by comparing all main closet include in this plan
        _P.getMainDepth = function () { return this._main.closet.getDepth(); }

        // Gets dimension bounds of this plan
        // Returns: dimension bounds as { width: {min: ..., max: ...}, height: {min: ..., max: ...}, depth: {min: ..., max: ...} }
        _P.getDimBounds = function () {
            var me = this;
            var HDeltaMin, HDeltaMax, greatestHeight, hdmin, hdmax;

            var bnds = PlanHelper.computeClosetBounds(me._main.closet); // me._main.computeBounds();
            HDeltaMin = me._main.closet.getHeight() - bnds.height.min;
            HDeltaMax = bnds.height.max - me._main.closet.getHeight();
            greatestHeight = me._main.closet.getHeight();

            var rceDepth = 0;
            var sidesBnds = $ez.createBounds();
            
            if (me._right) {
                var sbnds = PlanHelper.computeClosetBounds(me._right.closet);
                hdmin = me._right.closet.getHeight() - sbnds.height.min;
                hdmax = sbnds.height.max - me._right.closet.getHeight();

                // width
                bnds.width.min += me._right.closet.getDepth();

                // height
                if (hdmin < HDeltaMin) HDeltaMin = hdmin;
                if (hdmax < HDeltaMax) HDeltaMax = hdmax;
                if (greatestHeight < me._right.closet.getHeight()) greatestHeight = me._right.closet.getHeight();

                // depth
                if (sidesBnds.width.min < sbnds.width.min + rceDepth)
                    sidesBnds.width.min = sbnds.width.min + rceDepth;

                if (sidesBnds.width.max > sbnds.width.max + rceDepth)
                    sidesBnds.width.max = sbnds.width.max + rceDepth;
            }

            if (me._left) {
                var sbnds = PlanHelper.computeClosetBounds(me._left.closet);
                hdmin = me._left.closet.getHeight() - sbnds.height.min;
                hdmax = sbnds.height.max - me._left.closet.getHeight();

                // width
                bnds.width.min += me._left.closet.getDepth();

                // height
                if (hdmin < HDeltaMin) HDeltaMin = hdmin;
                if (hdmax < HDeltaMax) HDeltaMax = hdmax;
                if (greatestHeight < me._left.closet.getHeight()) greatestHeight = me._left.closet.getHeight();

                // depth
                if (sidesBnds.width.min < sbnds.width.min + rceDepth)
                    sidesBnds.width.min = sbnds.width.min + rceDepth;

                if (sidesBnds.width.max > sbnds.width.max + rceDepth)
                    sidesBnds.width.max = sbnds.width.max + rceDepth;
            }

            bnds.height.min = greatestHeight - HDeltaMin;
            bnds.height.max = greatestHeight + HDeltaMax;

            var widthDeltaSides = 0;
            if (me._left && me._right) widthDeltaSides = Math.abs(me._left.closet.getWidth() - me._right.closet.getWidth());
            bnds.depth.min = sidesBnds.width.min + widthDeltaSides;
            bnds.depth.max = sidesBnds.width.max;

            return bnds;
        }

        // Gets or sets the X dimension of this plan
        _P.getXDim = function () {
            var me = this;
            var w = me._main.closet.getWidth();

            if (me._right)
                w += me._right.closet.getDepth();

            if (me._left)
                w += me._left.closet.getDepth();

            return w;
        }
        _P.setXDim = function (d) {
            var me = this;

            var mainW = d;

            if (me._right)
                mainW -= me._right.closet.getDepth();

            if (me._left)
                mainW -= me._left.closet.getDepth();

            me.setClosetWidth(me._main.closet, mainW);
        }

        // Gets or sets the Y dimension of this plan
        _P.getYDim = function () {
            var me = this;
            var h = me._main.closet.getHeight();

            if (me._right)
                h = $ez.getMax(h, me._right.closet.getHeight());

            if (me._left)
                h = $ez.getMax(h, me._left.closet.getHeight());

            return h;
        }
        _P.setYDim = function (d) {
            var me = this;

            var oldH = me.getYDim();
            var delta = d - oldH;

            var newMainH = me._main.closet.getHeight() + delta;

            var newRightH = 0;
            if (me._right)
                newRightH = me._right.closet.getHeight() + delta;

            var newLeftH = 0;
            if (me._left)
                newLeftH = me._left.closet.getHeight() + delta;

            me.setClosetHeight(me._main.closet, newMainH); //me._main.closet.setHeight(newMainH);
            if (me._right) me.setClosetHeight(me._right.closet, newRightH); //me._right.closet.setHeight(newRightH);
            if (me._left) me.setClosetHeight(me._left.closet, newLeftH); //me._left.closet.setHeight(newLeftH);
        }

        // Gets or sets the Z dimension of this plan
        _P.getZDim = function () {
            var me = this;
            if (me.hasSingleCloset()) { // Single closet
                return me._main.closet.getDepth();
            }
            else {
                var dRight = me._right ? me._right.closet.getWidth() : 0;
                var dLeft = me._left ? me._left.closet.getWidth() : 0;

                return $ez.getMax(dRight, dLeft);
            }
        }
        _P.setZDim = function (d) {
            var me = this;
            if (!me._right && !me._left) return;

            var oldDim = me.getZDim();
            var deltaDim = d - oldDim;

            if (me._right)
                me.setClosetWidth(me._right.closet, me._right.closet.getWidth() + deltaDim);

            if (me._left)
                me.setClosetWidth(me._left.closet, me._left.closet.getWidth() + deltaDim);
        }

        // Return true if specified closet matching closet plan type
        _P.isMainCloset = function (C) { return this._main.closet === C; }
        _P.isRightCloset = function (C) { return this._right && this._right.closet === C; }
        _P.isLeftCloset = function (C) { return this._left && this._left.closet === C; }

        // Return true if plan has right shared corner. i.e has DTO.HasRightSharedCorner setted to true
        _P.hasRightSharedCorner = function () { return this._planDto.HasRightSharedCorner; }

        // Return true if plan has right shared corner. i.e has DTO.HasRightSharedCorner setted to true
        _P.hasLeftSharedCorner = function () { return this._planDto.HasLeftSharedCorner; }

    })(Plan.prototype);


    ////// ezPlan service
    return {
        $name: 'ezPlan',

        create: function () { return new Plan(); }
    };
}


////// EXPORT
export default {
    $init: _init
}