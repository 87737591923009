﻿///// Init method for injection
function _init(dcies, $ez) {

    var $log = $ez.getLogger();

    /////// Class Bom
    // Brief : The BOM relative to project
    // Constructor
    var Bom = function () {
        var me = this;
        me._items = [];
        me._totalPrice = 0;
        me._totalEcoTax = 0;
        me._totalWeight = 0;
    };
    // Methods
    (function (_P) {

        // Add BOM item and update totals.
        _P.add = function (item) {
            this._items.push(item);
            this._totalPrice += item.price;
            this._totalEcoTax += item.ecoTax;
            this._totalWeight += item.weight;
        }

        // Simply add price to the total price
        _P.addPrice = function (price) {
            this._totalPrice += price;
        }

        // Get the BOM item array.
        _P.getItems = function () { return this._items }

        // Get the total price (without ecotax part)
        _P.getTotalPrice = function () { return this._totalPrice }

        // Get the total of ecotax part
        _P.getTotalEcoTax = function () { return this._totalEcoTax }

        // Get the total weigth of project
        _P.getTotalWeight = function () { return this._totalWeight }

        // Clear BOM (remove all BOM items and set totals to 0)
        _P.clear = function () {
            this._items = [];
            this._totalPrice = 0;
            this._totalEcoTax = 0;
            this._totalWeight = 0;
        }

    })(Bom.prototype);


    ////// ezBOM service
    return {
        $name: 'ezBOM',

        // Create new BOM
        $new: function () { return new Bom(); },

        // Create new BOM item (use this object type in Bom.add() methods.
        $newItem: function () {
            return {
                cuid: 0,
                title: "(sans titre)",
                dims: [],
                dimsLabel: "(n.c)",
                surf: 0,
                price: 0,
                ecoTax: 0,
                weight: 0
            }
        }
    }
}


////// EXPORT
export default {
    $init: _init
}