﻿import * as _three_ from "three"
import { OrbitControls } from "three/examples/jsm/controls/OrbitControls"
import { OBJLoader } from "three/examples/jsm/loaders/OBJLoader";

// Reference to global THREE variable if any
//var _three_ = (window && window.THREE)  ? window.THREE : null;


///// Class Selector
// Brief : Selection manager to detect and select 3D object with mouse cursor.
// Run events :
//  - 3dSceneBinded
//  - selectionChanged
//
// Constructor
var Selector = function (el, camera, scene, ezObs) {
    var me = this;

    me.el = el;
    me.lastMDTime = 0; // Last mouse-down time
    me.ezObs = ezObs;

    // Scene parameters
    var bcr = el.getBoundingClientRect();
    // console.debug("Selector : top=%d, left=%d, w=%d ; h=%d", bcr.top, bcr.left, bcr.width, bcr.height);
    me.top = bcr.top;
    me.left = bcr.left;
    me.width = bcr.width;
    me.height = bcr.height;
    me.camera = camera;
    me.scene = scene;

    me.raycaster = new _three_.Raycaster();
    me.raycaster.layers.set(1);
    me.highLighted = null;
    me.selected = null;
    me._detectFilter = null; // Filter function that return true if detected object is accepted
    me._isLocked = false;

    me.posX = 0;
    me.posY = 0;
};
// Methods
(function (_P_) {

    // Set size
    _P_.setSize = function (w, h) {
        this.width = w;
        this.height = h;
    }

    // Reset the last mouse down time. Use it in mouseDown event.
    // timeStamp: (Number) The time-stamp as in returned by mouseDown event.
    _P_.resetMouseDownTime = function (timeStamp) {
        this.lastMDTime = timeStamp;
    }

    // Set detection function predicate
    // predicate: (Function) Predicate function to determine if detected object is accepted. function(detectedObject) { // must return true if detectedObject is accepted }
    _P_.setDetectFilter = function (predicate) { this._detectFilter = predicate; }

    // Detect highlighted object if any. Use it on mouseMove event.
    // clientX: (Number) Mouse X client coordinate as is returned by mousemove event.
    // clientY: (Number) Mouse Y client coordinate as is returned by mousemove event.
    _P_.detect = function (clientX, clientY) {
        var me = this;

        // corrected mouse coord
        var cmc = { x: 0, y: 0 };
        me.posX = cmc.x = ((clientX - me.left) / me.width) * 2 - 1;
        me.posY = cmc.y = -((clientY - me.top) / me.height) * 2 + 1;

        // Update ray-caster
        me.raycaster.setFromCamera(cmc, me.camera);
        var intersects = me.raycaster.intersectObjects(me.scene.children);
        me.highLighted = null;
        if (intersects && intersects.length && intersects.length > 0) {
            var n = intersects.length;
            for (var i = 0; i < n && me.highLighted === null; i++) {
                if (!me._detectFilter || me._detectFilter(intersects[i].object)) {
                    me.highLighted = intersects[i].object;
                    me.el.style.cursor = 'pointer';
                }
            }

            if (!me.highLighted)
                me.el.style.cursor = 'default';
        }
        else {
            me.el.style.cursor = 'default';
        }
    }

    // Select current highlighted object if any. Use it on click event.
    // Raise 'selectionChanged' event with selected object as parameter.
    // timeStamp: (Number) The time-stamp as in returned by click event.
    _P_.select = function (timeStamp) {
        var me = this;
        var td = timeStamp - me.lastMDTime;

        if (td < 250 && !me._isLocked) { // Click selection is valid only if is quick (to do not confuse with OrbitControls event)
            me.selected = me.highLighted !== null ? me.highLighted : null;
            me.ezObs.run('selectionChanged', me.selected);
        }
    }

    // Return current selected object if any, null otherwise.
    _P_.getSelected = function () { return this.selected; }

    // Unselect current selected object if any.
    // Raise 'selectionChanged' event with null as parameter.
    _P_.unselect = function () { this.selected = null; this.ezObs.run('selectionChanged', null); }

    // Lock or unlock selector. If locked, user cannot select anything.
    _P_.lock = function () { this._isLocked = true; }
    _P_.unlock = function () { this._isLocked = false; }

    // Return X position of mouse pointer in 3D scene coordinate
    _P_.getPosX = function () { return this.posX; }
    _P_.getPosY = function () { return this.posY; }

})(Selector.prototype);


///// Map of Colored THREE JS material (optimization)
var threeMatMap = {
    M: {},

    get: function (tdMatId, color) {
        var thm = this.M['ID' + tdMatId + color];
        return thm || null;
    },

    add: function (tdMatId, color, threeMat) {
        this.M['ID' + tdMatId + color] = threeMat;
    }
}


///// Init method for injection
function _init(dcies, $ez, ezObs) {

    var $log = $ez.getLogger();

    /////// Class ez3D
    // Brief : Wrapper for THREE JS
    // Constructor
    var ez3D = function () {
        var me = this;
        me.$name = 'ez3D';

        if (_three_) {
            _three_.Cache.enabled = true;
            $log.log("ez3D => THREE.JS is loaded");
        }

        me._3DEnabled = true;

        me._texLoader = _three_ ? new _three_.TextureLoader() : null;
        me._texMap = [];
        me.colorCorr = 0.137; // Color correction
        me.width = 800;
        me.height = 600;

        me._warnColor = 0xE25600;
        me._errColor = 0xFF0000;

        me.scene = _three_ ? new _three_.Scene() : null;
        me.renderer = _three_ ? new _three_.WebGLRenderer({ antialias: true }) : null;
        if (me.renderer) {
            me.renderer.setSize(me.width, me.height);
            me.renderer.shadowMap.enabled = true;
            me.renderer.shadowMap.type = _three_.PCFSoftShadowMap;
            // me.renderer.gammaInput = true;
            // me.renderer.gammaOutput = true;
            me.renderer.outputEncoding = _three_.sRGBEncoding;
            me.renderer.setClearColor(0xf1f1f1, 1);
        }

        me.camera = _three_ ? new _three_.PerspectiveCamera(45, me.width / me.height, 10, 10000) : null;
        if (me.camera) {
            me.camera.position.x = 158;
            me.camera.position.y = 139;
            me.camera.position.z = 453;
            me.scene.add(me.camera);
        }

        if (me.scene) {
            // Main light
            me.scene.add(new _three_.AmbientLight(0x404040, 1.0));
            var dl = new _three_.DirectionalLight(0xffffff, 0.65);
            dl.position.set(0, 280 * 6, 0);
            me.scene.add(dl);

            // Point of light
            var pol = new _three_.PointLight(0xcccccc, 1, 400);
            pol.position.set(0, 100, 200);
            pol.intensity = 1.0;
            me.scene.add(pol);

            // Main spotlight
            me._spotLight = new _three_.SpotLight(0xffffff);
            me._spotLight.position.set(200 * 6, 280 * 6, 200 * 6);
            me._spotLight.intensity = 0.7;
            me._spotLight.rotationAutoUpdate = false;
            me._spotLight.angle = Math.PI / 12;
            // must enable shadow casting ability for the light
            me._spotLight.castShadow = true; // OPTI
            me._spotLight.shadow.mapSize.width = // If value is too low, see triangles on face.
                me._spotLight.shadow.mapSize.height = 1280;
            me._spotLight.shadow.camera.far = 1000 * 6;
            me._spotLight.shadow.camera.near = 400;
            me._spotLight.penumbra = 0.05;
            me._spotLight.decay = 1;
            me._spotLight.distance = 1000 * 6;

            // this.scene.add(me._spotLight); // Static shadow
            me.camera.add(me._spotLight); // dynamic shadow
        }
        
        me.viewControls = null;
        me.objLoader = null;
        me.selector = null;

        // Screen shot management
        me._screenShotResolve = null;
        me._wantScreenShot = false;

        me.hasFreeCamera = false;
        me.noWheelZoom = false;
        me.zoneColor = "#6fb8d7";
        me.ZoneSelectionColor = "#ffffff";
        me.workPanelColor = "#bfbfc0";
    };
    // Methods
    (function (_P) {

        // Bind 3D scene with DOM element
        // elContainer: (DOM Element) The DOM element that contain 3D scene.
        // heightOffs: (Number) The height offset of canvas, default is 0.
        _P.bind = function (elContainer) {
            var me = this;

            me.el = elContainer;
            
            // The view control
            me.viewControls = new OrbitControls(me.camera, elContainer);
            me.viewControls.target.set(0, 0, 0);
            me.viewControls.noZoom = me.noWheelZoom;
            me.viewControls.noPan = false;
            me.viewControls.autoRotate = false;
            me.viewControls.staticMoving = false;
            me.viewControls.dynamicDampingFactor = 0.15;
            me.viewControls.minDistance = me.hasFreeCamera ? 0 : 250;
            me.viewControls.maxDistance = 7000;
            if (!me.hasFreeCamera)
                me.viewControls.maxPolarAngle = Math.PI / 2; // Don't go underground

            elContainer.appendChild(me.renderer.domElement);
            me.updateSize();

            // The selector
            me.selector = new Selector(me.el, me.camera, me.scene, ezObs);

            // Attach size update on window resizing
            window.onresize = function () { me.updateSize(); };

            var _render = function () {
                requestAnimationFrame(_render);

                me.renderer.render(me.scene, me.camera);

                // Screen shot must be done in render function after renderer.render() call.
                if (me._wantScreenShot) { 
                    me._wantScreenShot = false;
                    me._screenShotResolve(me.renderer.domElement.toDataURL('image/jpeg', 0.5));
                }
            }
            _render();
            

            // ezObs.run('3dSceneBinded', me);
        }

        // Update size. Use it when size of canvas change.
        // This method is automatically attached to window.resize event on bind() call.
        _P.updateSize = function () {
            var me = this;
            me.width = me.el.offsetWidth;
            me.height = me.el.offsetHeight;

            // console.debug("Canvas parent dims => %d x %d", me.el.offsetWidth, me.el.offsetHeight);
            // me.el.firstChild.style.height = me.el.offsetHeight;

            // Update camera
            me.renderer.setSize(me.width, me.height);
            me.camera.aspect = me.width / me.height;
            me.camera.updateProjectionMatrix();

            // Selector
            if (me.selector) me.selector.setSize(me.width, me.height);
        }

        // Add mesh in 3D scene
        _P.add = function (mesh) {
            this.scene.add(mesh);
        }

        // Remove mesh from 3D scene
        _P.remove = function (mesh) {
            this.scene.remove(mesh);
        }

        // Create and return texture
        // texModel: (THREE.Texture) The texture that used as model (shares the image)
        // ur: (Number | undefined) Texture repetition along U. Default is 1.
        // vr: (Number | undefined) Texture repetition along V. Default is 1.
        // _P.createTexture = function (texModel, ur, vr) {
        //     const tex = new _three_.Texture(texModel.image);
        //     tex.clone(texModel);
        //     tex.needsUpdate = true;
        //     tex.wrapS = tex.wrapT = _three_.RepeatWrapping;
        //     tex.repeat.set(ur || 1, vr || 1);
        //     return tex;
        // }

        // Load a texture from the specified image URL
        // urlStr: (String) The url of the image
        // Return promise with loaded texture
        _P.loadTexture = function(urlStr) {
            const prom = new Promise((resolve, reject) => { 
                this._texLoader.load(urlStr, 
                    (tex) => { 
                        //console.debug(">>>> Texture image '%s' is loaded.", key)
                        tex.encoding = _three_.sRGBEncoding;
                        resolve(tex)
                    },

                    undefined, // onProgress

                    (err) => { reject(err) }
                );
            });

            return prom;
        }

        // _P._isSameOrigin = function(urlStr) {
        //     if (urlStr.indexOf("http") === -1) return true; // is relative
        //     var url = new URL(urlStr);
        //     return url.origin === window.location.origin;
        // }

        // Create and return PHONG material
        // args: { The material description
        //  color: (Number) The color of material, ex: 0xCC00AA. Mutual exclusive with texture.
        //  texture: (Object) The texture of material. Mutual exclusive with color.
        //  [reflectivity]: (Number) The reflectivity of material. Default is 0.
        //  [opacity]: (Number) The opacity of material. Default is 1.
        //  [visible]: (Boolean) If true material is visible. Default is true.
        //  [envMap]: (Object) The environment map to simulate room reflection. Default is null.
        // }
        _P.createMaterial = function (args) {
            var me = this;

            var p = {};
            if (args.color) p.color = args.color
            else p.map = args.texture;

            var mat = new _three_.MeshPhongMaterial(p);

            if (args.hasOwnProperty('visible'))
                mat.visible = args.visible;

            if (args.color && me.colorCorr !== 0) {
                var c = me.colorCorr;
                mat.color.r -= c;
                mat.color.g -= c;
                mat.color.b -= c;
                if (mat.color.r < 0) mat.color.r = 0.0;
                if (mat.color.g < 0) mat.color.g = 0.0;
                if (mat.color.b < 0) mat.color.b = 0.0;
            }

            mat.combine = _three_.MixOperation;
            mat.reflectivity = args.reflectivity || 0;
            if (args.opacity < 1) {
                mat.opacity = args.opacity;
                mat.transparent = true;
            }

            // Environment map
            if (args.envMap) {
                mat.envMap = args.envMap;
                mat.shading = _three_.SmoothShading;
            }

            return mat;
        }

        // Create and return basic material
        // args: {
        //   color: (Number) The color of material, ex: 0xCC00AA.
        // }
        _P.createBasicMaterial = function (args) { return new _three_.MeshBasicMaterial(args) }

        // Create and return line basic material
        // args: {
        //   color: (Number) The color of material, ex: 0xCC00AA.
        // }
        _P.createLineMaterial = function (args) { return new _three_.LineBasicMaterial(args) }

        // Create vector with 3 parameters
        _P.createVector3 = function (x, y, z) { return new _three_.Vector3(x, y, z) }

        // Create and return box geometry
        // xdim: (Number) Dimension of box along X axis.
        // ydim: (Number) Dimension of box along Y axis.
        // zdim: (Number) Dimension of box along Z axis.
        _P.createBox = function (xdim, ydim, zdim) {
            return new _three_.BoxBufferGeometry(xdim, ydim, zdim);
        }

        // Create line geometry from 2 points as Vector3
        _P.createLineGeom = function (v1, v2) {
            var g = new _three_.BufferGeometry();
            g.vertices.push(v1);
            g.vertices.push(v2);
            return g;
        }

        // Create cylinder geometry from radius and length along Y axis.
        // r: (Number) Radius of cylinder.
        // l: (Number) Length of cylinder.
        _P.createCylinder = function (r, l) { return new _three_.CylinderGeometry(r, r, l); }

        // Create cone geometry from radius and height along Y Axis
        // r: (Number) Base radius of cone.
        // h: (Number) Height of cone.
        _P.createCone = function (r, h) { return new _three_.ConeGeometry(r, h); }

        // Create extrustion based on shape section.
        // s: (Shape) The section as is returned by svgToPath() method.
        // l: (Number) The length of extrusion.
        _P.createExtrusion = function (s, l) { return new _three_.ExtrudeGeometry(s, { amount: l, bevelEnabled: false }); }
        //_P.createExtrusion = function (s, l) { return new _three_.CylinderGeometry(4, 4, l); }

        // Create a mesh based on geometry and material
        // geom: (Object) The geometry as is returned by createBox() method for example.
        // mat: (Object) The material as is returned by createMaterial() method for example.
        _P.createMesh = function (geom, mat) { return new _three_.Mesh(geom, mat); }

        // Create an 3D object. Useful to be container of mesh. 
        _P.createObject3D = function () { return new _three_.Object3D(); }

        // Create an empty geometry. 
        _P.createGeometry = function () { return new _three_.BufferGeometry(); }

        // Create line from line geometry. See createLineGeom().
        _P.createLine = function (lineGeom, mat) { return new _three_.Line(lineGeom, mat); }

        // Create wireframe for the specified geometry
        _P.createGeomWireframe = function (geom, mat) {
            var wireframe = new _three_.WireframeGeometry(geom);
            var lines = new _three_.LineSegments(wireframe, mat);
            //lines.material.depthTest = false;
            //lines.material.opacity = 0.25;
            lines.material.transparent = false;

            return lines;
        }

        // Create wireframe for the specified object 3D
        _P.createWireframe = function (object3D, mat) {
            const wireframe = this.createObject3D();
            for (var i = 0; i < object3D.children.length; i++) {
                if (object3D.children[i].geometry !== undefined)
                    wireframe.add(this.createGeomWireframe(object3D.children[i].geometry, mat));
            }
            return wireframe;
        }

        // Clone THREE object 3D and set THREE material to cloned object 
        _P.cloneObject3D = function(object3D, threeMat) {
            const clone = object3D.clone();
            for (var i = 0; i < clone.children.length; i++) {
                if (clone.children[i].material !== undefined)
                    clone.children[i].material = threeMat;
            }
            return clone;
        }

        // Dispose object 3D. Useful to release a clone or wireframe.
        _P.disposeObject3D = function (object3D) {
            for (var i = 0; i < object3D.children.length; i++) {
                if (object3D.children[i].geometry !== undefined)
                    object3D.children[i].geometry.dispose();
            }
        }

        // Create 2 dimensionals grid to display in 3D environment.
        // size: (Number) Size of grid.
        // step: (Number) Number of square in grid.
        // color: (Color) The color of grid.
        _P.createGrid = function (size, step, color) {
            return new _three_.GridHelper(size, size / step, color, color);
        }

        // set geometry of existing mesh
        // mesh: (Object) The mesh
        // geom: (Object) The new geometry of mesh
        _P.setGeom = function (mesh, geom) { mesh.geometry = geom; }

        // Load OBJ file and return promise with 3D Object (container of meshes).
        // url: (String) Url of obj file.
        // Returns: (Object) The loaded object as : { object3D: (THREE.Object3D), bndMin: (THREE.Coord3), bndMax: (THREE.Coord3) }
        _P.loadObj = function (url) {
            var me = this;
           
            var prom = new Promise(function (resolve, reject) {

                if (_three_) {

                    if (!me.objLoader) me.objLoader = new OBJLoader(); //new _three_.ObjectLoader();

                    me.objLoader.load(url, function (obj3D) { // on success

                        var bb = new _three_.Box3();

                        obj3D.traverse(function (child) {
                            if (child instanceof _three_.Mesh) {
                                child.geometry.computeVertexNormals();
                                child.geometry.computeBoundingBox();
                                var childBB = child.geometry.boundingBox;
                                bb.expandByPoint(childBB.min);
                                bb.expandByPoint(childBB.max);
                            }
                        });

                        resolve({
                            object3D: obj3D,
                            bndMin: bb.min,
                            bndMax: bb.max
                        });
                    },
                    function () { }, // on progress
                    function (err) { // on error
                        reject(err);
                    });

                }
                else {
                    resolve({
                        object3D: null,
                        bndMin: null,
                        bndMax: null
                    });
                }


            });

            return prom;
        }

        // Set material of 3D object
        // obj: (Object) Can be a mesh or a collection of mesh.
        // mat: (Object) Material (see createMaterial() method).
        _P.setMaterial = function (obj, mat) {
            if (obj instanceof _three_.Mesh) {
                obj.material = mat;
                return;
            }
            else if (obj.children) {
                var n = obj.children.length, i;
                for (i = 0; i < n; i++) {
                    if (obj.children[i] instanceof _three_.Mesh)
                        obj.children[i].material = mat;
                }
            }
        }

        // Convert an SVG path to shape
        // pathStr: (String) The SVG path.
        _P.svgPathToShape = function (pathStr) {
            var DEGS_TO_RADS = Math.PI / 180, UNIT_SIZE = 100;
            var DIGIT_0 = 48, DIGIT_9 = 57, COMMA = 44, SPACE = 32, PERIOD = 46, MINUS = 45;
            var path = new _three_.Shape();
            var idx = 1, len = pathStr.length, activeCmd,
                x = 0, y = 0, nx = 0, ny = 0, firstX = null, firstY = null,
                x1 = 0, x2 = 0, y1 = 0, y2 = 0,
                rx = 0, ry = 0, xar = 0, laf = 0, sf = 0, cx, cy;
            function eatNum() {
                var sidx, c, isFloat = false, s;
                // eat delims
                while (idx < len) {
                    c = pathStr.charCodeAt(idx);
                    if (c !== COMMA && c !== SPACE)
                        break;
                    idx++;
                }
                if (c === MINUS)
                    sidx = idx++;
                else
                    sidx = idx;
                // eat number
                while (idx < len) {
                    c = pathStr.charCodeAt(idx);
                    if (DIGIT_0 <= c && c <= DIGIT_9) {
                        idx++;
                        continue;
                    }
                    else if (c === PERIOD) {
                        idx++;
                        isFloat = true;
                        continue;
                    }
                    s = pathStr.substring(sidx, idx);
                    return isFloat ? parseFloat(s) : parseInt(s, 10);
                }
                s = pathStr.substring(sidx);
                return isFloat ? parseFloat(s) : parseInt(s, 10);
            }
            function nextIsNum() {
                var c;
                // do permanently eat any delims...
                while (idx < len) {
                    c = pathStr.charCodeAt(idx);
                    if (c !== COMMA && c !== SPACE)
                        break;
                    idx++;
                }
                c = pathStr.charCodeAt(idx);
                return (c === MINUS || (DIGIT_0 <= c && c <= DIGIT_9));
            }
            var canRepeat;
            activeCmd = pathStr[0];
            while (idx <= len) {
                canRepeat = true;
                switch (activeCmd) {
                    // moveto commands, become lineto's if repeated
                    case 'M':
                        x = eatNum();
                        y = eatNum();
                        path.moveTo(x, y);
                        activeCmd = 'L';
                        firstX = x;
                        firstY = y;
                        break;
                    case 'm':
                        x += eatNum();
                        y += eatNum();
                        path.moveTo(x, y);
                        activeCmd = 'l';
                        firstX = x;
                        firstY = y;
                        break;
                    case 'Z':
                    case 'z':
                        canRepeat = false;
                        if (x !== firstX || y !== firstY)
                            path.lineTo(firstX, firstY);
                        break;
                    // - lines!
                    case 'L':
                    case 'H':
                    case 'V':
                        nx = (activeCmd === 'V') ? x : eatNum();
                        ny = (activeCmd === 'H') ? y : eatNum();
                        path.lineTo(nx, ny);
                        x = nx;
                        y = ny;
                        break;
                    case 'l':
                    case 'h':
                    case 'v':
                        nx = (activeCmd === 'v') ? x : (x + eatNum());
                        ny = (activeCmd === 'h') ? y : (y + eatNum());
                        path.lineTo(nx, ny);
                        x = nx;
                        y = ny;
                        break;
                    // - cubic bezier
                    case 'C':
                        x1 = eatNum(); y1 = eatNum();
                    case 'S':
                        if (activeCmd === 'S') {
                            x1 = 2 * x - x2; y1 = 2 * y - y2;
                        }
                        x2 = eatNum();
                        y2 = eatNum();
                        nx = eatNum();
                        ny = eatNum();
                        path.bezierCurveTo(x1, y1, x2, y2, nx, ny);
                        x = nx; y = ny;
                        break;
                    case 'c':
                        x1 = x + eatNum();
                        y1 = y + eatNum();
                    case 's':
                        if (activeCmd === 's') {
                            x1 = 2 * x - x2;
                            y1 = 2 * y - y2;
                        }
                        x2 = x + eatNum();
                        y2 = y + eatNum();
                        nx = x + eatNum();
                        ny = y + eatNum();
                        path.bezierCurveTo(x1, y1, x2, y2, nx, ny);
                        x = nx; y = ny;
                        break;
                    // - quadratic bezier
                    case 'Q':
                        x1 = eatNum(); y1 = eatNum();
                    case 'T':
                        if (activeCmd === 'T') {
                            x1 = 2 * x - x1;
                            y1 = 2 * y - y1;
                        }
                        nx = eatNum();
                        ny = eatNum();
                        path.quadraticCurveTo(x1, y1, nx, ny);
                        x = nx;
                        y = ny;
                        break;
                    case 'q':
                        x1 = x + eatNum();
                        y1 = y + eatNum();
                    case 't':
                        if (activeCmd === 't') {
                            x1 = 2 * x - x1;
                            y1 = 2 * y - y1;
                        }
                        nx = x + eatNum();
                        ny = y + eatNum();
                        path.quadraticCurveTo(x1, y1, nx, ny);
                        x = nx; y = ny;
                        break;
                    // - elliptical arc
                    case 'A':
                        rx = eatNum();
                        ry = eatNum();
                        xar = eatNum() * DEGS_TO_RADS;
                        laf = eatNum();
                        sf = eatNum();
                        nx = eatNum();
                        ny = eatNum();
                        if (rx !== ry) {
                            $log.warn(`Forcing elliptical arc to be a circular one :(${rx},${ry})`);
                        }
                        // SVG implementation notes does all the math for us! woo!
                        // http://www.w3.org/TR/SVG/implnote.html#ArcImplementationNotes
                        // step1, using x1 as x1'
                        x1 = Math.cos(xar) * (x - nx) / 2 + Math.sin(xar) * (y - ny) / 2;
                        y1 = -Math.sin(xar) * (x - nx) / 2 + Math.cos(xar) * (y - ny) / 2;
                        // step 2, using x2 as cx'
                        var norm = Math.sqrt(
                            (rx * rx * ry * ry - rx * rx * y1 * y1 - ry * ry * x1 * x1) /
                            (rx * rx * y1 * y1 + ry * ry * x1 * x1));
                        if (laf === sf)
                            norm = -norm;
                        x2 = norm * rx * y1 / ry;
                        y2 = norm * -ry * x1 / rx;
                        // step 3
                        cx = Math.cos(xar) * x2 - Math.sin(xar) * y2 + (x + nx) / 2;
                        cy = Math.sin(xar) * x2 + Math.cos(xar) * y2 + (y + ny) / 2;
                        var u = new _three_.Vector2(1, 0),
                            v = new _three_.Vector2((x1 - x2) / rx,
                                (y1 - y2) / ry);
                        var startAng = Math.acos(u.dot(v) / u.length() / v.length());
                        if (u.x * v.y - u.y * v.x < 0)
                            startAng = -startAng;
                        // we can reuse 'v' from start angle as our 'u' for delta angle
                        u.x = (-x1 - x2) / rx;
                        u.y = (-y1 - y2) / ry;
                        var deltaAng = Math.acos(v.dot(u) / v.length() / u.length());
                        // This normalization ends up making our curves fail to triangulate...
                        if (v.x * u.y - v.y * u.x < 0)
                            deltaAng = -deltaAng;
                        if (!sf && deltaAng > 0)
                            deltaAng -= Math.PI * 2;
                        if (sf && deltaAng < 0)
                            deltaAng += Math.PI * 2;
                        path.absarc(cx, cy, rx, startAng, startAng + deltaAng, sf);
                        x = nx;
                        y = ny;
                        break;
                    default:
                        throw new Error("weird path command: " + activeCmd);
                }
                // just reissue the command
                if (canRepeat && nextIsNum())
                    continue;
                activeCmd = pathStr[idx++];
            }
            return path;
        }

        // Get the selector
        _P.getSelector = function () { return this.selector; }

        // Create a ThreeJS material according to texture and _3DMaterial.
        // The texture wrapping respect the panel dimensions. If colour has color, panel dimensions are ignored.
        // args: { 
        //   clr: (IColour) The colour to apply
        // }
        _P.createPanelMat = function (clr) {
            const me = this;
            const tex = clr.texture;
            const tdmat = clr._3DMaterial;

            var threeMat;
            var matParams = {};
            if (clr.HasColor) { // Colour has color
                // Try to find in _three_ material color map
                var existing = threeMatMap.get(tdmat.Id, clr.Color);
                if (existing) return existing;

                matParams.color = parseInt(clr.Color, 16);
            }
            else { // Texture is picture
                // With real texture scale : matParams.texture = me.createTexture(tex.threeTexture, args.l / tex.RealLength, args.w / tex.RealWidth);
                matParams.texture = tex.threeTexture;
            }

            matParams.reflectivity = matParams.Reflectivity;
            matParams.opacity = matParams.opacity;
            //matParams.envMap = if necessary ...;
            threeMat = me.createMaterial(matParams);

            if (clr.HasColor) // Add in _three_ colored material in map
                threeMatMap.add(tdmat.Id, clr.Color, threeMat);

            return threeMat;
        }

        // Update texture repeat of threeMat according to panel dimension w and l
        // args: {
        //  threeMat: (THREE.Material) The material with map
        //  w: (Number) The width of panel
        //  l: (Number) The length of panel
        //  realW: (Number) The real width represented by texture image
        //  realL: (Number) The real length represented by texture image
        // }
        _P.updateTextureRep = function (args) {
            if (args.threeMat.map) args.threeMat.map.repeat.set(args.l / args.realL, args.w / args.realW);
        }

        // Return material of zone
        _P.getZoneMat = function () {
            var me = this;
            if (!me._autoZoneMat) {
                me._autoZoneMat = new _three_.MeshLambertMaterial({ color: me.zoneColor });
                me._autoZoneMat.transparent = true;
                me._autoZoneMat.opacity = 0.40;
            }

            return me._autoZoneMat
        }

        // Return material of hidden zone (used for design mode)
        _P.getHiddenZoneMat = function () {
            var me = this;
            if (!me._hiddenZoneMat) {
                me._hiddenZoneMat = me.createBasicMaterial({ color: 0x000000 });
                me._hiddenZoneMat.visible = false;
            }
            return me._hiddenZoneMat;
        }

        // Return warning material
        _P.getWarnMat = function () {
            if (!this._workWarnMat)
                this._workWarnMat = new _three_.MeshLambertMaterial({ color: 0xE25600 });
            return this._workWarnMat
        }

        // Return error material
        _P.getErrorMat = function () {
            if (!this._workErrMat)
                this._workErrMat = new _three_.MeshLambertMaterial({ color: 0xCC0000 });
            return this._workErrMat
        }

        // Return material of selected zone
        _P.getSelectedZoneMat = function () {
            var me = this;
            if (!me._selectedZoneMat)
                me._selectedZoneMat = new _three_.MeshLambertMaterial({ color: me.ZoneSelectionColor });
            return me._selectedZoneMat;
        }

        // Return panel material for 'work' scene mode.
        // visible: (Boolean) If true get visible material.
        // issueStatus: (String) Current issue status of pannel : 'OK' | 'WARN' | 'ERR'
        _P.getWorkPanelMat = function (visible, issueStatus) {
            var me = this;
            if (visible) {
                
                switch (issueStatus) {

                    case 'WARN':
                        return me.getWarnMat();

                    case 'ERR':
                        return me.getErrorMat();

                    default: // OK
                        if (!me._WorkPanelMat)
                            me._WorkPanelMat = new _three_.MeshLambertMaterial({ color: me.workPanelColor });
                        return me._WorkPanelMat;
                }

            }
            else {
                if (!me._WorkHiddenPanelMat)
                    me._WorkHiddenPanelMat = new _three_.MeshLambertMaterial({ visible: false });
                return me._WorkHiddenPanelMat;
            }
        }

        // Return swing-door symbol material for 'work' scene mode.
        _P.getSwingDoorSymbolMat = function () {
            var me = this;
            if (!me._swingDoorSymbolMat)
                me._swingDoorSymbolMat = new _three_.LineBasicMaterial({ color: 0x343434 }); 
            
            return me._swingDoorSymbolMat;
        }

        // Return accessory error material for 'work' scene mode.
        _P.getAccessoryErrorMat = function () {
            var me = this;
            if (!me._accessoryErrorMat)
                me._accessoryErrorMat = new _three_.LineBasicMaterial({ color: 0xCC0000 });

            return me._accessoryErrorMat;
        }

        // Return hinge material for 'work' scene mode.
        _P.getHingeMat = function (status) {
            var me = this;

            switch (status) {
                
                case 'WARN':
                    if (!me._hingeWarnMat)
                        me._hingeWarnMat = new _three_.MeshLambertMaterial({ color: me._warnColor });
                    return me._hingeWarnMat;

                case 'ERR':
                    if (!me._hingeErrMat)
                        me._hingeErrMat = new _three_.MeshLambertMaterial({ color: me._errColor });
                    return me._hingeErrMat;

                default: // OK
                    if (!me._hingeMat)
                        me._hingeMat = new _three_.MeshLambertMaterial({ color: 0x343434 });
                    return me._hingeMat;
            }
                        
        }

        // Return profile mateiral for 'work' scene mode.
        _P.getProfileMat = function () {
            var me = this;
            if (!me._profileMat)
                me._profileMat = new _three_.MeshLambertMaterial({ color: 0xcccccc });

            return me._profileMat;
        }

        // Take a screenshot of current 3D view.
        // Returns: Promise with image data as toDataURL() function result.
        _P.takeScreenshot = function () {
            var me = this;

            var prom = new Promise(function (resolve, reject) {
                me._screenShotResolve = resolve;
                me._wantScreenShot = true;
            });

            return prom;
            //return this.renderer.domElement.toDataURL('image/jpeg', 0.5);
        };

        // Manage 3D state
        _P.disable3D = function () { this._3DEnabled = false; $log.log("ez3D => 3D is disabled."); }
        _P.has3D = function () { return this._3DEnabled }

        // Zoom in, zoom out features
        _P.zoomIn = function () { this.viewControls.dollyIn(Math.pow(0.95, 2)); this.viewControls.update(); }
        _P.zoomOut = function () { this.viewControls.dollyOut(Math.pow(0.95, 2)); this.viewControls.update(); }

    })(ez3D.prototype);


    ////// Singleton
    return new ez3D();
}


////// EXPORT
export default {
    $init: _init
}