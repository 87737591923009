﻿///// Init method for injection
function _init(dcies, $ez, ezSplit, ezZone, ezPanel, ezSwingDoor, ezSchema, ezMinMaxResolver, ezPrice) {

    var $log = $ez.getLogger();


    /////// Class CNComputeParam
    var CNComputeParam = function (node) {
        var me = this;
        me.node = node;
        me.closestBack = null;
        me.closestFront = null;
        me.closestTop = null;
        me.closestBottom = null;
        me.aboveSeparator = null;
        me.belowSeparator = null;
        me.HSepMargins = { front: 0, back: 0 };
        me.VSepMargins = { front: 0, back: 0 };
        me.hitTop = false;
    };
    // Methods
    (function (_P) {

        // Update compute params with the current parts of node.
        // Call this method after compute of parts
        // parentParam : (CNComputeParam) The compute params of parent. Can be null.
        _P.updateFormParts = function (parentParam) {
            var me = this;

            me.closestBack = parentParam ? parentParam.closestBack : null;
            me.closestFront = parentParam ? parentParam.closestFront : null;
            me.closestTop = parentParam ? parentParam.closestTop : null;
            me.closestBottom = parentParam ? parentParam.closestBottom : null;
            me.belowSeparator = parentParam ? parentParam.belowSeparator : null;
            me.aboveSeparator = parentParam ? parentParam.aboveSeparator : null;

            if (me.node.parts) {
                if (me.node.parts.back) me.closestBack = me.node.parts.back;
                if (me.node.parts.front) me.closestFront = me.node.parts.front;
                if (me.node.parts.top) me.closestTop = me.node.parts.top;
                if (me.node.parts.bottom) me.closestBottom = me.node.parts.bottom;
            }

            me.HSepMargins.front = parentParam ? parentParam.HSepMargins.front : 0;
            me.HSepMargins.back = parentParam ? parentParam.HSepMargins.back : 0;

            me.VSepMargins.front = parentParam ? parentParam.VSepMargins.front : 0;
            me.VSepMargins.back = parentParam ? parentParam.VSepMargins.back : 0;
        }

        //[DEPRECATED] : since sub-tree was aborted
        _P.isRealRoot = function () { return (this.node.parent === null); }

        _P.hasClosestBack = function () { return this.closestBack !== null || (this.node.closet.getDriver() && this.node.closet.getDriver().isSharedModule(this.node)); }

        _P.hasClosestFront = function () { return this.closestFront !== null; }

        _P.getClosestBack = function () { return this.closestBack; }

        _P.getClosestFront = function () { return this.closestFront; }

        _P.hasClosestTop = function () { return this.closestTop !== null; }

        _P.hasClosestBottom = function () { return this.closestBottom !== null; }

        _P.getClosestTop = function () { return this.closestTop; }

        _P.getClosestBottom = function () { return this.closestBottom; }

        _P.getSeparatorBelow = function () { return this.belowSeparator; }
        _P.getSeparatorAbove = function () { return this.aboveSeparator; }

        _P.hasSeparatorBelow = function () { return this.belowSeparator !== null; }
        _P.hasSeparatorAbove = function () { return this.aboveSeparator !== null; }


        // Gets horizontal separator margins.
        // Returns: (Object) margins as { front: (Number), back: (Number) }
        _P.getHSepMargins = function () { return this.HSepMargins; }

        // Sets horizontal separator margins.
        _P.setHSepMargins = function (front, back) { this.HSepMargins.front = front; this.HSepMargins.back = back; }

        // Gets vertical separator margins.
        // Returns: (Object) margins as { front: (Number), back: (Number) }
        _P.getVSepMargins = function () { return this.VSepMargins; }

        // Sets vertical separator margins.
        _P.setVSepMargins = function (front, back) { this.VSepMargins.front = front; this.VSepMargins.back = back; }

        // Gets or sets hit top status
        _P.setHitTop = function (hitTop) { this.hitTop = hitTop; }
        _P.getHitTop = function () { return this.hitTop; }

    })(CNComputeParam.prototype);

    /////// Class CNIssues
    // Bief: collection of CNIssue mapped on their id
    // Constructor
    // node: (CNode) The node relative to these issues
    var CNIssues = function (node) {
        var me = this;
        me._node = node;
        me._moduleOffWidth = 'OK';
        me._topOffWidth = 'OK';
        me._bottomOffWidth = 'OK';
        me._backOffWidth = 'OK';
        me._consolidatorWithContent = 'OK';
    };
    (function (_P) {

        // Gets module off-width status
        _P.getModuleOffWidth = function () { return this._moduleOffWidth; }

        // Gets top off-width status
        _P.getTopOffWidth = function () { return this._topOffWidth; }

        // Gets bottom off-width status
        _P.getBottomOffWidth = function () { return this._bottomOffWidth; }

        // Returns true if back is off-width
        _P.hasBackOffWidth = function () { return this._backOffWidth === 'ERR'; }

        // Return true if consolidator with content conflict was detected
        _P.hasConsolidatorWithContent = function () { return this._consolidatorWithContent === 'ERR'; }

        // Clear all status to 'OK'
        _P._clear = function () {
            this._moduleOffWidth = 'OK';
            this._topOffWidth = 'OK';
            this._bottomOffWidth = 'OK';
            this._backOffWidth = 'OK';
            this._consolidatorWithContent = 'OK';
        }

        // Do validation of relative node before part compute
        _P.doValidationPreParts = function (parentComputeParam) {
            var me = this;
            var SP = me._node.closet.getSchemaProvider();
            var CM = me._node.closet.getClosetModel();
            var snCount = me._node.subNodes.getLength();

            me._clear();

            var isModelWithConsolidator = CM.Consolidator && CM.Consolidator.Height > 0;

            if (me._node.isModule()) {
                if (me._node.innerZone.xdim > CM.BackMaxWidth) { // off-width ERR
                    if (!isModelWithConsolidator) me._moduleOffWidth = 'ERR'; // module
                    if (me._node.schema.hasBack(me._node.schOpts)) me._backOffWidth = 'ERR'; // back
                    if (!isModelWithConsolidator && SP.getSchema1().HasTop) me._topOffWidth = 'ERR'; // Top
                    if (!isModelWithConsolidator && SP.getSchema1().HasBottom && !SP.getSchema1().HasBottomBand) me._bottomOffWidth = 'ERR'; // Bottom
                }
                else if (!isModelWithConsolidator && me._node.innerZone.xdim > CM.MaxZoneWidth) { // off-width WARN
                    me._moduleOffWidth = 'WARN'; // module
                    if (SP.getSchema1().HasTop) me._topOffWidth = 'WARN'; // Top
                    if (SP.getSchema1().HasBottom && !SP.getSchema1().HasBottomBand) me._bottomOffWidth = 'WARN'; // Bottom
                }
            }

            // If consolidator will be added
            // TODO : this test should be factorized because is used in CNParts too.
            if (isModelWithConsolidator && // Model has consolidator activated
                snCount === 0 && // No sub-nodes
                !me._node.hitTop(parentComputeParam ? parentComputeParam.getHitTop() : undefined) && // No contact with top
                me._node.outerZone.isOffWidth(CM.Consolidator.ApplyOnWidth)) { // Width out-of dimension
                if (/*me._node.parts.front ||*/ me._node.findItem(function (it) { return it.getEzType() === 'DrawerGroup'; }))
                    me._consolidatorWithContent = 'ERR';
            }
        }

        // Return off-width column message dedicated to user, according issue status.
        // status : (String) The issue status : 'WARN' | 'ERR'
        // colCount: (Number) The number of off-width columns 
        _P.getOffWidthMsg = function (status, colCount) {
            var CM = this._node.closet.getClosetModel();

            switch (status) {
                case 'WARN':
                    return colCount === 1 ? colCount + " colonne dépasse " + CM.MaxZoneWidth + " cm de large" : colCount + " colonnes dépassent " + CM.MaxZoneWidth + " cm de large";
                case 'ERR':
                    return colCount === 1 ? colCount + " colonne dépasse " + CM.BackMaxWidth + " cm de large" : colCount + " colonnes dépassent " + CM.BackMaxWidth + " cm de large";
                default:
                    $ez.THROW("Unsupported status '" + status + "'. Cannot get off-width column user message.");
            }
        }

        // Return off-width back message dedicated to user, according issue status.
        // status : (String) The issue status : 'WARN' | 'ERR'
        // backCount: (Number) The number of off-width back parts
        _P.getOffWidthBackMsg = function (status, backCount) {
            var CM = this._node.closet.getClosetModel();

            switch (status) {
                case 'ERR':
                    return backCount === 1 ? backCount + " fond dépasse " + CM.BackMaxWidth + " cm de large" : backCount + " fonds dépassent " + CM.BackMaxWidth + " cm de large";
                default:
                    $ez.THROW("Unsupported status '" + status + "'. Cannot get off-width back part user message.");
            }
        }

        // Return consolidator with content conflict message dedicated to user, according issue status.
        _P.getConsolidatorWithContentMsg = function (status, conflictCount) {
            switch (status) {
                case 'ERR':
                    return conflictCount === 1 ? conflictCount + " raidisseur est en conflit avec un tiroir." : conflictCount + " raidisseurs sont en conflit avec une porte ou un tiroir.";
                default:
                    $ez.THROW("Unsupported status '" + status + "'. Cannot get consolidator with content conflict user message.");
            }
        }

    })(CNIssues.prototype);

    /////// Class CNParts
    // Brief: Manager CNode parts like left, right, top, bottom, etc ...
    // Constructor
    // closet : (Closet) Closet that owns parts.
    var CNParts = function (closet) {
        var me = this;
        me.closet = closet;
        me.left = null;
        me.right = null;
        me.bottom = null;
        me.top = null;
        me.back = null;
        me.front = null;
        me.topBand = null;
        me.bottomBandFront = null;
        me.bottomBandBack = null;
        me.frontConsolidator = null;
        me.backConsolidator = null;
        me.marginProvider = null;
        me.computeParam = null;

        me.visualMargins = null; // null or object { front: 0.2, ... }

        // Margins of part according other part of same level. Useful since baseboard.
        me._bottomBackMargin = 0; // back margin of bottom part
        me._topBackMargin = 0; // back margin of top part

        // For different column height
        me.topLeftUserMargin = 0; // The user margin on top of left part
        me.topRightUserMargin = 0; // The user margin on top of right part
    };
    // Methods
    (function (_P) {

        // Update inner-zone dimension of CNode according to specified schema.
        // schema: (CNSchema) the schema that modify zone dimension. If null innerZone is not changed.
        // schOpts: (CNSchemaOptions) the schema otpions.
        // thickProvider: (ThickProvider) The provider of panel thicknesses
        // innerZone : (Zone) the inner-zone to update.
        _P.updateInnerZone = function (schema, schOpts, thickProvider, innerZone) {
            if (!schema) return;

            var me = this;

            innerZone.xdim -= schema.LeftMargin + schema.RightMargin;
            innerZone.ydim -= schema.BottomMargin + schema.TopMargin;
            innerZone.zdim -= schema.backMargin(schOpts);
            innerZone.x += (schema.LeftMargin / 2);
            innerZone.x -= (schema.RightMargin / 2);
            innerZone.y += (schema.BottomMargin / 2);
            innerZone.y -= (schema.TopMargin / 2);
            innerZone.z += (schema.backMargin(schOpts) / 2);
            innerZone.z -= (schema.frontMargin(schOpts) / 2);

            if (schema.HasLeft) {
                innerZone.xdim -= thickProvider.getLeft();
                innerZone.x += (thickProvider.getLeft() / 2);
            }

            if (schema.HasRight) {
                innerZone.xdim -= thickProvider.getRight();
                innerZone.x -= (thickProvider.getRight() / 2);
            }

            if (schema.HasBottom) {
                innerZone.ydim -= thickProvider.getBottom();
                innerZone.y += (thickProvider.getBottom() / 2);
            }

            if (schema.HasTop) {
                innerZone.ydim -= thickProvider.getTop();
                innerZone.y -= (thickProvider.getTop() / 2);
            }

            if (schema.hasBack(schOpts)) { // If back is present only
                //var baseboard = me.closet.getBaseboard();
                innerZone.zdim -= thickProvider.getBack() /*+ baseboard.getDepth()*/;
                innerZone.z += (thickProvider.getBack() / 2) /*+ (baseboard.getDepth() / 2)*/;
            }
            
            if (schema.hasFront(schOpts)) {
                if (!schOpts.frontDef) {
                    innerZone.zdim -= thickProvider.getFront();
                    innerZone.z -= (thickProvider.getFront() / 2);
                }
                else if (schOpts.frontDef.EZT === 'SWD') {
                    var im = me.closet.getClosetModel().FrontSeparatorMargin;
                    if (!me.closet.getClosetModel().HasSwingDoorOverlay) im += schOpts.frontDef.model.panelModel.thick.Value;
                    innerZone.zdim -= im;
                    innerZone.z -= (im / 2);
                }
            }
        }

        // Update the parts 
        _P.updateParts = function (schema, schOpts, thickProvider, marginProvider, parentCompParams, compParams, outerZone, innerZone) {
            if (!schema) return;

            var me = this;
            me.marginProvider = marginProvider;
            me.parentComputeParam = parentCompParams;
            me.computeParam = compParams;

            var panelModel = me.closet.getMainPanelModel();

            me._bottomBackMargin = 0; // computed by _updateBottom
            me._topBackMargin = 0; // computed by _updateTop

            me._updateLeft(schema, schOpts, outerZone, panelModel, thickProvider);
            me._updateRight(schema, schOpts, outerZone, panelModel, thickProvider);
            me._updateBottom(schema, schOpts, outerZone, panelModel, thickProvider);
            me._updateTop(schema, schOpts, outerZone, panelModel, thickProvider);
            me._updateBack(schema, schOpts, outerZone, thickProvider);
            me._updateFront(schema, schOpts, outerZone, panelModel, thickProvider);
            me._updateTopBand(schema, schOpts, outerZone, thickProvider);
            me._updateBottomBandFront(schema, schOpts, outerZone, thickProvider);
            me._updateBottomBandBack(schema, schOpts, outerZone, thickProvider);
            me._updateFrontConsolidator(schema, schOpts, outerZone, panelModel, thickProvider);
            me._updateBackConsolidator(schema, schOpts, outerZone, panelModel, thickProvider);

            // Baseboard is defined
            var baseboard = me.closet.getBaseboard();
            if (schema && baseboard.isDefined()) {

                if (me.back) {
                    me.back.compute(); // Required to check collision

                    if (parentCompParams) {
                        var back_ydim;
                        if (parentCompParams.hasClosestBottom())
                            back_ydim = me.back.ydim + (parentCompParams.closestBottom.ydim * 2);
                        else if (parentCompParams.hasSeparatorBelow())
                            back_ydim = me.back.ydim + (parentCompParams.belowSeparator.ydim * 2);
                        else
                            back_ydim = me.back.ydim;
                    }

                    if (baseboard.hasCollision(me.back.y, me.back.z, back_ydim, me.back.zdim)) {
                        me.back.positioner.offs = schema.BackMargin + baseboard.getDepth();
                        innerZone.zdim -= baseboard.getDepth();
                        innerZone.z += baseboard.getDepth() / 2;
                    }
                }

                if (me.bottom) {
                    me.bottom.compute(); // Required to check collision
                    if ((schema.hasBack(schOpts) && schema.HasBackPriorityOnBottom) || baseboard.hasCollision(me.bottom.y, me.bottom.z, me.bottom.ydim, me.bottom.zdim))
                        me.bottom.margins.back = me._bottomBackMargin + baseboard.getDepth();
                }

                if (me.top) {
                    me.top.compute(); // Required to check collision
                    if ((schema.hasBack(schOpts) && schema.HasBackPriorityOnTop) || baseboard.hasCollision(me.top.y, me.top.z, me.top.ydim, me.top.zdim))
                        me.top.margins.back = me._topBackMargin + baseboard.getDepth();
                }
            }

            if (me.back) me.back.update();
            if (me.bottom) me.bottom.update();
            if (me.top) me.top.update();

            if (me.bottomBandBack && me.bottom) {
                me.bottomBandBack.positioner.offs += me.bottom.margins.back;
                me.bottomBandBack.update();
            }

            me.parentComputeParam = null; // To free computeParam asap
        }

        // Update left part
        _P._updateLeft = function (sch, scho, outerZone, panelModel, thickProvider) {
            if (sch.HasLeft) {
                var me = this;
                var MP = me.marginProvider;

                if (!me.left) {
                    me.left = ezPanel.create(outerZone);
                    ezPanel.beOnSide(me.left, 'left', sch.LeftMargin);
                    me.left.title = "Joue gauche";
                    me.left.kind = $ez.KINDS.Left;
                    if (MP) me.left.setMargins({ front: MP.FrontLeftMargin, back: MP.BackLeftMargin });
                    me.left.setMaterialModel(panelModel);
                    me.left.thickness = thickProvider.getLeft();

                    if (scho.defaultLeftClr) {
                        me.left.setColour(scho.defaultLeftClr);
                        me.left.setHasOwnColour(true);
                    }
                }
                else {
                    me.left.positioner.zone = outerZone; // Zone can be changed by splitting !
                    me.left.thickness = thickProvider.getLeft();
                }

                me.left.addPrice = me.closet.model.AddPriceLeft;

                // Bottom margin (with bottom-band support)
                if (sch.HasBottomPriority)
                    me.left.margins.bottom = sch.HasBottom ? sch.BottomMargin + thickProvider.getBottom() : sch.BottomMargin;
                else
                    me.left.margins.top = 0; // MP.BottomLeftMargin does not exists yet

                // Top margin (with top-band support)
                if (sch.HasTopPriority)
                    me.left.margins.top = sch.HasTop ? sch.TopMargin + thickProvider.getTop() : sch.TopMargin;
                else
                    me.left.margins.top = MP ? MP.getTopLeftMargin() : 0;

                // Front margins
                var cornerMargins = me.closet.getDriver().getPartMargins(outerZone.relNode);
                me.left.margins.front = cornerMargins && cornerMargins.Left ? cornerMargins.Left.front : (MP ? MP.FrontLeftMargin : 0);

                // User margins
                me.left.setUserMargins({ top: me.topLeftUserMargin });

                me.left.update();
            }
        }

        // Update right part
        _P._updateRight = function (sch, scho, outerZone, panelModel, thickProvider) {
            if (sch.HasRight) {
                var me = this;
                var MP = me.marginProvider;

                if (!me.right) {
                    me.right = ezPanel.create(outerZone);
                    ezPanel.beOnSide(me.right, 'right', sch.RightMargin);
                    me.right.title = "Joue droite";
                    me.right.kind = $ez.KINDS.Right;
                    if (MP) me.right.setMargins({ front: MP.FrontRightMargin, back: MP.BackRightMargin });
                    me.right.setMaterialModel(panelModel);
                    me.right.thickness = thickProvider.getRight();

                    if (scho.defaultRightClr) {
                        me.right.setColour(scho.defaultRightClr);
                        me.right.setHasOwnColour(true);
                    }
                }
                else {
                    me.right.positioner.zone = outerZone;
                    me.right.thickness = thickProvider.getRight();
                }

                me.right.addPrice = me.closet.model.AddPriceRight;

                // Bottom margin (with bottom-band support)
                if (sch.HasBottomPriority)
                    me.right.margins.bottom = sch.HasBottom ? sch.BottomMargin + thickProvider.getBottom() : sch.BottomMargin;
                else
                    me.right.margins.top = 0; // MP.BottomRightMargin does not exists yet

                // Top margin (with top-band support)
                if (sch.HasTopPriority)
                    me.right.margins.top = sch.HasTop ? sch.TopMargin + thickProvider.getTop() : sch.TopMargin;
                else
                    me.right.margins.top = MP ? MP.getTopRightMargin() : 0;

                // Front margins
                var cornerMargins = me.closet.getDriver().getPartMargins(outerZone.relNode);
                me.right.margins.front = cornerMargins && cornerMargins.Right ? cornerMargins.Right.front : (MP ? MP.FrontRightMargin : 0);

                // User margins
                me.right.setUserMargins({ top: me.topRightUserMargin });

                me.right.update();
            }
        }

        // Update bottom part
        _P._updateBottom = function (sch, scho, outerZone, panelModel, thickProvider) {
            if (sch.HasBottom) {
                var me = this;
                var MP = me.marginProvider;

                if (!me.bottom) {
                    me.bottom = ezPanel.create(outerZone);
                    ezPanel.beOnSide(me.bottom, 'bottom', sch.BottomMargin);
                    me.bottom.title = "Dessous";
                    me.bottom.kind = $ez.KINDS.Bottom;
                    if (MP) me.bottom.setMargins({ front: MP.FrontBottomMargin });
                    me.bottom.setMaterialModel(panelModel);
                    me.bottom.thickness = thickProvider.getBottom();
                }
                else {
                    me.bottom.positioner.zone = outerZone;
                    me.bottom.thickness = thickProvider.getBottom();
                }

                me.bottom.addPrice = me.closet.model.AddPriceBottom;

                // Width validation
                me.bottom.setIssueStatus(outerZone.relNode._issues.getBottomOffWidth());

                // Margins
                if (sch.HasBottomPriority) {
                    me.bottom.margins.left = sch.LeftMargin + 0; // MP.LeftBottomMargin does not exists yet
                    me.bottom.margins.right = sch.RightMargin + 0; // MP.RightBottomMargin does not exists yet
                }
                else {
                    var cornerMargins = me.closet.getDriver().getPartMargins(outerZone.relNode);
                    me.bottom.margins.left = sch.HasLeft ? sch.LeftMargin + thickProvider.getLeft() : (cornerMargins && cornerMargins.Bottom ? cornerMargins.Bottom.left : 0) + sch.LeftMargin;
                    me.bottom.margins.right = sch.HasRight ? sch.RightMargin + thickProvider.getRight() : (cornerMargins && cornerMargins.Bottom ? cornerMargins.Bottom.right : 0) + sch.RightMargin;
                }

                me._bottomBackMargin = (sch.hasBack(scho) && sch.HasBackPriorityOnBottom ? sch.BackMargin + thickProvider.getBack() : (MP ? MP.BackBottomMargin : 0));
                me.bottom.margins.back = me._bottomBackMargin;                
            }
        }

        // Update top part
        _P._updateTop = function (sch, scho, outerZone, panelModel, thickProvider) {
            if (sch.HasTop) {
                var me = this;
                var MP = me.marginProvider;

                if (!me.top) {
                    me.top = ezPanel.create(outerZone);
                    ezPanel.beOnSide(me.top, 'top', sch.TopMargin);
                    me.top.title = "Dessus";
                    me.top.kind = $ez.KINDS.Top;
                    if (MP) me.top.setMargins({ front: MP.FrontTopMargin });
                    me.top.setMaterialModel(panelModel);
                    me.top.thickness = thickProvider.getTop();
                }
                else {
                    me.top.positioner.zone = outerZone;
                    me.top.thickness = thickProvider.getTop();
                }

                me.top.addPrice = me.closet.model.AddPriceTop;

                // Width validation
                me.top.setIssueStatus(outerZone.relNode._issues.getTopOffWidth());

                // Margins
                if (sch.HasTopPriority) {
                    me.top.margins.left = sch.LeftMargin + (MP ? MP.LeftTopMargin : 0);
                    me.top.margins.right = sch.RightMargin + (MP ? MP.RightTopMargin : 0);
                }
                else {
                    var cornerMargins = me.closet.getDriver().getPartMargins(outerZone.relNode);
                    me.top.margins.left = sch.HasLeft ? sch.LeftMargin + thickProvider.getLeft() : (cornerMargins && cornerMargins.Top ? cornerMargins.Top.left : 0) + sch.LeftMargin;
                    me.top.margins.right = sch.HasRight ? sch.RightMargin + thickProvider.getRight() : (cornerMargins && cornerMargins.Top ? cornerMargins.Top.right : 0) + sch.RightMargin;
                }

                me._topBackMargin = (sch.hasBack(scho) && sch.HasBackPriorityOnTop ? sch.BackMargin + thickProvider.getBack() : (MP ? MP.BackTopMargin : 0));
                me.top.margins.back = me._topBackMargin;
            }
        }

        // Update back part
        _P._updateBack = function (sch, scho, outerZone, thickProvider) {
            var me = this;
            if (sch.hasBack(scho) && me.closet.getDriver().canHaveBackPanel(outerZone.relNode)) {
                
                var closetMdl = me.closet.model;
                
                if (!me.back) {
                    me.back = ezPanel.create(outerZone);
                    ezPanel.beOnSide(me.back, 'back', sch.BackMargin);
                    me.back.title = "Fond";
                    me.back.kind = $ez.KINDS.Back;
                    me.back.setMaterialModel(me.closet.getDefaultBackPanelModel());
                    me.back.thickness = thickProvider.getBack();
                }
                else {
                    me.back.positioner.zone = outerZone;
                    me.back.positioner.offs = sch.BackMargin;
                    me.back.thickness = thickProvider.getBack();
                }

                if (scho.defaultBackClr) {
                    me.back.setColour(scho.defaultBackClr);
                    me.back.setHasOwnColour(true);
                }
                else {
                    me.back.setColour(me.closet.mainColour);
                    me.back.setHasOwnColour(false);
                }

                me.back.addPrice = me.closet.model.AddPriceBack;

                // Width validation
                var isOffW = outerZone.relNode._issues.hasBackOffWidth();
                if (isOffW)
                    me.back.margins.right = outerZone.xdim - closetMdl.BackMaxWidth;
                else
                    me.back.margins.right = 0;
                me.back.setIssueStatus(isOffW ? 'ERR' : 'OK');

                // Margins
                me.back.margins.left = (sch.HasLeft ? sch.LeftMargin + thickProvider.getLeft() : 0);
                me.back.margins.right = (sch.HasRight ? sch.RightMargin + thickProvider.getRight() : 0);
                me.back.margins.bottom = (sch.HasBottom && !sch.HasBackPriorityOnBottom ? sch.BottomMargin + thickProvider.getBottom() : 0);
                me.back.margins.top = (sch.HasTop && !sch.HasBackPriorityOnTop ? sch.TopMargin + thickProvider.getTop() : 0);

                // Additional margins (if part below or above only)
                if (me.parentComputeParam) {
                    if (me.parentComputeParam.hasClosestTop() || me.parentComputeParam.hasSeparatorAbove())
                            me.back.margins.top += closetMdl.Extensions.TopMarginOfBack;

                    if (me.parentComputeParam.hasClosestBottom() || me.parentComputeParam.hasSeparatorBelow())
                            me.back.margins.bottom += closetMdl.Extensions.BottomMarginOfBack;
                }

                // Oversizes
                me.back.margins.left    -= closetMdl.BackLROversize;
                me.back.margins.right   -= closetMdl.BackLROversize;
                me.back.margins.bottom  -= closetMdl.BackBTOversize;
                me.back.margins.top     -= closetMdl.BackBTOversize;
            }
        }

        // Update front part
        _P._updateFront = function (sch, scho, outerZone, panelModel, thickProvider) {
            var me = this;

            if (sch.hasFront(scho)) {

                if (!scho.frontDef) { // Default front panel 

                    if (!me.front) {
                        me.front = ezPanel.create(outerZone);
                        ezPanel.beOnSide(me.front, 'front', sch.FrontMargin);
                        me.front.setMaterialModel(scho.frontMat || panelModel);
                        me.front.thickness = thickProvider.getFront();
                    }
                    else {
                        me.front.positioner.zone = outerZone;
                        me.front.thickness = thickProvider.getFront();
                    }

                    if (scho.defaultFrontClr) {
                        me.front.setColour(scho.defaultFrontClr);
                        me.front.setHasOwnColour(true);
                    }
                    else {
                        me.front.setColour(me.closet.frontColour || me.closet.mainColour);
                        me.front.setHasOwnColour(false);
                    }

                    me.front.setIssueStatus('OK');

                    // Margins
                    me.front.margins.left = (sch.HasLeft ? sch.LeftMargin + thickProvider.getLeft() : 0);
                    me.front.margins.right = (sch.HasRight ? sch.RightMargin + thickProvider.getRight() : 0);
                    me.front.margins.bottom = (sch.HasBottom ? sch.BottomMargin + thickProvider.getBottom() : 0);
                    me.front.margins.top = (sch.HasTop ? sch.TopMargin + thickProvider.getTop() : 0);

                    // Visual margin
                    if (me.visualMargins && me.visualMargins.front) me.front.setVisualMargin(me.visualMargins.front);
                }
                else if (scho.frontDef.EZT === 'SWD') { // Swing door
                    if (!me.front) {
                        var DN = outerZone.relNode;
                        me.front = ezSwingDoor.createSwingDoor(DN, scho.frontDef.model);

                        if (scho.defaultFrontClr) {
                            me.front.setColour(scho.defaultFrontClr);
                            me.front.setHasOwnColour(true);
                        }
                        else
                            me.front.setColour(me.closet.frontColour || me.closet.mainColour);
                    }
                    else {
                        me.front.setModel(scho.frontDef.model);
                    }

                }
                else
                    $ez.THROW("Unkown Front EZType '" + scho.frontDef.EZT + "'. Cannot update front.");

                me.front.update();
            }
        }

        // Update top-band part
        _P._updateTopBand = function (sch, scho, outerZone, thickProvider) {
            var me = this;
            var closetMdl = me.closet.model;

            if (sch.HasTopBand) {
                var me = this;

                if (!me.topBand) {
                    me.topBand = ezPanel.create(outerZone);
                    ezPanel.beOnSide(me.topBand, 'front', closetMdl.FrontTopMargin + closetMdl.TopBandOffs);
                    me.topBand.setMaterialModel(thickProvider.getTopBandMatModel());
                    me.topBand.thickness = thickProvider.getTopBand();
                    me.topBand.title = "Bandeau";
                    me.topBand.kind = 20;
                }
                else {
                    me.topBand.positioner.zone = outerZone;
                    me.topBand.thickness = thickProvider.getTopBand();
                }

                //me.back.addPrice = me.closet.model.AddPriceBack;

                // Left and right margin
                me.topBand.margins.left = sch.LeftMargin + closetMdl.Extensions.LeftBandMargin;
                me.topBand.margins.right = sch.RightMargin + closetMdl.Extensions.RightBandMargin;
                if (!sch.HasTopPriority) {
                    me.topBand.margins.left += sch.HasLeft ? thickProvider.getLeft() : 0; // Part thickness
                    me.topBand.margins.right += sch.HasRight ? thickProvider.getRight() : 0; // Part thickness
                }

                // bottom margin
                me.topBand.margins.bottom = outerZone.ydim - sch.TopMargin;

                // top margin
                me.topBand.margins.top = sch.TopMargin - closetMdl.TopBandHeight;

                me.topBand.update();
            }
        }

        // Update bottom-band part on front side
        _P._updateBottomBandFront = function (sch, scho, outerZone, thickProvider) {
            var me = this;
            var closetMdl = me.closet.model;

            if (sch.HasBottomBand) {
                var me = this;

                if (!me.bottomBandFront) {
                    me.bottomBandFront = ezPanel.create(outerZone);
                    ezPanel.beOnSide(me.bottomBandFront, 'front', closetMdl.FrontBottomMargin + closetMdl.BottomBandOffs);
                    me.bottomBandFront.setMaterialModel(thickProvider.getBottomBandMatModel());
                    me.bottomBandFront.thickness = thickProvider.getBottomBand();
                    me.bottomBandFront.title = "Socle avant";
                    me.bottomBandFront.kind = 19;
                }
                else {
                    me.bottomBandFront.positioner.zone = outerZone;
                    me.bottomBandFront.thickness = thickProvider.getBottomBand();
                }

                //me.back.addPrice = me.closet.model.AddPriceBack;

                // Left and right margin
                me.bottomBandFront.margins.left = sch.LeftMargin + closetMdl.Extensions.LeftBandMargin;
                me.bottomBandFront.margins.right = sch.RightMargin + closetMdl.Extensions.RightBandMargin;
                if (!sch.HasBottomPriority) {
                    me.bottomBandFront.margins.left += sch.HasLeft ? thickProvider.getLeft() : 0; // Part thickness
                    me.bottomBandFront.margins.right += sch.HasRight ? thickProvider.getRight() : 0; // Part thickness
                }

                // bottom margin
                me.bottomBandFront.margins.bottom = sch.BottomMargin - closetMdl.BottomBandHeight;

                // top margin
                me.bottomBandFront.margins.top = outerZone.ydim - sch.BottomMargin; //me.closet.getOuterZone().ydim - sch.BottomMargin;

                me.bottomBandFront.update();
            }
        }

        // Update bottom-band part on back side
        _P._updateBottomBandBack = function (sch, scho, outerZone, thickProvider) {
            var me = this;
            
            if (sch.hasBack(scho) && sch.HasBackPriorityOnBottom) {
                if (me.bottomBandBack) {
                    me.bottomBandBack.dispose();
                    me.bottomBandBack = null;
                }
                return;
            }

            var closetMdl = me.closet.model;
            
            if (sch.HasBottomBand) {
                var me = this;

                // Distance of bottom-band from back of closet according to baseboard
                var baseBoard = me.closet.getBaseboard();
                var sideDist = 0;
                if (baseBoard.isDefined() && baseBoard.getHeight() <= closetMdl.BottomOffs)
                    sideDist = closetMdl.Extensions.BottomBandBackOffs; // Extensions.BottomBandOffs is a wrong name since is defined the offset of back-band is baseboard exists
                else
                    sideDist = closetMdl.BottomBandOffs;

                if (!me.bottomBandBack) {
                    me.bottomBandBack = ezPanel.create(outerZone);
                    ezPanel.beOnSide(me.bottomBandBack, 'back', sideDist); 
                    me.bottomBandBack.setMaterialModel(thickProvider.getBottomBandMatModel());
                    me.bottomBandBack.thickness = thickProvider.getBottomBand();
                    me.bottomBandBack.title = "Socle arrière";
                    me.bottomBandBack.kind = 19;
                }
                else {
                    me.bottomBandBack.positioner.zone = outerZone;
                    me.bottomBandBack.thickness = thickProvider.getBottomBand();
                    me.bottomBandBack.positioner.offs = sideDist;
                }

                //me.back.addPrice = me.closet.model.AddPriceBack;

                // Left and right margin
                me.bottomBandBack.margins.left = sch.LeftMargin + closetMdl.Extensions.LeftBandMargin;
                me.bottomBandBack.margins.right = sch.RightMargin + closetMdl.Extensions.RightBandMargin;
                if (!sch.HasBottomPriority) {
                    me.bottomBandBack.margins.left += sch.HasLeft ? thickProvider.getLeft() : 0; // Part thickness
                    me.bottomBandBack.margins.right += sch.HasRight ? thickProvider.getRight() : 0; // Part thickness
                }

                // bottom margin
                me.bottomBandBack.margins.bottom = sch.BottomMargin - closetMdl.BottomBandHeight;

                // top margin
                me.bottomBandBack.margins.top = outerZone.ydim - sch.BottomMargin; //me.closet.getOuterZone().ydim - sch.BottomMargin;

                me.bottomBandBack.update();
            }
        }

        // Update the front consolidator according width of zone and front
        _P._updateFrontConsolidator = function (sch, scho, outerZone, panelModel, thickProvider) {
            var me = this;
            var closetMdl = me.closet.model;

            var N = outerZone.relNode;
            //var parent = N.parent;
            var snCount = N.subNodes.getLength();

            var freeConsolidator = true;
            //if (closetMdl.Consolidator && closetMdl.Consolidator.Height > 0 && snCount === 0 && parent && parent.parent && !N.isLast()) {
            if (closetMdl.Consolidator && closetMdl.Consolidator.Height > 0 &&
                snCount === 0 &&
                !N.hitTop(me.parentComputeParam ? me.parentComputeParam.getHitTop() : undefined)) {

                var bandH = closetMdl.Consolidator.Height;
                var maxWidth = closetMdl.Consolidator.ApplyOnWidth;

                if (outerZone.isOffWidth(maxWidth)) { // Add consolidator
                    var thick = closetMdl.Consolidator.Thickness > 0 ? closetMdl.Consolidator.Thickness : null;
                    var offs = (me.parentComputeParam ? me.parentComputeParam.getHSepMargins().front : 0) + closetMdl.Consolidator.ShelfMargin;
                    if (me.front) offs += me.front.getPanels()[0].zdim; // behind the door

                    if (!me.frontConsolidator) {
                        me.frontConsolidator = ezPanel.create(outerZone);
                        ezPanel.beOnSide(me.frontConsolidator, 'front', offs);
                        me.frontConsolidator.setMaterialModel(panelModel);
                        me.frontConsolidator.thickness = thick;
                        me.frontConsolidator.title = "Raidisseur avant";
                        me.frontConsolidator.kind = 23;
                        me.frontConsolidator.setMargins({ left: closetMdl.Consolidator.LeftMargin, right: closetMdl.Consolidator.RightMargin });
                    }
                    else {
                        me.frontConsolidator.positioner.zone = outerZone;
                        me.frontConsolidator.positioner.offs = offs;
                        me.frontConsolidator.thickness = thick;
                    }

                    // bottom margin
                    me.frontConsolidator.margins.bottom = outerZone.ydim - bandH;

                    // Validation
                    me.frontConsolidator.setIssueStatus(N._issues.hasConsolidatorWithContent() ? 'ERR' : 'OK');

                    me.frontConsolidator.update();
                    freeConsolidator = false;
                }
            }

            // remove consolidator
            if (freeConsolidator && me.frontConsolidator) {
                me.frontConsolidator.hide();
                me.frontConsolidator.dispose();
                me.frontConsolidator = null;
            }
        }

        // Update the back consolidator according width of zone and back
        _P._updateBackConsolidator = function (sch, scho, outerZone, panelModel, thickProvider) {
            var me = this;
            var closetMdl = me.closet.model;

            var N = outerZone.relNode;
            //var parent = N.parent;
            var snCount = N.subNodes.getLength();

            if (me.backConsolidator) {
                me.backConsolidator.hide();
                me.backConsolidator.dispose();
                me.backConsolidator = null;
            }

            var freeConsolidator = true;
            //if (closetMdl.Consolidator && closetMdl.Consolidator.Height > 0 && snCount === 0 && parent && parent.parent && !N.isLast()) {
            if (closetMdl.Consolidator && closetMdl.Consolidator.Height > 0 &&
                snCount === 0 &&
                !N.hitTop(me.parentComputeParam ? me.parentComputeParam.getHitTop() : undefined)) {

                var hasBack = me.back || (me.parentComputeParam && me.parentComputeParam.hasClosestBack());
                var bandH = closetMdl.Consolidator.Height;
                var maxWidth = closetMdl.Consolidator.ApplyOnWidth;

                if (!hasBack && outerZone.xdim > maxWidth) { // Add consolidator
                    var thick = closetMdl.Consolidator.Thickness > 0 ? closetMdl.Consolidator.Thickness : null;
                    var offs = (me.parentComputeParam ? me.parentComputeParam.getHSepMargins().back : 0) + closetMdl.Consolidator.ShelfMargin;

                    if (!me.backConsolidator) {
                        me.backConsolidator = ezPanel.create(outerZone);
                        ezPanel.beOnSide(me.backConsolidator, 'back', offs);
                        me.backConsolidator.setMaterialModel(panelModel);
                        me.backConsolidator.thickness = thick;
                        me.backConsolidator.title = "Raidisseur arrière";
                        me.backConsolidator.kind = 23;
                        me.backConsolidator.setMargins({ left: closetMdl.Consolidator.LeftMargin, right: closetMdl.Consolidator.RightMargin });
                    }
                    else {
                        me.backConsolidator.positioner.zone = outerZone;
                        me.backConsolidator.positioner.offs = offs;
                        me.backConsolidator.thickness = thick;
                    }

                    // bottom margin
                    me.backConsolidator.margins.bottom = outerZone.ydim - bandH;

                    var baseboard = me.closet.getBaseboard();
                    if (baseboard.isDefined()) {    
                        me.backConsolidator.compute(); // Required to check collision
                        if (baseboard.hasCollision(me.backConsolidator.y, me.backConsolidator.z, me.backConsolidator.ydim, me.backConsolidator.zdim))
                            me.backConsolidator.positioner.offs = offs + baseboard.getDepth();
                    }

                    // Validation
                    me.backConsolidator.setIssueStatus(N._issues.hasConsolidatorWithContent() ? 'ERR' : 'OK');

                    freeConsolidator = false;
                    me.backConsolidator.update();
                }
            }

            // remove consolidator
            if (freeConsolidator && me.backConsolidator) {
                me.backConsolidator.hide();
                me.backConsolidator.dispose();
                me.backConsolidator = null;
            }
        }

        // Set colour of parts.
        // colour: (dal.Colour) new colour of parts
        _P.setColour = function (colour) {
            var me = this;
            if (me.left && !me.left.hasOwnColour()) { me.left.setColour(colour); me.left.updateThreeMat(); }
            if (me.right && !me.right.hasOwnColour()) { me.right.setColour(colour); me.right.updateThreeMat(); }
            if (me.bottom && !me.bottom.hasOwnColour()) { me.bottom.setColour(colour); me.bottom.updateThreeMat(); }
            if (me.top && !me.top.hasOwnColour()) { me.top.setColour(colour); me.top.updateThreeMat(); }
            if (me.back && !me.back.hasOwnColour()) { me.back.setColour(colour); me.back.updateThreeMat(); }
            if (me.front && !me.front.hasOwnColour()) { me.front.setColour(me.closet.frontColour || colour); me.front.updateThreeMat(); }
            if (me.topBand && !me.topBand.hasOwnColour()) { me.topBand.setColour(colour); me.topBand.updateThreeMat(); }
            if (me.bottomBandFront && !me.bottomBandFront.hasOwnColour()) { me.bottomBandFront.setColour(colour); me.bottomBandFront.updateThreeMat(); }
            if (me.bottomBandBack && !me.bottomBandBack.hasOwnColour()) { me.bottomBandBack.setColour(colour); me.bottomBandBack.updateThreeMat(); }
            if (me.frontConsolidator && !me.frontConsolidator.hasOwnColour()) { me.frontConsolidator.setColour(colour); me.frontConsolidator.updateThreeMat(); }
            if (me.backConsolidator && !me.backConsolidator.hasOwnColour()) { me.backConsolidator.setColour(colour); me.backConsolidator.updateThreeMat(); }
        }

        // Set visual margins. Set visual margin of each panel.
        // margins : (Object) Null if no margin, or margin of each part as { front: 0.2, ... }
        _P.setVisualMargins = function (margins) { this.visualMargins = margins; }

        // Display parts
        _P.display = function () {
            var me = this;
            if (me.left) me.left.display();
            if (me.right) me.right.display();
            if (me.bottom) me.bottom.display();
            if (me.top) me.top.display();
            if (me.back) me.back.display();
            if (me.front) me.front.display();
            if (me.topBand) me.topBand.display();
            if (me.bottomBandFront) me.bottomBandFront.display();
            if (me.bottomBandBack) me.bottomBandBack.display();
            if (me.frontConsolidator) me.frontConsolidator.display();
            if (me.backConsolidator) me.backConsolidator.display();
        }

        // Hide parts
        _P.hide = function () {
            var me = this;
            if (me.left) me.left.hide();
            if (me.right) me.right.hide();
            if (me.bottom) me.bottom.hide();
            if (me.top) me.top.hide();
            if (me.back) me.back.hide();
            if (me.front) me.front.hide();
            if (me.topBand) me.topBand.hide();
            if (me.bottomBandFront) me.bottomBandFront.hide();
            if (me.bottomBandBack) me.bottomBandBack.hide();
            if (me.frontConsolidator) me.frontConsolidator.hide();
            if (me.backConsolidator) me.backConsolidator.hide();
        }

        // Update Three Material
        _P.updateThreeMat = function () {
            var me = this;
            if (me.left) me.left.updateThreeMat();
            if (me.right) me.right.updateThreeMat();
            if (me.bottom) me.bottom.updateThreeMat();
            if (me.top) me.top.updateThreeMat();
            if (me.back) me.back.updateThreeMat();
            if (me.front) me.front.updateThreeMat();
            if (me.topBand) me.topBand.updateThreeMat();
            if (me.bottomBandFront) me.bottomBandFront.updateThreeMat();
            if (me.bottomBandBack) me.bottomBandBack.updateThreeMat();
            if (me.frontConsolidator) me.frontConsolidator.updateThreeMat();
            if (me.backConsolidator) me.backConsolidator.updateThreeMat();
        }

        // Remove and dispose back part if any
        _P.removeBack = function () {
            var me = this;
            if (me.back) {
                me.back.hide();
                me.back.dispose();
                me.back = null;
            }
        }

        // Remove and dispose front part if any
        _P.removeFront = function () {
            var me = this;
            if (me.front) {
                me.front.hide();
                me.front.dispose();
                me.front = null;
            }
        }

        // Remove consolidator parts if any
        _P.removeConsolidators = function () {
            var me = this;

            if (me.frontConsolidator) {
                me.frontConsolidator.hide();
                me.frontConsolidator.dispose();
                me.frontConsolidator = null;
            }

            if (me.backConsolidator) {
                me.backConsolidator.hide();
                me.backConsolidator.dispose();
                me.backConsolidator = null;
            }
        }

        // Dispose parts according specified schema.
        // schema : (CNSchema) If specified, only parts enabled for the schema are disposed. No parts disposed otherwise.
        _P.filteredDispose = function (schema) {
            if (!schema) return;
            var me = this;
            if (me.left && schema.HasLeft) { me.left.hide(); me.left.dispose(); me.left = null; }
            if (me.right && schema.HasRight) { me.right.hide(); me.right.dispose(); me.right = null; }
            if (me.bottom && schema.HasBottom) { me.bottom.hide(); me.bottom.dispose(); me.bottom = null; }
            if (me.top && schema.HasTop) { me.top.hide(); me.top.dispose(); me.top = null; }
            if (me.back && schema.HasBack) { me.back.hide(); me.back.dispose(); me.back = null; }
            if (me.front && schema.HasFront) { me.front.hide(); me.front.dispose(); me.front = null; }
            if (me.topBand && schema.HasTopBand) { me.topBand.hide(); me.topBand.dispose(); me.topBand = null; }
            if (me.bottomBandFront && schema.HasBottomBand) { me.bottomBandFront.hide(); me.bottomBandFront.dispose(); me.bottomBandFront = null; }
            if (me.bottomBandBack && schema.HasBottomBand) { me.bottomBandBack.hide(); me.bottomBandBack.dispose(); me.bottomBandBack = null; }

            //if (me.frontConsolidator) { me.frontConsolidator.hide(); me.frontConsolidator.dispose(); me.frontConsolidator = null; }
            //if (me.backConsolidator) { me.backConsolidator.hide(); me.backConsolidator.dispose(); me.backConsolidator = null; }
        }

        // Dispose parts.
        _P.dispose = function () {
            var me = this;
            if (me.left) { me.left.hide(); me.left.dispose(); me.left = null; }
            if (me.right) { me.right.hide(); me.right.dispose(); me.right = null; }
            if (me.bottom) { me.bottom.hide(); me.bottom.dispose(); me.bottom = null; }
            if (me.top) { me.top.hide(); me.top.dispose(); me.top = null; }
            if (me.back) { me.back.hide(); me.back.dispose(); me.back = null; }
            if (me.front) { me.front.hide(); me.front.dispose(); me.front = null; }
            if (me.topBand) { me.topBand.hide(); me.topBand.dispose(); me.topBand = null; }
            if (me.bottomBandFront) { me.bottomBandFront.hide(); me.bottomBandFront.dispose(); me.bottomBandFront = null; }
            if (me.bottomBandBack) { me.bottomBandBack.hide(); me.bottomBandBack.dispose(); me.bottomBandBack = null; }
            if (me.frontConsolidator) { me.frontConsolidator.hide(); me.frontConsolidator.dispose(); me.frontConsolidator = null; }
            if (me.backConsolidator) { me.backConsolidator.hide(); me.backConsolidator.dispose(); me.backConsolidator = null; }
        }

        // BOM filling for each part.
        // bom: (Bom) The BOM to fill.
        _P.fillBOM = function (bom) {
            var me = this;
            if (me.left) me.left.fillBOM(bom);
            if (me.right) me.right.fillBOM(bom);
            if (me.bottom) me.bottom.fillBOM(bom);
            if (me.top) me.top.fillBOM(bom);
            if (me.back) me.back.fillBOM(bom);
            if (me.front) me.front.fillBOM(bom);
            if (me.topBand) me.topBand.fillBOM(bom);
            if (me.bottomBandFront) me.bottomBandFront.fillBOM(bom);
            if (me.bottomBandBack) me.bottomBandBack.fillBOM(bom);
            if (me.frontConsolidator) me._fillBOMWithConsolidator(bom, me.frontConsolidator);
            if (me.backConsolidator) me._fillBOMWithConsolidator(bom, me.backConsolidator);
        }

        // Fill BOM with the specified consolidator
        _P._fillBOMWithConsolidator = function (bom, cons) {
            var me = this;
            var closetMdl = me.closet.model;

            var price = 0;
            if (closetMdl.Consolidator.PriceMode === 3) // linear mode 
                price = closetMdl.Consolidator.Price * (cons.xdim / 100);
            else if (closetMdl.Consolidator.PriceMode === 1) // unit mode
                price = closetMdl.Consolidator.Price;
            else
                $log.warn("Consolidator does not suport price mode '" + closetMdl.Consolidator.PriceMode + "'. Price will be 0.")

            cons.fillBOM(bom, price);
        }
       
    })(CNParts.prototype);


    /////// Class CNode
    // Brief : CNode is the base entity that compose the closet structure : the CTree.
    // Constructor
    // args : {
        //  closet: (Closet) The closet that own the node.
        //  schemaProvider: (CNSchemaProvider) The schema provider to manage parts of tree structure.
        //  thickProvider: (CNThickProvider) The thickness provider of parts.
        //  parent: (CNode) the parent node, null if is root.
        //  axis: X_AXIS or Y_AXIS, distibution axis of separators.
        // }
    var CNode = function (args) {
        var me = this;

        me.closet = args.closet; // Class Closet
        me.CUID = args.closet.getCUID();
        me.parent = args.parent || null; // Class CNode : the parent node. null if root.
        me.canDisplay = true; 

        me.schemaProvider = args.schemaProvider || null; // CNSchemaProvider
        me.thickProvider = args.thickProvider || null; // CNThickProvider
        me.marginProvider = args.marginProvider || null; // CNMargins: { FrontLeftMargin, FrontRightMargin, FrontTopMargin, FrontBottomMargin, FrontShelfMargin, FrontSeparatorMargin, ... }
        me.schema = null; // Class null or CNSchema. Reference to schema of provider.
        me.schOpts = ezSchema.$newOptions(); // Class CNSchemaOptions
        me.outerZone = ezZone.createZone(me.closet, me); // Class Zone : the zone that contains main parts. Never computed by this component, but by its parent.
        me.paddingZone = null; // Class Zone : if paddings specified, offer a reduction of outer-zone. Useful to keep control of outer-zone dimension.
        me.paddings = { left: 0, right: 0, bottom: 0, top: 0, back: 0, front: 0 }; // Paddings. See paddingZone.
        me.innerZone = ezZone.createZone(me.closet, me); // Class Zone : the zone that contains content (separators, items). Computed by this component.
        me.level = me.parent ? me.parent.level + 1 : 0;
        me.axis = args.axis || $ez.X_AXIS; // $ez.X_AXIS, $ez.Y_AXIS

        me.visualMargins = null; // See CNParts visualMargins
        me.parts = null; // CNParts class.
        me.items = null; // null or EZArray of object (drawer, accessory, ...)

        me.separators = null; // null or EZArray of class Panel
        me.subNodes = $ez.createEZArray(); // sub-nodes contained by innerZone
        me.door = null; // Door for the whole zone (i.e sliding door)
        //me.parentOfST = null; // CNode : parent of this sub-layout. Do not confuse with parent.
        me.SDDisplayable = true;
        me.splitWithBounds = true; // If true split algorithm check the bound of zone (must false for node or drawer-group)
        me.computedBounds = null; // To store bounds of zone (used by ISepInjector)
        me._usableDims = { w: 0, h: 0, d: 0 }; // The usable dimension of node, i.e the dimension that can be filled with content.
        me._issues = new CNIssues(me);

        me.smartGroupKey = null; // String | null
        me.isUserSG = false; // If true user had set the smart-module by selecting one
    };
    // Methods
    (function (_P) {

        //// Cleaning and subtree

        // Clean the X[]>Y[] or Y[]>X[] pattern if any for this node only.
        _P._cleanDegenerated = function () {
            var me = this;

            if (me.subNodes.getLength() === 1) { // 1 single sub-node ...
                var sn = me.subNodes.get(0);

                if (sn.subNodes.getLength() === 0) { // ... which has no sub-node
                    // Get items
                    if (sn.hasItems()) {
                        me.items = sn.items;
                        sn.items.forEach(function (item) { item.attachTo(me); });
                        sn.items = null;
                    }

                    // Get back if any
                    if (!me.schOpts.hasBackActive) {
                        me.schOpts.hasBackActive = sn.schOpts.hasBackActive;
                        me.schOpts.defaultBackClr = sn.schOpts.defaultBackClr;
                    }

                    // Get front if any
                    if (!me.schOpts.hasFrontActive) {
                        me.schOpts.hasFrontActive = sn.schOpts.hasFrontActive;
                        me.schOpts.defaultFrontClr = sn.schOpts.defaultFrontClr;
                        me.schOpts.frontDef = sn.schOpts.frontDef;
                    }

                    // Remove the sub-node
                    sn.hide();
                    sn.dispose();
                    me.subNodes.removeAll();
                }
            }
        }

        // Compact nodes around this pivot node.
        _P._compact = function () {
            var me = this;

            var tgt = me.parent;
            if (!tgt) $ez.THROW("Pivot node has no parent. Cannot compact this node.");

            var src = null;
            if (me.subNodes.getLength() !== 1) {
                $ez.THROW("Pivot node must have one and only one sub-node. Cannot compact this node.");
            }
            else
                src = me.subNodes.get(0);

            if (src.axis !== tgt.axis) $ez.THROW("Source and target node must have same axis orientation. Cannot compact this node.");

            var srcSubCount = src.subNodes.getLength();
            var iPivot = tgt.subNodes.indexOf(me); // Index of pivot to determine insertion
            for (var i = 0; i < srcSubCount; i++) { // For all subnodes of source
                var sn = src.subNodes.get(i);

                // sub-nodes
                tgt.subNodes.insertAt(sn, iPivot + i + 1); // Insert after the pivot to not change the pivot index
                sn.parent = tgt;

                // separator
                if (i > 0) {
                    if (!tgt.separators) tgt.separators = $ez.createEZArray();
                    tgt.separators.insertAt(src.separators.get(i - 1), iPivot + i);
                }
            }

            // Schema options
            //tgt.schOpts.copy(me.schOpts);
            tgt.schOpts.hasBackActive = tgt.schOpts.hasBackActive || me.schOpts.hasBackActive;
            tgt.schOpts.hasFrontActive = tgt.schOpts.hasFrontActive || me.schOpts.hasFrontActive;
            tgt.schOpts.frontDef = tgt.schOpts.frontDef || me.schOpts.frontDef;
            tgt.schOpts.frontMat = tgt.schOpts.frontMat || me.schOpts.frontMat;

            // Get items if any
            if (src.items) {
                tgt.items = src.items;
                src.items.forEach(function (item) { item.attachTo(tgt); });
                src.items = null; // detach
            }

            // detach transfered elements from source and clean-up
            src.separators = null;
            src.subNodes = null;
            src.dispose();

            // Remove pivot (me), from its parent
            me.hide();
            me.dispose();
            tgt.subNodes.set(null, iPivot); // Do note remove here directly cause we are in loop (see simplifyTree goDown case).
        }

        // Pack sub-nodes array of this node by removing null nodes.
        _P._packSubNodes = function () {
            var me = this;
            var subCount = me.subNodes.getLength();
            var sn, i = 0;
            while (i < subCount) {
                sn = me.subNodes.get(i);
                if (sn === null) {
                    me.subNodes.removeAt(i);
                    subCount--;
                }
                else
                    i++;
            }
        }

        // Simply tree by starting from this node. Replace _cleanTree() methods.
        // _caller : (CNode) The node that call this method. For internal use only. 
        _P.simplifyTree = function (_caller) {
            var me = this;

            //if (!args.updatable) args.updatable = me;

            // Try first clean degenerated (may change the sub-node count of current node)
            me._cleanDegenerated();

            var subCount, i, sn;

            // me is a pivot ?
            if (me.parent) { // Has parent
                if (!me.parts || (!me.parts.back && !me.parts.front)) {
                    subCount = me.subNodes.getLength();
                    if (subCount === 1) { // Has single sub-node
                        //args.updatable = me.parent;
                        me._compact();
                        if (!_caller) me.parent._packSubNodes(); // to pack sub-nodes on single call
                        return;
                    }
                }
            }

            // Go down in tree
            subCount = me.subNodes.getLength();
            for (i = 0; i < subCount; i++)
                me.subNodes.get(i).simplifyTree(me);

            // Clean sub-nodes by removing null nodes leave by compact()
            me._packSubNodes();

            // Finaly try simplify the X>Y or Y>X pattern
            me._cleanDegenerated();
        }


        //// Dimensions validation

        // Get the dimension bounds of node according zone dimension bounds and content of node (items and swing-door)
        _P.getNodeBnds = function () {
            var me = this;

            var B = me.closet.getDriver().getZoneBounds(me);

            if (me.items && !me.items.isEmpty()) {
                var IB = me.getItemBounds();
                if (B.width.min < IB.width.min) B.width.min = IB.width.min;
                if (B.height.min < IB.height.min) B.height.min = IB.height.min;
                if (B.depth.min < IB.depth.min) B.depth.min = IB.depth.min;
            }
            else if (me.parts && me.parts.front && me.parts.front.getMinWidth && me.parts.front.getMinHeight) {
                var front = me.parts.front;
                if (B.width.min < front.getMinWidth()) B.width.min = front.getMinWidth();
                if (B.height.min < front.getMinHeight()) B.height.min = front.getMinHeight();
            }

            return B;
        }

        // Get more restrictives item bounds according to items owned by this node.
        // Returns: (Object) Bounds as { width: {min: (Number) max: (Number) }, height: {...}, depth: {...} } 
        _P.getItemBounds = function () {
            var me = this;

            var B = $ez.createBounds();
            var n = me.items.getLength();
            for (var i = 0; i < n; i++) {
                var IB = me.items.get(i).getBounds();

                if (B.width.min < IB.width.min) B.width.min = IB.width.min;
                if (B.width.max > IB.width.max) B.width.max = IB.width.max;

                if (B.height.min < IB.height.min) B.height.min = IB.height.min;
                if (B.height.max > IB.height.max) B.height.max = IB.height.max;

                if (B.depth.min < IB.depth.min) B.depth.min = IB.depth.min;
                if (B.depth.max > IB.depth.max) B.depth.max = IB.depth.max;
            }

            return B;
        }

        // Get the owner closet
        _P.getCloset = function () { return this.closet; }

        // Return true if this node is root node
        _P.isRoot = function () { return this.parent === null }

        // Return true if this node is a module, i.e highest level column or row.
        _P.isModule = function () {
            var me = this;
            return (!me.parent && me.subNodes.getLength() === 0) // Single column/row
                || (me.parent && !me.parent.parent); // Pure module
        }

        // Return true if has items
        // ofEzType : (String) EZType of item (optional). if not specified, method returns true if has one item at least.
        _P.hasItems = function (ofEzType) {
            if (this.items === null || this.items.isEmpty()) return false;

            if (!ofEzType) {
                return true;
            }
            else {
                for (var i = 0; i < this.items.getLength(); i++) {
                    if (this.items.get(i).getEzType() === ofEzType) return true;
                }

                return false;
            }
        }

        // Return true if is split, i.e has more than 1 sub-nodes.
        _P.isSplit = function () { return this.subNodes.getLength() > 1 }

        // Return true if is empty, i.e is has no item and is not splitted.
        _P.isEmpty = function () { return !this.hasItems() && !this.isSplit(); }

        // Return true if is leaf, i.e is has no sub-nodes.
        _P.isLeaf = function () { return this.subNodes.isEmpty(); }

        // Return true if this node is the first node in its parent node
        _P.isFirst = function () {
            if (this.isRoot()) return true;
            return this.parent.subNodes.get(0) === this;
        }

        // Return true if this node is the last node in its parent node
        _P.isLast = function () {
            if (this.isRoot()) return true;
            var a = this.parent.subNodes;
            return a.get(a.getLastIndex()) === this;
        }

        // Return true if All sub-nodes is empty
        _P.subNodesAreEmpty = function () {
            var me = this;

            if (me.subNodes.isEmpty()) return true;

            for (var i = 0; i < me.subNodes.getLength(); i++) {
                if (!me.subNodes.get(i).isEmpty()) return false;
            }

            return true;
        }

        // Return the parent node or null if is root
        // Result : CNode class or null.
        _P.getParentNode = function () { return this.parent }

        // Add item to this node. Create items collection if necessary.
        // You are responsible to attach item to this node with attachTo() method.
        // o : (INodeItem) The item to add
        _P.addItem = function (o) {
            var me = this;
            if (!me.subNodes.isEmpty()) throw new Error("[EZCloset] Cannot add item in nodes that contains sub-nodes.");

            // Check INodeItem contract
            if (!o.getEzType) throw new Error("[EZCloset] Item of CNode must implement a 'getEzType()' method.");
            if (!o.getBounds) throw new Error("[EZCloset] Item of CNode must implement a 'getBounds()' method.");
            if (!o.display) throw new Error("[EZCloset] Item of CNode must implement a 'display()' method.");
            if (!o.hide) throw new Error("[EZCloset] Item of CNode must implement a 'hide()' method.");
            if (!o.dispose) throw new Error("[EZCloset] Item of CNode must implement a 'dispose()' method.");
            if (!o.attachTo) throw new Error("[EZCloset] Item of CNode must implement a 'attachTo(parentNode)' method.");
            if (!o.attachTo) throw new Error("[EZCloset] Item of CNode must implement a 'getGroup(groupName)' method.");

            if (!me.items) me.items = $ez.createEZArray();
            me.items.add(o);
            o.CUID = me.closet.getCUID();
        }

        // Remove item from this node
        _P.removeItem = function (o) {
            var me = this;
            if (!me.items) return;

            var idx = me.items.indexOf(o);
            if (idx >= 0) me.items.removeAt(idx);
        }

        // Find item according predicate function f.
        // f : (Function) predicate function that verify the item condition : function(item) { /* return true or false*/ }
        // Returns : the item or null if not found.
        _P.findItem = function (f) {
            var me = this;
            var item = null;

            if (me.items) {
                var n = me.items.getLength();

                for (var i = 0; i < n; i++) {
                    item = me.items.get(i);
                    if (f(item)) return item;
                }
            }

            // recurse in sub-nodes
            for (var i = 0; i < me.subNodes.getLength(); i++) {
                item = me.subNodes.get(i).findItem(f);
                if (item)
                    return item;
                else
                    me.subNodes.get(i).findItem(f);
            }

            return null;
        }

        // Return true if this node has contact with top-part.
        // This method do not use recursive pattern for optimisation purpose.
        // parentHitTop: (Boolean) True if parent of node hit top.
        _P.hitTop = function (parentHitTop) {
            var me = this;
            if (me.isRoot()) return true;
            if (parentHitTop) {
                if (me.axis === $ez.X_AXIS && me.isLast())
                    return true;
                else if (me.axis === $ez.Y_AXIS)
                    return true;
                else
                    return false;
            }
            else
                return false;
        }

        // Update the node recursively
        // parentComputeParam: (CNComputeParam) The node compute parameters
        _P.update = function (parentComputeParam) {
            var me = this;
            var snCount = me.subNodes.getLength();
            var MP = me.marginProvider;
            var computeParam = new CNComputeParam(me);
            computeParam.setHitTop(me.hitTop(parentComputeParam ? parentComputeParam.getHitTop() : undefined)); // To compute consolidator

            if (me.closet.isUIEasy())
                me.innerZone.canDisplay = (me.parent === null && me.subNodes.getLength() === 0) || (me.parent && me.parent.parent === null);
            else
                me.innerZone.canDisplay = me.schemaProvider && me.schemaProvider.zoneShown && me.subNodes.getLength() === 0 && me !== me.closet.doorNode;

            // Compute paddingZone from outer-zone
            me._initPaddingZone();

            // Compute innerZone from outer-zone
            me._initInnerZone();

            // update schema
            me._updateSchema();    

            // update inner-zone according parts schema
            if (me.parts)
                me.parts.updateInnerZone(me.schema, me.schOpts, me.thickProvider, me.innerZone);

            // Validate node before compute parts
            me._issues.doValidationPreParts(parentComputeParam);

            // update parts
            if (me.parts) {
                // For column with different heights
                me.parts.topLeftUserMargin = snCount > 1 ? me.subNodes.get(0).paddings.top : 0;
                me.parts.topRightUserMargin = snCount > 1 ? me.subNodes.get(snCount - 1).paddings.top : 0;

                // For nice displaying
                me.parts.setVisualMargins(me.visualMargins);

                me.parts.updateParts(me.schema, me.schOpts, me.thickProvider, me.marginProvider, parentComputeParam, computeParam, me.paddingZone || me.outerZone, me.innerZone);
            }

            // Update compute parameters with parts (always computed after parts)
            computeParam.updateFormParts(parentComputeParam);
            //var computeParam = new CNComputeParam(parentComputeParam, me);

            // Update inner zone
            me.innerZone.update();

            //// Compute items
            if (me.items && !me.items.isEmpty())
                me.items.forEach(function (item) { item.update() });

            // Compute usable dimensions
            me._usableDims.w = me.innerZone.xdim;
            me._usableDims.h = me.innerZone.ydim;
            me._usableDims.d = me.innerZone.zdim;
            if (!computeParam.hasClosestBack()) {
                var baseboard = me.closet.getBaseboard();
                if (baseboard.isDefined() && baseboard.hasCollision(me.innerZone.y, me.innerZone.z, me.innerZone.ydim, me.innerZone.zdim))
                    me._usableDims.d -= me.closet.getBaseboard().isDefined() ? me.closet.getBaseboard().getDepth() : 0;
            }
            if (!computeParam.hasClosestFront() && me.closet.getClosetModel().HasSwingDoorOverlay)
                me._usableDims.d -= me.closet.getClosetModel().FrontSeparatorMargin;
            if (me.closet.getClosetModel().SlidingDoorAllowed)
                me._usableDims.d -= me.closet.getClosetModel().FrontSeparatorMargin;

            //// Do sub-nodes compute
            if (snCount === 1) { // Only one subnode : no splitting
                var sn = me.subNodes.get(0);

                //// Compute subnode outer-zone from this inner-zone
                sn.outerZone.x = me.innerZone.x;
                sn.outerZone.y = me.innerZone.y;
                sn.outerZone.z = me.innerZone.z;
                sn.outerZone.xdim = me.innerZone.xdim;
                sn.outerZone.ydim = me.innerZone.ydim;
                sn.outerZone.zdim = me.innerZone.zdim;
                sn.outerZone.hasXDimAuto = me.innerZone.hasXDimAuto;
                sn.outerZone.hasYDimAuto = me.innerZone.hasYDimAuto;
                sn.outerZone.hasZDimAuto = me.innerZone.hasZDimAuto;
                sn.outerZone.update();
                sn.update(computeParam);
            }
            else if (me.subNodes.getLength() > 0) { // More than one sub-nodes
                me._splittingUpdate(computeParam); // Splitting : compute sub-nodes and separator
            }

            // door if any
            if (me.door)
                me.door.update();
        }

        // Initialize padding-zone from outer-zone if any paddings
        _P._initPaddingZone = function () {
            var me = this;
            var pad = me.paddings;

            if (pad.left !== 0 || pad.right !== 0 || pad.bottom !== 0 || pad.top !== 0 || pad.back !== 0 || pad.front !== 0) {
                if (!me.paddingZone)
                    me.paddingZone = ezZone.createZone(me.closet, me);

                me.paddingZone.x = me.outerZone.x + (pad.left / 2) - (pad.right / 2);
                me.paddingZone.y = me.outerZone.y + (pad.bottom / 2) - (pad.top / 2);
                me.paddingZone.z = me.outerZone.z + (pad.back / 2) - (pad.front / 2);

                me.paddingZone.xdim = me.outerZone.xdim - pad.left - pad.right;
                me.paddingZone.ydim = me.outerZone.ydim - pad.bottom - pad.top;
                me.paddingZone.zdim = me.outerZone.zdim - pad.back - pad.front;

                me.paddingZone.hasXDimAuto = me.outerZone.hasXDimAuto;
                me.paddingZone.hasYDimAuto = me.outerZone.hasYDimAuto;
                me.paddingZone.hasZDimAuto = me.outerZone.hasZDimAuto;
            }
            else {
                if (me.paddingZone) {
                    me.paddingZone.dispose();
                    me.paddingZone = null;
                }
            }
        }

        // Initialize inner-zone from outer zone
        _P._initInnerZone = function () {
            var me = this;

            var parentZone = me.paddingZone || me.outerZone;

            me.innerZone.x = parentZone.x;
            me.innerZone.y = parentZone.y;
            me.innerZone.z = parentZone.z;
            me.innerZone.xdim = parentZone.xdim;
            me.innerZone.ydim = parentZone.ydim;
            me.innerZone.zdim = parentZone.zdim;
            me.innerZone.hasXDimAuto = parentZone.hasXDimAuto;
            me.innerZone.hasYDimAuto = parentZone.hasYDimAuto;
            me.innerZone.hasZDimAuto = parentZone.hasZDimAuto;
        }

        // Update schema
        _P._updateSchema = function () {
            var me = this;
            var C = me.closet;
            var SP = me.schemaProvider;
            var snCount = me.subNodes.getLength();

            // Select schema 0 or 1
            var sch = null;
            if (me.parent === null) { // Level 0 : root level
                if (snCount === 0) {// empty closet : use a fused schema
                    sch = SP.getSchema01N();
                }
                else { // Has sub-nodes : use schema 0
                    sch = SP.getSchema0();
                    if (me.parts) me.parts.filteredDispose(SP.getSchema1N()); // If separator of 1st level was added, we have to remove fusion of 1 and N from root 
                }
            }
            else if (me.parent.parent === null) { // Level 1 : module level
                if (snCount === 0) { // empty node : use a fused schema
                    sch = SP.getSchema1N();
                }
                else { // Not empty : use schema 1
                    sch = SP.getSchema1();
                    //if (me.parts) me.parts.filteredDispose(SP.getSchemaN());
                }
            }
            else { // Level N : other level
                //if (me.parts) me.parts.filteredDispose(SP.getSchemaN());
                sch = SP.getSchemaN();
            }

            // Set new schema
            me.schema = sch;

            // Create parts if required
            if (me.schema && !me.parts)
                me.parts = new CNParts(C);
        }

        // Compute sub-nodes and separators
        _P._splittingUpdate = function (computeParam) {
            var me = this;
            var SP = ezSplit.createSplitter();
            var i;
            var InZ = me.innerZone;
            var C = me.closet;
            var MP = me.marginProvider;
            var isModule = me.isModule();

            // Get outer-zones informations
            var szInfos = [];
            var snCount = me.subNodes.getLength();
            me.subNodes.forEach(function (node, i) {
                var ZB = null;

                if (me.splitWithBounds)
                    ZB = ezMinMaxResolver.findMins(node);

                node.computedBounds = ZB;
                szInfos.push(ezSplit.createSzInfos(node.outerZone, me.axis, ZB));
            });

            // Map of splitting params according axis.
            // Distance of splitting is innerZone of this node.
            var spParamMap = {};
            spParamMap[$ez.X_AXIS] = { D: InZ.xdim, zonePos: InZ.x };
            spParamMap[$ez.Y_AXIS] = { D: InZ.ydim, zonePos: InZ.y };

            // Update splitting
            var splitRes = SP.split({
                D: spParamMap[me.axis].D,
                zonePos: spParamMap[me.axis].zonePos,
                sepThick: me.thickProvider.getSeparator(),
                szInfos: szInfos
            });

            // Compute separator margins
            if (me.axis === $ez.X_AXIS) { // Vertical separators
                computeParam.setVSepMargins(
                    MP ? (computeParam.isRealRoot() ? MP.FrontSeparator0Margin : (computeParam.hasClosestFront() ? computeParam.getClosestFront().getModel().BackMargin : MP.FrontSeparatorMargin)) : 0,
                    MP ? (computeParam.isRealRoot() ? MP.BackSeparator0Margin : (computeParam.hasClosestBack() ? 0 : MP.BackSeparatorMargin)) : 0);

            }
            else { // Horizontal separators
                computeParam.setHSepMargins(
                    MP ? (computeParam.hasClosestFront() ? computeParam.getClosestFront().getModel().BackMargin : MP.FrontShelfMargin) : 0,
                    MP ? (computeParam.hasClosestBack() ? 0 : MP.BackShelfMargin) : 0);
            }

            // Separator count
            var sepCount = splitRes.sepPositions.length;

            // Separators update
            for (i = 0; i < sepCount; i++) {               
                var P = me.separators.get(i);

                if (P === null) { // Not created
                    P = ezPanel.create(me.innerZone);

                    if (me.axis === $ez.X_AXIS) {
                        P.title = "Séparation verticale";
                        P.kind = $ez.KINDS.VSep;
                        P.addPrice = me.closet.model.AddPriceSeparator;
                    }
                    else {
                        P.title = "Séparation horizontale";
                        P.kind = $ez.KINDS.HSep;
                        P.addPrice = me.closet.model.AddPriceShelf;
                    }

                    P.thickness = me.thickProvider.getSeparator();
                    me.separators.set(P, i);
                }

                // For columns with different height
                var smallerTopMargin = me.subNodes.get(i).paddings.top < me.subNodes.get(i + 1).paddings.top ? me.subNodes.get(i).paddings.top : me.subNodes.get(i + 1).paddings.top;
                P.setUserMargins({ top: smallerTopMargin });

                // For issue status
                P.setIssueStatus(isModule ? me._issues.getModuleOffWidth() : 'OK');

                me._updateSeparator(computeParam, P, splitRes.sepPositions[i]);
            }

            var belowSepBckup = computeParam.belowSeparator;
            var aboveSepBckup = computeParam.aboveSeparator;

            // Sub-nodes outer-zone update
            me.subNodes.forEach(function (sub, i) {

                if (szInfos.length > 1) {
                    var outerSz = sub.outerZone;

                    var a = {
                        splittedZone: InZ,
                        szInfo: szInfos[i],
                        sz: outerSz,
                    };

                    if (me.axis === $ez.X_AXIS) {
                        ezSplit.updateZoneOfXSplit(a);
                    }
                    else if (me.axis === $ez.Y_AXIS) {
                        ezSplit.updateZoneOfYSplit(a);

                        computeParam.belowSeparator = i > 0 ? me.separators.get(i - 1) : belowSepBckup;
                        computeParam.aboveSeparator = i < me.separators.getLength() ? me.separators.get(i) : aboveSepBckup;
                    }
                    else
                        $ez.THROW("CNode does not support splitting along Z axis.");
                }

                sub.update(computeParam);
            });

            computeParam.belowSeparator = belowSepBckup;
            computeParam.aboveSeparator = aboveSepBckup;
        }

        // Update separator P according to axis. useful in splitting operation
        // computeParam : (CNComputeParam) The node compute parameters
        // P : (Panel class) the separator.
        // sepPos: (Number) the position of panel as is define in splitRes.
        _P._updateSeparator = function (computeParam, P, sepPos) {
            var me = this;
            var InZ = me.innerZone;
            var axis = me.axis;
            var C = me.closet;
            //var MP = me.marginProvider;

            if (axis === $ez.X_AXIS) {

                // Compute separator margins
                //var frontM = MP ? (computeParam.isRealRoot() ? MP.FrontSeparator0Margin : (computeParam.hasClosestFront() ? computeParam.getClosestFront().getModel().BackMargin : MP.FrontSeparatorMargin)): 0;
                //var backM = MP ? (computeParam.isRealRoot() ? MP.BackSeparator0Margin : (computeParam.hasClosestBack() ? 0 : MP.BackSeparatorMargin)): 0;
                //P.setMargins({ front: frontM, back: backM });
                P.setMargins({ front: computeParam.getVSepMargins().front, back: computeParam.getVSepMargins().back });

                // Before rotation
                P.length = InZ.getHeight() - P.getMargins().top - P.getUserMargins().top;
                P.width = InZ.getDepth() - P.getMargins().front - P.getMargins().back;

                // Global dim
                P.xdim = C.getMainThickness();
                P.ydim = P.length;
                P.zdim = P.width;

                P.rot = { x: -Math.PI / 2, y: 0, z: Math.PI / 2, order: 'YZX' };

                // After rotation
                P.x = sepPos;
                P.y = ezPanel.getMarginYOffs({ bottom: P.getMargins().bottom, top: P.getMargins().top + P.getUserMargins().top }) + InZ.y;
                P.z = ezPanel.getMarginZOffs(P.getMargins()) + InZ.z;

                P.parentZone = InZ; // Can be changed by simplify process

                // Baseboard management
                if (!computeParam.isRealRoot()) {
                    var baseboard = C.getBaseboard();
                    if (baseboard.isDefined()) {
                        // Required to check collision
                        if (baseboard.hasCollision(P.y, P.z, P.ydim, P.zdim)) {
                            P.setMargins({ back: computeParam.getVSepMargins().back + baseboard.getDepth() });
                            // Recompute pos and dim
                            P.width = InZ.getDepth() - P.getMargins().front - P.getMargins().back;
                            P.zdim = P.width;
                            P.z = ezPanel.getMarginZOffs(P.getMargins()) + InZ.z;
                        }
                    }
                }
            }
            else if (axis === $ez.Y_AXIS) {

                var cornerMargins = C.getDriver().getPartMargins(me);

                // Compute separator margins
                //var frontM = MP ? (computeParam.hasClosestFront() ? computeParam.getClosestFront().getModel().BackMargin : MP.FrontShelfMargin) : 0;
                //var backM = MP ? (computeParam.hasClosestBack() ? 0 : MP.BackShelfMargin) : 0;
                //computeParam.setHSepMargins(frontM, backM);
                //P.setMargins({ front: frontM, back: backM });
                P.setMargins({ front: computeParam.getHSepMargins().front, back: computeParam.getHSepMargins().back });

                if (cornerMargins && cornerMargins.HSeparator) { // For corner case
                    P.setMargins({ left: cornerMargins.HSeparator.left, right: cornerMargins.HSeparator.right });
                }

                // before rotation
                P.length = InZ.getWidth() - P.getMargins().left - P.getMargins().right;
                P.width = InZ.getDepth() - P.getMargins().front - P.getMargins().back;

                // Global dim
                P.xdim = P.length;
                P.ydim = C.getMainThickness();
                P.zdim = P.width;

                P.rot = { x: -Math.PI / 2, y: 0, z: 0, order: 'YZX' };

                // after rotation
                P.x = ezPanel.getMarginXOffs(P.getMargins()) + InZ.x;
                P.y = sepPos;
                P.z = ezPanel.getMarginZOffs(P.getMargins()) + InZ.z;

                P.parentZone = InZ; // Can be changed by simplify process

                // Baseboard management
                var baseboard = C.getBaseboard();
                if (baseboard.isDefined()) {
                    // Required to check collision
                    if (baseboard.hasCollision(P.y, P.z, P.ydim, P.zdim)) {
                        P.setMargins({ back: computeParam.getHSepMargins().back + baseboard.getDepth() });
                        // Recompute pos and dim
                        P.width = InZ.getDepth() - P.getMargins().front - P.getMargins().back;
                        P.zdim = P.width;
                        P.z = ezPanel.getMarginZOffs(P.getMargins()) + InZ.z;
                    }
                }
            }
            else { // INDEPTH
                $ez.THROW("Separator not implemented for 'INDEPTH' orientation.")
            }
                        
            P.update();
        }

        // Prepare this node before separator add. You must call this methode before addSeparator().
        // Consecutive call to addSeparator() could be done without call this method each time, if axis does not change only.
        // axis: X_AXIS or Y_AXIS, the axis along separator will be added.
        // Returns : (CNode) The node that must be passed as parameter of addSeparator() method.
        _P.prepareSeparatorAdd = function (axis) {
            var me = this;
            var result = me;

            // Node has same axis than separator orientation ? 
            var sameAxis = (axis === me.axis);

            if (me.isRoot()) { // The root
                result = me.initSubNodes(); // create a subnode to split and get it

                result.schOpts.copy(me.schOpts); // propagate schema options

                // Disable back and front if any to avoid unexpected part when return to single node configuration
                me.schOpts.hasBackActive = false;
                me.schOpts.hasFrontActive = false;
                me.schOpts.defaultBackClr = null;
                me.schOpts.defaultFrontClr = null;
                me.schOpts.frontDef = null;

                if (!sameAxis) { // Not same axis => need a sub-level
                    result.parent.innerZone.hide();
                    result = result.initSubNodes();
                }
            }
            else { // Deeper in tree ...
                if (sameAxis) { // Selected node has same axis than added separator
                    result = result.initSubNodes(); // create a subnode to split and get it
                }
                else if (me.parent.parent !== null && me.parts && (me.parts.back || me.parts.front))
                    result = result.initSubNodes().initSubNodes(); // Behind back or door
            }

            return result;
        }

        // Get the real-world level of node according to structure pattern.
        // Consider special case of 'X>Y[1-]' pattern, and sub-layout.
        // n :(Number) Internal use only. You must call this method without parameter.
        _P.getLevel = function (n) {
            var me = this;
            var level = n || 0;

            if (/*!me.parentOfST &&*/ me.subNodes.getLength() > 1)
                level++;

            if (me.parent)
                return me.parent.getLevel(level);
            else
                return level;
        }

        // Add separator with axis specified in this node.
        // Sub-node must belong to this node.
        // subNode: (CNode class) The sub-node of this node that will be splitted by separator.
        // insertBefore: (Boolean) If true insert added node BEFORE specified subnode, AFTER otherwise. Default is false : AFTER.
        // Returns: (CNode) The adjacent node to subNode that was created.
        _P.addSeparator = function (subNode, insertBefore) {
            var me = this;

            if (me.axis !== $ez.X_AXIS && me.axis !== $ez.Y_AXIS)
                throw new Error("[EZCloset] Add separator operation does not support orientation '" + me.axis + "'.");

            var iSplit = me.subNodes.indexOf(subNode);
            if (iSplit < 0) throw new Error("[EZcloset] Cannot find sub-node. addSeparator() method failed.");

            // prepare separator array
            if (!me.separators) me.separators = $ez.createEZArray();
            me.separators.insertAt(null, iSplit);

            // Keep current dimension before splitting
            var beforeSplitDim = (me.axis === $ez.X_AXIS) ? subNode.outerZone.xdim : subNode.outerZone.ydim;

            // Count of auto-zone before splitting
            var autoZoneCount = me.getAutoZoneCount();

            // The added node
            var added = new CNode({
                closet: me.closet,
                schemaProvider: me.schemaProvider,
                thickProvider: me.thickProvider,
                marginProvider: me.marginProvider,
                parent: me,
                axis: me.axis === $ez.X_AXIS ? $ez.Y_AXIS : $ez.X_AXIS
            });
            added.innerZone.canDisplay = true;
            added.outerZone.hasXDimAuto = me.outerZone.hasXDimAuto;
            added.outerZone.hasYDimAuto = me.outerZone.hasYDimAuto;
            added.outerZone.hasZDimAuto = me.outerZone.hasZDimAuto;
            me.subNodes.insertAt(added, insertBefore ? iSplit : iSplit + 1); // insert before or after

            // Manage schema options
            if (subNode.level === 1) {
                added.schOpts.hasBackActive = subNode.schOpts.hasBackActive;
            }

            me.innerZone.canDisplay = false;

            if (me.axis === $ez.X_AXIS && subNode.outerZone.hasXDimAuto) {
                subNode.outerZone.hasXDimAuto = true;   
                added.outerZone.hasXDimAuto = true;
            }
            else if (me.axis === $ez.Y_AXIS && subNode.outerZone.hasYDimAuto) {
                subNode.outerZone.hasYDimAuto = true;
                added.outerZone.hasYDimAuto = true;
            }
            else { // Fixed zone

                var subNodeDimAuto, addedDimAuto;
                var splittedIsAuto = (me.axis === $ez.X_AXIS) ? subNode.outerZone.hasXDimAuto : subNode.outerZone.hasYDimAuto;
                if (splittedIsAuto && autoZoneCount === 1) { // to avoid last auto-zone to become fixed.
                    subNodeDimAuto = false;
                    addedDimAuto = true;
                }
                else { // Not the last auto-zone, twice are fixed
                    subNodeDimAuto = false;
                    addedDimAuto = false;
                }

                // Dimension of splitting from current node innerZone.
                var dim = (beforeSplitDim / 2) - (me.closet.getMainThickness() / 2);

                if (me.axis === $ez.X_AXIS) {
                    subNode.outerZone.hasXDimAuto = subNodeDimAuto;
                    added.outerZone.hasXDimAuto = addedDimAuto;
                    subNode.outerZone.xdim = dim;
                    added.outerZone.xdim = dim;
                }
                else {
                    subNode.outerZone.hasYDimAuto = subNodeDimAuto;
                    added.outerZone.hasYDimAuto = addedDimAuto;
                    subNode.outerZone.ydim = dim;
                    added.outerZone.ydim = dim;
                }

            }

            // Clear consolidators if any
            if (me.parts) me.parts.removeConsolidators();

            // Manage paddings (different column height case)
            if (me.axis === $ez.X_AXIS) {
                added.paddings.top = subNode.paddings.top;
            }

            return added;
        }

        // Get count of auto zone owned by this node.
        // If node axis is X_AXIS, sub-node is counted if innerZone.hasXDimAuto is true.
        _P.getAutoZoneCount = function () {
            var me = this;
            var count = 0;
            if (me.axis === $ez.X_AXIS)
                me.subNodes.forEach(function (sn, i) { if (sn.innerZone.hasXDimAuto) count++; });
            else
                me.subNodes.forEach(function (sn, i) { if (sn.innerZone.hasYDimAuto) count++; });

            return count;
        }

        // Initialize sub-nodes collection with a new sub-node. Raise an error if already contains subNodes.
        // The created sub-node will always an opposite axis.
        // Ex: have $ez.Y_AXIS if current node axis is $ez.X_AXIS, and vice-versa.
        // Result : the created sub-node.
        _P.initSubNodes = function () {
            var me = this;

            if (!me.subNodes.isEmpty())
                throw new Error("[EZCloset] This node contains sub-nodes. Cannot initialize sub-nodes. May be verify selected zone.");

            var oppAxis;
            if (me.axis === $ez.Y_AXIS)
                oppAxis = $ez.X_AXIS;
            else if (me.axis === $ez.X_AXIS)
                oppAxis = $ez.Y_AXIS;
            else
                throw new Error("[EZCloset] Unsupported axis '" + me.axis + "'. initSubNodes() method failed.");

            // Create a sub-node
            var sub = new CNode({
                closet: me.closet,
                schemaProvider: me.schemaProvider,
                thickProvider: me.thickProvider,
                marginProvider: me.marginProvider,
                parent: me,
                axis: oppAxis
            });

            //sub.schOpts.copy(me.schOpts); // propagate schema options

            me.subNodes.add(sub);

            sub.outerZone.xdim = me.innerZone.xdim;
            sub.outerZone.ydim = me.innerZone.ydim;
            sub.outerZone.zdim = me.innerZone.zdim;
            sub.outerZone.x = me.innerZone.x;
            sub.outerZone.y = me.innerZone.y;
            sub.outerZone.z = me.innerZone.z;

            // Hide/show zone
            me.innerZone.canDisplay = false;
            sub.innerZone.canDisplay = true;

            return sub;
        }

        // Remove sub-nodes. Remove and dispose all sub-nodes and separators from this node.
        _P.removeSubNodes = function () {
            var me = this;

            if (me.separators && !me.separators.isEmpty())
                me.separators.forEach(function (sep, i) { sep.hide(); sep.dispose(); });
            me.separators = null;

            if (!me.subNodes.isEmpty()) {
                me.subNodes.forEach(function (sn, i) { sn.removeSubNodes(); sn.hide(); sn.dispose(); });
                me.subNodes.removeAll();
            }
        }

        // Remove items. Remove and dispose all items from this node.
        _P.removeItems = function () {
            var me = this;

            if (me.items && !me.items.isEmpty())
                me.items.forEach(function (item, i) { item.hide(); item.dispose(); });
            me.items = null;
        }

        // Remove separator from this node according to the specified sub-node.
        // Sub-node must belong to this node.
        // subNode: (CNode class) The sub-node that will be removed with separator.
        // sepLoc: 'BEFORE' or 'AFTER'. The separator location according to sub-node. ex. bottom or left separator are 'BEFORE' the sub-node.
        // Return : return a valid node (see _cleanTree() method).
        _P.removeSeparator = function (subNode, sepLoc) {
            var me = this;

            if (me.axis !== $ez.X_AXIS && me.axis !== $ez.Y_AXIS)
                throw new Error("[EZCloset] Remove same orientation seprator operation does not support axis '" + me.axis + "'.");

            if (sepLoc !== 'BEFORE' && sepLoc !== 'AFTER')
                throw new Error("[EZCloset] Remove same orientation seprator operation does not support separator location '" + sepLoc + "'.");

            var iSubNode = me.subNodes.indexOf(subNode);
            if (iSubNode < 0) throw new Error("[EZcloset] Cannot find sub-node. removeSeparator() method failed.");

            if (iSubNode === 0 && sepLoc === 'BEFORE')
                throw new Error("[EZcloset] Cannot remove separator before first sub-node.");
            if (iSubNode === me.subNodes.getLastIndex() && sepLoc === 'AFTER')
                throw new Error("[EZcloset] Cannot remove separator after last sub-node.");

            // Count of sub-node before removal
            var nSubNode = me.subNodes.getLength();

            // Get adjacent sub-nodes
            var iAdjSubNode = (sepLoc === 'BEFORE') ? iSubNode - 1 : iSubNode + 1;
            var adjSubNode = me.subNodes.get(iAdjSubNode);
            var adjSubNodeIsEmpty = adjSubNode.isEmpty();
            var subNodeIsEmpty = subNode.isEmpty();
            if (!adjSubNodeIsEmpty && !subNodeIsEmpty) throw new Error("[EZcloset] Cannot remove separator cause fused sub-nodes are not empty.");

            // Remove sub-node and separator
            me.subNodes.removeAt(iSubNode);
            var sep = me.separators.removeAt((sepLoc === 'BEFORE') ? iSubNode - 1 : iSubNode); // removed separator

            // Fuse removed sub-node and adjacent sub-node if required
            if (adjSubNodeIsEmpty && !subNodeIsEmpty) // Ajacent must get removed sub-node content
                adjSubNode.attachContentOf(subNode);

            // Fuse front of removed sub-node and adjacent sub-node if required
            if (!adjSubNode.schOpts.hasFrontActive && subNode.schOpts.hasFrontActive) {
                adjSubNode.schOpts.hasFrontActive = subNode.schOpts.hasFrontActive;
                adjSubNode.schOpts.frontDef = subNode.schOpts.frontDef;
            }

            // Fuse door colour of removed sub-node and adjacent sub-node if required
            if (adjSubNode.schOpts.hasFrontActive && !adjSubNode.schOpts.defaultFrontClr) // Has front and not specific color
                adjSubNode.schOpts.defaultFrontClr = subNode.schOpts.defaultFrontClr;


            // Fuse back of removed sub-node and adjacent sub-node if required
            if (!adjSubNode.schOpts.hasBackActive && subNode.schOpts.hasBackActive)
                adjSubNode.schOpts.hasBackActive = subNode.schOpts.hasBackActive;

            // Fuse back colour of removed sub-node and adjacent sub-node if required
            if (adjSubNode.schOpts.hasBackActive && !adjSubNode.schOpts.defaultBackClr) // Has back and not specific color
                adjSubNode.schOpts.defaultBackClr = subNode.schOpts.defaultBackClr;

            // Manage size of remaining zone if required
            if (me.axis === $ez.X_AXIS) {
                if (!subNode.outerZone.hasXDimAuto && !adjSubNode.outerZone.hasXDimAuto) // Adjacent zone and removed zone are fixed
                    adjSubNode.outerZone.xdim = subNode.outerZone.xdim + adjSubNode.outerZone.xdim + me.closet.getMainThickness();
                else // it becomes auto
                    adjSubNode.outerZone.hasXDimAuto = true;
            }
            else { // $ez.Y_AXIS
                if (!subNode.outerZone.hasYDimAuto && !adjSubNode.outerZone.hasYDimAuto) // Adjacent zone and removed zone are fixed
                    adjSubNode.outerZone.ydim = subNode.outerZone.ydim + adjSubNode.outerZone.ydim + me.closet.getMainThickness();
                else // it becomes auto
                    adjSubNode.outerZone.hasYDimAuto = true;
            }

            // Manage paddings (different column height)
            if (me.axis === $ez.X_AXIS) {
                var smallestPadding = subNode.paddings.top < adjSubNode.paddings.top ? subNode.paddings.top : adjSubNode.paddings.top;
                adjSubNode.paddings.top = smallestPadding;
            }

            sep.hide();
            sep.dispose();

            subNode.hide();
            subNode.dispose();

            // var args = { updatable: null };
            // DEBUG : adjSubNode.closet.root.simpleDump(null);
            var root = adjSubNode.closet.root; //adjSubNode.getFirstAncestor(function (n) { return n.isRoot(); });
            root.simplifyTree();
            return root; //args.updatable;
        }

        // Get the first selectable node by recursing down from this node.
        // If this node is selectable, returns itself.
        _P.get1stSelectable = function () {
            var me = this;
            if (me.innerZone.canDisplay) return me;

            var sn;
            // recurse in sub-nodes
            for (var i = 0; i < me.subNodes.getLength(); i++) {
                sn = me.subNodes.get(i).get1stSelectable();
                if (sn)
                    return sn;
                else
                    return sn.get1stSelectable();
            }

            throw new Error("[EZCloset] Cannot find descendent selectable node. Verify node that called this method.");
        }

        // Attach content of specified node (i.e : items, separators and sub-nodes), and detach all from node.
        // Used by remove separator to recover content of adjacent node. 
        // Nota : all sub-nodes of node will have parent-node setted with this node.
        _P.attachContentOf = function (node) {
            var me = this;

            if (!me.isEmpty()) throw new Error("[EZCloset] Cannot attach content cause this node is not empty.");

            //me.schOpts.copy(node.schOpts);

            me.items = node.items;
            if (me.items) me.items.forEach(function (item, i) { item.attachTo(me) })

            me.separators = node.separators;
            me.subNodes = node.subNodes;
            me.subNodes.forEach(function (n) { n.parent = me });

            // to avoid unexpected dispose() since is detached
            node.items = null;
            node.separators = null;
            node.subNodes = null;
        }

        // Tranfer content of this node to a new created node
        // Returns : the created node where content was transfered. The created node has no parent. You are responsible to attach this node.
        _P.transfer = function() {
            var me = this;
            var dest = new CNode({
                closet: me.closet,
                schemaProvider: me.schemaProvider,
                thickProvider: me.thickProvider,
                marginProvider: me.marginProvider, 
                parent: null,
                axis: me.axis
            });

            dest.attachContentOf(me);
            me.subNodes = $ez.createEZArray(); // because attachContentOf released it
            return dest;
        }

        // Get the dimension bound according auto-size status of inner-zone.
        _P._getMinWidth = function (ZB) { return this.innerZone.hasXDimAuto ? ZB.width.min : this.innerZone.xdim }
        _P._getMaxWidth = function (ZB) { return this.innerZone.hasXDimAuto ? ZB.width.max : this.innerZone.xdim }
        _P._getMinHeight = function (ZB) { return this.innerZone.hasYDimAuto ? ZB.height.min : this.innerZone.ydim }
        _P._getMaxHeight = function (ZB) { return this.innerZone.hasYDimAuto ? ZB.height.max : this.innerZone.ydim }

        // Set auto-size status of subNode outer-zone with the specified value of isAuto.
        _P.setZoneAutoSize = function (subNode, axis, isAuto) {
            var me = this;

            if (me.axis !== $ez.X_AXIS && me.axis !== $ez.Y_AXIS)
                throw new Error("[EZCloset] Set auto-size cannot be done on axis '" + me.axis + "'.");

            var iSubNode = me.subNodes.indexOf(subNode);
            if (iSubNode < 0) throw new Error("[EZcloset] Cannot find sub-node. setAutoSize() method failed.");

            if (!isAuto) {// Zone become fixed
                // We have to verify if remain 1 auto-size zone at least

                // Count of auto-zone before status change
                var autoZoneCount = me.getAutoZoneCount();

                var subNodeIsAuto = axis === $ez.X_AXIS ? subNode.outerZone.hasXDimAuto : subNode.outerZone.hasYDimAuto
                if (autoZoneCount === 1 && subNodeIsAuto) { // Only one auto-zone and its the sub-node 
                    // We have to set adjacent zone to auto-size
                    if (me.subNodes.getLength() === 1) throw new Error("[EZCloset] Set auto-size cannot be done cause sub-node contains only 1 sub-node.");

                    var lastIdx = me.subNodes.getLastIndex();
                    var adjSubNode;
                    if (iSubNode < lastIdx)
                        adjSubNode = me.subNodes.get(iSubNode + 1);
                    else
                        adjSubNode = me.subNodes.get(lastIdx - 1);

                    if (axis === $ez.X_AXIS)
                        adjSubNode.outerZone.hasXDimAuto = true;
                    else
                        adjSubNode.outerZone.hasYDimAuto = true;
                }
            }

            if (axis === $ez.X_AXIS)
                me.subNodes.get(iSubNode).outerZone.hasXDimAuto = isAuto;
            else
                me.subNodes.get(iSubNode).outerZone.hasYDimAuto = isAuto;
        }

        // Set colour with down recursion
        // colour: (dal.Colour) new colour of panels
        _P.setColour = function (colour) {
            var me = this;

            if (me.parts) me.parts.setColour(colour);

            if (me.separators && !me.separators.isEmpty())
                me.separators.forEach(function (sep, i) { sep.setColour(colour); sep.updateThreeMat(); });

            if (me.items && !me.items.isEmpty())
                me.items.forEach(function (item, i) { if (item.setColour) item.setColour(colour); });

            if (!me.subNodes.isEmpty())
                me.subNodes.forEach(function (sn, i) { sn.setColour(colour) });
        }

        // Set visual margins of parts.
        _P.setVisualMargins = function (margins) { this.visualMargins = margins; }

        // Set paddings
        // paddings: (Object) The padding values as { left: (Number), right: (Number), bottom: (Number), top: (Number), back: (Number), front: (Number) }
        //          Each value are optionals.
        _P.setPaddings = function (paddings) {
            var pad = this.paddings;

            if (paddings.hasOwnProperty('left')) pad.left = paddings.left;
            if (paddings.hasOwnProperty('right')) pad.right = paddings.right;
            if (paddings.hasOwnProperty('bottom')) pad.bottom = paddings.bottom;
            if (paddings.hasOwnProperty('top')) pad.top = paddings.top;
            if (paddings.hasOwnProperty('back')) pad.back = paddings.back;
            if (paddings.hasOwnProperty('front')) pad.front = paddings.front;
        }

        // Get 1st back-panel from this node in ancestor direction.
        // If this node contains back-panel itself, the back-panel is returned.
        // Return (Panel class) : the 1st found back-panel, null if none.
        //[DEPRECATED]
        _P.getBackPanel = function () {
            var me = this;

            if (me.parts && me.parts.back) return me.parts.back;

            if (me.parent)
                return me.parent.getBackPanel();
            else
                return null;
        }

        // Get 1st ancestor node that match predicate function f, by starting from this node.
        // f: (Function) predicate function with current node as parameter: function(node) { /* must return true or false */}
        // Return : CNode object if found, null otherwise.
        _P.getFirstAncestor = function (f) {
            var me = this;

            if (f(me)) return me;

            if (me.parent)
                return me.parent.getFirstAncestor(f);
            else
                return null;
        }

        // Recurse down from this node and execute function a on each sub-node.
        // a: (Function) The action to do on each sub-node function(node, args) { ... }. Action is done on 1st node call too.
        //    if a() return false, the exploration will be stopped. Must return true to explore whole tree.
        // args: (Object) The arguments passed to action function. Optional.
        _P.exploreDown = function (a, args) {
            var me = this;

            if (!a(me, args)) return;

            for (var i = 0; i < me.subNodes.getLength(); i++) {
                me.subNodes.get(i).exploreDown(a, args);
            }
        }

        // Return index of this sub-node in its parent. If this is root return -1
        _P.getNodeIndex = function () {
            var me = this;
            if (!me.parent) return -1;
            return me.parent.subNodes.indexOf(me);
        }

        // Returns usable dimensions of node
        _P.getUsableDims = function () { return this._usableDims; }

        // Returns issues of this nodes
        _P.getIssues = function () { return this._issues; }

        // Display the node recursively
        _P.display = function () {
            var me = this;

            if(!me.canDisplay) return;

            if (me.parts) me.parts.display();
            if (me.innerZone) me.innerZone.display();
            if (me.items) me.items.forEach(function (o) { o.display() });
            if (me.separators) me.separators.forEach(function (o) { if (o) o.display() });
            if (me.subNodes) me.subNodes.forEach(function (o) { o.display() });
            if (me.door && me.SDDisplayable) me.door.display();
        }

        // Hide the node recursively
        _P.hide = function () {
            var me = this;

            if (me.parts) me.parts.hide();
            if (me.innerZone) me.innerZone.hide();
            if (me.items) me.items.forEach(function (o) { o.hide() });
            if (me.separators) me.separators.forEach(function (o) { if (o) o.hide() });
            if (me.subNodes) me.subNodes.forEach(function (o) { o.hide() });
            if (me.door) me.door.hide();
        }

        // Update three material. Useful to change scene mode.
        _P.updateThreeMat = function () {
            var me = this;

            if (me.parts) me.parts.updateThreeMat();
            if (me.innerZone) me.innerZone.updateThreeMat();
            if (me.items) me.items.forEach(function (o) { if (o.updateThreeMat) o.updateThreeMat(); });
            if (me.separators) me.separators.forEach(function (o) { if (o) o.updateThreeMat(); });
            if (me.subNodes) me.subNodes.forEach(function (o) { o.updateThreeMat(); });
            if (me.door) me.door.updateThreeMat();
        }

        // Empty the node recursively. Node is disposed before.
        _P.empty = function () {
            var me = this;
            me.hide();
            me.dispose();

            if (me.parts) me.parts = null;
            if (me.items) me.items = null;
            if (me.separators) me.separators = null;
            if (me.subNodes && !me.subNodes.isEmpty()) {
                me.subNodes.forEach(function (sn) { sn.empty(); });
                me.subNodes.removeAll();
            }
            if (me.door) me.door = null;
        }

        // Dispose the node recursively
        _P.dispose = function () {
            var me = this;

            if (me.parts) me.parts.dispose();
            if (me.outerZone) me.outerZone.dispose();
            if (me.paddingZone) me.paddingZone.dispose();
            if (me.innerZone) me.innerZone.dispose();
            if (me.items) me.items.forEach(function (o) { o.dispose(); });
            if (me.separators) me.separators.forEach(function (o) { if (o) o.dispose(); });
            if (me.subNodes) me.subNodes.forEach(function (o) { o.dispose(); });
            if (me.door) me.door.dispose();
        }

        // BOM filling recursively
        // bom: (Bom) The BOM to fill.
        _P.fillBOM = function (bom) {
            var me = this;
            if (me.parts) me.parts.fillBOM(bom);
            if (me.items) me.items.forEach(function (o) { o.fillBOM(bom); });
            if (me.separators) me.separators.forEach(function (o) { if (o) o.fillBOM(bom); });
            if (me.subNodes) me.subNodes.forEach(function (o) { o.fillBOM(bom); });
            if (me.door) me.door.fillBOM(bom);
        }

        // Dump simplified tree recursively in console log (for debug purpose only)
        // Use: call method simpleDump(UIScene.getCurrentZone()). ind parameter for internal use only.
        _P.simpleDump = function (selectedZone, ind) {
            var me = this;
            var zoneText;
            if (!ind) {
                ind = '';
                $log.info("CNode:dump =============================");
                $log.info("Closet model class : " + me.closet.getClosetModel().ModelClass);
            }

            var schema = '';
            if (me.schema) {
                schema = '(';
                if (me.schema.HasLeft) schema += ' LF ';
                if (me.schema.HasRight) schema += ' RG ';
                if (me.schema.HasBottom) schema += ' BT ';
                if (me.schema.HasTop) schema += ' TP ';
                if (me.schema.HasBack && me.schOpts.hasBackActive) schema += ' BK ';
                if (me.schema.HasFront && me.schOpts.hasFrontActive) schema += ' FT ';
                schema += ')';
            }

            var scho = '';
            if (me.schOpts.hasFrontActive || me.schOpts.hasBackActive) {
                scho = '(';
                if (me.schOpts.hasBackActive) scho += ' bk ';
                if (me.schOpts.hasFrontActive) scho += ' ft ';
                scho += ')';
            }

            var inDims = 'InZon(' + $ez.round2(me.innerZone.xdim) + ' ; ' + $ez.round2(me.innerZone.ydim) + ' ; ' + $ez.round2(me.innerZone.zdim) + ')';


            var sep = '';
            if (me.separators && me.separators.getLength() > 0) {
                sep = '[' + me.separators.getLength() + (me.axis === $ez.X_AXIS ? '|' : '-') + ']';
            }

            var zoneText = " [W=" + (me.outerZone.hasXDimAuto ? "AUTO" : "FREE") + " / H=" + (me.outerZone.hasYDimAuto ? "AUTO" : "FREE") + "]";
            if (me.innerZone.canDisplay) zoneText += " [CAN_DISPLAY] ";

            var mins = ezMinMaxResolver.findMins(me);
            var maxs = ezMinMaxResolver.findMaxs(me);
            zoneText += " MinMax[";
            zoneText += " W(" + $ez.round2(mins.w) + "; " + $ez.round2(maxs.w) + ")";
            zoneText += " H(" + $ez.round2(mins.h) + "; " + $ez.round2(maxs.h) + ")";
            zoneText += " wd=" + $ez.round2(mins._wd);
            zoneText += " hd=" + $ez.round2(mins._hd); 
            zoneText += " ]";
            
            if (me.innerZone === selectedZone) zoneText += " << CURRENT SELECTED";

            var itemsText = ""
            if (me.items) {
                itemsText += "(";
                me.items.forEach(function (it) { itemsText += " " + it.getEzType() + " "; });
                itemsText += ")";
            }

            $log.info(ind + "+ " + me.axis + "(" + me.CUID + ")" + " " + sep + " " + inDims + " " + schema + " " + scho + " " + zoneText + " " + itemsText);

            ind += '  ';

            var n = me.subNodes.getLength();
            for (var i = 0; i < n; i++)
                me.subNodes.get(i).simpleDump(selectedZone, ind);
        }

    })(CNode.prototype);


    ////// ezCTree service
    return {
        $name: 'ezCTree',

        $newCNode: function (args) { return new CNode(args); }
    };
}


////// EXPORT
export default {
    $init: _init
}