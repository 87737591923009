﻿///// Init method for injection
function _init(dcies, $ez, ez3D, ezGPos) {

    var $log = $ez.getLogger();


    /////// ZoneSelection class
    // Brief : To display zone with "selected" state.
    // Constructor
    var ZoneSelection = function (zone) {
        var me = this;
        me.zone = zone;
        me.hasCrossedLines = false; // [DEPRECATED]

        me._bMesh;
        me._tMesh;
        me._lMesh;
        me._rMesh;

        me._line1;
        me._line2;
        me._line1P1 = ez3D.createVector3();
        me._line1P2 = ez3D.createVector3();
        me._line2P1 = ez3D.createVector3();
        me._line2P2 = ez3D.createVector3();

        me.mesh;
        me._udpdateRequired = true;
        me._isDisplayed = false;
    };
    // Methods
    (function (_P) {

        // Update the zone selection
        _P.update = function () {
            var wBox = this.zone.relNode.closet.isUIEasy() ? 4 : 2;
            var tBox = 1;
            var mat = ez3D.getSelectedZoneMat();
            var xoffs = 0;
            var yoffs = 0;
            var zoffs = 0.5;
            var grndAng = this.zone.closet.getGAng();
            var grndPos = this.zone.closet.getGPos();

            // Closet position
            var cx = this.zone.x;
            var cy = this.zone.y;
            var cz = this.zone.z + this.zone.zdim / 2;


            // Box length as zone dim
            var xLen = this.zone.xdim;
            var yLen = this.zone.ydim;

            // The base geometries
            var hbox = ez3D.createBox(xLen + (xoffs * 2), wBox, tBox);
            var vbox = ez3D.createBox(yLen + (yoffs * 2), wBox, tBox);

            if (this.hasCrossedLines) {
                // The cross lines
                /*this._line1P1.set(cx - xLen / 2, cy - yLen / 2, cz);
                this._line1P2.set(cx + xLen / 2, cy + yLen / 2, cz);

                this._line2P1.set(cx - xLen / 2, cy + yLen / 2, cz);
                this._line2P2.set(cx + xLen / 2, cy - yLen / 2, cz);*/
            }

            // The rotations
            var yCylRot = { x: 0, y: grndAng, z: Math.PI / 2, order: 'XYZ' }

            if (!this.mesh) {
                // bottom mesh 
                this._bMesh = ez3D.createMesh(hbox, mat);
                this._bMesh.rotation.set(0, grndAng, 0);

                // top mesh
                this._tMesh = ez3D.createMesh(hbox, mat);
                this._tMesh.rotation.set(0, grndAng, 0);

                // left mesh
                this._lMesh = ez3D.createMesh(vbox, mat);
                this._lMesh.rotation.set(yCylRot.x, yCylRot.y, yCylRot.z, yCylRot.order);

                // right mesh
                this._rMesh = ez3D.createMesh(vbox, mat);
                this._rMesh.rotation.set(yCylRot.x, yCylRot.y, yCylRot.z, yCylRot.order);

                this.mesh = ez3D.createObject3D();

                if (this.hasCrossedLines) {
                    // Lines
                    /*this._line1 = ez3D.createLine(ez3D.createLineGeom(this._line1P1, this._line1P2) ,_selectedZoneLineMat);
                    this._line2 = ez3D.createLine(ez3D.createLineGeom(this._line2P1, this._line2P2), _selectedZoneLineMat);

                    this.mesh.add(this._line1, this._line2);*/
                }

                this.mesh.add(this._bMesh, this._tMesh, this._lMesh, this._rMesh);
            }
            else {
                if (this._bMesh.geometry) this._bMesh.geometry.dispose();
                if (this._tMesh.geometry) this._bMesh.geometry.dispose();
                if (this._lMesh.geometry) this._bMesh.geometry.dispose();
                if (this._rMesh.geometry) this._bMesh.geometry.dispose();

                this._bMesh.geometry = hbox;
                this._tMesh.geometry = hbox;
                this._lMesh.geometry = vbox;
                this._rMesh.geometry = vbox;

                if (this.hasCrossedLines) {
                    //this._line1.geometry.verticesNeedUpdate = true;
                    //this._line2.geometry.verticesNeedUpdate = true;
                }
            }

            // Factorizable coordinates
            var bz = cz + zoffs;
            var horGx = ezGPos.toGx(cx, bz, grndAng, grndPos);
            var horGz = ezGPos.toGz(cx, bz, grndAng, grndPos);
            var verGy = ezGPos.toGy(cy, grndPos);

            // bottom mesh 
            this._bMesh.position.set(
                horGx,
                ezGPos.toGy(cy - ((-wBox / 2) + yoffs + (yLen / 2)), grndPos),
                horGz);

            // top mesh
            this._tMesh.position.set(
                horGx,
                ezGPos.toGy(cy + ((-wBox / 2) + yoffs + (yLen / 2)), grndPos),
                horGz);

            // left mesh
            var lbx = cx - ((-wBox / 2) + xoffs + (xLen / 2));
            this._lMesh.position.set(
                ezGPos.toGx(lbx, bz, grndAng, grndPos),
                verGy,
                ezGPos.toGz(lbx, bz, grndAng, grndPos));

            // right mesh
            var rbx = cx + ((-wBox / 2) + xoffs + (xLen / 2));
            this._rMesh.position.set(
                ezGPos.toGx(rbx, bz, grndAng, grndPos),
                verGy,
                ezGPos.toGz(rbx, bz, grndAng, grndPos));

            this._udpdateRequired = false;
        }

        // Display zone selection
        _P.display = function () {
            if (!this.mesh || this._udpdateRequired)
                this.update();

            ez3D.add(this.mesh);
            this._isDisplayed = true;
        }

        // Hide zone selection
        _P.hide = function () {
            if (this.mesh)
                ez3D.remove(this.mesh);

            this._isDisplayed = false;
        }

        // Hide and invalidate the selection
        _P.invalidate = function () {
            this.hide();
            this._udpdateRequired = true;
        }

        // Return true if selection is displayed
        _P.isDisplayed = function () { return this._isDisplayed; }

        // Dispose
        _P.dispose = function () {
            if (this._bMesh && this._bMesh.geometry)
                this._bMesh.geometry.dispose();

            if (this._tMesh && this._tMesh.geometry)
                this._tMesh.geometry.dispose();

            if (this._lMesh && this._lMesh.geometry)
                this._lMesh.geometry.dispose();

            if (this._rMesh && this._rMesh.geometry)
                this._rMesh.geometry.dispose();
        }


    })(ZoneSelection.prototype);

    /////// ZoneIssues class
    // Brief : Manage zone issue states as zone off-width.
    // Constructor
    /*var ZoneIssues = function (zone) {
        var me = this;
        me.zone = zone;
        me.offWidth = { isActive: false, obj3D: null, meshes: [] };
    };
    (function (_P) {

        // Constants for width visualization
        var CYL_RAD = 0.3;
        var CON_RAD = 2;
        var CON_H = 4;

        // Active or deactive off-width issue on this zone
        _P.activeOffWidth = function (yes) {
            this.offWidth.isActive = yes;
        }
        _P.hasOffWidth = function () { return this.offWidth.isActive; }

        // Return true if zone has issues
        _P.hasIssues = function () { return this.offWidth.isActive; }

        // Update
        _P.update = function () {
            var me = this;

            if (me.offWidth.isActive) {
                var cyl = ez3D.createCylinder(CYL_RAD, me.zone.xdim - (CON_H * 2) - 0.2);

                if (me.offWidth.obj3D === null) {
                    var con = ez3D.createCone(CON_RAD, CON_H);

                    me.offWidth.obj3D = ez3D.createObject3D();
                    me.offWidth.obj3D.visible = false;
                    me.offWidth.meshes.push(ez3D.createMesh(cyl, ez3D.getErrorMat()));
                    me.offWidth.meshes.push(ez3D.createMesh(con, ez3D.getErrorMat()));
                    me.offWidth.meshes.push(ez3D.createMesh(con, ez3D.getErrorMat()));

                    me.offWidth.obj3D.add(me.offWidth.meshes[0]);
                    me.offWidth.obj3D.add(me.offWidth.meshes[1]);
                    me.offWidth.obj3D.add(me.offWidth.meshes[2]);

                    ez3D.add(me.offWidth.obj3D);
                }
                else {
                    if (me.offWidth.meshes[0].geometry) me.offWidth.meshes[0].geometry.dispose();
                    if (me.offWidth.meshes[1].geometry) me.offWidth.meshes[1].geometry.dispose();
                    me.offWidth.meshes[0].geometry = cyl;
                }

                me.offWidth.meshes[1].position.set(0, (me.zone.xdim - 0.2) / 2 - (CON_H / 2), 0);
                me.offWidth.meshes[2].position.set(0, -(me.zone.xdim - 0.2) / 2 + (CON_H / 2), 0);
                me.offWidth.meshes[2].rotation.set(0, 0, Math.PI);

                var grndAng = me.zone.closet.getGAng();
                me.offWidth.obj3D.rotation.set(0, grndAng, -Math.PI / 2, 'XYZ');
                me.offWidth.obj3D.position.set(me.zone.GPos.x, me.zone.GPos.y, me.zone.GPos.z);
            }            
        }

        // Update Display
        _P.updateDisplay = function (sceneMode) {
            var me = this;
            if (me.offWidth.obj3D)
                me.offWidth.obj3D.visible = me.offWidth.isActive && sceneMode === 'work';
        }

        // Hide
        _P.hide = function () {
            var me = this;
            if (me.offWidth.obj3D)
                me.offWidth.obj3D.visible = false;
        }

        // Dispose
        _P.dispose = function () {
            var me = this;
            if (me.offWidth.obj3D) {
                if (me.offWidth.meshes[0].geometry)
                    me.offWidth.meshes[0].geometry.dispose();
                ez3D.remove(me.offWidth.obj3D);
                me.offWidth.obj3D = null;
                me.offWidth.meshes = [];
                me.offWidth.isActive = false;
            }
        }


    })(ZoneIssues.prototype);*/

    /////// Zone Class
    // Brief : Zone is the base geometric container of panel. It is used in splitting mecanism too.
    // Constructor
    var Zone = function (closet) {
        var me = this;

        var me = this;

        me.CUID = closet.getCUID();
        me.closet = closet;
        me.relNode = null; // Class CNode.
        //me._issues = null;

        me.xdim = 0;
        me.ydim = 0;
        me.zdim = 0;
        me.x = 0;
        me.y = 0;
        me.z = 0;

        // Ground position
        me.GPos = ezGPos.$new();

        me.mesh;
        me.hasXDimAuto = true;
        me.hasYDimAuto = true;
        me.hasZDimAuto = true;

        me._sel3D = ez3D.has3D() ? new ZoneSelection(me) : null;
        me.canDisplay = false;
        me.isSelected = false;

        me.ownBySlidingDoor = false; // useful to detect allowed zone during selection process according current mode : Closet or Sliding-door.
    };
    // Methods
    (function (_P) {

        // Gets closet that own this zone
        _P.getCloset = function () { return this.closet; }

        // Return the parent node or null if is root
        // Result : CNode class or null.
        _P.getParentNode = function () { return this.relNode ? this.relNode.getParentNode() : null }

        // Get the zone material according to auto-size status and scene mode
        _P.getCurrentZoneMat = function (sceneMode) {
            if (sceneMode === 'work')
                return ez3D.getZoneMat();
            else
                return ez3D.getHiddenZoneMat();
        }

        // Get issues of zone
        // Returns : (ZoneIssues) The issues of this zone
        /*_P.getIssues = function () {
            if (!this._issues) this._issues = new ZoneIssues(this);
            return this._issues;
        }*/

        // Compute the zone
        _P.compute = function () {
            var me = this;
           
            var grndAng = me.closet.getGAng();
            var grndPos = me.closet.getGPos();
            me.GPos.x = ezGPos.toGx(me.x, me.z, grndAng, grndPos);
            me.GPos.y = ezGPos.toGy(me.y, grndPos);
            me.GPos.z = ezGPos.toGz(me.x, me.z, grndAng, grndPos);
        }

        // Compute 3D object to display in scene 3D
        _P.compute3D = function () {
            var me = this;
            var sceneMode = me.closet.getSceneMode();

            //var g = new THREE.BoxGeometry(me.xdim - 0.2, me.ydim - 0.2, me.zdim + 0.2);
            var g = ez3D.createBox(me.xdim - 0.2, me.ydim - 0.2, me.zdim + 0.2);
            if (!me.mesh) {
                me.mesh = ez3D.createMesh(g, me.getCurrentZoneMat(sceneMode));
                me.mesh.userData.zone = me;
                me.mesh.layers.enable(1);
                me.mesh.name = me.title;
            }
            else {
                if (me.mesh.geometry) me.mesh.geometry.dispose();

                me.mesh.material = me.getCurrentZoneMat(sceneMode);
                me.mesh.geometry = g;
            }

            var grndAng = me.closet.getGAng();
            me.mesh.rotation.set(0, grndAng, 0);
            me.mesh.position.set(me.GPos.x, me.GPos.y, me.GPos.z);
            

            // invalidate the selection
            if (me.isSelected /*me._sel3D.isDisplayed()*/) {
                me._sel3D.invalidate();
                me._sel3D.display();
            }
            else
                me._sel3D.invalidate();

            // Issues
            //if (me._issues)
              //  me._issues.update();
        }

        // Update the zone
        _P.update = function () {
            var me = this;

            me.compute();

            if (me.relNode !== null || me.ownBySlidingDoor) { // Has mesh to use for user selection
                if (ez3D.has3D()) me.compute3D();
            }
        }

        // Helpers to get dimensions
        _P.getWidth = function () { return this.xdim }
        _P.getHeight = function () { return this.ydim }
        _P.getDepth = function () { return this.zdim }

        // Methods to set width
        _P.setWidth = function (w) {
            if (this.hasXDimAuto) $ez.THROW("Cannot set width of auto-size zone.");
            this.xdim = w;
        }

        // Methods to set height
        _P.setHeight = function (h) {
            if (this.hasYDimAuto) $ez.THROW("Cannot set height of auto-size zone.");
            this.ydim = h;
        }

        // Methods to set depth
        _P.setDepth = function (d) {
            if (this.hasZDimAuto) $ez.THROW("Cannot set depth of auto-size zone.");
            this.zdim = d;
        }

        // Selection and unselection
        _P.select = function () {
            var me = this;
            if (!me.mesh) $ez.THROW("No relative node. Cannot select zone.");
            me._sel3D.display();
            me.isSelected = true;
        }
        _P.unselect = function () {
            var me = this;
            if (!me.mesh) $ez.THROW("No relative node. Cannot select zone.");
            this._sel3D.hide();
            me.isSelected = false;
        }

        // Return true if two dimensions of zone are auto
        _P.isFullAuto = function () { return this.hasXDimAuto && this.hasYDimAuto }

        // Return true if width of zone is greater than specified value
        _P.isOffWidth = function (w) { return this.xdim > w; }

        // Display this zone
        _P.display = function () {
            var me = this;

            if (me.canDisplay) {
                if (me.mesh)
                    ez3D.add(me.mesh)
                //if (me._issues)
                  //  me._issues.updateDisplay(me.closet.getSceneMode());
            }
        }

        // Hide this zone
        _P.hide = function () {
            var me = this;

            if (me.mesh) {
                ez3D.remove(me.mesh)
            }

            if (me._sel3D)
                me._sel3D.hide();

            //if (me._issues)
              //  me._issues.hide();
        }

        // Update the material of zone
        _P.updateThreeMat = function () {
            var me = this;
            if (me.mesh)
                me.mesh.material = me.getCurrentZoneMat(me.closet.getSceneMode());

            //if (me._issues)
             //   me._issues.updateDisplay(me.closet.getSceneMode());
        }

        // Dispose
        _P.dispose = function () {
            var me = this;
            if (me.mesh && me.mesh.geometry)
                me.mesh.geometry.dispose();

            if (me._sel3D)
                me._sel3D.dispose();

            //if (me._issues)
             //   me._issues.dispose();
        }
        
    })(Zone.prototype);


    ////// ezZone service
    return {
        $name: 'ezZone',

        // Create zone of closet with its optionnal relative node.
        // closet : (Closet class) the closet.
        // [relNode] : (CNode) the relative nove. Can be null.
        createZone: function (closet, relNode) {
            var Z = new Zone(closet);
            Z.relNode = relNode || null;
            return Z;
        }
    }
}


////// EXPORT
export default {
    $init: _init
}