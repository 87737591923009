/**
 * Height in px of main menu of Mobibam web-site according the device type
 */
const HEIGHT_DESKTOP_MENU = 85;
const HEIGHT_MOBILE_MENU = 65;

/**
 * The current type of display :
 * - Desktop : display the standard desktop configurator
 * - Mobile : display the mobile version of configurator
 * - BadOrientation : display a message "Turn your device" when device is mobile, and is horizontal.
 */
export type DisplayTypes = "Desktop" | "Mobile" | "BadOrientation";

/**
 * Service to manage height of screen zones according the device screen size. 
 * There are main zone computed :
 * - The main menu height : accordingly Mobile or Desktop
 * - The configurator total height : 3D and controls height excluding menu height
 * - The configurator 3D height : the height or 3D area (depends on configurator controls area on mobile)
 * - The configurator controls height : the height of area that contains user controls. Exists on mobile only, and is always fixed (see constructor of DeviceScreen).
 */
export interface IScreen {

    /**
     * Call this method on window.scree.onchange event
     */
    orientationChange(): void;

    /**
     * Call this method on widow.resize event
     */
    sizeChange(): void;

    readonly displayType: DisplayTypes;

    readonly configuratorTotalHeight: number;

    readonly configurator3DHeight: number;

    readonly configuratorCtrlsHeight: number;

}

export class DeviceScreen implements IScreen {

    private readonly _isMobile: boolean;
    private _displayType!: DisplayTypes;
    private _mainMenuHeight!: number;

    private _configuratorTotalHeight: number;
    private readonly _configuratorCtrlsHeight: number;

    constructor(configuratorCtrlsHeight: number) { 
        this._isMobile = /Mobi|Android/i.test(navigator.userAgent);
        this._configuratorCtrlsHeight = configuratorCtrlsHeight;
        this._configuratorTotalHeight = configuratorCtrlsHeight * 3;
        this._updateDisplayType();
        this._updateConfiguratorTotalHeight();
    }

    orientationChange() {
        this._updateDisplayType();
        this._updateConfiguratorTotalHeight();
    }

    sizeChange() { this._updateConfiguratorTotalHeight() }

    get displayType(): DisplayTypes { return this._displayType }

    get configuratorTotalHeight(): number { return this._configuratorTotalHeight }

    get configurator3DHeight(): number { return this._configuratorTotalHeight - this._configuratorCtrlsHeight }

    get configuratorCtrlsHeight(): number { return this._configuratorCtrlsHeight }

    private _updateDisplayType() {
        const winWidth = window.screen.width;
        const winHeight = window.screen.height;

        this._displayType = "Desktop";
        this._mainMenuHeight = HEIGHT_DESKTOP_MENU;
    
        if (this._isMobile) { // Mobile
            this._displayType = "Mobile";
            this._mainMenuHeight = HEIGHT_MOBILE_MENU;

            if (winWidth < winHeight) { // Vertical orientation
                if (winHeight >= 1024) {
                    this._displayType = "BadOrientation";
                    this._mainMenuHeight = 0;
                }
            }
            else { // Horizontal orientation
                if (winWidth >= 1024) {
                    this._displayType = "Desktop";
                    this._mainMenuHeight = HEIGHT_DESKTOP_MENU;
                }
                else {
                    this._displayType = "BadOrientation";
                    this._mainMenuHeight = 0;
                }
            }
        }
    }

    private _updateConfiguratorTotalHeight() { this._configuratorTotalHeight = window.innerHeight - this._mainMenuHeight }

}