﻿///// Init method for injection
function _init(dcies, $ez, ez3D, ezPrice, ezBOM, ezGPos) {

    /////// Class Accessory
    // Brief : Accessory described by a mesh like dressing accessories or door-handles.
    // Constructor
    // model: (dal.Accessory) Model of accessory
    var Accessory = function (model) {
        this.CUID = 0;
        this._owner = null;
        this._model = model;
        this._posRef = null; // (Zone | Panel) The position ref as : { x, y, z, xdim, ydim, zdim }
        this._mesh = null;
        this._wireFrame = null;
        this._material = null;

        this.x = 0;
        this.y = 0;
        this.z = 0;
        this.xdim = 0;
        this.ydim = 0;
        this.zdim = 0;
        this.gx = 0;
        this.gy = 0;
        this.gz = 0;

        this.xscale = 1;
        this.yscale = 1;
        this.zscale = 1;

        this._wireMaterial = null;
        this._issueStatus = 'OK';
        this._group = "";
    };
    (function (_P) {

        // Update the wireframe material according to current issue status
        _P._updateWireframeMat = function () {

            if (ez3D.has3D()) {

                if (!this._wireMaterial)
                    this._wireMaterial = ez3D.createLineMaterial({ color: 0x343434 });

                if (this._issueStatus === 'OK')
                    this._wireMaterial.color.setRGB(0x34 / 255, 0x34 / 255, 0x34 / 255);
                else
                    this._wireMaterial.color.setRGB(0xCC / 255, 0, 0);

            }
        }

        // Gets coordinate from container
        // contCoord: (Number) The coordinate of container (along same axis than side)
        // contDim: (Number) The dimension of container (along same axis than side)
        // side: (string) The constainer side of a specified axis where the accessory is positioned : neg_side | center | pos_side
        // dist: (Number) The distance of accessory from the side
        _P._getCoordFromContainer = function (contCoord, contDim, side, dist) {
            var coord = 0;

            switch (side) {
                case "neg_side":
                    coord = contCoord - (contDim / 2) + dist;
                    break;

                case "center":
                    coord = contCoord + dist;
                    break;

                case "pos_side":
                    coord = contCoord + (contDim / 2) - dist;
                    break;

                default:
                    $ez.THROW("Unknown positioning side '" + side + "'.");
            }

            return coord;
        }

        // Gets the coordinate modifier according to hook of accessory model.
        // Hook is the referential of accessory position.
        // hookSide: (String) The accessory side of a specified axis where the hook is positioned : neg_side | center | pos_side
        // bndMinCoord: (Number) The min coordinate of container bounding-box (along same axis than hookside)
        // bndMaxCoord: (Number) The max coordinate of container bounding-box (along same axis than hookside)
        _P._getCoordModifier = function (hookSide, bndMinCoord, bndMaxCoord, coordScale) {
            switch (hookSide) {
                case "neg_side":
                    return -bndMinCoord * coordScale;

                case "center":
                    return -((bndMinCoord + bndMaxCoord) / 2) * coordScale;

                case "pos_side":
                    return -bndMaxCoord * coordScale;

                default:
                    $ez.THROW("Unknown hook side '" + hookSide + "'.");
            }
        }

        // Gets dimension of accessory
        _P._getDim = function (refDim, minAccDim, maxAccDim) {
            if (refDim < minAccDim)
                return minAccDim;
            else if (refDim > maxAccDim)
                return maxAccDim;
            else
                return refDim;
            /*if (refDim >= minAccDim && refDim <= maxAccDim)
                return refDim;
            else
                return maxAccDim;*/ // Cannot greater than its max dimension
        }

        // Compute accessory. this._model must be loaded
        _P.compute = function () {
            var me = this;
            if (!me._owner) $ez.THROW("Accessory must be attached to ist parent node. Cannot compute accessory.");
            // if (!me._model.isLoaded()) $ez.THROW("Accessory model must be loaded. Cannot compute accessory.");

            me._issueStatus = 'OK'; // Reset issue if any

            var positioning = me._model.positioning;
            var meshModel = me._model.mesh; //me._model.getMeshModel();

            // Position from container
            me.x = me._getCoordFromContainer(me._posRef.x, me._posRef.xdim, positioning.onX.side, positioning.onX.dist);
            me.y = me._getCoordFromContainer(me._posRef.y, me._posRef.ydim, positioning.onY.side, positioning.onY.dist);
            me.z = me._getCoordFromContainer(me._posRef.z, me._posRef.zdim, positioning.onZ.side, positioning.onZ.dist);

            // Dimensions
            me.xdim = me._getDim(me._posRef.xdim, positioning.onX.minDim, positioning.onX.maxDim);
            me.ydim = me._getDim(me._posRef.ydim, positioning.onY.minDim, positioning.onY.maxDim);
            me.zdim = me._getDim(me._posRef.zdim, positioning.onZ.minDim, positioning.onZ.maxDim);

            // Scaling : scale to fit in zone * scale of mesh
            me.xscale = me.xdim / meshModel.XDim * meshModel.scale.x;
            me.yscale = me.ydim / meshModel.YDim * meshModel.scale.y;
            me.zscale = me.zdim / meshModel.ZDim * meshModel.scale.z;

            // Position must take in account the mesh-model hook       
            me.x += me._getCoordModifier(meshModel.hook.xside, meshModel.bndMin.x, meshModel.bndMax.x, me.xscale);
            me.y += me._getCoordModifier(meshModel.hook.yside, meshModel.bndMin.y, meshModel.bndMax.y, me.yscale);
            me.z += me._getCoordModifier(meshModel.hook.zside, meshModel.bndMin.z, meshModel.bndMax.z, me.zscale);
                       
            // GPos
            var ga = me._owner.getCloset().getGAng();
            var gp = me._owner.getCloset().getGPos();
            me.gx = ezGPos.toGx(me.x, me.z, ga, gp);
            me.gy = ezGPos.toGy(me.y, gp);
            me.gz = ezGPos.toGz(me.x, me.z, ga, gp);
        }

        // Compute 3D of accessory
        _P.compute3D = function () {
            var me = this;
            var sceneMode = me._owner.getCloset().getSceneMode();
            var i;
            var ga = me._owner.getCloset().getGAng();

            if (!me._material) {
                me._material = ez3D.createMaterial({
                    color: parseInt(me._model.Color, 16),
                    reflectivity: me._model._3DMaterial.Reflectivity, 
                    opacity: me._model._3DMaterial.Opacity,
                });
            }

            if (!me._wireMaterial)
                me._wireMaterial = ez3D.createLineMaterial({ color: 0x343434 });

            if (!me._mesh) {
                //me._mesh = me._model.cloneObject3D(me._material);
                me._mesh = ez3D.cloneObject3D(me._model.mesh.object3D, me._material);
                me._mesh.rotation.set(0, ga, 0, 'XYZ');

                // me._wireFrame = me._model.createWireframe(ez3D, me._mesh, me._wireMaterial);
                me._wireFrame = ez3D.createWireframe(me._mesh, me._wireMaterial);
                me._wireFrame.rotation.set(0, ga, 0, 'XYZ');
            }

            me._mesh.position.set(me.gx, me.gy, me.gz);
            me._mesh.scale.set(me.xscale, me.yscale, me.zscale);
            

            me._updateWireframeMat();
            me._wireFrame.position.set(me.gx, me.gy, me.gz);
            me._wireFrame.scale.set(
                me._mesh.scale.x,
                me._mesh.scale.y,
                me._mesh.scale.z
            );

            me._mesh.castShadow = (sceneMode !== 'work');
            me._mesh.receiveShadow = (sceneMode !== 'work');
        }

        // Update accessory
        _P.update = function () {
            var me = this;
            me.compute();
            if (ez3D.has3D()) me.compute3D();
        }

        // Gets EZ type
        _P.getEzType = function () { return 'Accessory' }

        // Gets or set group
        _P.getGroup = function () { return this._group; }
        _P.setGroup = function (g) { this._group = g; }

        // Gets model of accessory
        _P.getModel = function () { return this._model; }

        // Gets bounds
        _P.getBounds = function () {
            var positioning = this._model.positioning;
            return {
                width: { min: positioning.onX.minDim, max: positioning.onX.maxDim },
                height: { min: positioning.onY.minDim, max: positioning.onY.maxDim },
                depth: { min: positioning.onZ.minDim, max: positioning.onZ.maxDim }
            }
        }

        // Attach to node 
        _P.attachTo = function (parentNode) {
            this.CUID = parentNode.getCloset().getCUID();
            this._owner = parentNode;
            this._posRef = this._owner.innerZone;
        }

        // Returns true if zone is too small to receive model of accessory
        _P.zoneIsTooSmall = function (zone) {
            var positioning = this._model.positioning;

            if (zone.xdim < positioning.onX.minDim + positioning.onX.dist) return true;
            if (zone.ydim < positioning.onY.minDim + positioning.onY.dist) return true;
            if (zone.zdim < positioning.onZ.minDim + positioning.onZ.dist) return true;

            return false;
        }

        // Returns true if zone is too large to contain model of accessory.
        // Check the variable-dimension only.
        _P.zoneIsTooLarge = function (zone) {
            var positioning = this._model.positioning;

            if (!positioning.onX.allowLargestContainer && zone.xdim > positioning.onX.maxDim) return true;
            if (!positioning.onY.allowLargestContainer && zone.ydim > positioning.onY.maxDim) return true;
            if (!positioning.onZ.allowLargestContainer && zone.zdim > positioning.onZ.maxDim) return true;

            return false;
        }

        // Gets or sets issue status of this panel
        _P.setIssueStatus = function (status) {
            this._issueStatus = status;
            this._updateWireframeMat();
        }
        //_P.getIssueStatus = function () { return this._issueStatus; }

        // Display this panel
        _P.display = function () {
            var sceneMode = this._owner.closet.getSceneMode();

            if (sceneMode !== 'work' && this._mesh)
                ez3D.add(this._mesh);

            if (sceneMode === 'work' && this._wireFrame)
                ez3D.add(this._wireFrame);
        }

        // Hide this panel
        _P.hide = function () {
            if (this._mesh)
                ez3D.remove(this._mesh);

            if (this._wireFrame)
                ez3D.remove(this._wireFrame);
        }

        // Update the 3d material.
        _P.updateThreeMat = function () {
            if (!this._mesh || !this._wireFrame) return;
            var sceneMode = this._owner.closet.getSceneMode();
            if (sceneMode === 'work') {
                this._updateWireframeMat();
                ez3D.add(this._wireFrame);
                ez3D.remove(this._mesh);
            }
            else {
                ez3D.add(this._mesh);
                ez3D.remove(this._wireFrame);
                this._mesh.castShadow = true;
                this._mesh.receiveShadow = true;
            }            
        }

        // Dispose
        _P.dispose = function () {
            if (this._wireFrame)
                ez3D.disposeObject3D(this._wireFrame);

            if (this._mesh)
                ez3D.disposeObject3D(this._mesh);
        }

        // Fill BOM
        _P.fillBOM = function (bom) {
            var me = this;
            var bi = ezBOM.$newItem();


            if (me._model.PriceMode === 3) // linear mode
                bi.price = me._model.Price * me.getLength();
            else if (me._model.PriceMode === 1) // unit mode
                bi.price = me._model.Price;

            bi.price = ezPrice.getSalePrice(bi.price);
            me.price = bi.price;

            bi.title = me._model.Title;
            bi.dims = [me.xdim, me.ydim, me.zdim];
            bi.dimsLabel = '(LxHxP)';
            bi.surf = 0;
            bi.weight = me._model.Weight;
            bi.ecoTax = ezPrice.getEcoTaxByWeight(bi.weight).Value;

            bom.add(bi);
        }

        _P._getBoxCoordModifier = function (hookSide, dim) {
            switch (hookSide) {
                case "neg_side":
                    return +dim / 2;

                case "center":
                    return 0;

                case "pos_side":
                    return -dim / 2;

                default:
                    $ez.THROW("Unknown hook side '" + hookSide + "'.");
            }
        }

        // Get box of accessory
        _P.getBox = function () {
            var me = this;

            var positioning = me._model.positioning;
            var meshModel = me._model.mesh; //me._model.getMeshModel();

            // Position from container
            var x = me._getCoordFromContainer(me._posRef.x, me._posRef.xdim, positioning.onX.side, positioning.onX.dist);
            var y = me._getCoordFromContainer(me._posRef.y, me._posRef.ydim, positioning.onY.side, positioning.onY.dist);
            var z = me._getCoordFromContainer(me._posRef.z, me._posRef.zdim, positioning.onZ.side, positioning.onZ.dist);

            x += me._getBoxCoordModifier(meshModel.hook.xside, me.xdim);
            y += me._getBoxCoordModifier(meshModel.hook.yside, me.ydim);
            z += me._getBoxCoordModifier(meshModel.hook.zside, me.zdim);

            return {
                x: x,
                y: y,
                z: z,
                xdim: me.xdim,
                ydim: me.ydim,
                zdim: me.zdim
            };
        }

        // Returns the box used to find collision with hinges
        _P.getDoorCollisionBox = function () {
            var me = this;
            var wholeBox = me.getBox();
            var positioning = me._model.positioning;
            if (positioning.doorColBoxMargins) {
                var collMargins = {
                    left: positioning.doorColBoxMargins.left ? positioning.doorColBoxMargins.left : 0,
                    right: positioning.doorColBoxMargins.right ? positioning.doorColBoxMargins.right : 0,
                    bottom: positioning.doorColBoxMargins.bottom ? positioning.doorColBoxMargins.bottom : 0,
                    top: positioning.doorColBoxMargins.top ? positioning.doorColBoxMargins.top : 0,
                    back: positioning.doorColBoxMargins.back ? positioning.doorColBoxMargins.back : 0,
                    front: positioning.doorColBoxMargins.front ? positioning.doorColBoxMargins.front : 0,
                };

                return {
                    x: wholeBox.x + ((collMargins.left - collMargins.right) / 2),
                    y: wholeBox.y + ((collMargins.bottom - collMargins.top) / 2),
                    z: wholeBox.z + ((collMargins.back - collMargins.front) / 2),
                    xdim: wholeBox.xdim - collMargins.left - collMargins.right,
                    ydim: wholeBox.ydim - collMargins.bottom - collMargins.top,
                    zdim: wholeBox.zdim + 1000 // No limit on Z to be sure to have collision : sliding accessory
                };
            }
            else {
                wholeBox.zdim += 1000;
                return wholeBox;
            }
        }

        // Returns true if this item can collide a door
        _P.canCollideDoor = function () { return this._model.positioning.canCollideDoor !== undefined ? this._model.positioning.canCollideDoor : false; }

    })(Accessory.prototype);


    ////// ezAccessory service
    return {

        $name: 'ezAccessory',

        // Create an accessory with the specified model.
        createAccessory: function (model) {
            var A = new Accessory(model);
            return A;
        }

    }

}

////// EXPORT
export default {
    $init: _init
}