﻿///// Init method for injection
function _init(dcies, $ez, ez3D, ezPrice, ezGPos) {

    var $log = $ez.getLogger();

    /////// Position margins
    // Methods to compute coordinate offset due to margins configuration
    // To position panel in zone, you must add the returned value to the desired position.
    // ex. : var x = _getMarginXOffs(M) + 10; // 10 is desired position on X
    function _getMarginXOffs(M) { return (M.left - ((M.left + M.right) / 2)) }
    function _getMarginYOffs(M) { return (M.bottom - ((M.bottom + M.top) / 2)) }
    function _getMarginZOffs(M) { return (M.back - ((M.back + M.front) / 2)) }



    /////// Class PanelPositioner
    // Brief : Provide methods to position panel according to face of zone.
    // Constructor
    var PanelPositioner = function () {
        var me = this;

        me.side = 'left';
        me.zone;
        me.offs = 0; // offset from face (if positive, inside zone)

        // global dimension
        me.xdim = 0;
        me.ydim = 0;
        me.zdim = 0;

        // Dimension (useful for panel)
        me.length = 0; // Dimension x of panel
        me.width = 0; // Dimension y of panel

        // Position and rotation
        me.rot = { x: 0, y: 0, z: 0, order: 'XYZ' };
        me.x = 0;
        me.y = 0;
        me.z = 0;
    };
    // Methods
    (function (_P) {

        // Update positioner
        // M : Margins as defined in Panel { left: , top: , etc ...}
        // thick: the pannel thickness
        _P.update = function (M, thick) {
            var me = this;
            var Z = this.zone;
            var panThick = thick;
            var Zw = Z.getWidth();
            var Zh = Z.getHeight();
            var Zd = Z.getDepth();

            // offset for position according to margins
            var xMOffs = _getMarginXOffs(M);
            var yMOffs = _getMarginYOffs(M);
            var zMOffs = _getMarginZOffs(M);

            // WARNING !
            // Dimension are computed before rotation
            // Position are computed after rotation

            if (this.side === 'left') {
                me.length = Zh - (M.top + M.bottom);
                me.width = Zd - (M.front + M.back);
                me.xdim = panThick;
                me.ydim = me.length;
                me.zdim = me.width;
                me.rot = { x: -Math.PI / 2, y: 0, z: Math.PI / 2, order: 'YZX' };
                me.x = Z.x - (Zw / 2) + (panThick / 2) + this.offs;
                me.y = Z.y + yMOffs;
                me.z = Z.z + zMOffs;
            }
            else if (this.side === 'right') {
                me.length = Zh - (M.top + M.bottom);
                me.width = Zd - (M.front + M.back);
                me.xdim = panThick;
                me.ydim = me.length;
                me.zdim = me.width;
                me.rot = { x: -Math.PI / 2, y: 0, z: Math.PI / 2, order: 'YZX' };
                me.x = Z.x + (Zw / 2) - (panThick / 2) - this.offs;
                me.y = Z.y + yMOffs;
                me.z = Z.z + zMOffs;
            }
            else if (this.side === 'bottom') {
                me.length = Zw - (M.left + M.right);
                me.width = Zd - (M.front + M.back);
                me.xdim = me.length;
                me.ydim = panThick;
                me.zdim = me.width;
                me.rot = { x: -Math.PI / 2, y: 0, z: 0, order: 'YZX' };
                me.x = Z.x + xMOffs;
                me.y = Z.y - (Zh / 2) + (panThick / 2) + this.offs;
                me.z = Z.z + zMOffs;
            }
            else if (this.side === 'top') {
                me.length = Zw - (M.left + M.right);
                me.width = Zd - (M.front + M.back);
                me.xdim = me.length;
                me.ydim = panThick;
                me.zdim = me.width;
                me.rot = { x: -Math.PI / 2, y: 0, z: 0, order: 'YZX' };
                me.x = Z.x + xMOffs;
                me.y = Z.y + (Zh / 2) - (panThick / 2) - this.offs;
                me.z = Z.z + zMOffs;
            }
            else if (this.side === 'front') {
                me.length = Zh - (M.top + M.bottom);
                me.width = Zw - (M.left + M.right);
                me.xdim = me.width;
                me.ydim = me.length;
                me.zdim = panThick;
                me.rot = { x: 0, y: 0, z: Math.PI / 2, order: 'YZX' };
                me.x = Z.x + xMOffs;
                me.y = Z.y + yMOffs;
                me.z = Z.z + (Zd / 2) - (panThick / 2) - this.offs;
            }
            else if (this.side === 'back') {
                me.length = Zh - (M.top + M.bottom);
                me.width = Zw - (M.left + M.right);
                me.xdim = me.width;
                me.ydim = me.length;
                me.zdim = panThick;
                me.rot = { x: 0, y: 0, z: Math.PI / 2, order: 'YZX' };
                me.x = Z.x + xMOffs;
                me.y = Z.y + yMOffs;
                me.z = Z.z - (Zd / 2) + (panThick / 2) + this.offs;
            }
            else
                throw $ez.THROW("Unsupported side '" + this.side + "' for PanelPositioner.");
        }

    })(PanelPositioner.prototype);



    /////// Class Panel
    // Brief : Define panel part, base of all panel parts of closet.
    // Constructor
    var Panel = function () {
        var me = this;

        me.CUID = 0;
        me.title = '(no title)';
        me.price = 0;
        me.kind = 0;

        me.parentZone;
        me.positioner;

        me.colour; // It is the colour provided by DataFactory, i.e colour as it is retrieved from the server.
        me._hasOwnColour = false; // If true, the panel has own colour (change closet colour has no effect on this panel)
        me.panelModel; // It is the panel-model provided by DataFactory
        me._renderThreeMat; // The THREE.Material used in rendering mode.
        me._issueStatus = 'OK';

        me.width = 0;
        me.length = 0;
        me.thickness = null;

        // IPositionable
        me.xdim = 0;
        me.ydim = 0;
        me.zdim = 0;
        me.x = 0;
        me.y = 0;
        me.z = 0;
        me.rot = undefined;

        // Ground position
        me.GPos = ezGPos.$new();

        // IBOMItem
        me.isBOMCompound = false;

        me.mesh = undefined;
        me.margins = { // Margins of panel in zone, according to closet orientation (back is back of closet, top is top of closet, etc...)
            top: 0, // Y closet
            bottom: 0, // Y closet
            left: 0, // X closet
            right: 0, // X closet
            front: 0, // Z closet
            back: 0 // Z closet
        };
        me._userMargins = { top: 0 }; // User margins of panel. its value will be added to matching margins (ex. _userMargins.top will be added to margins.top)
        me.visualMargin = 0;

        me.thickness = null; // can overrride default compound thickness
        me.ecoTax = 0;
        me.weight = 0;
        me.isOff = false;
        me.addPrice = 0;
    };
    // Methods
    (function (_P) {

        // Get the panel material according to scene mode.
        _P.getCurrentPanelMat = function (sceneMode, hasVisiblePanel) {
            var me = this;
            //var a = 50;

            if (sceneMode === "work")
                return ez3D.getWorkPanelMat(hasVisiblePanel, me._issueStatus);
            else {
                if (!me._renderThreeMat) {// The 3D material is not created
                    me._renderThreeMat = ez3D.createPanelMat(me.colour);
                }

                if (!me.colour.HasColor) {
                    ez3D.updateTextureRep({
                        threeMat: me._renderThreeMat,
                        l: me.getLength(), 
                        w: me.getWidth(),
                        realW: me.colour.texture.RealWidth,
                        realL: me.colour.texture.RealLength
                    })
                }
                
                return me._renderThreeMat;
            }
        }


        // Compute panel
        _P.compute = function () {
            var me = this;
            var pos = me.positioner;
            var C = me.parentZone.closet;
            var thick = me.thickness === null ? me.panelModel.thick.Value : me.thickness;

            if (pos) {
                var M = {
                    top: me.margins.top + me._userMargins.top,
                    bottom: me.margins.bottom, 
                    left: me.margins.left, 
                    right: me.margins.right,
                    front: me.margins.front,
                    back: me.margins.back 
                };
                pos.update(M, thick); // update positioner

                // Dimension in local coordinate of pannel
                me.length = pos.length,
                me.width = pos.width;

                // Dimension in coordinate of closet
                me.xdim = pos.xdim,
                me.ydim = pos.ydim;
                me.zdim = pos.zdim;

                me.rot = pos.rot;

                me.x = pos.x;
                me.y = pos.y;
                me.z = pos.z;
            }

            var grndAng = me.parentZone.closet.getGAng();
            var grndPos = me.parentZone.closet.getGPos();
            me.GPos.x = ezGPos.toGx(me.x, me.z, grndAng, grndPos);
            me.GPos.y = ezGPos.toGy(me.y, grndPos);
            me.GPos.z = ezGPos.toGz(me.x, me.z, grndAng, grndPos);
        }


        // Compute 3D panel object
        _P.compute3D = function () {
            var me = this;
            var thick = me.thickness === null ? me.panelModel.thick.Value : me.thickness;
            var sceneMode = me.parentZone.closet.getSceneMode();
            var hasVisiblePanel = me.parentZone.closet.hasVisiblePanel();

            var g = ez3D.createBox(me.length - me.visualMargin, me.width - me.visualMargin, thick);
            if (!me.mesh) {
                me.mesh = ez3D.createMesh(g, me.getCurrentPanelMat(sceneMode, hasVisiblePanel));
                //me.mesh.receiveShadow = true;
                me.mesh.name = me.title;
            }
            else {
                if (me.mesh.geometry) me.mesh.geometry.dispose();
                me.mesh.geometry = g;
                me.mesh.material = me.getCurrentPanelMat(sceneMode, hasVisiblePanel);
            }

            me.mesh.castShadow = (sceneMode !== 'work');
            me.mesh.receiveShadow = (sceneMode !== 'work');

            // Ground rotation angle
            var grndAng = me.parentZone.closet.getGAng();

            if (me.rot)
                me.mesh.rotation.set(me.rot.x, grndAng, me.rot.z, me.rot.order ? me.rot.order : 'XYZ');
            else
                me.mesh.rotation.set(0, grndAng, 0);

            me.mesh.position.set(me.GPos.x, me.GPos.y, me.GPos.z);
        }

        // Set Z position of panel. Useful to fix panel parts as consolidator in ezValidator
        // z: (Number) The value of new coordinate system in local system.
        _P.setZPos = function (z) {
            var me = this;
            me.z = z;

            var grndAng = me.parentZone.closet.getGAng();
            var grndPos = me.parentZone.closet.getGPos();
            me.GPos.z = ezGPos.toGz(me.x, me.z, grndAng, grndPos);
        }

        // Update the Panel mesh. useful if property change.
        _P.update = function () {
            var me = this;
            me.compute();
            if (ez3D.has3D()) me.compute3D();
        }

        // Get dimensions
        _P.getLength = function () { return this.length }
        _P.getWidth = function () { return this.width }
        _P.getThickness = function () { return this.thickness }

        // Get surface of panel in m²
        _P.getSurf = function () {
            return (this.getLength() / 100) * (this.getWidth() / 100);
        }

        // Get volume of panel in m3
        _P.getVol = function () {
            var me = this;
            var thick = me.thickness === null ? me.panelModel.thick.Value : me.thickness;
            return me.getSurf() * (thick / 100);
        }

        // Get price according to surface of panel and current material
        _P.getSurfacePrice = function (surf) {
            return ezPrice.getPriceOfSurf(this.panelModel, this.colour, surf);
        }

        // Fill the BOM
        _P.fillBOM = function (bom, price) {
            var me = this;
            var C = me.parentZone.closet;

            // HACK
            if (me.kind === 6)
                me.addPrice = C.model.AddPriceSeparator;
            else if (me.kind === 7)
                me.addPrice = C.model.AddPriceShelf;

            me.weight = ezPrice.getWeigthOfVol(me.panelModel, me.getVol());
            me.ecoTax = ezPrice.getEcoTaxByWeight(me.weight).Value;
            var surf = me.getSurf();

            me.price = price || ezPrice.getPriceOfSurf(me.panelModel, me.colour, surf);
            me.price += me.addPrice;
            me.price = ezPrice.getSalePrice(me.price);

            bom.add({
                id: me.id,
                title: me.title,
                dims: [me.getLength(), me.getWidth(), me.thickness === null ? me.panelModel.thick.Value : me.thickness],
                dimsLabel: '(L x l x e)',
                surf: me.getSurf(),
                price: me.price,
                ecoTax: me.ecoTax,
                weight: me.weight
            });
        }

        // Get box of panel
        _P.getBox = function () {
            var me = this;

            return {
                x: me.x,
                y: me.y,
                z: me.z,
                xdim: me.xdim,
                ydim: me.ydim,
                zdim: me.zdim
            };
        }

        // Change the colour of this panel. Useful if user change the closet colour in UI.
        _P.setColour = function (colour) {
            if (this.colour && this.colour.Id === colour.Id) return false; // no change

            this.colour = colour;
            this._renderThreeMat = undefined; // to force recompute of THREE material
            return true;
        }
        _P.getColour = function () { return this.colour }

        // Get or set the has "own colour status"
        _P.hasOwnColour = function () { return this._hasOwnColour; }
        _P.setHasOwnColour = function (yes) { this._hasOwnColour = yes; }

        // Change the material model of this panel. Useful if user change the model in UI.
        _P.setMaterialModel = function (model) {
            if (!model)
                $ez.THROW("Panel model cannot be null.");

            if (this.panelModel && this.panelModel.Id === model.Id) return false; // no change
            this.panelModel = model;
            return true;
        }
        _P.getMaterialModel = function () { return this.panelModel }

        // Gets or sets issue status of this panel
        _P.setIssueStatus = function (status) { this._issueStatus = status; }
        //_P.getIssueStatus = function () { return this._issueStatus; }

        // DEPRECATED
        _P.hasError = function () { return this._hasError; }
        _P.setHasError = function (yes) { this._hasError = yes; }

        // Set margins
        // margins : { left, top, right, bottom, back, front }
        _P.setMargins = function (margins) {
            var M = this.margins;
            if (margins.left !== undefined) M.left = margins.left;
            if (margins.right !== undefined) M.right = margins.right;
            if (margins.top !== undefined) M.top = margins.top;
            if (margins.bottom !== undefined) M.bottom = margins.bottom;
            if (margins.back !== undefined) M.back = margins.back;
            if (margins.front !== undefined) M.front = margins.front;
        }

        // Gets Margins
        _P.getMargins = function () { return this.margins; }

        // Set user-margins. User margins are useful for different column height in closet.
        // margins : { top }
        _P.setUserMargins = function (margins) {
            var M = this._userMargins;
            M.top = margins.top || 0;
        }

        // Gets user-margins
        _P.getUserMargins = function () { return this._userMargins; }

        // Set visual margin around the panel (does not affect panel dimensions)
        _P.setVisualMargin = function (margin) { this.visualMargin = margin; }

        // Display this panel
        _P.display = function () {
            if (this.mesh)
                ez3D.add(this.mesh)
        }

        // Hide this panel
        _P.hide = function () {
            if (this.mesh)
                ez3D.remove(this.mesh)
        }

        // Update the 3d material.
        _P.updateThreeMat = function () {
            if (!this.mesh) return;
            var sceneMode = this.parentZone.closet.getSceneMode();
            var hasVisiblePanel = this.parentZone.closet.hasVisiblePanel();
            this.mesh.material = this.getCurrentPanelMat(sceneMode, hasVisiblePanel);
            this.mesh.castShadow = (sceneMode !== 'work');
            this.mesh.receiveShadow = (sceneMode !== 'work');
        }

        // Dispose
        _P.dispose = function () {
            if (this.mesh && this.mesh.geometry)
                this.mesh.geometry.dispose();

            if (this.mesh && this.mesh.material && this.mesh.material.map) {
                this.mesh.material.map.dispose();
                // console.debug(">>>> Dispose texture");
            }
        }

    })(Panel.prototype);



    ////// ezPanel service
    return {
        $name: 'ezPanel',

        //create: function () { return new PanelPositioner(); },

        // Create new positioner
        // side: (String) side of zone. Can be 'left', 'right', 'bottom' and 'top'
        // offs: (Number) offset for the position, i.e distance from zone side (positive value are inside closet)
        // zone: (Zone) the zone used to compute position
        createPanelPositioner: function (side, offs, zone) {
            var p = new PanelPositioner();
            p.side = side;
            p.offs = offs;
            p.zone = zone;
            return p;
        },

        // Methods to compute coordinate offset due to margins configuration.
        // getMargin...Offs(M), with M are margins : {left, right, top, bottom, front, back}
        getMarginXOffs: _getMarginXOffs,
        getMarginYOffs: _getMarginYOffs,
        getMarginZOffs: _getMarginZOffs,

        // Create and return a new panel.
        // parentZone: (Zone) the zone that use to compute dimensions and position of the panel.
        // Returns: new Panel instance
        create: function (parentZone) {
            var P = new Panel();
            P.CUID = parentZone.closet.getCUID();
            P.parentZone = parentZone;
            P.colour = parentZone.closet.mainColour;
            P.panelModel = parentZone.closet.mainPanelModel;
            return P;
        },

        // Use this function to position panel on side of its parent zone.
        // The positioner of panel P is created.
        // P: (Panel) The panel instance to position
        // side: (String) The side of zone : 'left', 'right', 'bottom', 'top', 'back' or 'front'.
        // [sideOffs]: (Number) The side offset. Default is 0.
        beOnSide: function (P, side, sideOffs) {
            if (!P.positioner)
                P.positioner = this.createPanelPositioner(side, sideOffs || 0, P.parentZone);
            else {
                P.positioner.side = side;
                P.positioner.offs = offs;
            }
        }
    };
}


////// EXPORT
export default {
    $init: _init
}