import { createApp, reactive } from 'vue';
import App from "./App.vue";
import { Api as MobApi } from "@mobibamjs/api";
import { VueReCaptcha } from "vue-recaptcha-v3";
import { Scene3D } from './engine/Scene3D';
import { IStore, Store } from './Store';
import { EngineV4 } from './engine/Engine';
import { SvcAliases } from './SvcAliases';
import { DeviceScreen, IScreen } from './Screen';

const indexHtml = require("../local/index.html");
const _headers = require("../local/_headers");

MobApi.user.sameOriginEndpoint = "/api/user"; // TODO : changer en '/mob-api/user' pour la prod
MobApi.sync.sameOriginEndpoint = "/api/sync"; // TODO : changer en '/mob-api/sync' pour la prod

const store = new Store();
const screen = new DeviceScreen(260);

// Recaptcha public key
const recaptchaSiteKey = store.mobibam.MOB_RECAPTCHA_SITEKEY;

const app = createApp(App).use(VueReCaptcha, {
    siteKey: recaptchaSiteKey,
    loaderOptions: {
        autoHideBadge: true
    }
});
app.provide(SvcAliases.Store, reactive<IStore>(store));
app.provide(SvcAliases.Engine, new EngineV4(store.mobibam.MOB_ENV));
app.provide(SvcAliases.Scene3D, new Scene3D());
app.provide(SvcAliases.Screen, reactive<IScreen>(screen));

app.mount("#mob-ez2022");