﻿function _init(dcies, $ez, ezCTreeHelper) {


    var $log = $ez.getLogger();


    /////// Class MinMaxResolver
    var MinMaxResolver = function () {
        this.$name = 'ezMinMaxResolver';
    };
    // Methods
    (function (_P) {

        // Find min value of each dimension (width and heigth), for the specified node.
        // node : (CNode) The node to process
        // returns: (Object) { w: (Number) The min width of node, h: (Number) The min height of node, _wd: (Number) Internal use only, _wh: (Number) Internal use only }
        _P.findMins = function (node) {
            var me = this;
            var mins = { w: 0, h: 0, _wd: 0, _hd: 0 };
            var i, n;

            n = node.subNodes.getLength();
            if (n > 0) { // Not a leaf

                // For each sub-nodes
                for (i = 0; i < n; i++) {
                    var SN = node.subNodes.get(i);

                    // Get mins of sub-nodes
                    var subMins = me.findMins(SN);

                    if (SN.axis === $ez.X_AXIS) { // get width and sum of heights
                        if (mins.w < subMins.w) mins.w = subMins.w;

                        if (mins._wd < subMins._wd) mins._wd = subMins._wd; // To pass _wd up in recursion
                        mins.h = mins._hd += subMins._hd;

                        if (i > 0) { // Consider thickness of separators
                            mins.h += node.thickProvider.getSeparator();
                            mins._hd += node.thickProvider.getSeparator();
                        }
                        
                    }
                    else { // get height and sum of widths
                        if (mins.h < subMins.h) mins.h = subMins.h;

                        if (mins._hd < subMins._hd) mins._hd = subMins._hd; // To pass _hd up in recursion
                        mins.w = mins._wd += subMins._wd;

                        if (i > 0) { // Consider thickness of separators
                            mins.w += node.thickProvider.getSeparator();
                            mins._wd += node.thickProvider.getSeparator();
                        }
                    }
                }

                // node is a single column/row
                if (node.parent && node.parent.subNodes.getLength() === 1) { // Degenerated node : parent[]->Y[n-] or parent[]->X[n|]
                    if (node.axis === $ez.X_AXIS) {
                        mins._hd = mins.h; // Only one row in parent, height is not dependent on status of zone
                        if (!node.innerZone.hasXDimAuto) mins._wd = node.innerZone.xdim;
                    }
                    else {
                        mins._wd = mins.w; // Only one column in parent, width is not dependent on status of zone 
                        if (!node.innerZone.hasYDimAuto) mins._hd = node.innerZone.ydim;
                    }
                }
            }
            else { // Is leaf

                // Bounds of node according to its content (ex : drawers, swing-doors, ...)
                var nodeBnds = node.getNodeBnds();

                // The mins of zone is not dependent on status of zone
                mins.w = nodeBnds.width.min;
                mins.h = nodeBnds.height.min;

                /*if (node.axis === $ez.X_AXIS) {
                    mins.w = nodeBnds.width.min;
                    mins.h = isFirstCall || node.innerZone.hasYDimAuto ? nodeBnds.height.min : node.innerZone.ydim;
                }
                else {
                    mins.w = isFirstCall || node.innerZone.hasXDimAuto ? nodeBnds.width.min : node.innerZone.xdim;
                    mins.h = nodeBnds.height.min;
                }*/

                // To sum width/height of row/column : dimensions to sum are dependent on status of zone
                mins._wd = node.innerZone.hasXDimAuto ? nodeBnds.width.min : node.innerZone.xdim;
                mins._hd = node.innerZone.hasYDimAuto ? nodeBnds.height.min : node.innerZone.ydim;
            }

            return mins;
        }

        // Find maxis width and height of specified node. This method use findMins().
        // returns: (Object) { w: (Number) The max width of node, h: (Number) The max height of node }
        _P.findMaxs = function (node) {
            var me = this;
            var maxs = { w: $ez.MAX, h: $ez.MAX };
            var parent = node.parent;
            if (!parent) return maxs;

            var sums = { wSep: 0, hSep: 0, wMin: 0, hMin: 0 };

            var n = parent.subNodes.getLength();

            // Detect if all nodes have fixed dimension (by excluding node whose we compute its max)
            var hasFixedDimOnly = true;
            for (var i = 0; i < n; i++) {
                var SN = parent.subNodes.get(i);
                if (SN.axis === $ez.X_AXIS) {
                    if (SN !== node && SN.innerZone.hasYDimAuto) {
                        hasFixedDimOnly = false;
                        break;
                    } 
                }
                else {
                    if (SN !== node && SN.innerZone.hasXDimAuto) {
                        hasFixedDimOnly = false;
                        break;
                    } 
                }
            }


            for (var i = 0; i < n; i++) {
                var SN = parent.subNodes.get(i);
                

                if (SN.axis === $ez.X_AXIS) { // Sub-node is X_Axis : do sum of height

                    if (i > 0) // Sum each thickness separator
                        sums.hSep += node.thickProvider.getSeparator();

                    if (SN !== node) { // To exclude the node whose we compute its max
                        if (hasFixedDimOnly || SN.innerZone.hasYDimAuto) { // If fixed dims only, or is auto, we have to know its min height
                            var mins = me.findMins(SN);
                            sums.hMin +=  mins.h;
                        }
                        else // Is fixed : sum its current height
                            sums.hMin += SN.innerZone.ydim;
                    }
                }
                else { // Sub-node is Y_Axis : do sum of width

                    if (i > 0) // Sum each thickness separator
                        sums.wSep += node.thickProvider.getSeparator();

                    if (SN !== node) { // To exclude the node whose we compute its max
                        if (hasFixedDimOnly || SN.innerZone.hasXDimAuto) { // If fixed dims only, or is auto, we have to know its min width
                            var mins = me.findMins(SN);
                            sums.wMin += mins.w;
                        }
                        else // Is fixed : sum its current width
                            sums.wMin += SN.innerZone.xdim;
                    }
                }
            }

            // Max = dimension of parent node - sum of min dimension - sum of separator thickness
            if (sums.wMin > 0) maxs.w = parent.innerZone.xdim - sums.wMin - sums.wSep;
            if (sums.hMin > 0) maxs.h = parent.innerZone.ydim - sums.hMin - sums.hSep;

            return maxs;
        }

        // Find min depth by recursion from the specified node
        // Returns: (Number) The min depth of node.
        _P.findMinDepth = function (node) {
            var me = this;
            var i, n;
            var minDepth = 0;

            n = node.subNodes.getLength();
            if (n > 0) { // go deeper in tree

                for (i = 0; i < n; i++) {
                    var subMinDepth = me.findMinDepth(node.subNodes.get(i));
                    if (minDepth < subMinDepth) minDepth = subMinDepth;
                }

            }
            else { // Is leaf
                // Bounds of node according to its content (ex : drawers, swing-doors, ...)
                var nodeBnds = node.getNodeBnds();
                minDepth = nodeBnds.depth.min;
            }

            return minDepth;
        }
        
        // Gets the min/max of zone relative to the specified node
        // Returns: (Bounds) The min/max of width and height.
        _P.getMinMaxOfZone = function (node) { 
            var me = this;

            if (node.subNodes.getLength() > 0)
                $ez.THROW("Node is not a leaf. Cannot compute min/max of zone.");

            var result = $ez.createBounds();

            var wNode = ezCTreeHelper.getWidthNode(node);
            var hNode = ezCTreeHelper.getHeightNode(node);

            // Compute max of width
            var wMax = $ez.MAX;
            if (wNode) {
                var maxs = me.findMaxs(wNode);
                wMax = maxs.w;
            }

            // Compute max of height
            var hMax = $ez.MAX;
            if (hNode) {
                var maxs = me.findMaxs(hNode);
                hMax = maxs.h;
            }

            // Compute min of width
            var wMin = 0;
            if (wNode) {
                var mins = me.findMins(wNode); 
                wMin = mins.w;
                //$log.log("------ Compute min width of node " + wNode.CUID + " => " + wMin);
            }

            // Compute min of height
            var hMin = 0;
            if (hNode) {
                var mins = me.findMins(hNode);
                hMin = mins.h;
            }

            if (result.width.min < wMin) result.width.min = wMin;
            if (result.height.min < hMin) result.height.min = hMin;
            if (result.width.max > wMax) result.width.max = wMax;
            if (result.height.max > hMax) result.height.max = hMax;

            return result;
        }

        // Gets the min/max height of column
        // col: (CNode) The column module
        // Returns : (Object) {min: (Number) The min height, max: (Number) The max height }
        _P.getMinMaxColHeight = function (col) {
            var me = this;
            var mins = me.findMins(col);

            if (col.parts) {
                var TP = col.closet.getThickProvider();
                if (col.parts.top) mins.h += TP.getTop();
                if (col.parts.bottom) mins.h += TP.getBottom();
            }

            var closetModel = col.closet.getClosetModel();
            if (closetModel.TopOffs !== 0) mins.h += closetModel.TopOffs;
            if (closetModel.BottomOffs !== 0) mins.h += closetModel.BottomOffs;

            return {
                min: closetModel.MinZoneHeight < mins.h ? mins.h : closetModel.MinZoneHeight,
                max: col.closet.getHeight()
            }
        }

    })(MinMaxResolver.prototype);


    ////// ezMinMaxResolver singleton service
    return new MinMaxResolver();
}

////// EXPORT
export default {
    $init: _init
}