import { ISmartGroup } from "../../engine/DataModels/ISmartPattern";
import biblio_200_220 from "../../assets/patterns/biblio-200-220-1.3.png";
import biblio_221_250 from "../../assets/patterns/biblio-221-250-1.3.png";

const GKEY = "1.3";
export const BiblioGroup1_3: ISmartGroup = {
    key: GKEY,
    modulesByInterval: [
        {
            groupKey: GKEY,
            itvalIdx: 0,
            icon: biblio_200_220,
            zones: [
            { Ac:3, SWD: [1,1,1] },
            { Ac:1 }, 
            { Ac:1 }, 
            { Ac:2, SWD: [1,1] },
            { Ac:1 },
            { Ac:1 }
        ]},
        {
            groupKey: GKEY,
            itvalIdx: 1,
            icon: biblio_221_250,
            zones: [
            { Ac:3, SWD: [1,1,1] },
            { Ac:1 }, 
            { Ac:1 }, 
            { Ac:1 }, 
            { Ac:1 },
            { Ac:2, SWD: [1,1] },
            { Ac:1 }
        ]}
    ]
}