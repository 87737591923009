﻿function _init(dpcies, $ez) {

    var $log = $ez.getLogger(); // Logger

    // Set top margin of module adjacent to module of index i
    // root: (CNode) The root node of closet
    // moduleZone: (Zone) The zone of reference module (paddingZone or outerZone)
    // i : (Number) The index of reference module in root sub-nodes
    // adjPos: (String) The position of adjacent module from reference module : 'prev' or 'next'
    // topMargin: (Number) The top margin.
    var setAdjacentModuleTopMargin = function (root, moduleZone, i, adjPos, topMargin) {
        var adjIdx = adjPos === 'prev' ? i - 1 : i + 1;
        var sepIdx = adjPos === 'prev' ? i - 1 : i;

        var adjModule = root.subNodes.get(adjIdx);
        var adjModuleZone = adjModule.paddingZone || adjModule.outerZone;
        var adjMarginMax = adjModule.outerZone.ydim - adjModuleZone.ydim;
        if (adjModuleZone.ydim < moduleZone.ydim) {
            root.separators.get(sepIdx).setUserMargins({ top: topMargin <= adjMarginMax ? topMargin : adjMarginMax });
        }
    }


    ////// ezCTreeHelper service
    return {
        $name: 'ezCTreeHelper',

        // Return module according the specified node.
        // Module is the highest ancestor column or row (depending on root axis), that own the node. Root is considered as module if is empty.
        getModule: function (node) {
            if (node.parent === null)// root
                return node;
            else if (node.parent.parent === null) // module found
                return node; 
            else // go up
                return this.getModule(node.parent);
        },

        // Return module of idndex idx in closet C. If C is empty return its root node.
        getModuleByIndex: function (C, idx) {
            var n = C.root;
            if (n.subNodes.getLength() === 0) return n; // null

            return n.subNodes.get(idx);
        },

        // Convenient method to get first and last module
        getFirstModule: function (C) { return this.getModuleByIndex(C, 0); },
        getLastModule: function (C) { return this.getModuleByIndex(C, C.root.subNodes.getLastIndex()); },

        // Get first sub-tree root in ancestor direction. Return null if not found.
        //getSubTreeRoot: function (node) { return node ? node.getFirstAncestor(function (n) { return n.parentOfST !== null; }) : null; },

        // Set module padding-top according the specified  column height. Closet-model must have 'CLASS2' model-class
        // module: (CNode) The module of closet
        // h: (Number) The new height of module
        setModuleHeight: function (module, h) {
            var topMargin = module.outerZone.ydim - h;
            module.setPaddings({ top: topMargin });
        },

        // Get empty space ont top of closet when all module have height smallest than height of closet
        // root : (CNode) The root of closet
        getEmptySpaceAtTop: function (root) {
            var n = root.subNodes.getLength();
            if (n <= 1) return 0;

            var minTopPadding = $ez.MAX;
            for (var i = 0; i < n; i++) {
                var sn = root.subNodes.get(i);
                if (sn.paddings.top < minTopPadding)
                    minTopPadding = sn.paddings.top;
            }

            return minTopPadding;
        },

        // Get node that allow height dimension change
        // fromNode: (CNode) The node whose height was retrieved (in general the selected node).
        getHeightNode: function (fromNode) {
            return fromNode.getFirstAncestor(function (n) { return n.axis === $ez.X_AXIS && n.parent && n.parent.isSplit(); });
        },

        // Get node that allow width dimension change
        // node: (CNode) The node whose height was retrieved (in general the selected node).
        getWidthNode: function (fromNode) {
            return fromNode.getFirstAncestor(function (n) { return n.axis === $ez.Y_AXIS && n.parent && n.parent.isSplit(); });
        },

        // Get the first ancestor-node or self, from specified node, that contains a door
        // from: (CNode) The node from search will be started
        // Returns: (CNode) The node with a swing-door, or null if not found.
        get1stAncestorWithSwingDoor: function (fromNode) {
            return fromNode.getFirstAncestor(function (n) { return n.parts && n.parts.front && n.schOpts.frontDef && n.schOpts.frontDef.EZT === "SWD"; });
        },

        // TEST ONLY : Compute height of each module to assume slope to right
        computeSlopeToRight: function (closet, rightHeight) {
            var root = closet.root;
            var TP = closet.getThickProvider();

            var n = root.subNodes.getLength();
            if (n <= 1) return;

            // Left and right heights (HLeft > HRight)
            var HLeft = closet.getHeight();
            var HRight = rightHeight;

            // Angle
            var ang = Math.atan((HLeft - HRight) / closet.getWidth());

            // Adjacent side (on closet width)
            var adj = root.parts && root.parts.left ? TP.getLeft() : 0;
            for (var i = 0; i < n - 1; i++) {
                var col = root.subNodes.get(i);

                if (i > 0) adj += TP.getSeparator();
                adj += col.outerZone.xdim;


                // Compute opposite side (on closet height)
                var opp = Math.tan(ang) * adj;


                // Set padding with opp value
                col.setPaddings({ top: opp });
                root.separators.get(i).setUserMargins({ top: opp });

                if (i === 0 && root.parts && root.parts.left)
                    root.parts.left.setUserMargins({ top: opp });
            }

            root.subNodes.get(n - 1).setPaddings({ top: (HLeft - HRight) });
            if (root.parts && root.parts.right)
                root.parts.right.setUserMargins({ top: (HLeft - HRight) });

        }

    };

}

////// EXPORT
export default {
    $init: _init
}