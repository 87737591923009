import { IBounds } from "./Common";
import { ISmartGroup, ISmartInterval, ISmartPattern, ISmartModule, ISmartStyle, ISmallModule } from "./DataModels/ISmartPattern";

export class SmartPattern implements ISmartPattern {

    private readonly _moduleListByIntervalIdx: ISmartModule[][] = []; // [interval_idx][...]
    private readonly _groups: { [key: string]: ISmartGroup } = {}
    private readonly _styles: ISmartStyle[] = [];

    constructor(intervals: ReadonlyArray<ISmartInterval>) {
        this.intervals = intervals;

        const bnds = { min: 9999, max: 0 };
        for(const itval of intervals) {
            if (bnds.min > itval.bnds.min) bnds.min = itval.bnds.min;
            if (bnds.max < itval.bnds.max) bnds.max = itval.bnds.max;
        }
        this.closetDimBounds = bnds;
        // console.debug(">>>> this.closetDimBounds => %s", JSON.stringify(this.closetDimBounds))
    }

    readonly closetDimBounds: IBounds;

    moduleMaxDim: number = 80;

    moduleMinDim: number = 20;

    smallModule: ISmallModule | null = null;

    readonly intervals: ReadonlyArray<ISmartInterval>;

    addGroup(group: ISmartGroup) {
        if (group.modulesByInterval.length !== this.intervals.length) throw new Error("Smart group must have same count of patterns than intervals count");
        for (let itvalIdx = 0 ; itvalIdx < this.intervals.length ; itvalIdx++) {
            if (!this._moduleListByIntervalIdx[itvalIdx]) this._moduleListByIntervalIdx[itvalIdx] = [];
            this._moduleListByIntervalIdx[itvalIdx].push(group.modulesByInterval[itvalIdx]);
        }
        this._groups[group.key] = group;
    }

    addStyle(style: ISmartStyle) { this._styles.push(style) }

    getIntervalIndex(dim: number): number {
        let itvalIdx = -1;

        const itvalCount = this.intervals.length;
        for (let i = 0 ; i < itvalCount ; i++) {
            const itval = this.intervals[i];
            const bnds = itval.bnds;
            if (dim >= bnds.min) { // Min
                const maxAccepted = i < itvalCount - 1 ? dim < bnds.max : dim <= bnds.max; // Last max is always included in the interval
                if (maxAccepted) { // Max
                    itvalIdx = i;
                    break;
                }
            }
        }

        return itvalIdx;
    }

    getSmartGroup(key: string): ISmartGroup { return this._groups[key] }

    getSmartModules(intervalIdx: number): ReadonlyArray<ISmartModule> { return this._moduleListByIntervalIdx[intervalIdx] }

    getSmartStyles(): ReadonlyArray<ISmartStyle> { return this._styles }

}