import { shallowReactive } from "vue";
import { DataRepository, IDataRepository } from "./DataRepository";
import { IProject, ProjectV4 } from "./Project";
import { EZCompositionRoot } from "./ezV4/EZCompositionRoot";

import ezInjector from "./ezV4/core/ezInjector";
import ez3D from "./ezV4/core/ez3DPlus";
import { MobibamEnv } from "./Common";
import { IClosetNode } from "./ClosetNode";
import { ISeparatorInjector, SeparatorInjector } from "./SeparatorInjector";

export interface IEngine {

    readonly env: MobibamEnv;

    readonly dataRepo: IDataRepository;

    readonly project: IProject;

    readonly sepInjector: ISeparatorInjector;

    isUIEasyApp: boolean;

    //#region Node helpers

    /**
     * Find module by searching from specified node.
     * The node can be the result itself if is a module.
     */
    findModule(node: IClosetNode): IClosetNode;

    /**
     * Gets the sub-nodes count contained by specified node
     * @param node The node parent of sub-nodes
     */
    getSNCount(node: IClosetNode): number;

    /**
     * Gets the sub-node by index
     * @param node The node parent of sub-nodes
     * @param i The index of sub-node (based 0 index)
     */
    getSN(node: IClosetNode, i: number): IClosetNode;

    //#endregion

}


export class EngineV4 implements IEngine {

    private readonly _ez3D: any;
    private readonly _ezCTreeHelper: any;

    constructor(env: MobibamEnv) {
        EZCompositionRoot(ez3D); // Installs composition root with ezInjector first
        console.debug("EZCompositionRoot was installed");

        this._ez3D = ezInjector.get("ez3D");
        this._ezCTreeHelper = ezInjector.get("ezCTreeHelper");

        this.env = env;
        this.dataRepo = shallowReactive(new DataRepository(env));
        this.sepInjector = new SeparatorInjector();
        this.project = shallowReactive(new ProjectV4(this, this.dataRepo, this.sepInjector));
    }

    readonly env: MobibamEnv;

    readonly dataRepo: IDataRepository;

    readonly project: IProject;

    readonly sepInjector: ISeparatorInjector;

    isUIEasyApp: boolean = false;

    findModule(node: IClosetNode): IClosetNode { return this._ezCTreeHelper.getModule(node) }

    getSNCount(node: IClosetNode): number { return (node as any).subNodes.getLength() }

    getSN(node: IClosetNode, i: number): IClosetNode { return (node as any).subNodes.get(i) }

}