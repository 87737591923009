import { reactive } from "vue";
import { IClosetZone } from "./ClosetNode";
import ezInjector from "./ezV4/core/ezInjector";
import { IProject } from "./Project";

/**
 * Rendering modes :
 * - work : work mode with (transparent doors, visible zones , ...)
 * - rendering : like the closet is (almost..), in reality
 */
export type Mode3D = "work" | "rendering";

/**
 * Service to manage the 3D view
 */
export interface IScene3D {

    /**
     * Install the scene by creating a 3D canvas in the specified div element
     */
    setup(divEl: HTMLElement): void;

    /**
     * Activate the floor grid
     * @param size 
     * @param step 
     * @param color 
     */
    activateGrid(size: number, step: number, color: number): void;

    /**
     * Activate user selection detection and set the correct rendering mode of specified project
     * @param project 
     */
    activateUserSelection(project: IProject): void;

    /**
     * Udpate 3D view items like grid according to the specified project
     * @param project 
     */
    update(project: IProject): void;

    /**
     * Gets or sets the current selected zone. To unselect a zone use null value
     */
    selectedZone: IClosetZone | null;

    /**
     * Lock or unlock user selection
     * @param isLocked If true user cannot select zone.
     */
    lockSelection(isLocked: boolean): void;

    /**
     * Convenient reactive property to detect selection change.
     * Use it in vue component when you need to make any action on 3D selection change
     */
    readonly selection: Readonly<{ zone: IClosetZone | null }>;

}

export class Scene3D {

    private readonly _ez3D: any;
    private readonly _ezObs: any;

    private _floorGrid3D: any = null;
    private _wallGrid3D: any = null;
    private _gridSize: number = -1;
    private _hasUserSelectionActive = false;

    private _selectedZone: IClosetZone | null = null;

    constructor() {
        this._ez3D = ezInjector.get("ez3D");
        this._ezObs = ezInjector.get("ezObs");
    }

    setup(divEl: HTMLElement) { 
        this._ez3D.bind(divEl)
    }

    activateGrid(size: number, step: number, color: number) {
        this._gridSize = size;

        this._floorGrid3D = this._ez3D.createGrid(size, step, color);
        this._ez3D.add(this._floorGrid3D);

        this._wallGrid3D = this._ez3D.createGrid(size, step, color);
        this._ez3D.add(this._wallGrid3D);
    }

    activateUserSelection(project: IProject) {
        if (this._hasUserSelectionActive) return; // To avoid redundant ezObs subscription

        this._ezObs.subscribe("selectionChanged", (selected: any) => {
            if (!project.isReady) return;
            
            const zone = selected && selected.userData ? selected.userData.zone as IClosetZone : null;
            this.selectedZone = zone ? zone : null;

            // console.debug(">>>> selectionChanged => %s", zone ? "ZONE" : "No ZONE")

            if (zone) { // Zone was selected
                if (project.mainCloset.hasZone(zone)) {
                    project.mainCloset.sceneMode = "work";
                    if (project.leftCloset) project.leftCloset.sceneMode = "rendering";
                    if (project.rightCloset) project.rightCloset.sceneMode = "rendering";
                }
                else if (project.leftCloset && project.leftCloset.hasZone(zone)) {
                    project.mainCloset.sceneMode = "rendering";
                    project.leftCloset.sceneMode = "work";
                    if (project.rightCloset) project.rightCloset.sceneMode = "rendering";
                }
                else if (project.rightCloset && project.rightCloset.hasZone(zone)) {
                    project.mainCloset.sceneMode = "rendering";
                    if (project.leftCloset) project.leftCloset.sceneMode = "rendering";
                    project.rightCloset.sceneMode = "work";
                }
            }
            else { // Zone was deselected
                project.mainCloset.sceneMode = "rendering";
                if (project.leftCloset) project.leftCloset.sceneMode = "rendering";
                if (project.rightCloset) project.rightCloset.sceneMode = "rendering";
            }
        })

        this._hasUserSelectionActive = true;
    }

    update(project: IProject) {
        if (this._floorGrid3D && this._wallGrid3D) {
            const isWallCloset = project.mainCloset.model.IsWallCloset;
            let y = 0;
            let z = 0;
            
            if ( !isWallCloset ) { // If closet is set on the floor
                this._wallGrid3D.visible = false;
                y =  -project.height / 2;
                z = 0;
            }  
            else {   // If closet is set on the wall
                this._wallGrid3D.visible = true;
                y = -this._gridSize/2;
                z = this._gridSize/2 - project.depth /2;

                this._wallGrid3D.rotation.set(Math.PI / 2 , 0 , 0); 
                this._wallGrid3D.position.set(0, 0, -project.depth / 2);  
            }
            // console.debug("h = %f", project.height);
            this._floorGrid3D.position.set(0, y, z);
        }      
    }

    set selectedZone(zone: IClosetZone | null) {
        if (this._selectedZone === zone) return; // already selected

        if (this._selectedZone) (this._selectedZone as any).unselect(); // unselect current zone if any
        this._selectedZone = zone;
        if (this._selectedZone) (this._selectedZone as any).select(); // select new current zone if any

        this.selection.zone = this._selectedZone;
    }

    get selectedZone(): IClosetZone | null { return this._selectedZone }

    lockSelection(isLocked: boolean): void {
        const sltor = this._ez3D.getSelector();
        if (isLocked) 
            sltor.lock();
        else
            sltor.unlock();
    }

    selection: { zone: IClosetZone | null } = reactive<{zone: IClosetZone | null}>({ zone: null });

}