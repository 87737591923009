import { Api } from "@mobibamjs/api";
import ezInjector from "./ezV4/core/ezInjector";
import { ClosetV4, ICloset } from "./Closet";
import { IDataRepository } from "./DataRepository";
import { getShippingCost, IBounds, Mutable, ProjectDims } from "./Common";
import { IClosetLayout } from "./DataModels/IClosetLayout";
import { ISeparatorInjector } from "./SeparatorInjector";
import { IProject as IProjectDto, IMember } from "@mobibamjs/interface"
import { IEngine } from "./Engine";
import { IClosetNode } from "./ClosetNode";
import { ProjectStatus } from '@mobibamjs/interface';

const EMPTY_BNDS = { min: 0, max: 0 };

export interface IProject {

    readonly isReady: boolean;

    readonly id: number;

    readonly title: string;

    readonly ownerId: number;

    /**
     * True if current project can be processed by UI Easy (i.e created from UI Easy and not modified on desktop)
     */
    readonly isUIEasy: boolean;

    /**
     * Create a new project from specified closetLayout
     * @param closetLayout The closet layout
     * @param dims If specified the dimension of project. Only used if no JSON data specified in closet layout
     */
    create(closetLayout: IClosetLayout, dims?: ProjectDims): Promise<void>;

    /**
     * Open project from the specified project id. If project does not exist, a project is created, same as create() method.
     * @param projectId The project id
     */
    open(projectId: number): Promise<void>;

    /**
     * Save this project with the specified title. If project was never saved, this method works like a saveAs().
     * @param ownerId The owner Id of this project
     * @param operatorId The operator Id of this project. Can be the same as ownerId
     * @param title The title of project. If not speicified, automatic title is generate with name of model and Date/Time
     */
    save(ownerId: number, operatorId: number, title?: string): Promise<void>;

    /**
     * Save a copy of this project with the specified title.
     * @param ownerId The owner Id of this project
     * @param operatorId The operator Id of this project. Can be the same as ownerId
     * @param title The title of project. If not specified, title is generated by concatenation of "Copie de " and current title.
     */
    saveAs(ownerId: number, operatorId: number, title?: string): Promise<void>;

    /**
     * Save image of the project from a snapshot of current 3D scene.
     * The project had to be saved once before.
     */
    saveImage(): Promise<void>;

    show(isShown: boolean): void;

    readonly dataRepo: IDataRepository;

    readonly sepInjector: ISeparatorInjector;

    /**
     * Returns true if saving this project will create a new project in database.
     * @param currentUser The id of current user
     */
    isNew(currentUser: IMember): boolean;

    /**
     * True if project was modified once at least since its last opening or last saving
     */
    readonly isModified: boolean;

    /**
     * True if project is saved, i.e it do not need to be saved
     */
    readonly isSaved: boolean;

    /**
     * Sets this project as modified project (ex. user change dim of project).
     * isModified becomes true, and isSaved becomes false.
     */
    setAsChanged(): void;

    /**
     * Update closet and refresh visualization. For exmample, use it after dimension change
     */
    refresh(): void;

    //#region Pricing

    updatePrice(): void;

    /**
     * Shipping cost excluding VAT. Call updatePrice() to have up-to-date value.
     */
    readonly shippingCostExVAT: number;

    /**
     * Ecotax part excluding VAT. Call updatePrice() to have up-to-date value.
     */
    readonly ecotaxExVAT: number;

    /**
     * The price project excluding VAT, without shipping and ecotax. Call updatePrice() to have up-to-date value.
     */
    readonly priceExVAT: number;

    /**
     * Ecotax part excluding VAT. Call updatePrice() to have up-to-date value.
     * Sum of : shippingCostExVAT + ecotaxExVAT + priceExVAT
     */
    readonly totalPriceExVAT: number;

    /**
     * The weigth of project (kg). Call updatePrice() to have up-to-date value.
     */
    readonly weight: number;

    //#endregion

    readonly mainCloset: ICloset;

    readonly leftCloset: ICloset | null;

    readonly rightCloset: ICloset | null;

    width: number;

    height: number;

    /**
     * Gets or sets the space depth. To set closet depth, see setClosetDepth.
     */
    depth: number;

    setClosetDepth(closet: ICloset, depth: number): void;

    readonly widthBounds: Readonly<IBounds>;

    readonly heightBounds: Readonly<IBounds>;

    readonly projectStatus: ProjectStatus;

    readonly statusDate: Date | null;
    
}

export class ProjectV4 implements IProject {

    private readonly _ezPlan: any;
    private readonly _ezCloset: any;
    private readonly _plan: any;
    private readonly _ezBom: any;
    private readonly _ez3D: any;
    private readonly _bom: any;

    private readonly _engine: IEngine;
        
    private _mainCloset: ICloset | null = null;
    private _leftCloset: ICloset | null = null;
    private _rightCloset: ICloset | null = null;

    constructor(engine: IEngine, dataRepo: IDataRepository, sepInjector: ISeparatorInjector) {
        this._ezPlan = ezInjector.get("ezPlan");
        this._ezCloset = ezInjector.get("ezCloset");
        this._ezBom = ezInjector.get("ezBOM");
        this._ez3D = ezInjector.get("ez3D");

        this._engine = engine;

        this.dataRepo = dataRepo;
        this._plan = this._ezPlan.create();
        this._plan.sepInjector = sepInjector;
        this._bom = this._ezBom.$new();
    }

    get isUIEasy(): boolean { return this._plan.isUIEasy ? this._plan.isUIEasy : false }

    async create(closetLayout: IClosetLayout, dims?: ProjectDims) {
        this._clear();
        this._ezCloset.resetCUIDCounter();
        this.id = 0;
        this.ownerId = 0;

        // Initialize plan
        this._plan.clearClosets();
        this._plan.init(closetLayout);
        this._plan.isUIEasy = this._engine.isUIEasyApp;

        if (closetLayout.JsonData)
            await this._plan.readClosets(JSON.parse(closetLayout.JsonData), this.dataRepo);
        else {
            const colour = await this.dataRepo.getColour(0);
            await this._plan.generateClosets(dims ? dims : null, colour, this.dataRepo);
        }

        this.title = closetLayout.Title;

        this.isModified = false;
        this.isSaved = false;
        
        this._plan.update();
        this._plan.checkAndFix();
        this.updatePrice();
        this.isReady = true;
    }

    async open(projectId: number) {
        this._clear();

        const projDto = await Api.user.getProjectOrNullById(projectId);
        if (projDto) {
            this.id = projectId;
            this.ownerId = projDto.owner_id;
            this.title = projDto.title;
            this.projectStatus = projDto.status;
            this.statusDate = projDto.status_date;

            // Initialize plan
            this._plan.clearClosets();
            const closetLayout = await this.dataRepo.getClosetLayout(projDto.closet_layout_id);
            this._plan.init(closetLayout);
            await this._plan.readClosets(projDto.json_data, this.dataRepo);

            // To restore the current smart-pattern (later should be saved)
            if (this.mainCloset.model.code) {
                const smartPattern = this.dataRepo.getSmartPattern(this.mainCloset.model.code);
                (this.mainCloset as Mutable<ICloset>).currentSmartPattern = smartPattern;

                if (smartPattern) {
                    // Restore not overriden smartGroupKey of all modules
                    const style = smartPattern.getSmartStyles()[0];
                    const groupCount = style.groupKeys.length;
                    const moduleCount = this.mainCloset.moduleCount;
                    for(let i = 0 ; i < moduleCount ; i++) {
                        const module = this.mainCloset.getModule(i);
                        if (!module.smartGroupKey) {
                            const iGroup = i % groupCount; // Loop on column style if more column in closet than style
                            (module as Mutable<IClosetNode>).smartGroupKey = style.groupKeys[iGroup]; // The id of group
                        }
                    }
                }
            }

            this.isModified = false;
            this.isSaved = true;
            
            this._plan.update();
            this._plan.checkAndFix();
            this.updatePrice();
            this.isReady = true;
        }
        else {
            console.warn(`Cannot open project-id #${projectId} because is not found in backend. Create a new project instead`);
            const closetLayout = await this.dataRepo.getClosetLayout(this.dataRepo.closetStarters[0].PlanId);
            await this.create(closetLayout);
        }
    }

    //#region Saving

    async save(ownerId: number, operatorId: number, title?: string): Promise<void> {
        const now = new Date();
        const finalTitle = title ? title : `${this._plan._planDto.Title} ${now.toLocaleDateString("en-GB")} ${now.toLocaleTimeString("en-GB")}`;
        
        // It's a new project
        if (this.id === 0) return this.saveAs(ownerId, operatorId, finalTitle);

        // The project is not owned by current ownerId. We have to Save As ...
        if (this.ownerId > 0 && this.ownerId !== ownerId) return this.saveAs(ownerId, operatorId, finalTitle);

        this.updatePrice();

        const container = this._plan.writeClosets({ withBom: false });

        const inProjDto = this._makeProjectDto(ownerId, operatorId, finalTitle, container.toJson());
        const outProjDto = await Api.user.updateProject(inProjDto);

        this.id = outProjDto.id;
        this.ownerId = outProjDto.owner_id;
        this.title = outProjDto.title;
        this.projectStatus = outProjDto.status;
        this.statusDate = outProjDto.status_date;

        this.isModified = false;
        this.isSaved = true;
    }

    async saveAs(ownerId: number, operatorId: number, title?: string): Promise<void> {
        this.updatePrice();

        const container = this._plan.writeClosets({ withBom: false });

        const inProjDto = this._makeProjectDto(ownerId, operatorId, title ? title : `Copie de ${this.title}`, container.toJson());
        const outProjDto = await Api.user.insertProject(inProjDto);

        this.id = outProjDto.id;
        this.ownerId = outProjDto.owner_id;
        this.title = outProjDto.title;
        this.projectStatus = outProjDto.status;
        this.statusDate = outProjDto.status_date;

        this.isModified = false;
        this.isSaved = true;
    }

    async saveImage(): Promise<void> {
        if (this.id === 0) throw new Error("Cannot save image of project before it was saved. Call save() or saveAs() before.");

        if (this._ez3D.has3D()) {
            const imgDataUrl: string = await this._ez3D.takeScreenshot();
            return Api.user.setProjectImage(this.id, imgDataUrl);
        }
    }

    private _makeProjectDto(owner_id: number, operator_id: number, title: string, json_data: any): Partial<IProjectDto> {
        return {
            id: this.id,
            brand_id: this.dataRepo.brandId,
            shop_id: 0,
            title,
            price: this.priceExVAT + this.shippingCostExVAT,
            ecotax: this.ecotaxExVAT,
            vat_rate: this.dataRepo.VATRate,
            weight: this.weight,
	        closet_layout_id: this._plan._planDto.Id,
	        json_data,
	        owner_id,
            operator_id
        }
    }

    //#endregion

    show(isShown: boolean): void {
        if (isShown)
            this._plan.display();
        else
            this._plan.hide();
    }

    readonly dataRepo: IDataRepository;

    get sepInjector(): ISeparatorInjector { return this._plan.sepInjector }

    isReady: boolean = false;

    id: number = 0;

    ownerId: number = 0;

    title: string = "(sans titre)";

    projectStatus: ProjectStatus = ProjectStatus.Created;

    statusDate: Date | null = null;

    isNew(currentUser: IMember): boolean { 
        return  (this.id === 0) // The project has no id yet
                || (this.ownerId > 0 && this.ownerId !== currentUser.id); // Or, this project does not own by current user
    }

    isModified: boolean = false;

    isSaved: boolean = false;

    setAsChanged(): void {
        this.updatePrice();
        this.isModified = true;
        this.isSaved = false;
    }

    refresh() {
        this._plan.hide();
        this._plan.update();
        this._plan.display();
    }

    //#region Pricing

    shippingCostExVAT: number = 0;

    ecotaxExVAT: number = 0;

    priceExVAT: number = 0;

    weight: number = 0;

    totalPriceExVAT: number = 0;

    updatePrice(): void {
        this._bom.clear();
        this._plan.getMainCloset().fillBOM(this._bom);
        if (this._plan.getLeftCloset()) this._plan.getLeftCloset().fillBOM(this._bom);
        if (this._plan.getRightCloset()) this._plan.getRightCloset().fillBOM(this._bom);

        this.priceExVAT = this._bom.getTotalPrice();
        this.shippingCostExVAT = getShippingCost(this.priceExVAT);
        this.ecotaxExVAT = this._bom.getTotalEcoTax();
        this.totalPriceExVAT = this.shippingCostExVAT + this.priceExVAT + this.ecotaxExVAT; //ProjectV4.getShippingCost(bomPrice) + bomPrice + bom.getTotalEcoTax();
        this.weight = this._bom.getTotalWeight();
    }

    //#endregion

    //#region Closets

    get mainCloset(): ICloset { 
        if (this._mainCloset === null) {
            const ezCloset = this._plan._main.closet;
            this._mainCloset = new ClosetV4(this, "main", ezCloset);
        }
        return this._mainCloset;
    }

    get leftCloset(): ICloset | null {
        const ezCloset = this._plan.getLeftCloset();
        if (ezCloset) {
            if (this._leftCloset === null) this._leftCloset = new ClosetV4(this, "left", ezCloset);
            return this._leftCloset;
        }
        else 
            return null;
    }

    get rightCloset(): ICloset | null {
        const ezCloset = this._plan.getRightCloset();
        if (ezCloset) {
            if (this._rightCloset === null) this._rightCloset = new ClosetV4(this, "right", ezCloset);
            return this._rightCloset;
        }
        else 
            return null;
    }

    //#endregion

    //#region Dimensions

    get width(): number { return this.isReady ? this._plan.getXDim() : 0 }
    set width(dim: number) {
        // if (!this._plan) return;
        this._plan.setXDim(dim);
    }

    get height(): number { return this.isReady ? this._plan.getYDim() : 0 }
    set height(dim: number) {
        // if (!this._plan) return;
        this._plan.setYDim(dim);
    }

    setClosetDepth(closet: ICloset, depth: number): void {
        const ez_closet = (closet as ClosetV4).internalInstance;
        ez_closet.getDriver().setDepth(depth);
        ez_closet.getSwingDoorAssoc().invalidate();
        ez_closet.getSwingDoorAssoc().updateDoors();
        ez_closet.getValidator().checkAndFix(ez_closet.root);

        // this._lgcy_closet.hide();
        // this._lgcy_closet.getDriver().setDepth(dim);

        // let planV4 = this._lgcy_closet.getDriver().plan;
        // planV4.update();

        // this._lgcy_closet.getSwingDoorAssoc().invalidate();
        // this._lgcy_closet.getSwingDoorAssoc().updateDoors();
        // this._lgcy_closet.getValidator().checkAndFix(this._lgcy_closet.root);
        // this._lgcy_closet.display();
    }

    get depth(): number { return this.isReady ? this._plan.getZDim() : 0 }
    set depth(dim: number) {
        if (!this._plan) return;
        this._plan.setZDim(dim);
    }

    //#endregion

    //#region Dimension bounds

    get widthBounds(): Readonly<IBounds> {
        if (!this.isReady) return EMPTY_BNDS;

        const bndsV4 = this._plan.getDimBounds();
        return { min: bndsV4.width.min, max: bndsV4.width.max }
    }

    get heightBounds(): Readonly<IBounds> {
        if (!this.isReady) return EMPTY_BNDS;

        if (this.mainCloset.currentSmartPattern) {
            return this.mainCloset.currentSmartPattern.closetDimBounds;
        }
        else {
            const bndsV4 = this._plan.getDimBounds();
            return { min: bndsV4.height.min, max: bndsV4.height.max }
        }
    }

    //#endregion

    private _clear() {
        this._mainCloset = null;
        this._leftCloset = null;
        this._rightCloset = null;
        this.isReady = false;
        this.totalPriceExVAT = 0;
        this.projectStatus = ProjectStatus.Created;
    }

}