﻿// import numeral from 'numeral';

///// Init method for injection
function _init(dcies, $ez, ez3D, ezPrice, ezBOM, ezCloset, ezPanel, ezDrawer, ezHangingBar, ezAccessory, ezGPos) {

    var $log = $ez.getLogger();


    // The EZCloset file format information to tag file header.
    var EZCD_FORMAT = { fmt: 'EZCD', ver: 2, rel: 3 };

    /////// Class SimpleBOM
    // Brief : a simple BOM item collection. Use in fillBOM() method.
    // Constructor
    var SimpleBOM = function () {
        this._a = [];
    };
    // Methods
    (function (_P) {

        _P.add = function (e) {
            this._a.push(e);
        }

        _P.get = function (i) {
            return this._a[i || 0];
        }

        _P.getLength = function () { return this._a.length; }

    })(SimpleBOM.prototype);



    /////// Class ReadReport
    // Brief : Report to collect information during read operation.
    // Constructor
    var ReadReport = function () {
        this._items = [];
    };
    // Methods
    (function (_P) {

        // Generic add method.
        // t: (String) type of information 'WARN' | 'ERR'.
        // m: (String) The message.
        _P.add = function (t, m) {
            this._items.push({ typ: t, msg: m });
        }

        // Add 'WARN' message m.
        _P.addWarn = function (m) {
            this.add('WARN', m);
        }

        // Add 'ERR' message m.
        _P.addErr = function (m) {
            this.add('ERR', m);
        }

        // Get all added items as array of { typ: 'WARN' | 'ERR', msg: '...' }
        _P.getItems = function () { return this._items; }

    })(ReadReport.prototype);


    /////// Class Container
    // Brief : The container for EZCD format. Container is like a file.
    var Container = function () {
        var me = this;

        me._mode = null;
        me._dataLoadingWatcher = {}; // To watch data loading status
        me.DATA_NODE = null;
        me.report = null; // ReadReport class

        // me.onWatcherCompleteFn = null; // Function to call when watcher loading complete.
        // me.onWatcherCompleteArgs = null; // Argument to pass in onWatcherCompleteFn.
    };

    // Methods
    (function (_P) {

        // Prepare container for writing operation. Call this method before any write operation.
        _P.prepareToWrite = function (plan) {
            var me = this;

            if (!plan) $ez.THROW("Plan must be defined for the write preparation.");

            me._mode = 'write';
            // Prepare DATA_NODE
            me.DATA_NODE = {};
            me.DATA_NODE.head = EZCD_FORMAT;

            // Since 2.3
            me.DATA_NODE.proj = { uiez: plan.isUIEasy };
        }

        // Prepare container for reading operation. Call this method before any read operation.
        // DATA_NODE: (Object) The data-node as JS object. DataNode outcome of preceding writing.
        _P.prepareToRead = function (plan, DATA_NODE) {
            var me = this;

            me._mode = 'read';

            me.DATA_NODE = DATA_NODE;
            if (!DATA_NODE.head) $ez.THROW("Bad data-node. Missing 'head' information.");
            if (DATA_NODE.head.fmt !== EZCD_FORMAT.fmt) $ez.THROW("Bad data-node. Unknow format '" + dataNode.head.fmt + "'.");
            if (DATA_NODE.head.ver > EZCD_FORMAT.ver) $ez.THROW("Bad data-node. Version " + dataNode.head.ver + " is greater than current supported version " + EZCD_FORMAT.ver + ".");

            if (DATA_NODE.head.ver <= 1) {
                if (!DATA_NODE.desc) $ez.THROW("Description missing. DATA_NODE does not contains 'desc' property.");
            }
            else {
                if (!DATA_NODE.main && !DATA_NODE.left && !DATA_NODE.right) $ez.THROW("Description missing. DATA_NODE does not contains 'main', 'left' nor 'right' property.");
            }

            // Since 2.3
            if (me.DATA_NODE.proj) { 
                plan.isUIEasy = me.DATA_NODE.proj.uiez
            }
        }

        // Prepare to copy/paste operation
        _P.prepareToCopyPaste = function () {
            var me = this;
            me._mode = 'copyPaste';
            me.DATA_NODE = null;
        }

        // Gets mode of container
        _P.getMode = function () { return this._mode; }

        // Gets data-node of this container
        _P.getDataNode = function () { return this.DATA_NODE; }

        // Get closet description node by name.
        //  descName: (String) The name of closet description : 'main', 'left' or 'right'
        // Returns: (Object) The closet description node or null if not found.
        _P.getClosetDesc = function (descName) {
            var me = this;
            if (me.DATA_NODE.head.ver <= 1 && descName === 'main')
                return me.DATA_NODE.desc;
            else
                return me.DATA_NODE[descName];
        }

        // Convenient method to convert DATA_NODE to JSON
        _P.toJson = function () { return JSON.stringify(this.DATA_NODE); }

        // Create plan BOM dto used in export services from current DATA_NODE.
        _P.createPlanBOM = function (projectId) {
            var me = this;

            if (!me.DATA_NODE['main'].bom)
                $ez.THROW("No BOM found. Cannot create plan BOM. Check if 'withBom' option is true.")

            var planBom = { projectId: projectId };
            planBom.MainBom = me.DATA_NODE['main'].bom;
            planBom.LeftBom = me.DATA_NODE['left'] ? me.DATA_NODE['left'].bom : null;
            planBom.RightBom = me.DATA_NODE['right'] ? me.DATA_NODE['right'].bom : null;

            planBom.format = EZCD_FORMAT.fmt + " " + EZCD_FORMAT.ver + "." + EZCD_FORMAT.rel;

            return planBom;
        }

    })(Container.prototype);



    /////// Class Writer
    // Brief : Closet writer
    var Writer = function (container, opts) {
        var me = this;
        me.writeOpts = { withBom: false }; // Write options.
        me.container = container;

        // The options
        if (!opts)
            $ez.THROW("Missing write options. Writer constructor failed.");

        // The withBom option
        if (opts.withBom === undefined)
            $ez.THROW("Option 'withBom' is not specified. Writer constructor failed.");

        me.bom = null;
        me.writeOpts.withBom = opts.withBom;

        me.IIDIndexes = { // IID index states
            P: 0, // Panels
            A: 0, // Accessories
            B: 0, // Bars
            D: 0, // Drawers
            M: 0 // Machinings (ex. baseboard)
        }; 
    };

    // Methods
    (function (_P) {

        // Write closet in desc node named descName
        // closet: (Closet) The closet to write
        // descName: (String) The name of closet description according to type of closet : 'main', 'left' or 'right'.
        _P.writeCloset = function (closet, descName) {
            var me = this;

            if (descName !== 'main' && descName !== 'left' && descName !== 'right')
                $ez.THROW("Closet desc named '" + descName + "' is not supported. WriteCloset failed.");

            // Closet baseboard
            var baseboard = closet.getBaseboard();

            // Get description according to descName
            me.container.getDataNode()[descName] = {};
            var desc = me.container.getDataNode()[descName];

            // Write closet desc
            if (me.writeOpts.withBom) desc.CLASS = closet.model.ModelClass;

            // Always a root node
            desc.root = {};

            // The outerZone give closet dimensions
            desc.root.outerZone = {}
            desc.root.outerZone.xdim = closet.root.outerZone.xdim;
            desc.root.outerZone.ydim = closet.root.outerZone.ydim;
            desc.root.outerZone.zdim = closet.root.outerZone.zdim;

            // Prepare bom
            if (me.writeOpts.withBom) {
                var gp = closet.getGPos();

                me.bom = {
                    closetModelId: closet.getClosetModel().Id,
                    closetXDim: closet.root.outerZone.xdim,
                    closetYDim: closet.root.outerZone.ydim,
                    closetZDim: closet.root.outerZone.zdim,
                    closetGx: gp.x,
                    closetGy: gp.y,
                    closetGz: gp.z,
                    closetGAng: closet.getGAng(),
                    bomItems: [],
                    inZones: [] // The inner-zones of closet.
                };

                me.writeBaseboardInBOM(baseboard);

                me.container.getDataNode()[descName].bom = me.bom;
            }

            // Colour of closet
            desc.colourId = closet.getMainColour().Id;

            // Write by recursing along node tree
            me.writeNode(closet.root, desc.root);

            // Write closet door
            me.writeClosetDoor(closet.doorNode, desc);

            // Write baseboard
            if (baseboard.isDefined())
                desc.bboard = { d: baseboard.getDepth(), h: baseboard.getHeight() };

            // Write frontColour
            if (closet.frontColour) desc.ftClr = closet.frontColour.Id;
        }

        // Write node tree
        // node: (CNode) The current node to write
        _P.writeNode = function (node, nodeDesc) {
            var me = this;
            var withBom = me.writeOpts.withBom;
            var i, n;

            if (withBom && node.parts) { // BOM
                var parts = node.parts;
                if (parts.left) me.writePanelInBOM(parts.left);
                if (parts.right) me.writePanelInBOM(parts.right);
                if (parts.bottom) me.writePanelInBOM(parts.bottom);
                if (parts.top) me.writePanelInBOM(parts.top);
                if (parts.bottomBandFront) me.writeBandInBOM(parts.bottomBandFront);
                if (parts.bottomBandBack) me.writeBandInBOM(parts.bottomBandBack);
                if (parts.topBand) me.writeBandInBOM(parts.topBand);
                if (parts.frontConsolidator) me.writePanelInBOM(parts.frontConsolidator);
                if (parts.backConsolidator) me.writePanelInBOM(parts.backConsolidator);
            }

            if (node.parts && (node.parts.left || node.parts.right || node.parts.back || node.parts.front)) { // Optional Parts
                var partDesc = me.writeParts(node, node.parts);
                if (partDesc) nodeDesc.parts = partDesc;
            }

            var outZone = node.outerZone;
            if (!outZone.hasXDimAuto || !outZone.hasYDimAuto || !outZone.hasZDimAuto) { // Outer-zone
                var parent = node.parent;
                var zonDesc = {};
                if (!outZone.hasXDimAuto && parent && parent.outerZone.xdim !== outZone.xdim) zonDesc.xdim = outZone.xdim;
                if (!outZone.hasYDimAuto && parent && parent.outerZone.ydim !== outZone.ydim) zonDesc.ydim = outZone.ydim;
                if (!outZone.hasZDimAuto && parent && parent.outerZone.zdim !== outZone.zdim) zonDesc.zdim = outZone.zdim;

                if (zonDesc.xdim || zonDesc.ydim || zonDesc.zdim)
                    nodeDesc.outerZone = zonDesc;
            }

            var paddings = node.paddings;
            if (paddings.top !== 0) { // paddings (since EZCD v2.1)
                nodeDesc.padds = { t: paddings.top };
            }

            // Overriden smart-group (since EZCD v2.3)
            if (node.smartGroupKey && node.isUserSG) {
                nodeDesc.smgk = node.smartGroupKey;
                // console.debug(">>>> nodeDesc.smgk => %s", node.smartGroupKey);
            }

            var n = node.subNodes.getLength();

            // Inner zone
            if (withBom && !node.subTree && n === 0) {
                me.writeZoneInBOM(node.innerZone);
            }

            if (n > 0) { // sub-nodes and separators if any

                // Write separators in BOM
                if (withBom && node.separators) {
                    for (i = 0; i < n - 1; i++) {
                        var sep = node.separators.get(i);
                        var kindExt = null;
                        if (node.parent !== null)
                            kindExt = "LL";
                        me.writePanelInBOM(sep, { kindExt: kindExt });
                    }
                }

                // Write nodes in BOM
                nodeDesc.subNodes = [];
                if (n > 1) nodeDesc.sepAxis = node.axis; // node is split
                for (i = 0; i < n; i++) {
                    var snDesc = {};
                    me.writeNode(node.subNodes.get(i), snDesc);
                    nodeDesc.subNodes.push(snDesc);
                }
            }
            else if (node.items && node.items.getLength() > 0) { // Sub-items
                nodeDesc.items = [];
                n = node.items.getLength();
                for (i = 0; i < n; i++) {
                    var item = me.writeItem(node.items.get(i));
                    if (item)
                        nodeDesc.items.push(item);
                }
            }
        }

        // Write node parts and return written instance if any
        // parts: (CNParts) The parts
        _P.writeParts = function (node, parts) {
            var me = this;
            var withBom = me.writeOpts.withBom;

            var partsDesc = {};

            if (parts.left && parts.left.hasOwnColour()) {
                partsDesc.left = {};
                partsDesc.left.ezTyp = 'PAN';
                partsDesc.left.colourId = parts.left.getColour().Id;
            }

            if (parts.right && parts.right.hasOwnColour()) {
                partsDesc.right = {};
                partsDesc.right.ezTyp = 'PAN';
                partsDesc.right.colourId = parts.right.getColour().Id;
            }

            if (parts.back) {
                partsDesc.back = {};
                partsDesc.back.ezTyp = 'PAN';
                if (parts.back.hasOwnColour())
                    partsDesc.back.colourId = parts.back.getColour().Id;

                if (withBom) {
                    var args = { margins: null };
                    if (node.level > 0 && (parts.back.margins.top !== 0 || parts.back.margins.bottom !== 0))
                        args.margins = { left: 0, right: 0, top: parts.back.margins.top, bottom: parts.back.margins.bottom };

                    me.writePanelInBOM(parts.back, args);
                }
            }

            if (parts.front) {
                partsDesc.front = {};
                partsDesc.front.ezTyp = 'SWD';
                partsDesc.front.modelId = parts.front.getModel().Id;
                if (parts.front.hasOwnColour())
                    partsDesc.front.colourId = parts.front.getColour().Id;

                if (withBom) me.writeSwingDoorInBOM(parts.front);
            }

            return partsDesc;
        }

        // Write band by taking in accout left and right margins.
        // band: (Panel) The band panel.
        _P.writeBandInBOM = function (band) {
            var args = { margins: null };
            if (band.margins.left !== 0 || band.margins.right !== 0)
                args.margins = { left: band.margins.left, right: band.margins.right, top: 0, bottom: 0 };

            this.writePanelInBOM(band, args);
        }

        // Write item and return created instance if success
        _P.writeItem = function (item) {
            var me = this;

            if (!item.getEzType) {
                $log.warn("RW: Item '" + item.title + "' must implement method 'getEzType()'. Item cannot be written.");
                return null;
            }

            var withBom = me.writeOpts.withBom;

            var itemDesc = null;
            var ezTyp = item.getEzType();
            switch (ezTyp) {
                case 'DRW':
                case 'DrawerGroup':
                    itemDesc = {};
                    itemDesc.ezTyp = 'DRW';
                    itemDesc.modelId = item.getModel().Id;
                    itemDesc.handleModelId = item.getHandleModel() ? item.getHandleModel().Id : 0;
                    itemDesc.colourId = item.getFacadeColour() ? item.getFacadeColour().Id : 0;
                    if (withBom) me.writeDrawerInBOM(item);
                    break;

                case 'HGB':
                case 'HangingBar':
                    itemDesc = {};
                    itemDesc.ezTyp = 'HGB';
                    itemDesc.modelId = item.getModel().Id;
                    if (withBom) me.writeHangingBarInBOM(item);
                    break;

                case 'Accessory':
                    itemDesc = {};
                    itemDesc.ezTyp = 'ACC';
                    itemDesc.modelId = item.getModel().Id;
                    if (withBom) me.writeAccessoryInBOM(item);
                    break;

                default:
                    $log.warn("RW: Not supported ezTyp '" + ezTyp + "'. Item will be not written.");
            }

            return itemDesc;
        }

        // Write closet door if any
        _P.writeClosetDoor = function (doorNode, closetDesc) {
            if (!doorNode || !doorNode.door) return;

            var me = this;
            var i, n;
            var door = doorNode.door;
            var doorModel = door.doorModel;
            var withBom = me.writeOpts.withBom;

            var doorDesc = {};
            doorDesc.ezTyp = 'SLD';
            doorDesc.modelId = doorModel.Id;

            if (door.bottomRail && door.bottomRail.profDef.Id !== doorModel.AllowedBottomRailProfilesId[0]) {
                doorDesc.bottomRailId = door.bottomRail.profDef.Id;
            }
            if (withBom && door.bottomRail) me.writeProfileInBOM(door, door.bottomRail, 15, " (bas)");

            if (door.topRail.profDef.Id !== doorModel.AllowedTopRailProfilesId[0])
                doorDesc.topRailId = door.topRail.profDef.Id;
            if (withBom && door.topRail) me.writeProfileInBOM(door, door.topRail, 15, " (haut)");

            if (door.crossProfile && door.crossProfile.profDef.Id !== doorModel.AllowedCrossProfilesId[0])
                doorDesc.crossId = door.crossProfile.profDef.Id;

            // Write leafs
            n = door.getLeafCount();
            if (n > 0) {
                doorDesc.leafs = [];
                for (i = 0; i < n; i++) {
                    var leaf = door.getLeaf(i);
                    var leafDesc = me.writeLeaf(leaf);
                    if (leafDesc) doorDesc.leafs.push(leafDesc);
                    if (withBom) me.writeLeafInBOM(leaf, i + 1);
                }
            }

            closetDesc.door = doorDesc;
        }

        // Write leaf and return created instance
        _P.writeLeaf = function (leaf) {
            var i, n;

            var leafDesc = {};
            n = leaf.panels.getLength();
            if (n === 1 && leaf.panels.get(0).hasOwnColour()) { // Not split
                leafDesc.zones = [];
                leafDesc.zones.push({ colourId: leaf.panels.get(0).getColour().Id });
            }
            else if (n > 1) {
                var zone, zonDesc, pan;
                leafDesc.zones = [];
                var zoneHeights = leaf.getZoneUserHeights();
                for (i = 0; i < n; i++) { // Split
                    pan = leaf.panels.get(i);
                    zone = leaf.subZones.get(i);
                    zonDesc = {};
                    if (!zone.hasYDimAuto) zonDesc.userHeight = zoneHeights[i];
                    if (pan.hasOwnColour()) zonDesc.colourId = pan.getColour().Id;

                    leafDesc.zones.push(zonDesc);
                }
            }

            return leafDesc;
        }


        ///////////////////////// Write BOM methods

        // TODO_EZCD :
        // Add bomItem.owner with cuid of item owner

        // Write zone in BOM.
        _P.writeZoneInBOM = function (zon) {
            var me = this;

            var ga = me.bom.closetGAng;
            var gp = { x: me.bom.closetGx, y: me.bom.closetGy, z: me.bom.closetGz };

            var Lbx = {
                x: zon.x,
                y: zon.y,
                z: zon.z,
                xdim: zon.xdim,
                ydim: zon.ydim,
                zdim: zon.zdim
            };

            var z = {
                cuid: zon.CUID,
                Lbox: Lbx,
                Gbox: ezGPos.toGBox(Lbx, ga, gp)
            }

            me.bom.inZones.push(z);
            return z;
        }

        // Write panel in BOM
        //_P.writePanelInBOM = function (pan, kindExt, iidTag) {
        _P.writePanelInBOM = function (pan, args) {
            var me = this;
            var nativeBom = new SimpleBOM();
            pan.fillBOM(nativeBom);
            var natBomItem = nativeBom.get();

            var bomit = me.writeInBOM(natBomItem, pan, 'PAN');
            bomit.iid = me._getIID(args && args.iidTag ? args.iidTag : 'P');
            bomit.Ref = pan.getMaterialModel().thick.Ref; //ezRepo.getThickRef(pan.getMaterialModel());
            bomit.kind = pan.kind;
            bomit.modelId = pan.getMaterialModel().Id;
            bomit.colourId = pan.getColour().Id;
            bomit.qtyOfMat = natBomItem.surf;
            if (args) {
                if (args.kindExt)
                    bomit.kindExt = args.kindExt;

                if (args.margins)
                    bomit.margins = args.margins;
            }
        }

        // Write swingdoor in BOM
        _P.writeSwingDoorInBOM = function (swd) {
            var me = this;
            var i, j, bomit;
            var nativeBom, natBomItem;

            nativeBom = new SimpleBOM();
            swd.fillBOM(nativeBom);

            var panelCount = swd.getPanelCount();
            for (i = 0; i < panelCount; i++) {
                natBomItem = nativeBom.get(i);
                var swdPanel = swd.panels[i];

                bomit = me.writeInBOM(natBomItem, swdPanel, 'PAN');
                bomit.iid = me._getIID('P');
                bomit.Ref = swdPanel.getMaterialModel().thick.Ref; //ezRepo.getThickRef(swdPanel.getMaterialModel());
                bomit.kind = 17;
                bomit.modelId = swdPanel.getMaterialModel().Id;
                bomit.colourId = swd.getColour().Id;
                bomit.qtyOfMat = natBomItem.surf;

                // Kind ext
                bomit.kindExt = swd.model.IsOverlay ? "Out" : "In";
                switch (swd.model.DoorType) {
                    case 1: bomit.kindExt += "LH"; break; // Hinge on left
                    case 2: bomit.kindExt += "RH"; break; // Hinge on right
                    case 3: bomit.kindExt += i === 0 ? "LH" : "RH"; break; // Double-door
                }
            }

            var hingeGroups = swd.getHingeGroups();
            for (i = 0; i < hingeGroups.length; i++) {
                var hingeGroup = hingeGroups[i];
                if (hingeGroup && hingeGroup.positions.length > 0) { // Has hinges
                    var positions = hingeGroup.positions;
                    var ruleModel = hingeGroup.ruleModel;
                    var ga = me.bom.closetGAng;
                    var gp = { x: me.bom.closetGx, y: me.bom.closetGy, z: me.bom.closetGz };

                    for (j = 0; j < positions.length; j++) {
                        var pos = positions[j];
                        natBomItem = {
                            title: "charnière " + ruleModel.Ref,
                            dims: [ruleModel.HingeWidth, ruleModel.HingeHeight, ruleModel.HingeDepth],
                            dimsLabel: '(l x h x p)',
                            ecoTax: ezPrice.getEcoTaxByWeight(ruleModel.Weight).Value,
                            price: ezPrice.getSalePrice(ruleModel.Price),
                            weight: ruleModel.Weight
                        };
                        bomit = me.writeInBOM(natBomItem, {}, 'FER');
                        bomit.cuid = hingeGroup.cuids[i];
                        bomit.iid = me._getIID('A');
                        bomit.kind = 18;
                        bomit.kindExt = hingeGroup.kindExt;
                        bomit.Lbox = {
                            x: pos.x,
                            y: pos.y,
                            z: pos.z,
                            xdim: ruleModel.HingeWidth,
                            ydim: ruleModel.HingeHeight,
                            zdim: ruleModel.HingeDepth
                        }
                        bomit.Gbox = ezGPos.toGBox(bomit.Lbox, ga, gp);
                    }
                }
            }

            if (swd.tipOns) {
                var ruleModel = swd.tipOns.ruleModel;
                me.writeTipOnInBOM(swd.tipOns.tipOn1, ruleModel);
                if (swd.tipOns.tipOn2.pos)
                    me.writeTipOnInBOM(swd.tipOns.tipOn2, ruleModel);
            }
        }

        // Write tip-on in BOM
        // tipOn: (Object) The tip-on object as is define in TipOns class.
        // ruleModel: (ezcTipOnRule) The tip-on rules
        _P.writeTipOnInBOM = function (tipOn, ruleModel) {
            var me = this;
            var ga = me.bom.closetGAng;
            var gp = { x: me.bom.closetGx, y: me.bom.closetGy, z: me.bom.closetGz };

            var natBomItem = {
                title: "Tip-on " + ruleModel.Ref,
                dims: [ruleModel.TipOnWidth, ruleModel.TipOnHeight, ruleModel.TipOnDepth],
                dimsLabel: '(l x h x p)',
                ecoTax: ezPrice.getEcoTaxByWeight(ruleModel.Weight).Value,
                price: ezPrice.getSalePrice(ruleModel.Price),
                weight: ruleModel.Weight
            };
            var bomit = me.writeInBOM(natBomItem, {}, 'TIP');
            bomit.cuid = tipOn.cuid;
            bomit.iid = me._getIID('A');
            bomit.kind = 21;
            bomit.Lbox = {
                x: tipOn.pos.x,
                y: tipOn.pos.y,
                z: tipOn.pos.z,
                xdim: ruleModel.TipOnWidth,
                ydim: ruleModel.TipOnHeight,
                zdim: ruleModel.TipOnDepth
            };
            bomit.Gbox = ezGPos.toGBox(bomit.Lbox, ga, gp);
        }

        // Write drawer in BOM
        _P.writeDrawerInBOM = function (drw) {
            var me = this;

            var nativeBom = new SimpleBOM();
            drw.fillBOM(nativeBom);
            var natBomItem = nativeBom.get();

            // Drawer
            var bomit = me.writeInBOM(natBomItem, drw, 'DRW');
            bomit.iid = me._getIID('D');
            bomit.Ref = drw.getModel().Ref;
            bomit.kind = 8;
            bomit.modelId = drw.getModel().Id;
            bomit.colourId = drw.getCurrentFacadeColour().Id;

            // Handle ?
            if (nativeBom.getLength() > 1) {
                for (var i = 1; i < nativeBom.getLength(); i++)
                    me.writeAccessoryInBOM(drw.handles[i - 1], nativeBom.get(i));
            }
        }

        // Write accessory in BOM
        _P.writeAccessoryInBOM = function (acc, natBomItem) {
            var me = this;

            var nativeBom = new SimpleBOM();
            acc.fillBOM(nativeBom);
            var natBomItem = nativeBom.get();

            // Accessories
            var bomit = me.writeInBOM(natBomItem, acc, 'ACC');
            bomit.iid = me._getIID('A');
            bomit.Ref = acc.getModel().Ref;
            bomit.kind = 9;
            bomit.kindExt = acc.getModel().positioning.kindExt ? acc.getModel().positioning.kindExt : null;
            bomit.modelId = acc.getModel().Id;
        }

        // Write hanging-bar in BOM
        _P.writeHangingBarInBOM = function (hgb) {
            var me = this;

            var nativeBom = new SimpleBOM();
            hgb.fillBOM(nativeBom);
            var natBomItem = nativeBom.get();

            // Hanging-bar
            var bomit = me.writeInBOM(natBomItem, hgb, 'HGB');
            bomit.iid = me._getIID('B');
            bomit.Ref = hgb.model.Ref;
            bomit.kind = 10;
            bomit.modelId = hgb.model.Id;
        }

        // Write sliding-door leaf in BOM
        _P.writeLeafInBOM = function (leaf, index) {
            var me = this;
            var nativeBom = new SimpleBOM();
            var panelInfos = leaf.fillBOM(nativeBom, index);
            var natBomItem = nativeBom.get();
            var ga = me.bom.closetGAng;
            var gp = { x: me.bom.closetGx, y: me.bom.closetGy, z: me.bom.closetGz };

            // Left Profiles
            if (leaf.leftProfile)
                me.writeProfileInBOM(leaf, leaf.leftProfile, 13, " gauche");

            // Right Profiles
            if (leaf.rightProfile)
                me.writeProfileInBOM(leaf, leaf.rightProfile, 13, " droite");

            // Bottom Profiles
            if (leaf.bottomProfile)
                me.writeProfileInBOM(leaf, leaf.bottomProfile, 13, " bas");

            // Top Profiles
            if (leaf.topProfile)
                me.writeProfileInBOM(leaf, leaf.topProfile, 13, " haut");

            // Cross profiles
            for (var i = 0; i < leaf.crossProfiles.length; i++)
                me.writeProfileInBOM(leaf, leaf.crossProfiles[i], 14, "");

            // Add each panel
            for (var i = 0; i < panelInfos.infos.length; i++) {
                var pi = panelInfos.infos[i];
                var bomit = me.writeInBOM(natBomItem, leaf, 'PAN');
                bomit.Ref = pi.panel.getMaterialModel().Ref;
                bomit.iid = me._getIID('P');
                bomit.cuid = pi.panel.CUID;
                bomit.kind = 12;
                bomit.modelId = pi.panel.getMaterialModel().Id;
                bomit.colourId = pi.panel.getColour().Id;
                bomit.price = pi.price;
                bomit.ecotax = pi.ecoTax;
                bomit.weight = pi.weight;
                bomit.qtyOfMat = pi.surf;
                bomit.Lbox.xdim = pi.xdim;
                bomit.Lbox.ydim = pi.ydim;
                bomit.Lbox.zdim = pi.panel.zdim;
                if (panelInfos.infos.length > 1) { // Panel was cutted
                    bomit.Lbox.x = pi.panel.x;
                    bomit.Lbox.y = pi.panel.y;
                    bomit.Lbox.z = pi.panel.z;
                }
                bomit.Gbox = ezGPos.toGBox(bomit.Lbox, ga, gp);
            }
        }

        // Write profile in BOM
        _P.writeProfileInBOM = function (owner, prf, kind, posTxt) {
            var me = this;
            var nativeBom = new SimpleBOM();
            prf.fillBOM(nativeBom);
            var natBomItem = nativeBom.get();

            var bomit = me.writeInBOM(natBomItem, prf, 'PRF');
            bomit.iid = me._getIID('B');
            bomit.Ref = prf.profDef.Ref;
            bomit.title = bomit.title + posTxt;
            bomit.kind = kind;
            bomit.modelId = prf.profDef.Id;
            bomit.qtyOfMat = prf.length;
        }

        // Write baseboard in BOM
        _P.writeBaseboardInBOM = function (baseboard) {
            if (!baseboard.isDefined()) return;

            var natBomItem = {
                title: "Découpe de plinthe",
                dims: [baseboard.getDepth(), baseboard.getHeight()],
                dimsLabel: "(P x H)",
                price: baseboard.getPrice(),
                ecoTax: 0,
                weight: 0
            }

            var bomit = this.writeInBOM(natBomItem, baseboard, "");
            bomit.kind = 22;
            bomit.iid = this._getIID('M');
        }

        // Write item in BOM
        _P.writeInBOM = function (natBomItem, item, eztyp) {
            var me = this;

            var ga = me.bom.closetGAng;
            var gp = { x: me.bom.closetGx, y: me.bom.closetGy, z: me.bom.closetGz };

            var Lbx = item.getBox ? item.getBox() : null;
            var Gbx = Lbx ? ezGPos.toGBox(Lbx, ga, gp) : null;

            var bomItem = {
                cuid: item.CUID,
                iid: '',
                Ref: '',
                title: natBomItem.title,
                ezTyp: eztyp,
                kind: 0,
                kindExt: null,
                dim1: natBomItem.dims && natBomItem.dims.length > 0 ? natBomItem.dims[0] : 0,
                dim2: natBomItem.dims && natBomItem.dims.length > 1 ? natBomItem.dims[1] : 0,
                dim3: natBomItem.dims && natBomItem.dims.length > 2 ? natBomItem.dims[2] : 0,
                dimsLabel: natBomItem.dimsLabel,
                modelId: 0,
                colourId: 0,
                price: natBomItem.price ? natBomItem.price : 0,
                ecotax: natBomItem.ecoTax ? natBomItem.ecoTax : 0,
                qty: 1,
                weight: natBomItem.weight,
                qtyOfMat: 0,
                comment: '',
                Lbox: Lbx,
                Gbox: Gbx,
                margins: null
            }

            this.bom.bomItems.push(bomItem);
            return bomItem;
        }

        // Get a new IID by incrementing the specified index
        // tag: (String) The IID tag is letter that define a range of consecutive index : 'P' for parts, 'A' for accessories, 'B' for bars and 'D' for drawers.
        _P._getIID = function (tag) {
            var me = this;
            //var idx;
            if (me.IIDIndexes.hasOwnProperty(tag)) {
                // return tag + numeral(++me.IIDIndexes[tag]).format('000');
                const iid = ++me.IIDIndexes[tag];
                if (iid < 10) return `00${iid}`;
                else if (iid < 100) return `0${iid}`;
                else return iid.toString();
            }
            else
                return '';
        }

    })(Writer.prototype);



    /////// Class Reader
    // Brief : Closet reader
    var Reader = function (container, closetModel, dataRepo, report) {
        var me = this;

        me.container = container;
        me.closetModel = closetModel; // ezcClosetModel
        me.closet = null; // Closet Class
        me.report = report || null; // ReadReport class

        me.closetColour = null;
        // me.panelModel = null;
        // me.backPanelModel = null;

        me.readDoorImmediately = false;

        me.dataRepo = dataRepo;
    };

    // Methods
    (function (_P) {

        // Set current closet model. useful if closet model is unknown at contruction time.
        _P.setClosetModel = function (closetModel) { this.closetModel = closetModel; }

        // Read closet description
        // desc: (Object) The closet description (ex. : container.DATA_NODE.main)
        _P.readCloset = async function (desc) {
            var me = this;

            const panelModel = me.closetModel.panelMaterial;
            const backPanelModel = me.closetModel.backPanelMaterial;
            const colour = await me.dataRepo.getColour(desc.colourId ? desc.colourId : panelModel.AllowedIdOfColours[0]);

            var rootZone = desc.root.outerZone;

            me.closet = ezCloset.createCloset(
                me.closetModel,
                rootZone.xdim ? rootZone.xdim : me.closetModel.MinWidth,
                rootZone.ydim ? rootZone.ydim : me.closetModel.MinHeight,
                rootZone.zdim ? rootZone.zdim : (me.closetModel.MaxDepth - me.closetModel.MinDepth) / 2,
                colour,
                panelModel, 
                backPanelModel,
                me.dataRepo);

            await me.readNodeDesc(me.closet.root, desc.root);

            // Read baseboard
            if (desc.bboard) {
                var baseboard = me.closet.getBaseboard();
                baseboard.setDepth(desc.bboard.d);
                baseboard.setHeight(desc.bboard.h);
            }

            // Since 2.3 : read frontColour
            if (desc.ftClr) {
                const clr = await me.dataRepo.getColour(desc.ftClr);
                if (clr) me.closet.frontColour = clr;
            }
        }

        // Read node description in node instance.
        // node: (CNode) the node where description will be loaded.
        // desc: (NodeDesc) The Node description
        _P.readNodeDesc = async function (node, desc) {
            var me = this;
            var i, n, sn, si;

            if (desc.padds) { // Optional paddings
                if (desc.padds.t) node.paddings.top = desc.padds.t;
            }

            if (desc.smgk) { // Optional overriden smart-group key (since EZCD v2.3)
                node.smartGroupKey = desc.smgk;
                node.isUserSG = true;
            }

            if (desc.parts) { // Optional Parts
                await me.readPartDesc(node, desc.parts);
            }

            if (desc.subNodes && desc.subNodes.length === 1) { // "degenerated" node
                sn = node.initSubNodes();
                await me.readNodeDesc(sn, desc.subNodes[0]);
            }
            else if (desc.sepAxis) { // Split
                n = desc.subNodes.length; // Count of sub-nodes

                sn = node.prepareSeparatorAdd(desc.sepAxis); // Can change the parent
                var parentNode = sn.getParentNode();
                for (i = 1; i < n; i++) { // Start by 1 to have count of separator
                    sn = parentNode.addSeparator(sn, true);
                }

                for (i = 0; i < n; i++) {
                    sn = parentNode.subNodes.get(i);
                    if (desc.subNodes[i].outerZone) me.readZoneDesc(sn.outerZone, desc.subNodes[i].outerZone);
                    await me.readNodeDesc(sn, desc.subNodes[i]); // Recurse down the node
                }
            }
            else if (desc.items && desc.items.length > 0) { // Contains sub-items
                n = desc.items.length;

                for (i = 0; i < n; i++) {
                    si = await me.readItemDesc(node, desc.items[i]);
                    if (si) {
                        node.addItem(si);
                        si.attachTo(node);
                    }
                    else {
                        $log.warn("RW: Sub-item of type '" + desc.items[i].ezTyp + "' cannot be readen. it was removed from closet.");
                        if (me.report) me.report.addErr("La lecture d'un élément de type '" + desc.items[i].ezTyp + "' a échoué. Il a été supprimé du meuble");
                    }
                }
            }
            else if (desc.subTree) { // sub-tree detected : support of version prior EZCD 2.2
                await me._readSubTree(node, desc.subTree);
            }
        }

        // Read sub-tree from format oldest than EZCD 2.2
        // node: (CNode) The node that own sub-tree
        // STDesc: (NodeDesc) The sub-tree description
        _P._readSubTree = async function (node, STDesc) {
            var me = this;

            if (STDesc.subNodes) {
                var n = STDesc.subNodes.length;
                if (n === 1) { // Y -> ST_X -> Y[1-] OR  X -> ST_Y -> X[1|]
                    var srcDesc = STDesc.subNodes[0]; // It's Y[1-] OR X[1-]
                    await me.readNodeDesc(node, srcDesc);
                }
                else if (n > 1) { // Y -> ST_X[1|] OR X -> ST_Y[1-]
                    var sn = node.initSubNodes();
                    await me.readNodeDesc(sn, STDesc);
                }

                if (node.parent === null && node.subNodes.getLength() === 1) { // owner of sub-tree is root (X), and has only one sub node as X->Y[n-]
                    var sn = node.subNodes.get(0);
                    sn.schOpts.copy(node.schOpts); // Copy options from root X to single sub-node Y, and clear option of X.
                    node.schOpts.clear();
                }
            }
            
        }

        // Read part description for node
        // node: (CNode) The node that own the parts
        // desc: (PartDesc) The parts description
        _P.readPartDesc = async function (node, desc) {
            var me = this;

            if (desc.left) { // Has left
                if (desc.left.colourId) {
                    var colour = await me.dataRepo.getColour(desc.left.colourId);
                    node.schOpts.defaultLeftClr = colour;
                }
            }

            if (desc.right) { // Has right
                if (desc.right.colourId) {
                    var colour = await me.dataRepo.getColour(desc.right.colourId || 0);
                    node.schOpts.defaultRightClr = colour;
                }
            }

            if (desc.back) { // Has back (only Panel for the moment)
                node.schOpts.hasBackActive = true;
                if (desc.back.colourId) {
                    var colour = await me.dataRepo.getColour(desc.back.colourId);
                    node.schOpts.defaultBackClr = colour;
                }
            }

            if (desc.front) { // Has front
                if (desc.front.ezTyp === 'SWD') {
                    var model = me.dataRepo.getSwingDoor(desc.front.modelId);

                    if (model) { // if model found
                        node.schOpts.hasFrontActive = true;
                        if (desc.front.colourId) {
                            var colour = await me.dataRepo.getColour(desc.front.colourId);
                            node.schOpts.defaultFrontClr = colour;
                        }
                        node.schOpts.setFrontDef('SWD', model);

                        if (!me.closetModel.SwingDoorAllowed) {
                            $log.warn("RW: Swing-door detected on reading, but closet model Id '" + me.closetModel.Id + "' does not allowed swing-door. Swing-door will be removed.");
                            if (me.report) me.report.addWarn("Des portes battantes ont été supprimé car le modèle de meuble ne les autorise pas.");
                        }
                    }
                }
                else if (desc.front.ezTyp === 'PAN') {
                    $log.warn("RW: Front of type 'PAN' not implemented.");
                    if (me.report) me.report.addErr("Les panneaux ne sont pas encore supporté en tant qu'élément frontaux.");
                }
                else {
                    $log.warn("RW: not supported type '" + desc.front.ezTyp + "' for front part of node.");
                    if (me.report) me.report.addErr("Le type '" + desc.front.ezTyp + "' n'est pas supporté en tant qu'élément frontal.");
                }
            }

        }

        // Read zone description in zone instance.
        // zone: (Zone) the zone where description will be loaded.
        // desc: (ZoneDesc) The zone description
        _P.readZoneDesc = function (zone, desc) {
            if (desc.xdim) {
                zone.xdim = desc.xdim;
                zone.hasXDimAuto = zone.xdim <= 0;
            }

            if (desc.ydim) {
                zone.ydim = desc.ydim;
                zone.hasYDimAuto = zone.ydim <= 0;
            }

            if (desc.zdim) {
                zone.zdim = desc.zdim;
                zone.hasZDimAuto = zone.zdim <= 0;
            }
        }

        // Read item owned by node with the specified description
        // node : (CNode) The current owner node
        // desc : (ItemModel) The item description
        // Returns: instance of item according its ezTyp
        _P.readItemDesc = async function (node, desc) {
            var me = this;
            var item = null;

            switch (desc.ezTyp) {
                case 'DRW':
                    var drawerModel, facadeModel, handleModel;
                    drawerModel = me.dataRepo.getDrawerGroup(desc.modelId); //me.getDrawer(desc.modelId);
                    if (drawerModel) {
                        facadeModel = drawerModel.defaultFacade; // me.getMatPanel(drawerModel.DefaultFacadeId);
                        if (facadeModel) {
                            // handleModel = me.getAccessory(desc.handleModelId || 0);
                            item = ezDrawer.$new(drawerModel, facadeModel, null);
                            item.setGroup("2");
                            if (desc.colourId) {
                                var colour =  await me.dataRepo.getColour(desc.colourId); //me.getColour(desc.colourId);
                                item.setFacadeColour(colour);
                            }
                        }
                    }
                    break;

                case 'HGB':
                    var barModel = await me.dataRepo.getHangingBar(desc.modelId); //me.getHangingBar(desc.modelId);
                    if (barModel) {
                        item = ezHangingBar.create(barModel);
                        item.setGroup("3");
                    }
                    break;

                case 'ACC':
                    var accModel = await me.dataRepo.getAccessory(desc.modelId); //me.getAccessory(desc.modelId);
                    if (accModel) {
                        item = ezAccessory.createAccessory(accModel);
                        if (item.setGroup) item.setGroup(accModel.AccType.toString());
                    }
                    break;

                default:
                    $ez.THROW("Not supported ItemModel type '" + itemModel.ezTyp + "'.");
            }

            return item;
        }

        // Get the readen closet. Call readCloset() method before.
        _P.getCloset = function () { return this.closet; }


    })(Reader.prototype);

    // ClipBoard
    var _clipBoard = {
        container: new Container(),
        writer: new Writer(this.container, { withBom: false }),
        reader: new Reader(this.container, null, null),
        buffer: null
    };

    ////// ezRW service
    return {

        $name: 'ezRW',

        // Create container
        createContainer: function () { return new Container(); },

        // Create and return new instance of writer.
        createWriter: function (container, opts) { return new Writer(container, opts); },

        // Create and return new instance of reader.
        createReader: function (container, closetModel, dataRepo, report) { return new Reader(container, closetModel, dataRepo, report); },

        // Create a new report instance to pass to readCloset()
        // Useful to have problem reporting after reading.
        createReadReport: function () { return new ReadReport(); },

        // Copy node to buffer
        // node: (CNode) The node to copy
        // bufferized: (Boolean) If true buffer is saved to the clipboard. Default is false.
        // Returns : the buffer with copied node.
        copy: function (node, bufferized) {
            var buff = {};
            _clipBoard.container.prepareToCopyPaste();
            _clipBoard.writer.writeNode(node, buff);

            if (bufferized) _clipBoard.buffer = buff;
            return buff;
        },

        // Paste buffer in node
        // node: (CNode) The node where to copy buffer
        // buff: (Object) The buffer from previously copy done. If null, try to past from clipBoard buffer.
        paste: async function (node, buff) {
            if (!buff && !_clipBoard.buffer) return;
            await _clipBoard.reader.readNodeDesc(node, buff ? buff : _clipBoard.buffer);
        }
                
    };
}


////// EXPORT
export default {
    $init: _init
}