// import axios, { AxiosStatic } from "axios";
import { IClosetStarter } from "./DataModels/IClosetStarter";
import { IComponentModel } from "./DataModels/IComponentModel";
import { ClosetLayoutCode, MobibamEnv } from "./Common";
import { CachedCollection, ClosetClasses, Mutable } from "./Common";
import { IClosetLayout } from "./DataModels/IClosetLayout";
import IColour from "./DataModels/IColour";
import IPanelModel, { PanelTypes } from "./DataModels/IPanelModel";
import { ITexture } from "./DataModels/ITexture";
import { I3DMaterial } from "./DataModels/I3DMaterial";
import { IThick } from "./DataModels/IThick";
import { ISwingDoor } from "./DataModels/ISwingDoor";
import { IHingeRule } from "./DataModels/IHingeRule";
import { ITiponRule } from "./DataModels/ITiponRule";
import { IHangingBar } from "./DataModels/IHangingBar";
import { IDrawer } from "./DataModels/IDrawer";
import { IMesh } from "./DataModels/IMesh";
import { IAccessory } from "./DataModels/IAccessory";
import ezInjector from "./ezV4/core/ezInjector";
import { IPattern } from "./DataModels/IPattern";
import { IClosetModel } from "./DataModels/IClosetModel";
import { IDataLoader } from "./DataLoader";
import { ISmartPattern } from "./DataModels/ISmartPattern";
import { SmartPattern } from "./SmartPattern";
import { BiblioGroup1_1 } from "../smart/biblio/Group1_1";
import { BiblioGroup1_2 } from "../smart/biblio/Group1_2";
import { BiblioGroup2_1 } from "../smart/biblio/Group2_1";
import { BiblioGroup2_2 } from "../smart/biblio/Group2_2";
import { BiblioGroup3_1 } from "../smart/biblio/Group3_1";
import { BiblioGroup3_2 } from "../smart/biblio/Group3_2";
import { BiblioGroup4_1 } from "../smart/biblio/Group4_1";
import { BiblioGroup4_2 } from "../smart/biblio/Group4_2";
import { BiblioGroup1_3 } from "../smart/biblio/Group1_3";
import { BiblioGroup2_3 } from "../smart/biblio/Group2_3";
import { BiblioGroup3_3 } from "../smart/biblio/Group3_3";
import { BiblioGroup4_3 } from "../smart/biblio/Group4_3";

const DEV_COLOURS: { [key: string]: 1 } = { "9": 1, "8": 1, "11": 1,  "19": 1 };
const STAG_COLOURS: { [key: string]: 1 } = { "8": 1, "74": 1, "10": 1,  "104": 1, "103": 1, "9": 1 };
const PROD_COLOURS: { [key: string]: 1 } = { "8": 1, "24": 1, "127": 1,  "171": 1, "122": 1, "10": 1, "158": 1, "154": 1 };

export interface IDataRepository {

    readonly componentModels: IComponentModel;

    readonly closetStarters: ReadonlyArray<IClosetStarter>;

    readonly closetStartersAreReady: boolean;

    readonly brandId: number;

    readonly bestHandleHeight: number;

    /**
     * Returns the current VAT rate (20 %)
     */
    readonly VATRate: number;

    load(dataLoader: IDataLoader): Promise<void>;

    loadClosetStarters(env: MobibamEnv): Promise<ReadonlyArray<IClosetStarter>>;

    getClosetLayout(planId: number): Promise<IClosetLayout>;

    isActiveColour(colour: IColour): boolean;

    getColour(colourId: number): Promise<IColour>;

    getColoursByPanelModel(panelModel: IPanelModel): Promise<ReadonlyArray<IColour>>;

    getColourCouples(): Promise<ReadonlyArray<{ closet: IColour, door: IColour}>>;

    getPanelModel(id: number): IPanelModel;

    getSwingDoor(id: number): ISwingDoor;

    getHingeRule(id: number): IHingeRule;

    getTiponRule(id: number): ITiponRule;

    getHangingBar(id: number): Promise<IHangingBar>;

    getDrawerGroup(id: number): IDrawer;

    getAccessory(id: number): Promise<IAccessory>;

    getSmartPattern(code: ClosetLayoutCode): ISmartPattern | null;

}

export class DataRepository implements IDataRepository {

    private readonly _env: MobibamEnv;
    private readonly _ez3D: any;
    private _dataLoader!: IDataLoader;
    private _componentModels!: IComponentModel;
    private _smartPatterns: { [key: string]: ISmartPattern } = {} ; //ISmartPattern | null = null;

    private readonly _closetLayouts = new CachedCollection<IClosetLayout>();
    private readonly _textures = new CachedCollection<ITexture>();
    private readonly _3DMaterials = new CachedCollection<I3DMaterial>();
    private readonly _Meshes = new CachedCollection<IMesh>();

    private readonly _coloursByPanelModel: { [key: string]: Array<IColour> } = {};

    private readonly _default3DMaterial: I3DMaterial;
    private readonly _defaultColour: IColour;
    private readonly _defaultThick: IThick;
    private readonly _defaultPanelModel: IPanelModel;
    private readonly _defaultSwingDoor: ISwingDoor;
    private readonly _defaultHingeRule: IHingeRule;
    private readonly _defaultTiponRule: ITiponRule;
    private readonly _defaultHangingBar: IHangingBar;
    private readonly _defaultDrawer: IDrawer;
    private readonly _defaultMesh: IMesh;
    private readonly _defaultAccessory: IAccessory;

    constructor(env: MobibamEnv) {
        this._env = env;
        this._ez3D = ezInjector.get("ez3D");

        ezInjector.get("ezPrice").dataRepo = this; // Set dataRepo to ezPrice service

        this._default3DMaterial = {"Id": -1,"Title":"(default)","Opacity":1,"IsShiny":true,"Shininess":30,"Reflectivity":0.01,"EmissiveIntensity":0 };

        this._defaultColour = {
            Id: -1,
            Ref: "DEFAULT",
            Title: "(default)",
            HasColor: true,
            Color: "0046C0",
            TextureId: 0,
            _3DMaterialId: -1,
            ImageUrl: "",
            PriceCategory: 1,
            texture: {} as ITexture,
            _3DMaterial: {} as I3DMaterial
        }

        this._defaultThick = {
            Id: -1,
            Ref: "(DEFAULT)",
            Value: 1.9,
            Density: 980,
            PriceOfColourCat1: 0,
            PriceOfColourCat2: 0,
            PriceOfColourCat3: 0,
            PriceOfColourCat4: 0,
            PriceOfColourCat5: 0,
            PriceMode: 1
        }

        this._defaultPanelModel = {
            Id: -1,
            Title: "(default)",
            MatType: PanelTypes.CorePanel,
            ThicknessId: -1,
            AllowedIdOfColours: [-1],
            thick: this._defaultThick
        }

        this._defaultSwingDoor = {
            Id: -1,
            Ref: "(DEFAULT)",
            Title: "(default)",
            DoorType: 1,
            PanelMatId: -1,
            BackMargin: 0,
            AddPrice: 0,
            MinWidth: 0,
            MaxWidth: 9999,
            MinHeight: 0,
            MaxHeight: 9999,
            IsOverlay: false,
            HeightOffs: 0,
            SepCollOffs: 0,
            TipOnRuleId: 0,
            HingeRuleId: 0,
            OHingeRuleId: 0,
            HOHingeRuleId: 0,
            panelModel: this._defaultPanelModel,
            tiponRule: null,
            hingeRule: null,
            OHingeRule: null,
            HOHingeRule: null
        }

        this._defaultHingeRule = {
            Id: -1,
            Ref: "(DEFAULT)",
            Title: "(default",
            HingeWidth: 3,
            HingeHeight: 3,
            HingeDepth: 8,
            BottomHingeY: 5,
            TopHingeY: 5,
            Price: 0,
            Weight: 0,
            MinDistFromBottom: 0,
            InterHingeDefs: []
        }

        this._defaultTiponRule = {
            Id: -1,
            Ref: "(DEFAULT)",
            Title: "(default)",
            TipOnWidth: 2,
            TipOnHeight: 2,
            TipOnDepth: 6,
            MaxHeightDoorForSingle: 0,
            Price: 0,
            Weight: 0
        }

        this._defaultHangingBar = {
            Id: -1,
            Ref: "(DEFAULT)",
            Title: "(default)",
            Diameter: 1,
            TopOffset: 10,
            _3DMaterialId: -1,
            Color: "0046C0",
            Price: 0,
            PriceMode: 1,
            WeightPerMeter: 0,
            ImageUrl: "",
            _3DMaterial: this._default3DMaterial
        }

        this._defaultDrawer = {
            Id: -1,
            Ref: "(DEFAULT)",
            Title: "(default)",
            DrawerType: 1,
            DrawerCount: 1,
            DefaultFacadeId: -1,
            DefaultBoxThickness: 0,
            MinWidth: 0,
            MaxWidth: 9999,
            MinHeight: 0,
            MaxHeight: 9999,
            MinDepth: 0,
            MaxDepth: 9999,
            Price: 0,
            PriceMode: 1,
            AddDrawerFacadePrice: false,
            Density: 0,
            ImageUrl: "",
            IsSelected: false,
            defaultFacade: this._defaultPanelModel
        }

        this._defaultMesh = {
            Id: -1,
            Title: "(default)",
            UmbFileUrl: "/api/ezcloset/files?url=%2Fmedia%2F1206%2F69306-69331.obj",
            XDim: 20,
            YDim: 20,
            ZDim: 20,
            HookAsJson: "",
            hook: { xside: "center", yside: "center", zside: "center" }
        } as IMesh;

        this._defaultAccessory = {
            Id: -1,
            AccType: 1,
            Ref: "(DEFAULT)",
            Title: "(default)",
            MeshId: -1,
            _3DMaterialId: -1,
            Color: "0046C0",
            Price: 0,
            PriceMode: 1,
            Weight: 0,
            PositioningAsJson: "",
            positioning: { 
                kindExt: "SFrame",
                onX: { side: "center", minDim: 0, maxDim: 9999, dist: 0, allowLargestContainer: false },
                onY: { side: "center", minDim: 0, maxDim: 9999, dist: 0, allowLargestContainer: false },
                onZ: { side: "center", minDim: 0, maxDim: 9999, dist: 0, allowLargestContainer: false },
                doorColBoxMargins: { bottom: 0 },
                canCollideDoor: false,
                hingeCollisionOffs: 0,
                tiponCollisionOffs: 0
            },
            ImageUrl: "",
            mesh: this._defaultMesh,
            _3DMaterial: this._default3DMaterial
        }
    }

    async load(dataLoader: IDataLoader) { 
        this._dataLoader = dataLoader;
        // this.closetStartersAreReady = false;

        const callBrand = this._dataLoader.loadBestHandleKey(); // Load best handle height
        const callModels = this._dataLoader.loadComponentModels(); // Load all component-models

        const [ bestHandleHeight, componentModels ] = await Promise.all([ callBrand, callModels ]);
        
        this.bestHandleHeight = bestHandleHeight;
        this._componentModels = componentModels;
    }

    async loadClosetStarters(env: MobibamEnv): Promise<ReadonlyArray<IClosetStarter>> {
        this.closetStartersAreReady = false;
        this.closetStarters.length = 0;
        const allClosetStarters = await this._dataLoader.loadClosetStarters();
        for (const closetStarter of allClosetStarters) {
            const code = this._getClosetLayoutCode(closetStarter.PlanId);
            if (code) { 
                (closetStarter as Mutable<IClosetStarter>).code = code;
                this.closetStarters.push(closetStarter);
            }
        }

        this.closetStartersAreReady = true;
        return this.closetStarters;
    }

    get componentModels(): IComponentModel { return this._componentModels }

    readonly closetStarters: IClosetStarter[] = [];

    closetStartersAreReady: boolean = false;

    bestHandleHeight: number = 0;

    readonly brandId: number = 2;

    readonly VATRate: number = 20;

    async getClosetLayout(planId: number): Promise<IClosetLayout> {
        let closetLayout = this._closetLayouts.get(planId);
        if (!closetLayout) {
            closetLayout = await this._dataLoader.loadClosetLayout(planId);
            if (!closetLayout) throw new Error(`Closet layout #${planId} does not exists in backend`);
            (closetLayout as Mutable<IClosetLayout>).Id = planId; // Because EZCloset service does not return the Id !!??
            
            const code = this._getClosetLayoutCode(planId);
            this._initClosetModel(closetLayout.MainModel, code);
            if (closetLayout.LeftModel) this._initClosetModel(closetLayout.LeftModel, code);
            if (closetLayout.RightModel) this._initClosetModel(closetLayout.RightModel, code);

            this._closetLayouts.add(planId, closetLayout);
        }

        return closetLayout;
    }

    /**
     * Convenient method to create a new instance of closet model. Mauly useful in unit test.
     * @param modelClass The class of closet
     */
    createClosetModel(modelClass: ClosetClasses): IClosetModel {
        const model = {
            Id:1,
            Title: "(no title)",
            ModelClass: modelClass,
            SlidingDoorAllowed: false,
            SwingDoorAllowed: false,
            SplitMaxLevel: 0,
            PanelMaterialId: 1,
            BackPanelMaterialId: 2,
            TopBandMaterialId: 1,
            TopBandHeight: 6,
            BottomBandMaterialId: 1,
            BottomBandHeight: 3,
            ConsolidatorId: 0,
            Consolidator: null,
    
            HasBackPriorityOnBottom: false,
            HasBackPriorityOnTop: false,
            BackLROversize: 0,
            BackBTOversize: 0,

            ManufConfigName: "",
    
            AddPrice: 0,
            AddPriceLeft: 0,
            AddPriceRight: 0,
            AddPriceTop: 0,
            AddPriceBottom: 0,
            AddPriceBack: 0,
            AddPriceShelf: 0,
            AddPriceSeparator: 0,
            BaseboardPrice: 0,
    
            MinClosetWidth: 50,
            MaxClosetWidth: 300,
            MinClosetHeight: 50,
            MaxClosetHeight: 250,
            MinClosetDepth: 30,
            MaxClosetDepth: 60,
    
            MinZoneWidth: 15,
            MinZoneHeight: 15,
            MaxZoneWidth: 9999,
            MaxZoneHeight: 9999,
    
            DGWidthBehavior: 1,
            DGHeightBehavior: 1,
    
            FHBackPanelMode: false,
            HasSwingDoorOverlay: false,
            FHSwingDoorMode: false,

            UserCanColourBackPanel: true,
    
            FrontTopMargin: 0,
            FrontBottomMargin: 0,
            FrontLeftMargin: 0,
            FrontRightMargin: 0,
            FrontShelfMargin: 0,
            FrontSeparator0Margin: 0,
            FrontSeparatorMargin: 0,
            BackPanelOffs: 0,
            BottomOffs: 0,
            TopOffs: 0,
            BottomBandOffs: 0,
            TopBandOffs: 0,
            TopLeftMargin: 0,
            TopRightMargin: 0,
    
            BackMaxWidth: 9999,
    
            LeftMargin: 0,
            RightMargin: 0,
            BottomMargin: 0,
            TopMargin: 0,
            BackMargin: 0,
            FrontMargin: 0,
    
            BackLeftMargin: 0,
            BackRightMargin: 0,
            BackBottomMargin: 0,
            BackTopMargin: 0,
            BackShelfMargin: 0,
            BackSeparator0Margin: 0,
            BackSeparatorMargin: 0,
    
            LeftTopMargin: 0,
            RightTopMargin: 0,
    
            DGWidthBehaviorLabel: "",
            DGHeightBehaviorLabel: "",
    
            Extensions: {
                BaseboardMaxDepth: 3.5,
                BottomBandBackOffs: 6.5,
                LeftBandMargin: 0,
                RightBandMargin: 0,
                TopMarginOfBack: 0,
                BottomMarginOfBack: 0
            }

        } as IClosetModel;

        this._initClosetModel(model, undefined);
        return model;
    }

    private _initClosetModel(closetModel: IClosetModel, code: ClosetLayoutCode | undefined) {
        const mutModel = closetModel as Mutable<IClosetModel>;
        mutModel.panelMaterial = this.getPanelModel(closetModel.PanelMaterialId);
        mutModel.backPanelMaterial = this.getPanelModel(closetModel.BackPanelMaterialId);
        mutModel.HasLeft = true;
        mutModel.HasRight = true;
        mutModel.code = code;
        if (code) {
            const smartPattern = this.getSmartPattern(code);
            if (smartPattern) 
                mutModel.emptyPattern = this._createEmptyPattern(closetModel, smartPattern.moduleMaxDim);
            else
                mutModel.emptyPattern = this._createEmptyPattern(closetModel);
        }
        else
            mutModel.emptyPattern = this._createEmptyPattern(closetModel);
    }

    isActiveColour(colour: IColour): boolean {
        switch(this._env) {
            case "STAGING": return STAG_COLOURS[colour.Id] !== undefined;
            case "DEVELOPMENT": return DEV_COLOURS[colour.Id] !== undefined;
            default: return PROD_COLOURS[colour.Id] !== undefined;
        }
    }

    async getColour(id: number): Promise<IColour> {

        let colour = this._componentModels.colours.find(c => c.Id === id);
        if (!colour) {
            colour = this._defaultColour;
            console.warn(`Colour #${id} not found. Use of default colour instead`);
        }

        if (!colour.HasColor && !colour.texture) { // download texture
            let texture = this._textures.get(colour.TextureId);
            if (!texture) {
                try {
                    texture = await this._dataLoader.loadTexture(colour.TextureId);
                    this._textures.add(colour.TextureId, texture);
                    (colour as Mutable<IColour>).texture = texture;

                    // Try to create THREE texture model
                    (texture as Mutable<ITexture>).threeTexture = await this._ez3D.loadTexture(texture.UmbMediaUrl);
                }
                catch {
                    console.warn(`Texture #${colour.TextureId} not found. Use of default instead`);
                    colour = this._defaultColour;
                }
            }
            else 
                (colour as Mutable<IColour>).texture = texture;
        }

        if (!colour._3DMaterial) {
            (colour as Mutable<IColour>)._3DMaterial = await this._get3DMaterial(colour._3DMaterialId);
        }

        return colour;
    }

    async getColoursByPanelModel(panelModel: IPanelModel): Promise<ReadonlyArray<IColour>> {
       let clrsByPM = this._coloursByPanelModel[panelModel.Id];
       if (!clrsByPM) {
            clrsByPM = [];
            for (const clrID of panelModel.AllowedIdOfColours) {
                const clr = await this.getColour(clrID);
                clrsByPM.push(clr);
            }

            this._coloursByPanelModel[panelModel.Id] = clrsByPM;
       }

       return clrsByPM;
    }

    async getColourCouples(): Promise<ReadonlyArray<{ closet: IColour, door: IColour}>> {
        switch(this._env) {
            case "DEVELOPMENT":
                return [
                    // Corps de meuble : Chêne Hamilton / Façades : Blanc Premium
                    { closet: await this.getColour(19), door: await this.getColour(9) },

                    // Corps de meuble : Blanc Premium / Façades : Bleu
                    { closet: await this.getColour(9), door: await this.getColour(8) },
                ];

            case "STAGING":
                return [
                    // Corps de meuble : Chêne Hamilton / Façades : Blanc Premium
                    { closet: await this.getColour(10), door: await this.getColour(8) },

                    // Corps de meuble : Chêne Hamilton / Façades : Gris Ombre
                    { closet: await this.getColour(10), door: await this.getColour(15) },

                    // Corps de meuble : Chêne Hamilton / Façades : Vert Niagara
                    { closet: await this.getColour(10), door: await this.getColour(9) },

                    // Corps de meuble : Noyer Lincoln / Façades : Gris cachemire
                    { closet: await this.getColour(104), door: await this.getColour(74) }
                ];

            default: // PRODUCTION
                return [
                    // Corps de meuble : Chêne Hamilton / Façades : Blanc Premium
                    { closet: await this.getColour(10), door: await this.getColour(8) },

                    // Corps de meuble : Chêne Hamilton / Façades : Gris Ombre
                    { closet: await this.getColour(10), door: await this.getColour(127) },

                    // Corps de meuble : Chêne Hamilton / Façades : Vert Niagara
                    { closet: await this.getColour(10), door: await this.getColour(143) },

                    // Corps de meuble : Noyer Lincoln / Façades : Gris cachemire
                    { closet: await this.getColour(158), door: await this.getColour(24) }
                ];
        }
    }

    getPanelModel(id: number): IPanelModel {
        let panelMat = this._componentModels.panelMaterials.find(c => c.Id === id);
        if (!panelMat) {
            panelMat = this._defaultPanelModel;
            console.warn(`Panel model #${id} not found. Use of default panel model instead`);
        }

        let thick = this._componentModels.thicks.find(t => t.Id === panelMat!.ThicknessId);
        if (!thick) {
            thick = this._defaultThick
            console.warn(`Thick #${id} not found. Use of default thick instead`);
        }
        (panelMat as Mutable<IPanelModel>).thick = thick;
        
        return panelMat;
    }

    getSwingDoor(id: number): ISwingDoor {
        let door = this._componentModels.swingDoors.find((s) => s.Id === id);
        if (!door) {
            door = this._defaultSwingDoor;
            console.warn(`Swing-door model #${id} not found. Use of default swing-door model instead`);
        }

        if (!door.panelModel) {
            (door as Mutable<ISwingDoor>).panelModel = this.getPanelModel(door.PanelMatId);
        }

        if (door.TipOnRuleId !==0 && !door.tiponRule) {
            (door as Mutable<ISwingDoor>).tiponRule = this.getTiponRule(door.TipOnRuleId);
        }

        if (door.HingeRuleId !== 0 && !door.hingeRule) {
            (door as Mutable<ISwingDoor>).hingeRule = this.getHingeRule(door.HingeRuleId);
        }

        if (door.OHingeRuleId !== 0 && !door.OHingeRule) {
            (door as Mutable<ISwingDoor>).OHingeRule = this.getHingeRule(door.OHingeRuleId);
        }

        if (door.HOHingeRuleId !== 0 && !door.HOHingeRule) {
            (door as Mutable<ISwingDoor>).HOHingeRule = this.getHingeRule(door.HOHingeRuleId);
        }

        return door;
    }

    getHingeRule(id: number): IHingeRule {
        let rule = this._componentModels.hingeRules.find((r) => r.Id === id);
        if (!rule) {
            rule = this._defaultHingeRule;
            console.warn(`Hinge-rule model #${id} not found. Use of default hinge-rule model instead`);
        }

        return rule;
    }

    getTiponRule(id: number): ITiponRule {
        let rule = this._componentModels.tiponRules.find((r) => r.Id === id);
        if (!rule) {
            rule = this._defaultTiponRule;
            console.warn(`Tipon-rule model #${id} not found. Use of default Tipon-rule model instead`);
        }

        return rule;
    }

    async getHangingBar(id: number): Promise<IHangingBar> {
        let hb = this._componentModels.hangingBars.find((h) => h.Id === id);
        if (!hb) {
            hb = this._defaultHangingBar;
            console.warn(`Hanging-bar model #${id} not found. Use of default Hanging-bar model instead`);
        }

        if (!hb._3DMaterial) {
            (hb as Mutable<IHangingBar>)._3DMaterial = await this._get3DMaterial(hb._3DMaterialId);
        }

        return hb;
    }

    getDrawerGroup(id: number): IDrawer {
        let drw = this._componentModels.drawerGroups.find((d) => d.Id === id);
        
        if (!drw) {
            drw = this._defaultDrawer;
            console.warn(`Drawer-group model #${id} not found. Use of default Drawer-group model instead`);
        }

        if (!drw.defaultFacade) {
            (drw as Mutable<IDrawer>).defaultFacade = this.getPanelModel(drw.DefaultFacadeId);
        }

        return drw;
    }

    async getAccessory(id: number): Promise<IAccessory> {
        let acc = this._componentModels.accessories.find((a) => a.Id === id);

        if (!acc) {
            acc = this._defaultAccessory;
            console.warn(`Accessory model #${id} not found. Use of default Accessory model instead`);
        }

        if (!acc.positioning) {
            (acc as Mutable<IAccessory>).positioning = JSON.parse(acc.PositioningAsJson);
        }

        if (!acc.mesh) {
            (acc as Mutable<IAccessory>).mesh = await this._getMesh(acc.MeshId);
        }

        if (!acc._3DMaterial) {
            (acc as Mutable<IAccessory>)._3DMaterial = await this._get3DMaterial(acc._3DMaterialId);
        }

        return acc;
    }

    getSmartPattern(code: ClosetLayoutCode): ISmartPattern | null {
        let sp = this._smartPatterns[code] as SmartPattern;

        if (!sp) {
            switch(code) {
                case "BIBLIO":
                    sp = new SmartPattern([{ maxACount: 9, bnds: { min: 200, max: 221 } }, { maxACount: 10, bnds: { min: 221, max: 250} }]);
                    sp.moduleMaxDim = 60;
                    sp.moduleMinDim = 40;
                    sp.smallModule = { indexesByModuleCount: [-1, -1, 0, 1, 1, 2, 2, 2, 3, 3], dim: 25, dimIsRatioOfLarge: false };

                    // Group of module patterns
                    sp.addGroup(BiblioGroup1_1);
                    sp.addGroup(BiblioGroup1_2);
                    sp.addGroup(BiblioGroup1_3);
                    
                    sp.addGroup(BiblioGroup2_1);
                    sp.addGroup(BiblioGroup2_2);
                    sp.addGroup(BiblioGroup2_3);

                    sp.addGroup(BiblioGroup3_1);
                    sp.addGroup(BiblioGroup3_2);
                    sp.addGroup(BiblioGroup3_3);

                    sp.addGroup(BiblioGroup4_1);
                    sp.addGroup(BiblioGroup4_2);
                    sp.addGroup(BiblioGroup4_3);

                    // Styles
                    sp.addStyle({ title: "STYLE 1", groupKeys: [
                        "1.2", 
                        "2.2",
                        "3.2",
                        "1.2",
                        "4.2",
                        "3.2",
                        "1.2",
                        "2.2",
                        "4.2",
                        "3.2",
                        "2.2"
                    ]});

                    this._smartPatterns[code] = sp;
                    break;

                case "DRESS":
                    // TODO
                    break;
            }
        }

        return sp ? sp : null;
    }

    private async _get3DMaterial(id: number): Promise<I3DMaterial> {
        let _3DMat = this._3DMaterials.get(id);

        if (!_3DMat) {
            _3DMat = await this._dataLoader.load3DMaterial(id);
            if (_3DMat) {
                this._3DMaterials.add(id, _3DMat);
            }
            else {
                _3DMat = this._default3DMaterial;
                console.warn(`3D Materials #${id} not found. Use of default material instead`);
            }
        }

        return _3DMat;
    }

    private async _getMesh(id: number): Promise<IMesh> {
        // https://my.mobibam.eu/api/ezcloset/meshes/JW9dS8ExDXWYkJH3w8HYiQ2/14
        let mesh = this._Meshes.get(id);

        if (!mesh) {
            mesh = await this._dataLoader.loadMesh(id);
            if (mesh) {
                (mesh as Mutable<IMesh>).hook = JSON.parse(mesh.HookAsJson);
                this._Meshes.add(id, mesh);
            }
            else {
                mesh = this._defaultMesh;
                console.warn(`Mesh #${id} not found. Use of default mesh instead`);
            }
        }

        if (!mesh.object3D) {
            const objInfo = await this._ez3D.loadObj(mesh.UmbFileUrl);
            const mutMesh = mesh as Mutable<IMesh>;
            mutMesh.object3D = objInfo.object3D; // The triangles from OBJ file

            // Compute the scale according to real dimension specified in backend
            var xsc, ysc, zsc;
            if (objInfo.bndMax && objInfo.bndMin) {
                xsc = mutMesh.XDim / (objInfo.bndMax.x - objInfo.bndMin.x);
                ysc = mutMesh.YDim / (objInfo.bndMax.y - objInfo.bndMin.y);
                zsc = mutMesh.ZDim / (objInfo.bndMax.z - objInfo.bndMin.z);
                mutMesh.bndMin = { x: objInfo.bndMin.x, y: objInfo.bndMin.y, z: objInfo.bndMin.z };
                mutMesh.bndMax = { x: objInfo.bndMax.x, y: objInfo.bndMax.y, z: objInfo.bndMax.z };
            }
            else {
                xsc = 1;
                ysc = 1;
                zsc = 1;
                mutMesh.bndMin = { x: -mutMesh.XDim / 2, y: -mutMesh.YDim / 2, z: -mutMesh.ZDim / 2 };
                mutMesh.bndMax = { x: +mutMesh.XDim / 2, y: +mutMesh.YDim / 2, z: +mutMesh.ZDim / 2 };
            }

            mutMesh.scale = { x: xsc, y: ysc, z: zsc };
        }

        return mesh;
        
    }

    private _createEmptyPattern(closetModel: IClosetModel, moduleMaxDim?: number): IPattern {
        return {
            Id: -2,
            Title: "(empty)",
            JsonData: "",
            ImageUrl: "",
            addSep: { 
                onWidth: moduleMaxDim === undefined ? closetModel.MaxZoneWidth : moduleMaxDim,
                onHeight: closetModel.MaxZoneHeight
            },
            remSep: { 
                onWidth: 40, //closetModel.MinZoneWidth,
                onHeight: 0
            },
            FHBackPanel: false
        }
    }

    private _getClosetLayoutCode(planId: number): ClosetLayoutCode | undefined {
        switch(this._env) {
            case "DEVELOPMENT":
                switch(planId) {
                    case 12: return "BIBLIO";
                    case 20: return "DRESS";
                    default: return undefined;
                }
                
            case "STAGING":
                switch(planId) {
                    case 9: return "BIBLIO";
                    case 43: return "DRESS";
                    default: return undefined;
                }

            case "PRODUCTION":
                switch(planId) {
                    case 20: return "BIBLIO";
                    case 98: return "DRESS";
                    default: return undefined;
                }
        }
    }

}

// function closetModel(closetModel: any): IPattern {
//     throw new Error("Function not implemented.");
// }
