import { ISmartGroup } from "../../engine/DataModels/ISmartPattern"
import biblio_200_220_2_2 from "../../assets/patterns/biblio-200-220-2.2.png";
import biblio_221_250_2_2 from "../../assets/patterns/biblio-221-250-2.2.png";

const GKEY = "2.2";
export const BiblioGroup2_2: ISmartGroup = {
    key: GKEY,
    modulesByInterval: [
        {
            groupKey: GKEY,
            itvalIdx: 0,
            icon: biblio_200_220_2_2,
            zones: [
            { Ac:3, SWD: [0,0] },
            { Ac:1.5 }, 
            { Ac:1.5 }, 
            { Ac:1.5 }, 
            { Ac:1.5 }
        ]},
        {
            groupKey: GKEY,
            itvalIdx: 1,
            icon: biblio_221_250_2_2,
            zones: [
            { Ac:3, SWD: [0, 0] },
            { Ac:1.5 }, 
            { Ac:1.5 }, 
            { Ac:1.5 }, 
            { Ac:1.5 }, 
            { Ac:1 }
        ]}
    ]
}