import { ISmartGroup } from "../../engine/DataModels/ISmartPattern";
import biblio_200_220_3_1 from "../../assets/patterns/biblio-200-220-3.1.png";
import biblio_221_250_3_1 from "../../assets/patterns/biblio-221-250-3.1.png";

const GKEY = "3.1";
export const BiblioGroup3_1: ISmartGroup = {
    key: GKEY,
    modulesByInterval: [
        {
            groupKey: GKEY,
            itvalIdx: 0,
            icon: biblio_200_220_3_1,
            zones: [
                { Ac:1.5 },
                { Ac:1.5 }, 
                { Ac:1 }, 
                { Ac:1 }, 
                { Ac:2 }, 
                { Ac:1 }, 
                { Ac:1 }
            ]},
        {
            groupKey: GKEY,
            itvalIdx: 1,
            icon: biblio_221_250_3_1,
            zones: [
            { Ac:1 },
            { Ac:1 }, 
            { Ac:1 }, 
            { Ac:2 }, 
            { Ac:1 }, 
            { Ac:2 }, 
            { Ac:1 },
            { Ac:1 }
        ]}
    ]
}