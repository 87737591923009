﻿///// Init method for injection
function _init(dcies, $ez, ez3D, ezPrice, ezBOM, ezGPos) {

    var $log = $ez.getLogger();


    /////// Class HangingBar
    // Brief : HangingBar item added in closet zone.
    // Constructor
    // model: (dal.HangingBar) Model of hanging-bar
    var HangingBar = function (model) {
        var me = this;

        me.zone = null;
        me.mesh = null;
        me._mat3D = null;
        me.model = model || null;
        me.ecoTax = 0;
        me.weight = 0;
        me.x = 0;
        me.y = 0;
        me.z = 0;
        me.gx = 0;
        me.gy = 0;
        me.gz = 0;
        me._barLength = 0;
        me._leftExt = 0; // Left extension
        me._rightExt = 0; // right extension
        me._topOffs = 0; // Top offset
        me.bounds = $ez.createBounds();
        me.price = 0;
        me._group = "";
        me._issueStatus = 'OK';
    };
    // Methods
    (function (_P) {

        // Gets or sets left extension of this hanging-bar. Default is 0.
        _P.getLeftExtension = function () { return this._leftExt; }
        _P.setLeftExtension = function (ext) { this._leftExt = ext; }

        // Gets or sets right extension of this hanging-bar. Default is 0.
        _P.getRightExtension = function () { return this._rightExt; }
        _P.setRightExtension = function (ext) { this._rightExt = ext; }

        // Gets or sets top offset of this hanging-bar. Default is Model TopOffset.
        // If specified It will be added to hanging-bar model TopOffset.
        _P.getTopOffset = function () { return this._topOffs; }
        _P.setTopOffset = function (offs) { this._topOffs = offs; }

        // Compute this hanging-bar
        _P.compute = function () {
            var me = this;

            // Position of mesh
            var PZ = me.zone;
            var ga = PZ.closet.getGAng();
            var gp = PZ.closet.getGPos();

            me._barLength = PZ.xdim + me._leftExt + me._rightExt;
            me.x = PZ.x - (PZ.xdim / 2) - me._leftExt + (me._barLength / 2);

            me.y = PZ.y + (PZ.ydim / 2 - me.model.TopOffset - me._topOffs);

            me.z = PZ.z;

            me.gx = ezGPos.toGx(me.x, me.z, ga, gp);
            me.gy = ezGPos.toGy(me.y, gp);
            me.gz = ezGPos.toGz(me.x, me.z, ga, gp);
        }

        _P.setYOffs = function (yOffs) {
            var gp = this.zone.closet.getGPos();
            this.y = this.zone.y + (this.zone.ydim / 2 - yOffs - this._topOffs);
            this.gy = ezGPos.toGy(this.y, gp);
        }

        // Compute 3D of this hanging-bar
        _P.compute3D = function () {
            var me = this;
            var ga = me.zone.closet.getGAng();

            me._issueStatus = 'OK'; // Reset issue if any

            // Create geometry and mesh
            var r = me.model.Diameter / 2;
            var cyl = ez3D.createCylinder(r, me._barLength);
            if (!me.mesh) {
                me._mat3D = ez3D.createMaterial({
                    color: parseInt(me.model.Color, 16),
                    reflectivity: me.model._3DMaterial.Reflectivity,
                    opacity: me.model._3DMaterial.Opacity
                });
                me.mesh = ez3D.createMesh(cyl, me._mat3D);
                me.mesh.rotation.set(0, ga, -Math.PI / 2, 'XYZ');
            }
            else {
                if (me.mesh.geometry) me.mesh.geometry.dispose();
                me.mesh.geometry = cyl;
                me.updateThreeMat();
            }

            me.mesh.position.set(me.gx, me.gy, me.gz);
        }

        // Gets or sets issue status of this panel
        _P.setIssueStatus = function (status) {
            this._issueStatus = status;
            this.updateThreeMat();
        }

        // Update the 3d material.
        _P.updateThreeMat = function () {
            var me = this;
            if (!me._mat3D) return;

            var sceneMode = this.zone.closet.getSceneMode();
            if (sceneMode === 'work' && this._issueStatus !== 'OK') { // Work and error
                me._mat3D.color.setRGB(0xCC / 255, 0, 0);
            }
            else {
                me._mat3D.color.set(parseInt(me.model.Color, 16))
            }
        }

        // Update this hanging bar
        _P.update = function () {
            var me = this;
            me.compute();
            if (ez3D.has3D()) me.compute3D();
        }

        // Get and set current model
        _P.getModel = function () { return this.model; }

        // Set current model
        _P.setModel = function (model) {
            var me = this;
            if (me.mesh) ez3D.remove(me.mesh);
            if (me.mesh && me.mesh.geometry) me.mesh.geometry.dispose();
            me.mesh = null;
            me.model = model;
        }

        // Get length in m of hanging-bar
        _P.getLength = function () { return this._barLength / 100; }

        // Get weight in kg of hanging-bar
        _P.getWeight = function () { return this.model.WeightPerMeter * this.getLength(); }

        // Get ezType
        _P.getEzType = function () { return 'HangingBar' }

        // Gets or set group
        _P.getGroup = function () { return this._group; }
        _P.setGroup = function (g) { this._group = g; }

        // Get Bounds
        _P.getBounds = function () { return this.bounds }

        // Attach to parent node
        _P.attachTo = function (parentNode) { this.zone = parentNode.innerZone; }

        // Display hanging bar
        _P.display = function () {
            if (this.mesh)
                ez3D.add(this.mesh);
        }

        // Hide this hanging-bar
        _P.hide = function () {
            if (this.mesh)
                ez3D.remove(this.mesh);
        }

        // Dispose
        _P.dispose = function () {
            if (this.mesh && this.mesh.geometry) this.mesh.geometry.dispose();
        }

        // BOM filling.
        // bom: (Bom) The BOM to fill.
        _P.fillBOM = function (bom) {
            var me = this;
            var bi = ezBOM.$newItem();


            if (me.model.PriceMode === 3) // linear mode
                bi.price = me.model.Price * me.getLength();
            else if (me.model.PriceMode === 1) // unit mode
                bi.price = me.model.Price;

            bi.price = ezPrice.getSalePrice(bi.price);
            me.price = bi.price;

            bi.title = me.model.Title;
            bi.dims = [me._barLength];
            bi.dimsLabel = '(L)';
            bi.surf = 0;
            bi.weight = me.getWeight();
            bi.ecoTax = ezPrice.getEcoTaxByWeight(bi.weight).Value;

            bom.add(bi);
        }

        // Get box of HangingBar
        _P.getBox = function () {
            var me = this;
            return {
                x: me.x,
                y: me.y,
                z: me.z,
                xdim: me._barLength,
                ydim: me.model.Diameter,
                zdim: me.model.Diameter
            };
        }

    })(HangingBar.prototype);



    ////// ezHangingBar service
    return {
        $name: 'ezHangingBar',

        // Create and return new hanging-bar with the specified model.
        create: function (model) { return new HangingBar(model); }
    };
}


////// EXPORT
export default {
    $init: _init
}