import { IThick } from "./IThick";

export enum PanelTypes {
    CorePanel = 1,
    DrawerFacade = 2,
    BackPanel = 3,
    SlidingDoor = 4,
    SwingDoor = 5                               
}

export default interface IPanelModel {

    readonly Id: number;

    readonly Title: string;

    readonly MatType: PanelTypes;

    readonly ThicknessId: number;

    readonly AllowedIdOfColours: ReadonlyArray<number>;

    //#region Extensions

    readonly thick: IThick;

    //#endregion

}