﻿///// Init method for injection
function _init(dcies, $ez) {

    var $log = $ez.getLogger();

    /////// Class Splitter
    // Brief : Algo to compute zone splitting in several directions
    // Constructor
    var Splitter = function () {};
    // Methods
    (function (_P) {

        // Split a dimension D according to separator thickness and the desired sub-zone count.
        // args : {
        //  D: (number) the dimension to split, i.e the width or height of splitted zone.
        //  zonePos: (number) the position of splitted zone (x or y coordinate).
        //  sepThick: (number) the separators thickness.
        //  szInfos: (array of {dim: (number), pos: (number), auto: (bool) }) the splitted-zones informations. 
        // }
        // Return : {
        //  sepPositions: (array of number) the position of separators along the splitting axis.
        //  szInfos: like args.szInfos. For convenience only.
        // }
        _P.split = function (args) {
            var D = args.D;
            var zonePos = args.zonePos;
            var thick = args.sepThick;
            var szInfos = args.szInfos;
            var szCount = szInfos.length;

            // Results
            var sepPositions = [];

            var i;
            var sepCount = szCount - 1;

            // Compute count and sum of fixed zone
            var sumOfFixed = 0;
            var fixedCount = 0;
            for (i = 0; i < szCount; i++) {
                if (!szInfos[i].auto) {
                    sumOfFixed += szInfos[i].dim;
                    fixedCount++;
                }
            }

            // Compute dim of auto-zone
            var sumOfAuto = (D - (sepCount * thick)) - sumOfFixed;
            var autoZoneDim = sumOfAuto / (szCount - fixedCount);

            // Update the zone infos
            var sumOfDiff = 0; // In case of autozone dim less than minimum of zone
            var sumOfDim = D - (sepCount * thick);
            var sumOfMin = 0;
            var countOfInBound = 0;
            for (i = 0; i < szCount; i++) {
                
                if (szInfos[i].auto) {
                    var minIsRespected = autoZoneDim >= szInfos[i].min;
                    sumOfMin += szInfos[i].min;

                    if (minIsRespected || sumOfMin > sumOfDim) {
                        szInfos[i].dim = autoZoneDim;
                        szInfos[i].dimInBound = true;
                        countOfInBound++;
                    }
                    else {
                        szInfos[i].dim = szInfos[i].min;
                        szInfos[i].dimInBound = false;
                        sumOfDiff += szInfos[i].min - autoZoneDim;
                    }
                }
                else {
                    sumOfMin += szInfos[i].dim;
                }
            }

            // Finally, fix the position if needed, and set position
            var offs = 0;
            var diff = countOfInBound > 0 ? sumOfDiff / countOfInBound : 0; // To distribute on each auto-size zone that respect the bounds
            for (i = 0; i < szCount; i++) {

                if (szInfos[i].dimInBound)
                    szInfos[i].dim -= diff; // Correction of dim

                szInfos[i].pos = zonePos + (-(D / 2) + offs) + (szInfos[i].dim / 2);
                offs += szInfos[i].dim + thick;

                if (i < szCount - 1)
                    sepPositions.push(szInfos[i].pos + (szInfos[i].dim / 2) + (thick / 2));
            }

            return {
                sepPositions: sepPositions,
                szInfos: szInfos
            }
        }

    })(Splitter.prototype);


    ////// ezSplit service
    return {
        $name: 'ezSplit',

        // Create splitter
        createSplitter: function () { return new Splitter(); },

        // Create sub-zone information from zone according to axis.
        // sz: (Zone class) The zone from get infos
        // axis: $ez.X_AXIS, $ez.Y_AXIS or $ez.Z_AXIS
        createSzInfos: function (sz, axis, zbnd) {
            if (sz) {
                if (axis === $ez.X_AXIS)
                    return { dim: sz.xdim, auto: sz.hasXDimAuto, min: zbnd ? zbnd.w : 0 }; // zbnd.width.min : 0 };
                else if (axis === $ez.Y_AXIS)
                    return { dim: sz.ydim, auto: sz.hasYDimAuto, min: zbnd ? zbnd.h : 0 }; // zbnd.height.min : 0 };
                else if (axis === $ez.Z_AXIS)
                    return { dim: sz.zdim, auto: sz.hasZDimAuto, min: 0 }; // zbnd.width.min : 0 };
                else
                    throw new Error("[EZCloset] Unsupported orientation '" + axis + "'. Cannot get splitted-zone information.");
            }
            else {
                return { dim: null, auto: true };
            }
        },

        // Update zone according the split result of split along X axis. 
        // args: {
        //  splittedZone: (Zone) The splitted zone.
        //  szInfo: Array item of splitRes.szInfos returned by Splitter.split() method.
        //  sz: (Zone) the zone to update. Result of splitting.
        // }
        updateZoneOfXSplit: function (args) {
            var Z = args.splittedZone;
            var szInfo = args.szInfo;
            var sz = args.sz;

            sz.xdim = szInfo.dim;
            sz.ydim = Z.ydim;
            sz.zdim = Z.zdim;
            sz.x = szInfo.pos;
            sz.y = Z.y;
            sz.z = Z.z;
            sz.hasXDimAuto = szInfo.auto;
            sz.hasYDimAuto = Z.hasYDimAuto;
            sz.hasZDimAuto = Z.hasZDimAuto;            
        },

        // Update zone according the split result of split along Y axis. 
        // args: {
        //  splittedZone: (Zone) The splitted zone.
        //  szInfo: Array item of splitRes.szInfos returned by Splitter.split() method.
        //  sz: (Zone) the zone to update.
        //}
        updateZoneOfYSplit: function (args) {
            var Z = args.splittedZone;
            var szInfo = args.szInfo;
            var sz = args.sz;

            sz.xdim = Z.xdim;
            sz.ydim = szInfo.dim;
            sz.zdim = Z.zdim;
            sz.x = Z.x;
            sz.y = szInfo.pos;
            sz.z = Z.z;
            sz.hasXDimAuto = Z.hasXDimAuto;
            sz.hasYDimAuto = szInfo.auto;
            sz.hasZDimAuto = Z.hasZDimAuto;
        },

        // Update zone according the split result of split along Z axis. 
        // args: {
        //  splittedZone: (Zone) The splitted zone.
        //  szInfo: Array item of splitRes.szInfos returned by Splitter.split() method.
        //  sz: (Zone) the zone to update.
        //}
        updateZoneOfZSplit: function (args) {
            var Z = args.splittedZone;
            var szInfo = args.szInfo;
            var sz = args.sz;

            sz.xdim = Z.xdim;
            sz.ydim = Z.ydim;
            sz.zdim = szInfo.dim;
            sz.x = Z.x;
            sz.y = Z.y;
            sz.z = szInfo.pos;
            sz.hasXDimAuto = Z.hasXDimAuto;
            sz.hasYDimAuto = Z.hasYDimAuto;
            sz.hasZDimAuto = szInfo.auto;
        },

        // Get the dimension of auto-zone according to dimension of split zone, and count of separator
        getAutoZoneDim: function (D, sepCount, sepDim) {
            return (D - (sepCount * sepDim)) / (sepCount + 1);
        }
    };
}


////// EXPORT
export default {
    $init: _init
}