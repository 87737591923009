﻿//brief : Injector to manage service DI.
// Advantages : check argument matching. Support of argument minification.

// Map of registred services
var _map = {};

export default {

    // Return service by name. Service must be regsitred with register() method before.
    get: function (name) {
        var _registred = _map[name];
        if (!_registred) throw new Error("Cannot get registred service '" + name + "'. Not found in injector.");
        return _registred;
    },

    has: function(name) { return _map[name] ? true : false },

    // Register service with its name and dependencies.
    // name: (String) Name of service
    // svc : (Service) Service object with $init() method. $init() is responsible to create service instance. Service instance must have $name property.
    // dependencies: (Array of String) Array of service dependencies if any.
    register: function (name, svc, dependencies) {
        if (_map[name])
            throw new Error("Service '" + name + "' is already registred.");

        if (!svc.$init)
            throw new Error("Service '" + name + "' must implement method $init().");

        var dcies = dependencies || [];
        //var initParamCount =  svc.$init.length === 0 ? 1 /* mimic empty dpcies array */ : svc.$init.length;
        if (svc.$init.length !== dcies.length + 1)
            throw new Error("Service '" + name + "' $init() method, must have " + (dcies.length + 1) + " argument(s).");

        var prms = [];
        prms.push(dcies);
        for (var i = 0; i < dcies.length; i++) {
            var p = this.get(dcies[i]);
            prms.push(p);
        }

        var s = svc.$init.apply(svc, prms); // setup factory in injector
        if (!s.$name)
            throw new Error("Service instance '" + name + "' must implement property $name.");

        _map[name] = s; // register
    },

    // Check arguments of $init() methods. Use this method at begining of $init().
    // args: (Object) The arguments local variable from $init() method.
    checkArgs: function (args) {
        var dcies = args[0];
        var n = args.length, i;
        for (i = 1 ; i < n; i++) {
            if (dcies[i - 1] !== args[i].$name)
                throw new Error("Service name dependency/argument no match : dependency => " + dcies[i - 1] + ", injected => " + args[i].$name + ".");
        }
    }

    // Return Vue plugin. Useful to inject services in Vue components.
    // getVuePlugin: function () {
    //     var me = this;

    //     return {

    //         install: function (Vue, options) {
    //             Vue.mixin({
    //                 created: function () {
    //                     if (this.$options.dpcies) {
    //                         var dpcies = this.$options.dpcies;
    //                         for (var d in dpcies) {
    //                             if (this[d]) throw new Error("Conflict detected when injecting service in component : property '" + d + "' already exists.");
    //                             this[d] = me.get(dpcies[d]);
    //                         }
    //                     }
    //                 }
    //             });
    //         }

    //     }
    // }
}