import { ISmartGroup } from "../../engine/DataModels/ISmartPattern";
import biblio_200_220_1_2 from "../../assets/patterns/biblio-200-220-1.2.png";
import biblio_221_250_1_2 from "../../assets/patterns/biblio-221-250-1.2.png";

const GKEY = "1.2";
export const BiblioGroup1_2: ISmartGroup = {
    key: GKEY,
    modulesByInterval: [
        {
            groupKey: GKEY,
            itvalIdx: 0,
            icon: biblio_200_220_1_2,
            zones: [
            { Ac:3, SWD: [1,1,1] },
            { Ac:1 }, 
            { Ac:1 }, 
            { Ac:1 }, 
            { Ac:1 },
            { Ac:1 },
            { Ac:1 }
        ]},
        {
            groupKey: GKEY,
            itvalIdx: 1,
            icon: biblio_221_250_1_2,
            zones: [
            { Ac:3, SWD: [1,1,1] },
            { Ac:1 }, 
            { Ac:1 }, 
            { Ac:1 }, 
            { Ac:1 },
            { Ac:1 },
            { Ac:1 },
            { Ac:1 }
        ]}
    ]
}