﻿///// Init method for injection
function _init(dcies, $ez) {

    var $log = $ez.getLogger();

    /////// Empty provider singleton
    var _emptyProvider = null;


    /////// Schema helper
    var _schHelper = {

        // Return true if closet model has top band
        hasTopBand: function (closetModel) {
            return (closetModel.TopBandHeight && closetModel.TopBandMaterialId && closetModel.TopBandHeight > 0 && closetModel.TopBandMaterialId !== 0);
        },

        // Return true if closet model has bottom band
        hasBottomBand: function (closetModel) {
            return (closetModel.BottomBandHeight && closetModel.BottomBandMaterialId && closetModel.BottomBandHeight > 0 && closetModel.BottomBandMaterialId !== 0);
        }
    };


    /////// Class CNSchemaOptions
    // Brief : Transform base schema with options like back activation.
    // Constructor
    var CNSchemaOptions = function () {
        var me = this;

        // State of part : result of user action
        me.hasBackActive = false;
        me.hasFrontActive = false;
        me.frontDef = null;
        me.frontMat = null; // For drawer-facade material

        me.defaultBackClr = null;
        me.defaultFrontClr = null;

        me.colour = null; // To pass colour from old version read
    };
    // Methods
    (function (_P) {

        // Copy other options in this options.
        _P.copy = function (other) {
            var me = this;
            me.hasBackActive = other.hasBackActive;
            me.hasFrontActive = other.hasFrontActive;
            me.frontMat = other.frontMat;
            if (other.frontDef) {
                me.frontDef = {
                    EZT: other.frontDef.EZT,
                    model: other.frontDef.model
                };
            }
            me.defaultBackClr = other.defaultBackClr;
            me.defaultFrontClr = other.defaultFrontClr;
        }

        // Clear all options
        _P.clear = function () {
            var me = this;

            me.hasBackActive = false;
            me.hasFrontActive = false;
            me.frontDef = null;
            me.frontMat = null; // For drawer-facade material

            me.defaultBackClr = null;
            me.defaultFrontClr = null;
        }

        // Set front definition of this options
        // ezt: (String) EZType of front element (ex. 'SWD').
        // model: (Object) Model of front element like is stored in ezcFactory.
        _P.setFrontDef = function (ezt, model) {
            var me = this;
            me.frontDef = {
                EZT: ezt,
                model: model
            };
        }

    })(CNSchemaOptions.prototype);


    /////// Class CNSchema
    // Brief : Describe parts of CNode (Closet-Node-Schema)
    // Constructor
    var CNSchema = function () {
        var me = this;
        me.key = ''; // [ABORTED]

        var b = false;
        me.HasLeft = b;
        me.HasRight = b;
        me.HasBottom = b;
        me.HasTop = b;
        me.HasBack = b;
        me.HasFront = b;
        me.HasTopBand = b;
        me.HasBottomBand = b;

        me.LeftMargin = 0; // Distance of left part from left closet side
        me.RightMargin = 0; // Distance of right part from right closet side
        me.BottomMargin = 0; // Distance of bottom part from bottom closet side
        me.TopMargin = 0; // Distance of top part from top closet side
        me.BackMargin = 0; // Distance of back part from back closet side
        me.FrontMargin = 0; // Distance of front or door part from front closet side

        // To resolve priority in same schema.
        me.HasTopPriority = b;
        me.HasBottomPriority = b;
        //me.HasBackPriority = b; // replaced by HasBackPriorityOnBottom and HasBackPriorityOnTop            
        me.HasBackPriorityOnBottom = b;
        me.HasBackPriorityOnTop = b;
    };
    // Methods
    (function (_P) {

        // Return true if schema has part activated
        // opts: (CNSchemaOptions) The options of schema.
        _P.hasBack = function (opts) { return this.HasBack && opts.hasBackActive }
        _P.hasFront = function (opts) { return this.HasFront && opts.hasFrontActive }

        // Return margin according to hasXXX() method result
        // opts: (CNSchemaOptions) The options of schema.
        _P.backMargin = function (opts) { return this.hasBack(opts) ? this.BackMargin : 0 }
        _P.frontMargin = function (opts) { return this.hasFront(opts) ? this.FrontMargin : 0 }

        // Fuse this schema and other schema and return the fused schema.
        // This method return a new instance and does not modify this schema.
        // o: (CNSchema) The other schema. If not specified, the method return reference to this.
        _P.fuse = function (o) {
            var me = this;
            if (!o) return me;

            var fused = new CNSchema();
            fused.key = me.key + o.key;

            fused.HasLeft = me.HasLeft || o.HasLeft;
            fused.HasRight = me.HasRight || o.HasRight;
            fused.HasBottom = me.HasBottom || o.HasBottom;
            fused.HasTop = me.HasTop || o.HasTop;
            fused.HasBack = me.HasBack || o.HasBack;
            fused.HasFront = me.HasFront || o.HasFront;
            fused.HasTopBand = me.HasTopBand || o.HasTopBand;
            fused.HasBottomBand = me.HasBottomBand || o.HasBottomBand;

            fused.LeftMargin = me.LeftMargin + o.LeftMargin;
            fused.RightMargin = me.RightMargin + o.RightMargin;
            fused.BottomMargin = me.BottomMargin + o.BottomMargin;
            fused.TopMargin = me.TopMargin + o.TopMargin;
            fused.BackMargin = me.BackMargin + o.BackMargin;
            fused.FrontMargin = me.FrontMargin + o.FrontMargin;

            fused.HasTopPriority = me.HasTopPriority || o.HasTopPriority;
            fused.HasBottomPriority = me.HasBottomPriority || o.HasBottomPriority;
            fused.HasBackPriorityOnBottom = me.HasBackPriorityOnBottom || o.HasBackPriorityOnBottom;
            fused.HasBackPriorityOnTop = me.HasBackPriorityOnTop || o.HasBackPriorityOnTop;

            return fused;
        }

    })(CNSchema.prototype);


    /////// Class CNSchemaProvider
    // Brief : Responsible of schema providing to CNode.
    // Constructor
    var CNSchemaProvider = function () {
        var me = this;
        me.schema0 = null;
        me.schema1 = null;
        me.schemaN = null;
        me.axis0 = $ez.X_AXIS;
        me.zoneShown = true;
    };
    // Methods
    (function (_P) {

        _P.initSchemas = function (schema0, schema1, schemaN) {
            if (!schema0) $ez.THROW("schema0 must be specified.");
            var me = this;
            me.schema0 = schema0;
            me.schema1 = schema1 ? schema1 : null;
            me.schemaN = schemaN ? schemaN : null;
            me._initFusedSchemas(schema0, schema1, schemaN);

            //me._initSchemaN(schema1);
        }

        _P._initFusedSchemas = function (schema0, schema1, schemaN) {
            var me = this;
            me.schema01 = schema0.fuse(schema1);
            me.schema01N = schema0.fuse(schema1).fuse(schemaN);
            me.schema1N = me.schema1 ? me.schema1.fuse(me.schemaN) : me.schemaN;
        }

        // Get schemas
        _P.getSchema0 = function () { return this.schema0; }
        _P.getSchema1 = function () { return this.schema1; }
        _P.getSchemaN = function () { return this.schemaN; }

        // Get the fused schemas
        _P.getSchema01N = function () { return this.schema01N; }
        _P.getSchema01 = function () { return this.schema01; }
        _P.getSchema1N = function () { return this.schema1N; }

    })(CNSchemaProvider.prototype);


    /////// Class provider
    var _classProvider = {
        
        // Get CLASS1 provider
        getCLASS1: function (closetModel) {
            var CLASS1;

            var schema0 = new CNSchema();
            schema0.key = '0';
            schema0.HasLeft = closetModel.HasLeft;
            schema0.HasRight = closetModel.HasRight;
            schema0.HasBottom = true;
            schema0.HasTop = true;
            schema0.HasBottomPriority = true;
            schema0.HasTopPriority = true;
            schema0.LeftMargin = 0;
            schema0.RightMargin = 0;
            schema0.BottomMargin = closetModel.BottomOffs || 0;
            schema0.TopMargin = closetModel.TopOffs || 0;

            schema0.HasBackPriorityOnBottom = closetModel.HasBackPriorityOnBottom;
            schema0.HasBackPriorityOnTop = closetModel.HasBackPriorityOnTop;

            schema0.HasTopBand = _schHelper.hasTopBand(closetModel);
            schema0.HasBottomBand = _schHelper.hasBottomBand(closetModel);

            var schema1 = new CNSchema();
            schema1.key = '1';
            schema1.HasBack = true;
            schema1.BackMargin = closetModel.BackPanelOffs;

            var schemaN = null;
            if (!closetModel.FHBackPanelMode) { // Not Full-height back panel                               
                schemaN = new CNSchema();
                schemaN.key = 'N';
                schemaN.HasBack = true;
                schemaN.BackMargin = closetModel.BackPanelOffs;
            }

            if (closetModel.SwingDoorAllowed) {
                schema1.HasFront = true;

                if (!closetModel.FHSwingDoorMode) { // Not Full-height swing-door
                    if (schemaN === null) {
                        schemaN = new CNSchema();
                        schemaN.key = 'N';
                    }
                    schemaN.HasFront = true;
                }

            }

            CLASS1 = new CNSchemaProvider();
            CLASS1.initSchemas(schema0, schema1, schemaN);

            return CLASS1;
        },

        // Get CLASS2 provider
        getCLASS2: function (closetModel) {
            var CLASS2;

            CLASS2 = new CNSchemaProvider();

            var schema0 = new CNSchema();
            schema0.key = '0';
            schema0.HasLeft = closetModel.HasLeft;
            schema0.HasRight = closetModel.HasRight;

            var schema1 = new CNSchema();
            schema1.key = '1';
            schema1.HasBottom = true;
            schema1.HasTop = true;
            schema1.TopMargin = closetModel.TopOffs;
            schema1.BottomMargin = closetModel.BottomOffs;
            schema1.HasBack = true;
            schema1.BackMargin = closetModel.BackPanelOffs;
            schema1.HasBackPriorityOnBottom = closetModel.HasBackPriorityOnBottom;
            schema1.HasBackPriorityOnTop = closetModel.HasBackPriorityOnTop;

            schema1.HasTopBand = _schHelper.hasTopBand(closetModel);
            schema1.HasBottomBand = _schHelper.hasBottomBand(closetModel);

            var schemaN = null;
            if (!closetModel.FHBackPanelMode) { // Not Full-height back panel
                schemaN = new CNSchema();
                schemaN.key = 'N';
                schemaN.HasBack = true;
                schemaN.BackMargin = closetModel.BackPanelOffs;
            }

            if (closetModel.SwingDoorAllowed) {
                schema1.HasFront = true;

                if (!closetModel.FHSwingDoorMode) { // Not Full-height swing-door
                    if (schemaN === null) {
                        schemaN = new CNSchema();
                        schemaN.key = 'N';
                    }
                    schemaN.HasFront = true;
                }
            }

            CLASS2.initSchemas(schema0, schema1, schemaN);

            return CLASS2;
        },

        // Get CLASS3 provider
        getCLASS3: function (closetModel) {
            var CLASS3;

            var schema0 = new CNSchema();
            schema0.key = '0';
            schema0.HasLeft = closetModel.HasLeft;
            schema0.HasRight = closetModel.HasRight;
            schema0.HasBottom = true;
            schema0.HasTop = true;
            schema0.HasBottomPriority = false;
            schema0.HasTopPriority = true;
            schema0.TopMargin = closetModel.TopOffs || 0;
            schema0.BottomMargin = closetModel.BottomOffs || 0;

            schema0.HasBackPriorityOnBottom = closetModel.HasBackPriorityOnBottom;
            schema0.HasBackPriorityOnTop = closetModel.HasBackPriorityOnTop;

            schema0.HasTopBand = _schHelper.hasTopBand(closetModel);
            schema0.HasBottomBand = _schHelper.hasBottomBand(closetModel);

            var schema1 = new CNSchema();
            schema1.key = '1';
            schema1.HasBack = true;
            schema1.BackMargin = closetModel.BackPanelOffs;
            
            var schemaN = null;
            if (!closetModel.FHBackPanelMode) { // Not Full-height back panel
                schemaN = new CNSchema();
                schemaN.key = 'N';
                schemaN.HasBack = true;
                schemaN.BackMargin = closetModel.BackPanelOffs;
            }

            if (closetModel.SwingDoorAllowed) {
                schema1.HasFront = true;

                if (!closetModel.FHSwingDoorMode) { // Not Full-height swing-door

                    if (schemaN === null) {
                        schemaN = new CNSchema();
                        schemaN.key = 'N';
                    }
                    schemaN.HasFront = true;
                }
            }

            CLASS3 = new CNSchemaProvider();
            CLASS3.initSchemas(schema0, schema1, schemaN);

            return CLASS3;
        },

        // Get CLASS4 provider
        getCLASS4: function (closetModel) {
            var CLASS4;

            var schema0 = new CNSchema();
            schema0.key = '0';
            schema0.HasTop = true;
            schema0.HasLeft = closetModel.HasLeft;
            schema0.HasRight = closetModel.HasRight;
            schema0.HasTopPriority = true;
            schema0.TopMargin = closetModel.TopOffs || 0;
            schema0.HasTopBand = _schHelper.hasTopBand(closetModel);
            schema0.HasBackPriorityOnTop = closetModel.HasBackPriorityOnTop;

            var schema1 = new CNSchema();
            schema1.key = '1';
            schema1.HasBottom = true;
            schema1.BottomMargin = closetModel.BottomOffs || 0;
            schema1.HasBottomBand = _schHelper.hasBottomBand(closetModel);
            schema1.HasBackPriorityOnBottom = closetModel.HasBackPriorityOnBottom;
            schema1.HasBack = true;
            schema1.BackMargin = closetModel.BackPanelOffs;
            //schema1.HasBackPriorityOnBottom = true; // TODO : must be an option. Compatibility Mobibam
            //schema1.HasBackPriorityOnTop = true; // TODO : must be an option. Compatibility Mobibam

            var schemaN = null;
            if (!closetModel.FHBackPanelMode) { // Not Full-height back panel
                schemaN = new CNSchema();
                schemaN.key = 'N';
                schemaN.HasBack = true;
                schemaN.BackMargin = closetModel.BackPanelOffs;
            }

            if (closetModel.SwingDoorAllowed) {
                schema1.HasFront = true;

                if (!closetModel.FHSwingDoorMode) { // Not Full-height swing-door
                    if (schemaN === null) {
                        schemaN = new CNSchema();
                        schemaN.key = 'N';
                    }
                    schemaN.HasFront = true;
                }
            }

            CLASS4 = new CNSchemaProvider();
            CLASS4.initSchemas(schema0, schema1, schemaN);

            return CLASS4;
        },

        // Get CLASS5 provider
        getCLASS5: function (closetModel) {
            var CLASS5;

            var schema0 = new CNSchema();
            schema0.key = '0';
            schema0.HasLeft = closetModel.HasLeft;
            schema0.HasRight = closetModel.HasRight;
            schema0.HasBottom = true;
            schema0.HasTop = true;
            schema0.HasBottomPriority = false;
            schema0.HasTopPriority = false;
            schema0.LeftMargin = 0;
            schema0.RightMargin = 0;
            schema0.BottomMargin = closetModel.BottomOffs || 0;
            schema0.TopMargin = closetModel.TopOffs || 0;

            schema0.HasTopBand = _schHelper.hasTopBand(closetModel);
            schema0.HasBottomBand = _schHelper.hasBottomBand(closetModel);

            var schema1 = new CNSchema();
            schema1.key = '1';
            schema1.HasBack = true;
            schema1.BackMargin = closetModel.BackPanelOffs;

            var schemaN = null;
            if (!closetModel.FHBackPanelMode) { // Not Full-height back panel
                schemaN = new CNSchema();
                schemaN.key = 'N';
                schemaN.HasBack = true;
                schemaN.BackMargin = closetModel.BackPanelOffs;
            }

            if (closetModel.SwingDoorAllowed) {
                schema1.HasFront = true;

                if (!closetModel.FHSwingDoorMode) { // Not Full-height swing-door
                    if (schemaN === null) {
                        schemaN = new CNSchema();
                        schemaN.key = 'N';
                    }
                    schemaN.HasFront = true;
                }
            }

            CLASS5 = new CNSchemaProvider();
            CLASS5.initSchemas(schema0, schema1, schemaN);

            return CLASS5;
        },

        // Get CLASS50 provider
        getCLASS50: function (closetModel) {
            var CLASS50;

            var schema0 = new CNSchema();
            schema0.key = '0';

            CLASS50 = new CNSchemaProvider();
            CLASS50.initSchemas(schema0, null, null);

            return CLASS50;
        },

        // Get CLASS200 provider
        getCLASS200: function (closetModel) {
            var CLASS200;

            CLASS200 = new CNSchemaProvider();
            CLASS200.axis0 = $ez.Y_AXIS;

            var schema0 = new CNSchema();
            schema0.key = '0';
            schema0.HasBottom = true;
            schema0.HasTop = true;
            schema0.HasTopPriority = true;
            schema0.HasBottomPriority = true;
            schema0.TopMargin = closetModel.TopOffs;
            schema0.BottomMargin = closetModel.BottomOffs;

            var schema1 = new CNSchema();
            schema1.key = '1';
            schema1.HasLeft = false; // TYSON
            schema1.HasRight = false; // TYSON
            schema1.LeftMargin = 0; // TYSON
            schema1.RightMargin = 0; // TYSON
            schema1.HasTopBand = _schHelper.hasTopBand(closetModel);
            schema1.HasBottomBand = _schHelper.hasBottomBand(closetModel);
            schema1.HasBack = true;
            schema1.BackMargin = closetModel.BackPanelOffs;
            schema1.HasBackPriorityOnBottom = closetModel.HasBackPriorityOnBottom;
            schema1.HasBackPriorityOnTop = closetModel.HasBackPriorityOnTop;

            var schemaN = null;
            if (!closetModel.FHBackPanelMode) { // Not Full-height back panel
                schemaN = new CNSchema();
                schemaN.key = 'N';
                schemaN.HasBack = true;
                schemaN.BackMargin = closetModel.BackPanelOffs;
            }

            if (closetModel.SwingDoorAllowed) {
                schema1.HasFront = true;

                if (!closetModel.FHSwingDoorMode) { // Not Full-height swing-door
                    if (schemaN === null) {
                        schemaN = new CNSchema();
                        schemaN.key = 'N';
                    }
                    schemaN.HasFront = true;
                }
            }

            CLASS200.initSchemas(schema0, schema1, schemaN);

            return CLASS200;
        }

    };



    ////// ezSchema service
    return {
        $name: 'ezSchema',

        // Create new instance of schema
        $new: function () { return new CNSchema(); },

        // Create new instance of schema
        $newProvider: function () { return new CNSchemaProvider(); },

        // Create new schema options
        $newOptions: function () { return new CNSchemaOptions(); },

        // Create new schemas provider with the specified schemas.
        createProvider: function (schema0, schema1, schemaN) {
            var sp = new CNSchemaProvider();
            sp.initSchemas(schema0, schema1, schemaN);
            return sp;
        },

        // Get schema-provider according to closet model
        // closetClass: (String) The closet class, ex 'CLASS1', 'CLASS2', etc ...
        // closetModel: (ezcClosetModel) The closet model.
        getProvider: function (closetClass, closetModel) {
            switch (closetClass) {
                case 'CLASS1':
                case 'FramHorPrior':
                    closetModel.ModelClass = 'CLASS1';
                    return _classProvider.getCLASS1(closetModel);

                case 'CLASS2':
                case 'FullVerPrior':
                    closetModel.ModelClass = 'CLASS2';
                    return _classProvider.getCLASS2(closetModel);

                case 'CLASS3': return _classProvider.getCLASS3(closetModel);
                case 'CLASS4': return _classProvider.getCLASS4(closetModel);
                case 'CLASS5': return _classProvider.getCLASS5(closetModel);
                case 'CLASS50': return _classProvider.getCLASS50(closetModel);
                case 'CLASS200': return _classProvider.getCLASS200(closetModel);
                default: throw new Error("[EZCloset] Unknown closet class '" + closetClass + "'. Cannot get schema provider.");
            }
        },

        // Return an empty provider. Useful for sub-layout node.
        emptyProvider: function () {
            if (!_emptyProvider)
                _emptyProvider = this.createProvider(this.$new(), null, null);
            return _emptyProvider;
        }
    };
}


////// EXPORT
export default {
    $init: _init
}