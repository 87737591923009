﻿///// Init method for injection
function _init(dcies, $ez) {

    ////// ezGPos service
    return {
        $name: 'ezGPos',

        // Create new instance of position
        // Returns : (Object) { x: 0, y: 0, z:0 }
        $new: function () { return { x: 0, y: 0, z: 0 } },

        // Convert X closet coordinate to ground X coordinate
        // x: (Number) The x closet coordinate
        // z: (Number) The z closet coordinate
        // ga: (Number) The closet ground angle defined from main Y rotation axis
        // gp: (Object) The closet ground position defined from main XYZ axis : ex: {x: 0, y:0, z:0 }
        toGx: function (x, z, ga, gp) {
            return (x * Math.cos(ga) + z * Math.sin(ga)) + gp.x;
        },

        // Convert Y closet coordinate to ground Y coordinate
        // y: (Number) The y closet coordinate
        // gp: (Object) The closet ground position defined from main XYZ axis : ex: {x: 0, y:0, z:0 }
        toGy: function (y, gp) { return y + gp.y },

        // Convert Z closet coordinate to ground Z coordinate
        // x: (Number) The x closet coordinate
        // z: (Number) The z closet coordinate
        // ga: (Number) The closet angle defined from main Y rotation axis
        // gp: (Object) The closet ground position defined from main XYZ axis : ex: {x: 0, y:0, z:0 }
        toGz: function (x, z, ga, gp) {
            return (-x * Math.sin(ga) + z * Math.cos(ga)) + gp.z;
        },

        // Convert coordinates to ground coordinates
        // coord: (Object) The coordinates. Must have x, y and z properties.
        // ga: (Number) The closet angle defined from main Y rotation axis
        // gp: (Object) The closet ground position defined from main XYZ axis : ex: {x: 0, y:0, z:0 }
        // Returns: none, coord is directly modified by this method.
        toGCoord: function (coord, ga, gp) {
            var x = coord.x, y = coord.y, z = coord.z;
            coord.x = this.toGx(x, z, ga, gp);
            coord.y = this.toGy(y, gp);
            coord.z = this.toGz(x, z, ga, gp);
        },

        // Get ground coordinate value of closet position. Useful to compute position of closet from reference closet.
        // Cd: (Number) The dimension of reference closet. ex. : closet.getWidth()
        // g: (Number) The coordinate value of ground position of reference closet. ex. : closet.getGPos().x
        // CDir: (Number) The direction coef of reference closet. ex. : +1
        // Pd: (Number) The dimension of reference closet. ex. : otherCloset.getWidth()
        // CDir: (Number) The direction coef of positioned closet. ex. : -1
        getGCoordValue: function (Cd, g, Cdir, Pd, Pdir) { return g + (Cd / 2 * Cdir) + (Pd / 2 * Pdir); },

        // Convert box to ground box.
        // box: (Object) The local box : { x, y, z, xdim, ydim, zdim }
        // ga: (Number) The closet angle defined from main Y rotation axis
        // gp: (Object) The closet ground position defined from main XYZ axis : ex: {x: 0, y:0, z:0 }
        // Returns: (Object) The ground box : { x, y, z, xdim, ydim, zdim }
        toGBox: function (box, ga, gp) {
            var me = this;

            // half dimensions
            var xdim2 = box.xdim / 2;
            var ydim2 = box.ydim / 2;
            var zdim2 = box.zdim / 2;

            // Conversion of corner box
            var bcorn1 = { x: box.x - xdim2, y: box.y - ydim2, z: box.z - zdim2 };
            var bcorn2 = { x: box.x + xdim2, y: box.y + ydim2, z: box.z + zdim2 };
            me.toGCoord(bcorn1, ga, gp);
            me.toGCoord(bcorn2, ga, gp);

            return {
                x: me.toGx(box.x, box.z, ga, gp),
                y: me.toGy(box.y, gp),
                z: me.toGz(box.x, box.z, ga, gp),
                xdim: Math.abs(bcorn1.x - bcorn2.x),
                ydim: Math.abs(bcorn1.y - bcorn2.y),
                zdim: Math.abs(bcorn1.z - bcorn2.z)
            };            
        }

    }

}

////// EXPORT
export default {
    $init: _init
}