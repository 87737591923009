﻿///// Init method for injection
function _init(dcies, $ez, ez3D, ezPrice, ezBOM, ezPanel, ezGPos) {

    var $log = $ez.getLogger();

    /////// Class SwingDoorAssoc
    // Brief : to manage side-by-side swing-door association. Useful for overlay door.
    // Constructor
    // thickProvider: (ThickProvider) The thickness provider.
    var SwingDoorAssoc = function (thickProvider) {
        var me = this;
        me._map = {};
        me._TP = thickProvider;
        me._invalidated = false;
    };
    // Methods
    (function (_P) {

        // Create a map key for the specified door. The key is created with the CNode.CUID that contains the door
        // node : (CNode) The node that contains the door.
        _P._makeKey = function (node) { return "n" + node.CUID; }

        // Add swing-door in collection and compute association with other door in map
        // node : (CNode) The node that contains the swing-door.
        // Returns: Array of associate doors.
        _P.add = function (node) {
            var me = this;

            // The map key
            var key = me._makeKey(node);
            if (me._map[key])
                $ez.THROW("Swing-door of node id" + node.CUID + " already exists in association map.");

            // Create a map item
            var item = { k: key, n: node, L: {}, R: {}, B: {}, T: {} };

            // Resolve association
            var associates = [];
            for (var k in me._map) {
                if (me._resolveAssoc(item, me._map[k]))
                    associates.push(me._map[k].n);
            }

            me._map[key] = item;
            return associates;
        }

        // Get associations of door owned by node. This methods do not add the node in map.
        // Useful to make check operation.
        // node : (CNode) The node that contains the swing-door.
        // Returns: item object acsociate with this node.
        _P.getAssociations = function (node) {
            var me = this;

            // The map key
            var key = me._makeKey(node);

            // Create a map item
            var item = { k: key, n: node, L: {}, R: {}, B: {}, T: {} };

            // Resolve association
            for (var k in me._map) {
                me._resolveAssoc(item, me._map[k], true);
            }

            return item;
        }

        // Remove swing-door from collection. All association are cleaned.
        // node : (CNode) The node that own the door
        // Returns: Array of associated door nodes.
        _P.remove = function (node) {
            var me = this;
            var k;
            var associates = [];

            // The map key
            var key = me._makeKey(node);

            // Get Item
            var item = me._map[key];
            if (!item) return associates; // Does not exists in map

            // Delete item from all other right assoc
            for (k in item.L) { // Each left item assoc match right assoc in other item
                var otherItem = item.L[k];
                delete otherItem.R[key];
                associates.push(otherItem.n);
            }

            // Delete item from all other left assoc
            for (k in item.R) {
                var otherItem = item.R[k];
                delete otherItem.L[key];
                associates.push(otherItem.n);
            }

            // Delete item from all other top assoc
            for (k in item.B) {
                var otherItem = item.B[k];
                delete otherItem.T[key];
                associates.push(otherItem.n);
            }

            // Delete item from all other bottom assoc
            for (k in item.T) {
                var otherItem = item.T[k];
                delete otherItem.B[key];
                associates.push(otherItem.n);
            }

            // finally delete door entry
            delete me._map[key];

            return associates;
        }

        // Invalidate association. Next call to hasAssoc raise an update of association resolution.
        _P.invalidate = function () { this._invalidated = true; }

        // Update all door. Useful on zone dimension change for example.
        _P.updateDoors = function () {
            var me = this;
            if (me._invalidated) me._updateAssoc();

            for (var k in me._map) {
                var item = me._map[k];
                item.n.parts.front.update();
            }
        }

        // Returns: true if door d has association on side s, false otherwise.
        // n: (CNode) The node owner of the door that need to check association on specified side.
        // s: (String) The side : 'L', 'R', 'B' or 'T'.
        _P.hasAssoc = function (n, s) {
            var me = this;
            if (me._invalidated) me._updateAssoc();

            // The map key
            var key = me._makeKey(n);

            // Get Item
            var item = me._map[key];
            if (!item)
                $ez.THROW("Cannot find association for door node id" + n.CUID + ".");

            // Result
            return Object.keys(item[s]).length > 0;
        }

        // Recompute all associations.
        _P._updateAssoc = function () {
            var me = this;
            var k1, k2, rItem, oItem;

            // Initialize associations
            for (k1 in me._map) {
                rItem = me._map[k1];
                rItem.L = {};
                rItem.R = {};
                rItem.B = {};
                rItem.T = {};
            }

            // Resolution
            for (k1 in me._map) {
                rItem = me._map[k1];

                for (k2 in me._map) {
                    if (k1 !== k2) {
                        oItem = me._map[k2];
                        me._resolveAssoc(rItem, oItem);
                    }
                }
            }

            me._invalidated = false;
        }

        // Try to resolve association of refItem with otherItem
        // refItem: (Item) The reference item
        // otherItem: (Item) The other item that potentially associate with item.
        // noReciprocity: (Bool) If true, the reciprocity is not recorded in map (must be true for checking method)
        // Returns: (bool) true if association found.
        _P._resolveAssoc = function (refItem, otherItem, noReciprocity) {
            var me = this;
            var prec = 1000; // precision


            // ref item rectangle
            var rZone = refItem.n.paddingZone || refItem.n.outerZone;
            var rRect = {
                xmin: Math.round((rZone.x - (rZone.xdim / 2) - me._TP.getLeft() / 2) * prec) / prec,
                xmax: Math.round((rZone.x + (rZone.xdim / 2) + me._TP.getRight() / 2) * prec) / prec,
                ymin: Math.round((rZone.y - (rZone.ydim / 2) - me._TP.getBottom() / 2) * prec) / prec,
                ymax: Math.round((rZone.y + (rZone.ydim / 2) + me._TP.getTop() / 2) * prec) / prec,
            };

            // other item rectangle
            var oZone = otherItem.n.paddingZone || otherItem.n.outerZone;
            var oRect = {
                xmin: Math.round((oZone.x - (oZone.xdim / 2) - me._TP.getLeft() / 2) * prec) / prec,
                xmax: Math.round((oZone.x + (oZone.xdim / 2) + me._TP.getRight() / 2) * prec) / prec,
                ymin: Math.round((oZone.y - (oZone.ydim / 2) - me._TP.getBottom() / 2) * prec) / prec,
                ymax: Math.round((oZone.y + (oZone.ydim / 2) + me._TP.getTop() / 2) * prec) / prec,
            };

            var assocFound = false;

            // resolve left of ref
            if (Math.abs(rRect.xmin - oRect.xmax) <= me._TP.getLeft() && me._isCommonOnY(rRect, oRect)) {
                refItem.L[otherItem.k] = otherItem; // Association refItem -> otherItem on L
                if (!noReciprocity) otherItem.R[refItem.k] = refItem; // Reciprocity
                assocFound = true;
            } // resolve right of ref
            else if (Math.abs(rRect.xmax - oRect.xmin) <= me._TP.getRight() && me._isCommonOnY(rRect, oRect)) {
                refItem.R[otherItem.k] = otherItem; // Association refItem -> otherItem on L
                if (!noReciprocity) otherItem.L[refItem.k] = refItem; // Reciprocity
                assocFound = true;
            }

            // resolve bottom of ref
            if (Math.abs(rRect.ymin - oRect.ymax) <= me._TP.getBottom() && me._isCommonOnX(rRect, oRect)) {
                refItem.B[otherItem.k] = otherItem; // Association refItem -> otherItem on L
                if (!noReciprocity) otherItem.T[refItem.k] = refItem; // Reciprocity
                assocFound = true;
            } // resolve top of ref
            else if (Math.abs(rRect.ymax - oRect.ymin) <= me._TP.getTop() && me._isCommonOnX(rRect, oRect)) {
                refItem.T[otherItem.k] = otherItem; // Association refItem -> otherItem on L
                if (!noReciprocity) otherItem.B[refItem.k] = refItem; // Reciprocity
                assocFound = true;
            }

            return assocFound;
        }

        // Return true if x coordinates of rRect and oRect have common segment
        _P._isCommonOnX = function (rRect, oRect) {
            var amin, amax, bmin;
            if (rRect.xmin < oRect.xmin) {
                amin = rRect.xmin;
                amax = rRect.xmax;
                bmin = oRect.xmin;
            }
            else {
                amin = oRect.xmin;
                amax = oRect.xmax;
                bmin = rRect.xmin;
            }

            return amin <= bmin && bmin <= amax;
        }

        // Return true if y coordinates of rRect and oRect have common segment
        _P._isCommonOnY = function (rRect, oRect) {
            var amin, amax, bmin;
            if (rRect.ymin < oRect.ymin) {
                amin = rRect.ymin;
                amax = rRect.ymax;
                bmin = oRect.ymin;
            }
            else {
                amin = oRect.ymin;
                amax = oRect.ymax;
                bmin = rRect.ymin;
            }

            return amin <= bmin && bmin <= amax;
        }
        
    })(SwingDoorAssoc.prototype);


    /////// Class SwingDoorSymbol
    // Brief : to visualize swing-door in design mode.
    // Constructor
    var SwingDoorSymbol = function () {
        var me = this;
        me.symbol = null;
    };
    // Methods
    (function (_P) {

        // Update according to door-panel and opening orient.
        // DP : (Panel) The swing-door panel.
        // orient: (Number) 1 or 2 as defined in DoorType property of swing-door model.
        // sceneMod: (String) The current scene mode 'work' or 'rendering'
        _P.update = function (DP, orient, sceneMod) {
            var me = this;
            me.hide();

            var geom;

            var ga = DP.parentZone.closet.getGAng();
            var gp = DP.parentZone.closet.getGPos();

            // Panel position
            var px = DP.x;
            var py = DP.y;
            var pz = DP.z + DP.zdim / 2 + 0.2;

            // Box dim
            var xDim = DP.xdim - 0.2;
            var yDim = DP.ydim - 0.2;

            // Rectangle
            var rect = [];
            rect.push(ez3D.createVector3(px - xDim / 2, py - yDim / 2, pz));
            rect.push(ez3D.createVector3(px + xDim / 2, py - yDim / 2, pz));
            rect.push(ez3D.createVector3(px + xDim / 2, py + yDim / 2, pz));
            rect.push(ez3D.createVector3(px - xDim / 2, py + yDim / 2, pz));
            ezGPos.toGCoord(rect[0], ga, gp);
            ezGPos.toGCoord(rect[1], ga, gp);
            ezGPos.toGCoord(rect[2], ga, gp);
            ezGPos.toGCoord(rect[3], ga, gp);

            // Opening orientation
            var olpnts = me._getOrientationLinePoints(orient, px, py, pz, xDim, yDim, ga, gp);

            if (!me.symbol) {
                var _symbolLineMat = ez3D.getSwingDoorSymbolMat();
                me.symbol = ez3D.createObject3D();

                // Orientation line #1
                geom = ez3D.createGeometry();
                geom.setFromPoints([olpnts[0], olpnts[1]]);
                me.symbol.add(ez3D.createLine(geom, _symbolLineMat));

                // Orientation line #2
                geom = ez3D.createGeometry();
                geom.setFromPoints([olpnts[2], olpnts[3]]);
                me.symbol.add(ez3D.createLine(geom, _symbolLineMat));

                // Rectangle
                geom = ez3D.createGeometry();
                geom.setFromPoints([rect[0], rect[1], rect[2], rect[3], rect[0]]);
                me.symbol.add(ez3D.createLine(geom, _symbolLineMat));
            }
            else {
                // Orientation line #1
                geom = me.symbol.children[0].geometry;
                geom.setFromPoints([olpnts[0], olpnts[1]]);

                // Orientation line #2
                geom = me.symbol.children[1].geometry;
                geom.setFromPoints([olpnts[2], olpnts[3]]);

                // Rectangle
                geom = me.symbol.children[2].geometry;
                geom.setFromPoints([rect[0], rect[1], rect[2], rect[3], rect[0]]);
            }

            if (sceneMod === 'work') me.display();
        }

        // Get the opening orientation points of lines
        // orient: (Number) 1 for 'open on left', or 2 for 'open on right'.
        // return : (Array of THREE.Vector3) [0] 1st point of line #1, [1] last point of line #1. [2] 1st point of line #2, [3] last point of line #2.
        _P._getOrientationLinePoints = function (orient, px, py, pz, xDim, yDim, ga, gp) {
            var pnts = [];
            if (orient === 1) {
                // P1
                pnts.push(ez3D.createVector3(px - xDim / 2, py - yDim / 2, pz));
                pnts.push(ez3D.createVector3(px + xDim / 2, py, pz));

                // P2
                pnts.push(ez3D.createVector3(px - xDim / 2, py + yDim / 2, pz));
                pnts.push(ez3D.createVector3(px + xDim / 2, py, pz));
            }
            else if (orient === 2) {
                // P1
                pnts.push(ez3D.createVector3(px + xDim / 2, py - yDim / 2, pz));
                pnts.push(ez3D.createVector3(px - xDim / 2, py, pz));

                // P2
                pnts.push(ez3D.createVector3(px + xDim / 2, py + yDim / 2, pz));
                pnts.push(ez3D.createVector3(px - xDim / 2, py, pz));
            }
            else {
                $ez.THROW("Unsuppported opening orientation '" + orient + "'. Cannot get orientation points.");
            }

            for (var i = 0; i < pnts.length; i++)
                ezGPos.toGCoord(pnts[i], ga, gp)
            return pnts;
        }

        // Display
        _P.display = function () {
            var me = this;
            if (me.symbol) ez3D.add(me.symbol);
        }

        // Hide
        _P.hide = function () {
            var me = this;
            if (me.symbol) ez3D.remove(me.symbol);
        }

        // Dispose
        _P.dispose = function () {
            var me = this;
            if (me.symbol) {
                for (var i = 0; i < me.symbol.children.length; i++)
                    me.symbol.children[i].geometry.dispose();
                me.symbol = null;
            }
        }
        
    })(SwingDoorSymbol.prototype);



    /////// Class HingeGroup
    // Brief : Group of hinges associate to door and computed according to hinge-rule.
    // Constructor
    // swingDoor: (SwingDoor) The swing-door that owns hinges.
    // ruleModel: (dal.HingeRule) The rule to apply hinge on door.
    // orient: (Number) 1 or 2 as defined in DoorType property of swing-door model.
    // heightOffs: (Number) Hinge geight offset used to avoid opposite conflicts
    var HingeGroup = function (swingDoor, ruleModel, orient, heightOffs) {
        var me = this;
        me.swingDoor = swingDoor || null;
        me.positions = []; // Array of {x,y,z};
        me.GPositions = []; // Array of {x,y,z} for ground positions;
        me.cuids = []; // Array of CUID
        me.ruleModel = ruleModel || null;
        me.geom = null;
        me.meshes = [];
        me.orient = orient;
        me.heightOffs = heightOffs;
        me.kindExt = null;
    };
    // Methods
    (function (_P) {

        _P.getInterHingeDef = function (h) {
            const interHinges = this.ruleModel.InterHingeDefs;
            for (let i = interHinges.length - 1; i >= 0; i--) {
                if (interHinges[i].MinHeight < h)
                    return interHinges[i];
            }
            return null;
        }

        // Compute the group of hinges
        _P.compute = function () {
            var me = this;
            var pan = me.swingDoor.panels[0]; // Must be updated
            var swModel = me.swingDoor.getModel();
            var swZone = me.swingDoor.owner.paddingZone || me.swingDoor.owner.outerZone;
            var closet = me.swingDoor.owner.closet;
            var x, y, z;
            var i = 0;
            me.positions = [];
            me.GPositions = [];
            me.cuids = [];

            var ga = closet.getGAng();
            var gp = closet.getGPos();

            // Consider left and right thickness and margins if parts are present in schema (possible at root)
            var LThick = 0, RThick = 0;
            if (me.swingDoor.owner.schema) {
                var sch = me.swingDoor.owner.schema;
                var TP = closet.getThickProvider();
                if (sch.HasLeft) LThick = TP.getLeft() + sch.LeftMargin;
                if (sch.HasRight) RThick = TP.getRight() + sch.RightMargin;
            }

            // x of hinges
            if (me.orient === 1) // Left hinges
                x = swZone.x - (swZone.xdim / 2) + (me.ruleModel.HingeWidth / 2) + LThick;
            else if (me.orient === 2) // Right hinges
                x = swZone.x + (swZone.xdim / 2) - (me.ruleModel.HingeWidth / 2) - RThick;
            else
                $ez.THROW("Unsupported door-type '" + swModel.DoorType + "'. Cannot update hinge-group.");

            // z of hinges
            z = pan.z - (pan.zdim / 2) - (me.ruleModel.HingeDepth / 2);

            // Compute bottom hinge
            y = pan.y - (pan.ydim / 2) + me.ruleModel.BottomHingeY;
            me.positions.push({ x: x, y: y + me.heightOffs, z: z });
            me.cuids.push(closet.getCUID());

            // Compute intermediate hinge if any
            var hdef = me.getInterHingeDef(pan.ydim);
            if (hdef) {
                // Height to distribute
                var H = pan.ydim - me.ruleModel.BottomHingeY - me.ruleModel.TopHingeY - me.ruleModel.HingeHeight;

                // Interval between each hinge
                var I = (H - (hdef.Qty * me.ruleModel.HingeHeight)) / (hdef.Qty + 1);

                // height of hinge / 2
                var hdiv2 = me.ruleModel.HingeHeight / 2;

                y += hdiv2; // to compute consecutive position from bottom hinge
                for (i = 0; i < hdef.Qty; i++) {
                    y += I + hdiv2;

                    me.positions.push({ x: x, y: y - me.heightOffs, z: z });
                    me.cuids.push(closet.getCUID());

                    y += hdiv2;
                }
            }

            // Compute top hinge
            y = pan.y + (pan.ydim / 2) - me.ruleModel.TopHingeY;
            me.positions.push({ x: x, y: y - me.heightOffs, z: z });
            me.cuids.push(closet.getCUID());

            // compute ground positions
            for (i = 0; i < me.positions.length; i++) {
                var pos = me.positions[i];
                me.GPositions.push({
                    x: ezGPos.toGx(pos.x, pos.z, ga, gp),
                    y: ezGPos.toGy(pos.y, gp),
                    z: ezGPos.toGz(pos.x, pos.z, ga, gp)
                });
            }
        }

        // Compute 3D
        _P.compute3D = function (issueStatus) {
            var me = this;
            var pos;
            var ga = me.swingDoor.owner.closet.getGAng();

            me.hide();
            me._prepareMeshUpdate(issueStatus);

            if (!me.geom)
                me.geom = ez3D.createBox(me.ruleModel.HingeWidth, me.ruleModel.HingeHeight, me.ruleModel.HingeDepth);

            // Compute position of meshes
            var n = me.GPositions.length;
            for (var i = 0 ; i < n ; i++) {
                pos = me.GPositions[i];
                me._setMesh(i, pos.x, pos.y, pos.z, ga);
            }

            me._clearLostMeshes();
            me.display();
        }

        // Update the goup of hinges
        _P.update = function (issueStatus) {
            var me = this;
            me.compute();
            if (ez3D.has3D()) me.compute3D(issueStatus);
        }

        // Prepare update of meshes : set flag isLost to true.
        _P._prepareMeshUpdate = function (issueStatus) {
            var me = this;
            for (var i = 0; i < me.meshes.length; i++) {
                me.meshes[i].isLost = true;
                if (issueStatus) me.meshes[i].mesh.material = ez3D.getHingeMat(issueStatus);
            }
        }

        // Set mesh of index i associated with hinge position
        _P._setMesh = function (i, x, y, z, ga) {
            var me = this;
            var m;
            if (i >= me.meshes.length) {
                m = new ez3D.createMesh(me.geom, ez3D.getHingeMat("OK"));
                m.position.set(x, y, z);
                me.meshes.push({ mesh: m, isLost: false });
            }
            else {
                m = me.meshes[i].mesh;
                m.position.set(x, y, z);
                me.meshes[i].isLost = false;
            }
            m.rotation.set(0, ga, 0);
        }

        // Set issue status
        _P.setIssueStatus = function (i, status) {
            if (ez3D.has3D())
                this.meshes[i].mesh.material = ez3D.getHingeMat(status);
        }

        // Get positions of hinges. First item of array is bottom hinge, and last item is top hinges.
        // Returns : (Array) [{ x: Number, y: Number, z: Number }, ...]
        _P.getPositions = function () { return this.positions; }

        // Set Y position of hinge. GPosition is updated too.
        // used by ezValidator to fix hinge position
        // i: (Number) The index of hinge
        // y: (Number) The new Y coordinate 
        _P.setYPos = function (i, y) {
            var me = this;
            var gp = me.swingDoor.owner.closet.getGPos();
            me.positions[i].y = y;
            me.GPositions[i].y = ezGPos.toGy(y, gp);
        }

        // clear lost meshes
        _P._clearLostMeshes = function () {
            var me = this;
            var newMeshes = [];
            for (var i = 0; i < me.meshes.length; i++) {
                var item = me.meshes[i];
                if (!item.isLost)
                    newMeshes.push(item);
            }

            me.meshes = newMeshes;
        }

        // display
        _P.display = function () {
            var me = this;
            for (var i = 0; i < me.meshes.length; i++)
                ez3D.add(me.meshes[i].mesh);
        }

        // hide
        _P.hide = function () {
            var me = this;
            for (var i = 0; i < me.meshes.length; i++)
                ez3D.remove(me.meshes[i].mesh);
        }

        // dispose
        _P.dispose = function () {
            var me = this;
            if (me.geom) {
                me.geom.dispose();
                me.geom = null;
            }
        }

        // BOM filling.
        _P.fillBOM = function (bom) {
            var me = this;

            var n = me.positions.length;

            var bi = ezBOM.$newItem();
            bi.title = n + " x charnière(s) " + me.ruleModel.Ref;
            bi.dims = [me.ruleModel.HingeWidth, me.ruleModel.HingeHeight, me.ruleModel.HingeDepth];
            bi.dimsLabel = '(l x h x p)';
            bi.surf = 0;
            bi.ecoTax = ezPrice.getEcoTaxByWeight(me.ruleModel.Weight).Value * n;
            bi.price = ezPrice.getSalePrice(me.ruleModel.Price) * n;
            bi.weight = me.ruleModel.Weight * n;

            bom.add(bi);
        }

    })(HingeGroup.prototype);



    /////// Class TipOns
    // Brief : Group of tip-on associate to door and computer according to tip-on-rule
    // Constructor
    // swingDoor: (SwingDoor) The swing-door that owns hinges.
    // ruleModel: (dal.TiponRule) The rule to apply tip-on to door.
    var TipOns = function (swingDoor, ruleModel) {
        var me = this;
        me.CUID = swingDoor.owner.getCloset().getCUID();
        me.swingDoor = swingDoor || null;
        me.tipOn1 = { pos: null, gpos: null, cuid: 0, mesh: null };
        me.tipOn2 = { pos: null, gpos: null, cuid: 0, mesh: null };
        me.ruleModel = ruleModel || null;
        me.geom = null;
    };
    (function (_P) {

        // Get Y position of tip-on at top or bottom of inner-zone.
        _P.getYPos = function (zone, yside) {
            
            if (yside === 'top')
                return zone.y + (zone.ydim / 2) - (this.ruleModel.TipOnHeight / 2);
            else if (yside === 'bottom')
                return zone.y - (zone.ydim / 2) + (this.ruleModel.TipOnHeight / 2);
            else
                $ez.THROW("Not supported yside '" + yside + "'.");
        }

        // Get X position of tip-on according its index.
        _P.getXPos = function (zone, i) {
            if (i === 0)
                return zone.x - (this.ruleModel.TipOnWidth / 2);
            else if (i === 1)
                return zone.x + (this.ruleModel.TipOnWidth / 2);
            else
                $ez.THROW("Tip-on index " + i + " out-of-bounds.");
        }

        // Get Zone used to compute tip-on position
        _P.getPosZone = function () { return this.swingDoor.owner.paddingZone || this.swingDoor.owner.outerZone; }

        // Compute the group of tip-ons
        _P.compute = function () {
            var me = this;
            var panelCount = me.swingDoor.getPanelCount();
            var pan = me.swingDoor.panels[0]; // Must be updated
            var swModel = me.swingDoor.getModel();
            var swZone = me.getPosZone();
            var closet = me.swingDoor.owner.closet;
            var x, y, z;
            var i = 0;

            var ga = closet.getGAng();
            var gp = closet.getGPos();

            // Consider left, right, top thickness and margins if parts are present in schema (possible at root)
            var LThick = 0, RThick = 0, TThick = 0;
            if (me.swingDoor.owner.schema) {
                var sch = me.swingDoor.owner.schema;
                var TP = closet.getThickProvider();
                if (sch.HasLeft) LThick = TP.getLeft() + sch.LeftMargin;
                if (sch.HasRight) RThick = TP.getRight() + sch.RightMargin;
                if (sch.HasTop) TThick = TP.getTop() + sch.TopMargin;
            }

            // x,y of tip-on 1
            if (swModel.DoorType === 1) {// Left hinges
                x = swZone.x + (swZone.xdim / 2) - (me.ruleModel.TipOnWidth / 2) - RThick; // On the right (Opposite of hinges)
                y = pan.y;
            }
            else if (swModel.DoorType === 2) { // Right hinges
                x = swZone.x - (swZone.xdim / 2) + (me.ruleModel.TipOnWidth / 2) + LThick; // On the left (Opposite of hinges)
                y = pan.y;
            }
            else if (swModel.DoorType === 3) { // Double door
                x = swZone.x - (me.ruleModel.TipOnWidth / 2) - RThick; // On right of left-door
                y = swZone.y + (swZone.ydim / 2) - (me.ruleModel.TipOnWidth / 2) - TThick;
            }
            else 
                $ez.THROW("Unsupported door-type '" + swModel.DoorType + "'. Cannot update tip-ons.");

            // z of tip-ons
            z = pan.z - (pan.zdim / 2) - (me.ruleModel.TipOnDepth / 2);

            // Compute 1st door tip-on
            me.tipOn1.pos = { x: x, y: y, z: z };
            me.tipOn1.gpos = {
                x: ezGPos.toGx(x, z, ga, gp),
                y: ezGPos.toGy(y, gp),
                z: ezGPos.toGz(x, z, ga, gp)
            };
            me.tipOn1.cuid = closet.getCUID();

            if (panelCount > 1) { // Compute 2nd door tip-on
                x = swZone.x + (me.ruleModel.TipOnWidth / 2) + LThick;
                me.tipOn2.pos = { x: x, y: y, z: z };
                me.tipOn2.gpos = {
                    x: ezGPos.toGx(x, z, ga, gp),
                    y: ezGPos.toGy(y, gp),
                    z: ezGPos.toGz(x, z, ga, gp)
                };
                me.tipOn2.cuid = closet.getCUID();
            }
            else {
                me.tipOn2.pos = null; // Set to null if no tip-on No2
                me.tipOn2.gpos = null;
            }

        }

        // Compute 3D
        _P.compute3D = function (issueStatus) {
            var me = this;
            var pos;
            var ga = me.swingDoor.owner.closet.getGAng();

            me.hide();

            if (!me.geom)
                me.geom = ez3D.createBox(me.ruleModel.TipOnWidth, me.ruleModel.TipOnHeight, me.ruleModel.TipOnDepth);

            //var mat = ez3D.getHingeMat(issueStatus); // Get Material

            // Tip-on No1
            if (!me.tipOn1.mesh) {
                me.tipOn1.mesh = new ez3D.createMesh(me.geom, ez3D.getHingeMat(issueStatus));
            }
            else {
                if (issueStatus) me.tipOn1.mesh.material = ez3D.getHingeMat(issueStatus);
            }
            me.tipOn1.mesh.position.set(me.tipOn1.gpos.x, me.tipOn1.gpos.y, me.tipOn1.gpos.z);
            me.tipOn1.mesh.rotation.set(0, ga, 0);

            // Has tip-on No 2 ?
            if (me.tipOn2.pos) {
                if (!me.tipOn2.mesh) {
                    me.tipOn2.mesh = new ez3D.createMesh(me.geom, ez3D.getHingeMat(issueStatus));
                }
                else {
                    if (issueStatus) me.tipOn2.mesh.material = ez3D.getHingeMat(issueStatus);
                }
                me.tipOn2.mesh.position.set(me.tipOn2.gpos.x, me.tipOn2.gpos.y, me.tipOn2.gpos.z);
                me.tipOn2.mesh.rotation.set(0, ga, 0);
            }
            else
                me.tipOn2.mesh = null; // Free mesh

            me.display();
        }

        // Update tip-ons
        _P.update = function (issueStatus) {
            var me = this;
            me.compute();
            if (ez3D.has3D()) me.compute3D(issueStatus);
        }

        // Set issue status
        _P.setIssueStatus = function (status) {
            if (ez3D.has3D()) {
                var mat = ez3D.getHingeMat(status);
                this.tipOn1.mesh.material = mat;
                if (this.tipOn2.mesh.material) this.tipOn2.mesh.material = mat;
            }
        }

        // Set Y position of tip-ons #1 and #2. GPosition is updated too.
        // used by ezValidator to fix tip-on position
        // y: (Number) The new Y coordinate of tip-on #1 
        _P.setYPos = function (y) {
            var me = this;
            var gp = me.swingDoor.owner.closet.getGPos();
            me.tipOn1.pos.y = y;
            me.tipOn1.gpos.y = ezGPos.toGy(y, gp);

            if (me.tipOn2.pos) { // Has tip-on #2
                me.tipOn2.pos.y = y;
                me.tipOn2.gpos.y = me.tipOn1.gpos.y;
            }
        }

        // Set X position of tip-ons i. GPosition is updated too.
        // used by ezValidator to fix tip-on position in case of double-door
        // x: (Number) The new X coordinate of tip-on i
        // i: (Number) The index of tip-on : 0 for tip-on #1, and 1 for tip-on #2
        _P.setXPos = function (x, i) {
            var me = this;
            var gp = me.swingDoor.owner.closet.getGPos();
            var ga = me.swingDoor.owner.closet.getGAng();

            if (i === 0) {
                me.tipOn1.pos.x = x;
                me.tipOn1.gpos.x = ezGPos.toGx(x, me.tipOn1.pos.z, ga, gp);
            }
            else if (i === 1) {
                me.tipOn2.pos.x = x;
                me.tipOn2.gpos.x = ezGPos.toGx(x, me.tipOn2.pos.z, ga, gp);
            }
            else
                $ez.THROW("Tip-on index " + i + " is out-of-bounds.");
        }

        // Return bounding box including TipOn1 and TipOn2 if any.
        _P.getBndBox = function () {
            var me = this;
            if (me.tipOn2.pos === null) { // Single tip-on
                return { // simply tipOn1 informations
                    xdim: me.ruleModel.TipOnWidth,
                    ydim: me.ruleModel.TipOnHeight,
                    zdim: me.ruleModel.TipOnDepth,
                    x: me.tipOn1.pos.x,
                    y: me.tipOn1.pos.y,
                    z: me.tipOn1.pos.z
                };
            }
            else { // Two tip-ons
                return { // Return fusion of tipOn1 and TipOn2 on their widths
                    xdim: me.ruleModel.TipOnWidth * 2,
                    ydim: me.ruleModel.TipOnHeight,
                    zdim: me.ruleModel.TipOnDepth,
                    x: me.tipOn1.pos.x + (me.ruleModel.TipOnWidth / 2),
                    y: me.tipOn1.pos.y,
                    z: me.tipOn1.pos.z
                };
            }
        }

        // display
        _P.display = function () {
            var me = this;
            ez3D.add(me.tipOn1.mesh);
            if (me.tipOn2.mesh) ez3D.add(me.tipOn2.mesh);
        }

        // hide
        _P.hide = function () {
            var me = this;
            if (ez3D.has3D()) {
                ez3D.remove(me.tipOn1.mesh);
                if (me.tipOn2.mesh) ez3D.remove(me.tipOn2.mesh);
            }
        }

        // dispose
        _P.dispose = function () {
            var me = this;
            if (me.geom) {
                me.geom.dispose();
                me.geom = null;
            }
        }

        // BOM filling.
        _P.fillBOM = function (bom) {
            var me = this;

            var n = me.tipOn2.pos === null ? 1 : 2;

            var bi = ezBOM.$newItem();
            bi.title = n + " x tip-on(s) " + me.ruleModel.Ref;
            bi.dims = [me.ruleModel.TipOnWidth, me.ruleModel.TipOnHeight, me.ruleModel.TipOnDepth];
            bi.dimsLabel = '(l x h x p)';
            bi.surf = 0;
            bi.ecoTax = ezPrice.getEcoTaxByWeight(me.ruleModel.Weight).Value * n;
            bi.price = ezPrice.getSalePrice(me.ruleModel.Price) * n;
            bi.weight = me.ruleModel.Weight * n;

            bom.add(bi);
        }

    })(TipOns.prototype);




    /////// Class SwingDoor
    // Brief : Swing-door to apply in closet zone.
    // Constructor
    // owner: (CNode) The CNode that own the door.
    // model: (ezcSwingDoor) Model of swingDoor
    var SwingDoor = function (owner, model) {
        var me = this;
        me.CUID = owner.getCloset().getCUID();

        me.owner = owner;
        me.model = model;
        me.panels = [];
        me.price = 0;
        me.colour = null;
        me._hasOwnColour = false;

        me.symbols = [];

        me.hingeGroups = []; // Class HingeGroup
        me.tipOns = null; // Class TipOns
    };
    // Methods
    (function (_P) {

        // Get closet that own this door
        _P.getCloset = function () { return this.owner.closet; }

        // Get and set model of door
        _P.getModel = function () { return this.model; }
        _P.setModel = function (model) {
            if (model.Id === this.model.Id) return;
            var newPanelCount = model.DoorType < 3 ? 1 : 2;
            if (newPanelCount !== this.getPanelCount()) {
                this.hide();
                this._clear();
            }

            this.model = model;
        }

        // Get or set colour of door
        _P.setColour = function (c) {
            this.colour = c;
            if (this.panels.length > 0) {
                for (var i = 0; i < this.panels.length; i++)
                    this.panels[i].setColour(c);
            }
        }
        _P.getColour = function () { return this.colour; }
        _P.hasOwnColour = function () { return this._hasOwnColour; }
        _P.setHasOwnColour = function (yes) { this._hasOwnColour = yes; }

        // Get the hinge-goups if any
        // Returns : (Array of HingeGroup) The hinge-goups if any, null otherwise.
        _P.getHingeGroups = function () { return this.hingeGroups; }

        // Get the tip-ons if any
        // Returns : (TipOns) The tip-ons if any, null otherwise
        _P.getTipOns = function () { return this.tipOns; }

        // Get hinge dimensions according to current hinge-rule model.
        // throw an exception if no hinge-rule.
        // Returns: (Object) { xdim: Number, ydim: Number, zdim: Number}
        _P.getHingeDims = function () {
            var me = this;
            if (!me.model.HingeRuleId || me.model.HingeRuleId === 0)
                $ez.THROW("No hinge rule defined. Cannot get hinge dimensions.");

            var ruleModel = me.model.hingeRule;
            return { xdim: ruleModel.HingeWidth, ydim: ruleModel.HingeHeight, zdim: ruleModel.HingeDepth };
        }

        // Get swing-door panel count according of door-type
        _P.getPanelCount = function () { return this.model.DoorType < 3 ? 1 : 2; }

        // Get swing-door panels
        // Returns: (Array of Panel)
        _P.getPanels = function () { return this.panels; }

        // Get the min height of swing door. Useful to check height of closet.
        _P.getMinHeight = function () { return this.model.MinHeight; }

        // Get the min width of swing door.
        _P.getMinWidth = function () { return this.model.MinWidth; }

        // Return EZ type
        _P.getEzType = function () { return 'SWD'; }

        // Compute swing-door
        _P.compute = function () {
            var me = this;
            var closet = me.owner.getCloset();
            var ownerZone = me.owner.paddingZone || me.owner.outerZone;
            var i;
            var panelCount = me.getPanelCount();

            if (me.panels.length === 0) {
                var mat = me.model.panelModel;

                for (i = 0; i < panelCount; i++) {
                    me.panels.push(ezPanel.create(ownerZone));
                    if (!me.model.IsOverlay)
                        ezPanel.beOnSide(me.panels[i], 'front', closet.getClosetModel().FrontSeparatorMargin);
                    else
                        ezPanel.beOnSide(me.panels[i], 'front', 0);
                    me.panels[i].setMaterialModel(mat);
                    me.panels[i].setColour(me.colour);
                }
            }
            else {
                for (i = 0; i < panelCount; i++)
                    me.panels[i].positioner.zone = ownerZone;
            }

            // Always setted because is not serialized
            for (i = 0; i < panelCount; i++)
                me.panels[i].setVisualMargin(0.2);


            // Margins according original application schema
            me._updateMargins();

            // To avoid out-of-bounds width
            if (panelCount === 1 && ownerZone.xdim > me.model.MaxWidth) {
                    if (me.model.DoorType === 1) // Open on left
                        me.panels[0].margins.right += ownerZone.xdim - me.model.MaxWidth;
                    else if (me.model.DoorType === 2) // Open on right
                        me.panels[0].margins.left += ownerZone.xdim - me.model.MaxWidth;
                    else
                        $ez.THROW("Not supported type of door : " + me.model.DoorType);
            }
            if (panelCount === 2 && ownerZone.xdim > (me.model.MaxWidth * 2)) {
                var marg = (ownerZone.xdim - (me.model.MaxWidth * 2)) / 2;
                me.panels[0].margins.right += marg;
                me.panels[1].margins.left += marg;
            }

            // To avoid out-of-bounds height
            if (ownerZone.ydim > me.model.MaxHeight) {
                for (i = 0; i < panelCount; i++)
                    me.panels[i].margins.top += ownerZone.ydim - me.model.MaxHeight;
            }

            // Update panel
            for (i = 0; i < panelCount; i++)
                me.panels[i].compute();

            // Update of hinges ?
            me._updateHinges();
            
            // Update of tip-ons ?
            if (me.model.tiponRule) {
                if (!me.tipOns)
                    me.tipOns = new TipOns(me, me.model.tiponRule);

                me.tipOns.compute();
            }
        }

        // Compute 3D of swing-door
        _P.compute3D = function () {
            var me = this;
            var sceneMod = me.owner.getCloset().getSceneMode();
            var i;

            if (me.panels.length === 1) {
                me.panels[0].compute3D();

                if (me.symbols.length === 0)
                    me.symbols.push(new SwingDoorSymbol());

                me.symbols[0].update(me.panels[0], me.model.DoorType, sceneMod);
            }
            else {
                me.panels[0].compute3D();
                me.panels[1].compute3D();

                if (me.symbols.length === 0) {
                    me.symbols.push(new SwingDoorSymbol());
                    me.symbols.push(new SwingDoorSymbol());
                }

                me.symbols[0].update(me.panels[0], 1, sceneMod);
                me.symbols[1].update(me.panels[1], 2, sceneMod);
            }

            for (i = 0; i < me.panels.length; i++) {
                me.panels[i].compute3D();
            }

            for (i = 0; i < me.hingeGroups.length; i++) {
                me.hingeGroups[i].compute3D();
            }

            if (me.tipOns) me.tipOns.compute3D();
        }

        // Update door
        _P.update = function () {
            var me = this;
            me.compute();
            if (ez3D.has3D()) me.compute3D();
        }

        // Update margins of door
        _P._updateMargins = function () {
            var me = this;
            var closet = me.owner.getCloset();
            var TP = closet.getThickProvider();
            var PM;

            // Determine margins according schema and thickness of parts.
            var sch = me.owner.schema;
            var LeftM = sch && sch.HasLeft ? sch.LeftMargin + TP.getLeft() : 0;
            var rightM = sch && sch.HasRight ? sch.RightMargin + TP.getRight() : 0;
            var bottomM = sch && sch.HasBottom ? sch.BottomMargin + TP.getBottom() : 0;
            var topM = sch && sch.HasTop ? sch.TopMargin + TP.getTop() : 0;

            // For each panel, set margins according count of panel
            var panCount = me.panels.length;
            for (var i = 0; i < panCount; i++) {
                PM = me.panels[i].getMargins();

                PM.left = panCount === 1 || i === 0 ? LeftM : 0; // Single panel or first panel
                PM.right = panCount === 1 || i === 1 ? rightM : 0; // Single panel or last panel
                PM.bottom = bottomM;
                PM.top = topM;

                if (me.model.IsOverlay) { // Overlay swing-door
                    var SWA = closet.swingDoorAssoc;

                    if (panCount === 1 || i === 0) // Single panel or first panel
                        PM.left -= SWA.hasAssoc(me.owner, 'L') ? TP.getLeft() / 2 : TP.getLeft();

                    if (panCount === 1 || i === 1) // Single panel or last panel
                        PM.right -= SWA.hasAssoc(me.owner, 'R') ? TP.getRight() / 2 : TP.getRight();

                    PM.bottom -= SWA.hasAssoc(me.owner, 'B') ? TP.getBottom() / 2 : TP.getBottom();
                    PM.top -= SWA.hasAssoc(me.owner, 'T') ? TP.getTop() / 2 : TP.getTop();
                }
            }

            if (panCount > 1) { // double-door
                var ownerZone = me.owner.paddingZone || me.owner.outerZone;

                PM = me.panels[0].getMargins();
                PM.right += ownerZone.xdim / 2;

                PM = me.panels[1].getMargins();
                PM.left += ownerZone.xdim / 2;
            }
        }

        // Update hinge groups according to overlay status of door
        _P._updateHinges = function () {
            var me = this;
            var closet = me.owner.getCloset();
            var SWA = closet.swingDoorAssoc;
            var panelCount = me.getPanelCount();
            
            for (var i = 0; i < panelCount; i++) {
                var HG;
                var orient = (panelCount === 1 ? me.model.DoorType : i + 1);
                var heightOffs = (panelCount === 1 ? me.model.HeightOffs : (i === 0 ? me.model.HeightOffs : 0)); // Offs on left hinges for double-doors

                var hingeRule = me.model.hingeRule; //(me.model.HingeRuleId && me.model.HingeRuleId) ? me.model.HingeRuleId : 0;
                var hingeKindExt = null;

                if (me.model.IsOverlay) {
                    if (orient === 1) { // Left
                        hingeKindExt = SWA.hasAssoc(me.owner, 'L') ? "HO" : "O";
                    }
                    else { // Right
                        hingeKindExt = SWA.hasAssoc(me.owner, 'R') ? "HO" : "O";
                    }

                    //hingeRuleId = hingeKindExt === "O" ? me.model.OHingeRuleId : me.model.HOHingeRuleId;
                    hingeRule = hingeKindExt === "O" ? me.model.OHingeRule : me.model.HOHingeRule;
                }

                if (hingeRule) {
                    if (i === me.hingeGroups.length) {
                        HG = new HingeGroup(me,
                            hingeRule,
                            orient,
                            heightOffs);
                        me.hingeGroups.push(HG);
                    }
                    else {
                        HG = me.hingeGroups[i];
                        HG.orient = orient;
                        HG.heightOffs = heightOffs;
                    }

                    HG.kindExt = hingeKindExt;
                    HG.compute();
                }
            }
                

        }

        // Update three material
        _P.updateThreeMat = function () {
            var me = this;
            var sceneMod = me.owner.getCloset().getSceneMode();
            var i;

            // Display or hide orientation
            if (sceneMod === 'work') {
                if (me.panels.length > 0) {
                    for (i = 0; i < me.panels.length; i++)
                        me.panels[i].hide();
                }

                if (me.symbols.length > 0) {
                    for (i = 0; i < me.symbols.length; i++)
                        me.symbols[i].display()
                }

                if (me.hingeGroups.length > 0) {
                    for (i = 0; i < me.hingeGroups.length; i++)
                        me.hingeGroups[i].display();
                }
                    
                if (me.tipOns) me.tipOns.display();
            }
            else {
                if (me.panels.length > 0) {
                    for (i = 0; i < me.panels.length; i++) {
                        me.panels[i].updateThreeMat();
                        me.panels[i].display();
                    }
                }

                if (me.symbols.length > 0) {
                    for (i = 0; i < me.symbols.length; i++)
                        me.symbols[i].hide()
                }

                if (me.hingeGroups.length > 0) {
                    for (i = 0; i < me.hingeGroups.length; i++)
                        me.hingeGroups[i].hide();
                }

                if (me.tipOns) me.tipOns.hide();
            }
        }

        // Display door
        _P.display = function () {
            var me = this;
            var sceneMod = me.owner.getCloset().getSceneMode();
            var i;

            if (me.panels.length > 0 && sceneMod !== 'work') {
                for (i = 0; i < me.panels.length; i++)
                    me.panels[i].display();
            }

            if (me.symbols.length > 0 && sceneMod === 'work') {
                for (i = 0; i < me.symbols.length; i++)
                    me.symbols[i].display();
            }

            if (me.hingeGroups.length && sceneMod === 'work') {
                for (i = 0; i < me.hingeGroups.length; i++)
                    me.hingeGroups[i].display();
            }
                
            if (me.tipOns && sceneMod === 'work') me.tipOns.display();
        }

        // hide door
        _P.hide = function () {
            var me = this;
            var i;

            if (me.panels.length > 0) {
                for (i = 0; i < me.panels.length; i++)
                    me.panels[i].hide();
            }

            for (i = 0; i < me.symbols.length; i++)
                me.symbols[i].hide();

            for (i = 0; i < me.hingeGroups.length; i++)
                me.hingeGroups[i].hide();

            if (me.tipOns) me.tipOns.hide();
        }

        // Dispose door
        _P.dispose = function () {
            var me = this;

            if (me.model.IsOverlay)
                me.owner.getCloset().getSwingDoorAssoc().remove(me.owner);

            me._clear();
        }

        // Clear swing-door. Used before model change
        _P._clear = function () {
            var me = this;

            if (me.panels.length > 0) {
                for (var i = 0; i < me.panels.length; i++)
                    me.panels[i].dispose();
                me.panels = [];
            }

            if (me.symbols.length > 0) {
                for (i = 0; i < me.symbols.length; i++)
                    me.symbols[i].dispose();
                me.symbols = [];
            }

            if (me.hingeGroups.length > 0) {
                for (i = 0; i < me.hingeGroups.length; i++)
                    me.hingeGroups[i].dispose();
                me.hingeGroups = [];
            }

            if (me.tipOns) {
                me.tipOns.hide();
                me.tipOns.dispose();
                me.tipOns = null;
            }
        }

        // Fill the BOM
        _P.fillBOM = function (bom) {
            var me = this;
            var i;

            for (i = 0; i < me.panels.length; i++) {
                me.panels[i].addPrice = me.model.AddPrice;
                me.panels[i].title = me.model.Title;
                me.panels[i].fillBOM(bom);
            }

            for (i = 0; i < me.hingeGroups.length; i++)
                me.hingeGroups[i].fillBOM(bom);

            if (me.tipOns) me.tipOns.fillBOM(bom);
        }

        // Get box of swing-door
        _P.getBox = function () {
            var bx1 = this.panels[0];
            var bx2 = this.panels.length > 1 ? this.panels[1] : null;
            return {
                x: bx2 === null ? bx1.x : (bx1.x + bx2.x) / 2,
                y: bx1.y,
                z: bx1.z,
                xdim: bx2 === null ? bx1.xdim : bx1.xdim + bx2.xdim,
                ydim: bx1.ydim,
                zdim: bx1.zdim
            };
        }

        // Returns true if door can be extended in the specified direction
        _P.canExtend = function(direction) {
            if (this.owner.parent === null) return false;

            var parentSNs = this.owner.parent.subNodes;
            var DNidx = parentSNs.indexOf(this.owner);
            var lastIdx = parentSNs.getLastIndex();

            switch(direction) {
                case "left":
                    return this.owner.parent.parent !== null && this.owner.axis !== $ez.X_AXIS && DNidx > 0 && !this._hasDoor(parentSNs.get(DNidx - 1));

                case "right":
                    return this.owner.parent.parent !== null && this.owner.axis !== $ez.X_AXIS && DNidx < lastIdx && !this._hasDoor(parentSNs.get(DNidx + 1));

                case "bottom":
                    return this.owner.axis !== $ez.Y_AXIS && DNidx > 0 && !this._hasDoor(parentSNs.get(DNidx - 1));

                case "top":
                    return this.owner.axis !== $ez.Y_AXIS && DNidx < lastIdx && !this._hasDoor(parentSNs.get(DNidx + 1));

                default:
                    $ez.THROW(`Unsupported direction '${direction}'.`);
            }
        }

        // Returns true if door can be reduced in the specified direction
        _P.canReduce = function(direction) {
            if (this.owner.parent === null) return false;

            var SNCount = this.owner.subNodes.getLength();
            if (SNCount === 0) return false;

            var SN = this.owner.subNodes.get(0);

            switch(direction) {
                case "left":
                    return this.owner.axis === $ez.X_AXIS ? SNCount > 1 : SNCount === 1 && SN.subNodes.getLength() > 1;

                case "right":
                    return this.owner.axis === $ez.X_AXIS ? SNCount > 1 : SNCount === 1 && SN.subNodes.getLength() > 1;

                case "bottom":
                    return this.owner.axis === $ez.Y_AXIS ? SNCount > 1 : SNCount === 1 && SN.subNodes.getLength() > 1;

                case "top":
                    return this.owner.axis === $ez.Y_AXIS ? SNCount > 1 : SNCount === 1 && SN.subNodes.getLength() > 1;

                default:
                    $ez.THROW(`Unsupported direction '${direction}'.`);
            }
        }

        // Extends door to next or previous zone
        _P.extend = function(direction) {
            var me = this;
            if (direction !== "left" &&  direction !== "right" && direction !== "bottom" && direction !== "top")
                $ez.THROW(`Unknown extension direction '${direction}'. Cannot extend door.`);

            var isHorizontal = (direction === "left" || direction === "right" );

            var newSelectedNode = null;

            var DN = this.owner; // The door node
            if (DN.parent.subNodes.getLength() > 2) {
                var DNsub; // 1st subnode of DN
                var dim; // dimension of DN
                var buffNode = null;

                if (DN.subNodes.getLength() === 1) { // has already sub Y->X 
                    DNsub = DN.subNodes.get(0);
                    dim = isHorizontal ? DNsub.outerZone.xdim : DNsub.outerZone.ydim;
                }
                else { // Not empty : must create sub Y->X with transfer of content and dimension 
                    buffNode = DN.transfer();
                    DNsub = DN.initSubNodes();
                    var N = DNsub.initSubNodes();
                    N.attachContentOf(buffNode); // transfer content

                    if (isHorizontal) { // transfer width
                        dim = DN.outerZone.xdim;
                        N.outerZone.xdim = dim;
                        N.outerZone.hasXDimAuto = N.innerZone.hasXDimAuto = DN.outerZone.hasXDimAuto;
                    }
                    else { // transfer height
                        dim = DN.outerZone.ydim;
                        N.outerZone.ydim = dim;
                        N.outerZone.hasYDimAuto = N.innerZone.hasYDimAuto = DN.outerZone.hasYDimAuto;
                    }
                }
                
                var DNidx = DN.parent.subNodes.indexOf(DN);
                var movedNode, nodeInsIdx, movedSep, sepInsIdx;
                if (direction === "top" || direction === "right") { // extends to top or right side
                
                    movedNode = DN.parent.subNodes.removeAt(DNidx + 1);
                    nodeInsIdx = DNsub.subNodes.getLength();

                    movedSep = DN.parent.separators.removeAt(DNidx);
                    sepInsIdx = DNsub.subNodes.getLength() - 1;

                }
                else { // extends to bottom or left side
             
                    movedNode = DN.parent.subNodes.removeAt(DNidx - 1);
                    nodeInsIdx = 0;

                    movedSep = DN.parent.separators.removeAt(DNidx - 1);
                    sepInsIdx = 0;
                      
                }

                // Move node
                DNsub.subNodes.insertAt(movedNode, nodeInsIdx);
                movedNode.parent = DNsub;

                // Move separator
                if (DNsub.separators === null) DNsub.separators = $ez.createEZArray();
                DNsub.separators.insertAt(movedSep, sepInsIdx);
                movedSep.parentZone = DNsub.innerZone;

                // New selected node is first or last node depending on extension direction
                newSelectedNode = movedNode;

                const subNodesList = DN.parent.subNodes.getArray();
                var autoNodesList = [];
                var mustBeAuto = null;

                if (isHorizontal) {
                    autoNodesList = subNodesList.filter((item) => item.innerZone.hasXDimAuto === true && item.CUID !== DN.CUID);
                    mustBeAuto = autoNodesList.length === 0 ? true : false; 

                    DN.outerZone.xdim = dim + movedNode.outerZone.xdim + movedSep.xdim;
                    DN.outerZone.hasXDimAuto = DN.innerZone.hasXDimAuto = mustBeAuto;
                }
                else {    
                    autoNodesList = subNodesList.filter((item) => item.innerZone.hasYDimAuto === true && item.CUID !== DN.CUID);
                    mustBeAuto = autoNodesList.length === 0 ? true : false; 
                    
                    DN.outerZone.ydim = dim + movedNode.outerZone.ydim + movedSep.ydim;
                    DN.outerZone.hasYDimAuto = DN.innerZone.hasYDimAuto = mustBeAuto;   
                }
            }
            else { // Door must be defined at upper level
                if (DN.subNodes.getLength() > 0) { // Sub-nodes must be moved close to DN
                    var DNsub = DN.subNodes.get(0); // 1st subnode of DN

                    if (DN.subNodes.getLength() === 1) {
                        var DNidx = DN.parent.subNodes.indexOf(DN); 

                        // Save content
                        var transfered = DNsub.subNodes.get(0);
                        var buffNode = transfered.transfer();

                        var movedCount = DNsub.subNodes.getLength();
                        for (var i = 1 ; i < movedCount ; i++) {
                            var movedNod = DNsub.subNodes.get(i);
                            DN.parent.subNodes.insertAt(movedNod, DNidx + i);
                            movedNod.parent = DN.parent;

                            var movedSep = DNsub.separators.get(i - 1);
                            DN.parent.separators.insertAt(movedSep, DNidx + i - 1);
                            movedSep.parentZone = DN.parent.innerZone;
                        }

                        DN.subNodes.removeAt(0);
                        DN.attachContentOf(buffNode);

                        if (isHorizontal) {
                            DN.outerZone.xdim = transfered.outerZone.xdim;
                            DN.outerZone.hasXDimAuto = DN.innerZone.hasXDimAuto = transfered.outerZone.hasXDimAuto;
                        }
                        else {
                            DN.outerZone.ydim = transfered.outerZone.ydim;
                            DN.outerZone.hasYDimAuto = DN.innerZone.hasYDimAuto = transfered.outerZone.hasYDimAuto;
                        }
                    }
                }

                if (direction === "bottom" || direction === "left")
                    newSelectedNode = DN.parent.subNodes.get(0);
                else
                    newSelectedNode = DN.parent.subNodes.get(DN.parent.subNodes.getLastIndex());

                // Transfert door to parent
                me._moveDoor(DN, DN.parent);
            }

            return newSelectedNode;
        }

        // Extends door to next or previous zone
        _P.reduce = function(direction) {
            var me = this;
            
            if (direction !== "left" &&  direction !== "right" && direction !== "bottom" && direction !== "top") 
                $ez.THROW(`Unknown reduction direction '${direction}'. Cannot reduce door.`);

            var isHorizontal = (direction === "left" || direction === "right" );

            var newSelectedNode = null;

            var DN = this.owner; // The door node
            if (DN.subNodes.getLength() === 1) {
                var DNidx = DN.parent.subNodes.indexOf(DN);
                var DNSub = DN.subNodes.get(0);

                var dim = isHorizontal ? DN.outerZone.xdim : DN.outerZone.ydim;
                
                var movedNod, nodeInsIdx, movedSep, sepInsIdx;
                if (direction === "bottom" || direction === "left") { // reduces to bottom or left side
                    var lastNodIdx = DNSub.subNodes.getLastIndex(); // save last node index before removing
                    movedNod = DNSub.subNodes.removeAt(lastNodIdx);
                    nodeInsIdx = DNidx + 1;

                    movedSep = DNSub.separators.removeAt(lastNodIdx - 1);
                    sepInsIdx = lastNodIdx - 1;

                    if (lastNodIdx > 0)
                        newSelectedNode = DNSub.subNodes.get(lastNodIdx - 1);
                }
                else { // reduces to top or right side
                    movedNod = DNSub.subNodes.removeAt(0);
                    nodeInsIdx = DNidx;

                    movedSep = DNSub.separators.removeAt(0);
                    sepInsIdx = 0;

                    newSelectedNode = DNSub.subNodes.get(0);
                }

                // Move node
                DN.parent.subNodes.insertAt(movedNod, nodeInsIdx);
                movedNod.parent = DN.parent;

                // Move separator
                if (DN.parent.separators === null) DN.parent.separators = $ez.createEZArray();
                DN.parent.separators.insertAt(movedSep, sepInsIdx);
                movedSep.parentZone =  DN.parent.innerZone;

                // Simplify the tree if needed
                if (DNSub.subNodes.getLength() === 1) {
                    var removed = DN.subNodes.removeAt(0);
                    var transfered = removed.subNodes.get(0);
                    DN.attachContentOf(transfered); // transfer content

                    if (isHorizontal) { // transfer width
                        DN.outerZone.xdim = transfered.outerZone.xdim;
                        DN.outerZone.hasXDimAuto = DN.innerZone.hasXDimAuto = transfered.outerZone.hasXDimAuto;
                    }
                    else { // transfer height
                        DN.outerZone.ydim = transfered.outerZone.ydim;
                        DN.outerZone.hasYDimAuto = DN.innerZone.hasYDimAuto = transfered.outerZone.hasYDimAuto;
                    }

                    newSelectedNode = DN;
                }
                else {
                    if (isHorizontal) {
                        DN.outerZone.xdim = dim - movedNod.outerZone.xdim - movedSep.xdim;
                        DN.outerZone.hasXDimAuto = DN.innerZone.hasXDimAuto = false;
                    }
                    else {
                        DN.outerZone.ydim = dim - movedNod.outerZone.ydim - movedSep.ydim;
                        DN.outerZone.hasYDimAuto = DN.innerZone.hasYDimAuto = false;
                    }
                 
                }                   

            }
            else if (DN.subNodes.getLength() > 1) { // Door must be defined at lower level

                var newDN, movedStartIdx, movedEndIdx;
                if (direction === "bottom" || direction === "left") { // Reduces to bottom or left direction
                    newDN = DN.subNodes.get(0);

                    movedStartIdx = 1;
                    movedEndIdx = DN.subNodes.getLength() - 2;

                    newSelectedNode = DN.subNodes.get(movedEndIdx); // Selected zone when no node moving required
                }
                else { // Reduces to top or right direction
                    newDN = DN.subNodes.get(1);
                    movedStartIdx = 2;
                    movedEndIdx = DN.subNodes.getLength() - 1;

                    newSelectedNode = newDN; // Selected zone when no node moving required
                }

                // Move nodes if needed
                if (movedStartIdx <= movedEndIdx) {
                    var dim = isHorizontal ? newDN.outerZone.xdim : newDN.outerZone.ydim;

                    var buffNode = newDN.transfer(); // Save content
                    var newDNSub = newDN.initSubNodes();
                    newDNSub.separators = $ez.createEZArray();

                    var destNode = newDNSub.initSubNodes(); // Restore content
                    destNode.attachContentOf(buffNode);

                    for (var i = movedEndIdx ; i >= movedStartIdx ; i--) {
                        var movedNod = DN.subNodes.removeAt(i);
                        newDNSub.subNodes.insertAt(movedNod, 1);
                        movedNod.parent = newDNSub;

                        var movedSep = DN.separators.removeAt(i - 1);
                        newDNSub.separators.insertAt(movedSep, 0);
                        movedSep.parentZone = newDNSub.innerZone;

                        //newSelectedZone = movedNod.innerZone; // Select the last zone

                        dim += isHorizontal ? movedNod.outerZone.xdim : movedNod.outerZone.ydim;
                        dim += isHorizontal ? movedSep.xdim : movedSep.ydim;
                    }

                    if (isHorizontal) {
                        destNode.outerZone.xdim = newDN.outerZone.xdim;
                        destNode.outerZone.hasXDimAuto = destNode.innerZone.hasXDimAuto = newDN.outerZone.hasXDimAuto;

                        newDN.outerZone.xdim = dim;
                        newDN.outerZone.hasXDimAuto = newDN.innerZone.hasXDimAuto = false;
                    }
                    else {
                        destNode.outerZone.ydim = newDN.outerZone.ydim;
                        destNode.outerZone.hasYDimAuto = destNode.innerZone.hasYDimAuto = newDN.outerZone.hasYDimAuto;

                        newDN.outerZone.ydim = dim;
                        newDN.outerZone.hasYDimAuto = newDN.innerZone.hasYDimAuto = false;
                    }
                } 

                // Transfert door to child
                me._moveDoor(DN, newDN);

            }
            else {
                $ez.THROW(`Cannot reduce door contained by single node.`);
            }

            return newSelectedNode;
        }

        // Move door from node to sepcified node
        _P._moveDoor = function(fromNode, toNode) {
            fromNode.schOpts.hasFrontActive = false;
            toNode.schOpts.hasFrontActive = true;
            toNode.schOpts.frontDef = fromNode.schOpts.frontDef;
            fromNode.parts.front.hide();
            fromNode.parts.front.dispose();
            fromNode.parts.front = null;
            fromNode.schOpts.frontDef = null;
        }

        // Returns true if node has door
        _P._hasDoor = function(n) { return n.parts && n.parts.front && n.parts.front.getEzType() === 'SWD' }

    })(SwingDoor.prototype);


    




    ////// ezSwingDoor service
    return {

        $name: 'ezSwingDoor',

        // Create and return new swing-door with the specified model.
        createSwingDoor: function (owner, model) {
            var swd = new SwingDoor(owner, model);
            if (model.IsOverlay) {
                var associates = owner.closet.getSwingDoorAssoc().add(owner);
                for (var i = 0; i < associates.length; i++) // Update all associate door
                    associates[i].parts.front.update();
            }
            return swd;
        },

        // Create and return new swing-door association manager.
        createSwingDoorAssoc: function (thickProvider) { return new SwingDoorAssoc(thickProvider); }

    };
}


////// EXPORT
export default {
    $init: _init
}