export type Mutable<T> = { -readonly [P in keyof T]: T[P] };

export type MobibamEnv = "DEVELOPMENT" | "STAGING" | "PRODUCTION";

export class CachedCollection<T> {
    private _list: T[] = [];
    private _map: { [key: string]: T } = {};

    add(id: number | string, item: T) {
        const existing = this._map[id.toString()];
        if (!existing) {
            this._map[id.toString()] = item;
            this._list.push(item);
        }
    }

    get(id: number | string): T | null {
        const item = this._map[id.toString()];
        return item ? item : null;
    }

    get list(): ReadonlyArray<T> { return this._list }

    clear() {
        this._list.length = 0;
        this._map = {};
    }
}

export interface ICoord3 {
    x: number;
    y: number;
    z: number;
}

export const getShippingCost = (totalPriceExVAT: number): number => { return Math.min(Math.max((totalPriceExVAT) * 0.12, 79), 350) }

export interface ProjectDims {
    readonly width: number;
    readonly height: number;
    readonly depth: number;
}

export type ClosetClasses = "CLASS1" | "CLASS2" | "CLASS3" | "CLASS4";

export type ClosetLayoutCode = "BIBLIO" | "DRESS";

export interface IBounds { min: number, max: number }