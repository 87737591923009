﻿///// Init method for injection
function _init(dcies, $ez) {

    var $log = $ez.getLogger();

    ////// ezPrice service
    return {
        $name: 'ezPrice',

        _discount: 0, // Discount coef
        _coef: 1,
        _shop: null,
        dataRepo: null,

        // Set current shop. These methods return true if shop changed, false otherwise.
        // s: (ezcShop) The shop
        // id: (Number) The id of shop
        setShop: function (s) {
            var me = this;
            if (me._shop === s) return false;

            me._shop = s;
            me._discount = s.Discount;
            me._coef = s.Coef;
            $log.info("Current shop is : " + s.Title);
            return true;
        },
        
        // Get the current shop.
        // Returns : (ezcShop) The current shop.
        getShop: function () { return this._shop; },

        // Get the weight in kg for a m3 volume
        // panelMat: (PanelMat) The panel material
        // Vm3: (Number) Volume in m3
        getWeigthOfVol: function (panelMat, Vm3) {
            return Vm3 * (panelMat.thick.Density);
        },

        // Get eco-tax by weight
        // w : (Number) weight in kg
        // Returns: (Ecotax) The ecotax
        getEcoTaxByWeight: function (w) {
            var ecot = this.dataRepo.componentModels.ecoTaxes;
            var n = ecot.length;
            for (var i = 0; i < n; i++) {
                if (w <= ecot[i].WeightBound) return ecot[i];
            }

            // upper the last weight bound
            return ecot[n - 1];
        },

        // Get the price for a m² surface according to thickness model and colour model
        // thick: (ezcThickness) The thickness with price category.
        // colour: (Colour) The colour with its category.
        getThickPriceOfSurf: function (thick, colour, Sm2) {
            switch (colour.PriceCategory) {
                case 1: return Sm2 * thick.PriceOfColourCat1;
                case 2: return Sm2 * thick.PriceOfColourCat2;
                case 3: return Sm2 * thick.PriceOfColourCat3;
                case 4: return Sm2 * thick.PriceOfColourCat4;
                case 5: return Sm2 * thick.PriceOfColourCat5;
                default: $ez.THROW("Unsupported colour price category '" + colour.PriceCategory + "'.");
            }
        },

        // Get the price for a m² surface according to panel material and colour model
        // panelMat: (PanelMat) The panel material
        // colour: (Colour) The colour
        // Sm2: (Numnber) The suface in m²
        getPriceOfSurf: function (panelMat, colour, Sm2) {
            return this.getThickPriceOfSurf(panelMat.thick, colour, Sm2);
        },

        // Set the price discount and coef of current shop. Use it on shop change.
        // discountRate: (Number) The discount in % (ex 15%).
        // coef: (Number) The profit coef (ex 1.2)
        setPriceParameters: function (discountRate, coef) { this._discount = discountRate / 100; this._coef = coef; },

        // Get sale price according to current discount and coef
        getSalePrice: function (buyPrice) { return buyPrice * (1 - this._discount) * this._coef; }

    };
}


////// EXPORT
export default {
    $init: _init
}