﻿function _init(dpcies) {

    ////// Logger object
    var logger = {
        tag: "[EZ] ",

        enabled: true,

        log: function (msg) { if (this.enabled) console.log(this.tag + msg); },

        info: function (msg) { if (this.enabled) console.info(this.tag + msg); },

        warn: function (msg) { if (this.enabled) console.warn(this.tag + msg); },

        error: function (msg) { if (this.enabled) { console.error(this.tag + msg); console.trace(); } }

        //debug: function (msg) { console.trace(this.tag + msg); }
    };

    ////// EZArray class
    // Brief : Make easier collection management. Encapsulate JS Array
    // Constructor
    var EZArray = function () {
        this._a = [];
    };
    // Methods
    (function (_P) {

        // Add item in array
        _P.add = function (a) { return this._a.push(a) }

        // Concat all item of array a in this array
        // a: (EZArray) the array to add.
        // _P.concat = function (a) {
        //     var n = a.getLength();
        //     for (var i = 0; i < n; i++)
        //         this._a.push(a._a[i]);
        // }

        // Insert item at position i.
        _P.insertAt = function (a, i) { this._a.splice(i, 0, a) /* this._a.splice(i, 0, a) */ }

        // Remove item at position i, and return removed item.
        _P.removeAt = function (i) {
            var a = this._a[i];
            this._a.splice(i, 1);
            return a;
        }

        // Get lentgh
        _P.getLength = function () { return this._a.length }

        // Direct access to Array
        _P.getArray = function () { return this._a }

        // Get item with index i
        _P.get = function (i) { return this._a[i] }

        // Set item at index i
        _P.set = function (a, i) { return this._a[i] = a }

        // Return last index of collection. Return -1 if is empty.
        _P.getLastIndex = function () { return this._a.length - 1 }

        // Return index of item. -1 if not found
        _P.indexOf = function (a) {
            return this._a.indexOf(a)
            // var me = this;
            // var n = me._a.length;
            // for (var i = 0; i < n; i++) {
            //     if (a === me._a[i]) return i;
            // }

            // return -1;
        }

        // Return true if is empty
        _P.isEmpty = function () { return this._a.length === 0 }

        // Execute action on each item of this array
        // f: (Function) The function called on each item. 
        //    f can declare 2 parameters f(item, index) : 'item' is the current element, 'index' the current item index.
        _P.forEach = function (f) {
            this._a.forEach((e, i) => { f(e, i) })
            // if (!f) logger.error("Function argument expected in forEach method.");

            // var me = this;
            // for (var i = 0; i < me._a.length; i++) f(me._a[i], i);
        }

        // Remove all item
        _P.removeAll = function () { this._a.length = 0 }

    }(EZArray.prototype));


    ////// $ez object
    return {
        $name: '$ez',

        // Max constant
        MAX: 9999,

        // Axis
        X_AXIS: 'X_AXIS', // Distribution along X
        Y_AXIS: 'Y_AXIS', // Distribution along Y
        Z_AXIS: 'Z_AXIS', // Distribution along Z

        KINDS: { None: 0, Left: 1, Right: 2, Top: 3, Bottom: 4, Back: 5, VSep: 6, HSep: 7, Drawer: 8, Front: 9 },

        // Return maximum value between a and b.
        getMax: function (a, b) {
            return a > b ? a : b;
        },

        // Return true if value is numeric
        isNumeric: function (n) { return !isNaN(parseFloat(n)) && isFinite(n); },

        // Get Axis from separator orientation
        // sepOrient: 'VERTICAL' or 'HORIZONTAL'
        getAxis: function (sepOrient) {
            if (sepOrient === 'VERTICAL') return this.X_AXIS;
            else if (sepOrient === 'HORIZONTAL') return this.Y_AXIS;
            else throw new Error("[EZCloset] No axis match separator orientation '" + sepOrient + "'.");
        },

        // Create bounds object
        createBounds: function () {
            var me = this;
            return { width: { min: 0, max: me.MAX }, height: { min: 0, max: me.MAX }, depth: { min: 0, max: me.MAX } }
        },

        // Create EZArray instance
        createEZArray: function () { return new EZArray() },

        // Throw EZCloset error, with the specified error message.
        THROW: function (msg) { throw new Error("[EZ] " + msg); },

        // Return logger
        getLogger: function () { return logger; },

        // Extend object a with properties of b (looks like jQuery.extend())
        extend: function(a, b) {
            for (var key in b)
                if (b.hasOwnProperty(key))
                    a[key] = b[key];
            return a;
        },

        // Round number with 2 decimals precision
        round2: function (n) { return Math.round(n * 100) / 100; },

        freeJsonData: true

    }
}

////// EXPORT
export default {
    $init: _init
}