import ezInjector from "./ezV4/core/ezInjector";
import { IClosetModel } from "./DataModels/IClosetModel";
import { IProject, ProjectV4 } from "./Project";
import { IBom } from "./BOM";
import { Mode3D } from "./Scene3D";
import { IClosetNode, IClosetZone } from "./ClosetNode";
import { ISmartPattern, ISmartModule, ISmartStyle, ISmartGroup } from "./DataModels/ISmartPattern";
import IColour from "./DataModels/IColour";
import { Mutable } from "./Common";

export type ClosetLocations = "main" | "right" | "left";

export interface ICloset {

    readonly project: IProject;

    readonly location: ClosetLocations;

    readonly model: IClosetModel;

    sceneMode: Mode3D;

    refresh(): void;

    readonly width: number;

    readonly height: number;

    readonly depth: number;

    createBOM(): IBom;

    readonly moduleCount: number;

    getModule(i: number): IClosetNode;

    readonly currentSmartPattern: ISmartPattern | null;

    colour: IColour;

    /**
     * Gets or sets the colour of swing-door. If null the doors have the same colour than this closet
     */
    swingDoorColour: IColour | null;

    /**
     * Return true if zone is onwed by this closet.
     * @param zone The zone
     */
    hasZone(zone: IClosetZone): boolean;

    /**
     * Set smart-pattern to the specified module
     * @param module The module where pattern is set
     * @param sm The smart-module
     */
    setSmartModule(module: IClosetNode, sm: ISmartModule): Promise<void>;

    /**
     * Fill the closet with the specified smart-style
     * @param manager The smart manager instance
     * @param style The smart-style used to fill closet
     * @param clearModule If true module will be cleared before pattern application. Default is false.
     */
    smartFill(manager: ISmartPattern, style: ISmartStyle, clearModule?: boolean): Promise<void>;

}

export class ClosetV4 implements ICloset {

    private readonly _ezCloset: any;
    private readonly _ezRW: any;
    private readonly _ez3D: any;

    private _ezContainer: any;
    private _ezReader: any;
    
    constructor(project: ProjectV4, loc: ClosetLocations, ezCloset: any) {
        this.project = project;
        this.location = loc;
        this._ezCloset = ezCloset;
        this._ezRW = ezInjector.get("ezRW");
        this._ez3D = ezInjector.get("ez3D");
    }

    get internalInstance(): any { return this._ezCloset }

    readonly project: IProject;

    readonly location: ClosetLocations;

    get model(): IClosetModel { return this._ezCloset.getClosetModel() }

    get sceneMode(): Mode3D { return this._ezCloset.getSceneMode() }

    set sceneMode(m: Mode3D) {  
        if (this._ezCloset.getSceneMode() === m) return;
        this._ezCloset.setSceneMode(m);
        this._ezCloset.root.updateThreeMat();
    }

    createBOM(): IBom {
        const container = this._ezRW.createContainer();
        container.prepareToWrite(this._ezCloset.getDriver().plan);

        const writer = this._ezRW.createWriter(container, { withBom: true });
        writer.writeCloset(this._ezCloset, this.location);
        return container.getDataNode().main.bom;
    }

    refresh() {
        this._ezCloset.hide();
        const plan = this._ezCloset.getDriver().plan;
        plan.update();

        this._ezCloset.getSwingDoorAssoc().invalidate();
        this._ezCloset.getSwingDoorAssoc().updateDoors();
        this._ezCloset.getValidator().checkAndFix(this._ezCloset.root);
        this._ezCloset.display();
    }

    //#region Dimensions

    get width(): number { return this._ezCloset ? this._ezCloset.getWidth() : 0 }
    // set width(dim: number) { 
    //     if (!this._ezCloset) return;

    //     this._ezCloset.hide();
    //     this._ezClosetHelper.setWidth(this._ezCloset, dim);

    //     this._refresh();
    // }

    get height(): number { return this._ezCloset ? this._ezCloset.getHeight() : 0 }
    // set height(dim: number) { 
    //     if (!this._ezCloset) return;

    //     this._ezCloset.hide();
    //     this._ezClosetHelper.setHeight(this._ezCloset, dim);

    //     this._refresh();
    // }

    get depth(): number { return this._ezCloset ? this._ezCloset.getDepth() : 0; }
    // set depth(dim: number) { 
    //     if (!this._ezCloset) return;

    //     this._ezCloset.hide();
    //     this._ezCloset.getDriver().setDepth(dim);

    //     this._refresh();
    // }

    // private _refresh() {
    //     const plan = this._ezCloset.getDriver().plan;
    //     plan.update();

    //     this._ezCloset.getSwingDoorAssoc().invalidate();
    //     this._ezCloset.getSwingDoorAssoc().updateDoors();
    //     this._ezCloset.getValidator().checkAndFix(this._ezCloset.root);
    //     this._ezCloset.display();
    // }

    //#endregion

    get moduleCount(): number { return this._ezCloset.root.subNodes.getLength() > 0 ? this._ezCloset.root.subNodes.getLength() : 1 }

    getModule(i: number): IClosetNode {
        if (this._ezCloset.root.subNodes.getLength() === 0) return this._ezCloset.root;
        return this._ezCloset.root.subNodes.get(i);
    }

    hasZone(zone: IClosetZone): boolean { return (zone as any).closet === this._ezCloset }

    //#region Smart pattern

    currentSmartPattern: ISmartPattern | null = null;

    async setSmartModule(module: IClosetNode, sm: ISmartModule): Promise<void> {
        if (!this.currentSmartPattern) return;

        this._ezCloset.hide();

        const maxACount = this.currentSmartPattern.intervals[sm.itvalIdx].maxACount;

        const root = this._ezCloset.root;
        const sepThick = root.thickProvider ? root.thickProvider.getSeparator() : 0.5;
        
        // Compute A
        const moduleDim = root.axis === "X_AXIS" ? module.innerZone.ydim : module.innerZone.xdim;
        const A = (moduleDim - ((maxACount -1) * sepThick)) / maxACount;

        // Construct the module content as ezRW format
        const sepAxis = root.axis === "X_AXIS" ? "Y_AXIS" : "X_AXIS"
        const rwDesc: any = { parts: { back: { ezTyp: "PAN" } }, subNodes: [], sepAxis };
        for (const zoneDesc of sm.zones) {
            const sn = this._zoneDescToSN(A, sepThick, zoneDesc.Ac);

            if (zoneDesc.SWD) { // Set door with separator behind
                if (!sn.parts) sn.parts = {};
                sn.parts.front = { ezTyp: "SWD", modelId: 0 };
        
                // Separator behind
                if (zoneDesc.SWD.length > 1) {
                    const snBehind: any = { "subNodes": [], sepAxis };
                    for (const Ac of zoneDesc.SWD) {
                        const sn = this._zoneDescToSN(A, sepThick, Ac);
                        snBehind.subNodes.push(sn);
                    }
                    sn.subNodes = [];
                    sn.subNodes.push(snBehind);
                }
            }

            rwDesc.subNodes.push(sn);
        }

        // Inject the pattern
        if (!this._ezContainer) {
            this._ezContainer = this._ezRW.createContainer();
            this._ezReader = this._ezRW.createReader(this._ezContainer, this._ezCloset.getClosetModel(), this.project.dataRepo);
        }

        module.empty();
        (module as Mutable<IClosetNode>).smartGroupKey = sm.groupKey;
        return this._ezReader.readNodeDesc(module, rwDesc);
    }

    private _zoneDescToSN(A: number, sepThick: number, Ac: number): any {
        let sn: any;

        if (Ac <= 1) { // Auto zone
            sn = {};
        }
        else { // Multiple dimension of zone
            const zonDim = (Ac * A) + ((Ac - 1) * sepThick);
            sn = { outerZone: { ydim: zonDim } };
        }

        return sn;
    }

    async smartFill(pattern: ISmartPattern, style: ISmartStyle, clearModule?: boolean): Promise<void> {
        this.currentSmartPattern = pattern;

        this.project.sepInjector.smallModule = pattern.smallModule;
        this.project.sepInjector.addSepOnDim = pattern.moduleMaxDim;
        this.project.sepInjector.remSepOnDim = pattern.moduleMinDim;

        const closetDim = this._ezCloset.root.axis === "X_AXIS" ? this.height : this.width;
        const itvalIdx = pattern.getIntervalIndex(closetDim);
        if (itvalIdx < 0) return;

        const moduleCount = this.moduleCount;
        const groupCount = style.groupKeys.length;
        for(let i = 0 ; i < moduleCount ; i++) {
            const module = this.getModule(i);
            if (module.isEmpty() || clearModule) {
                const iGroup = i % groupCount; // Loop on column style if more column in closet than style
                const sgKey = style.groupKeys[iGroup]; // The id of group

                // If module has overriden smart-group then keep it !
                const sg = (module.smartGroupKey && module.isUserSG) ? pattern.getSmartGroup(module.smartGroupKey) :  pattern.getSmartGroup(sgKey);

                await this.setSmartModule(module, sg.modulesByInterval[itvalIdx]);
            }
        }
    }

    //#endregion

    get colour(): IColour { return this._ezCloset.getMainColour() }

    set colour(c: IColour) { this._ezCloset.setMainColour(c) }

    get swingDoorColour(): IColour | null { return this._ezCloset.frontColour }

    set swingDoorColour(clr: IColour | null) {
        if (this._ezCloset.frontColour === clr) return;
        this._ezCloset.frontColour = clr;
        this._ezCloset.root.exploreDown((node: any): boolean => {
            if (node.parts && node.parts.front && node.schOpts.frontDef && node.schOpts.frontDef.EZT === "SWD") {
                node.parts.front.setColour(clr ? clr : this._ezCloset.getMainColour());
                node.parts.front.updateThreeMat();
            }

            return true;
        })
    }

}
