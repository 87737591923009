import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveDynamicComponent as _resolveDynamicComponent, createBlock as _createBlock } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "z-50 absolute top-0 h-screen w-full bg-white"
}
const _hoisted_2 = {
  key: 0,
  class: "flex flex-col bg-mobi-blue-light"
}
const _hoisted_3 = ["src"]
const _hoisted_4 = {
  key: 0,
  class: "text-center text-mobi-blue text-xl font-bold my-4"
}
const _hoisted_5 = {
  key: 1,
  class: "text-center texte-base mb-4 mx-6"
}
const _hoisted_6 = { key: 1 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return ($setup.store.currentModal !== 'NONE')
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        ($setup.title !== '' || $setup.subtitle !== '')
          ? (_openBlock(), _createElementBlock("header", _hoisted_2, [
              _createElementVNode("img", {
                src: $setup.crossIcon,
                alt: "cross-icon",
                onClick: _cache[0] || (_cache[0] = ($event: any) => ($setup.store.currentModal = 'NONE')),
                class: "h-6 w-6 cursor-pointer mb-[-25px] ml-auto mt-2 mr-4"
              }, null, 8 /* PROPS */, _hoisted_3),
              ($setup.title !== '')
                ? (_openBlock(), _createElementBlock("div", _hoisted_4, _toDisplayString($setup.title), 1 /* TEXT */))
                : _createCommentVNode("v-if", true),
              ($setup.subtitle !== '')
                ? (_openBlock(), _createElementBlock("div", _hoisted_5, _toDisplayString($setup.subtitle), 1 /* TEXT */))
                : _createCommentVNode("v-if", true)
            ]))
          : _createCommentVNode("v-if", true),
        _createElementVNode("main", null, [
          (_openBlock(), _createBlock(_resolveDynamicComponent($setup.component)))
        ]),
        ($setup.footerComponent)
          ? (_openBlock(), _createElementBlock("footer", _hoisted_6, [
              (_openBlock(), _createBlock(_resolveDynamicComponent($setup.footerComponent)))
            ]))
          : _createCommentVNode("v-if", true)
      ]))
    : _createCommentVNode("v-if", true)
}