﻿function _init(dpcies, $ez, ezCTreeHelper, ezMinMaxResolver) {

    var $log = $ez.getLogger(); // Logger


    ////// ezZoneHelper service
    return {
        $name: 'ezZoneHelper',

        // Return true if split max level is reached.
        // Z: (Zone) The zone to check
        // splitMaxLevel: (Number) Value or split max level. If 0, level is considered as infinite.
        splitMaxLevelIsReached: function (Z, splitMaxLevel, sepOrient) {
            var SN = Z.relNode;
            // Axis according separator orientation
            var axis = $ez.getAxis(sepOrient);
            var sameAxis = (axis === SN.axis);

            var sml = splitMaxLevel || 0;
            if (sml > 0) {
                var level = SN.getLevel();
                var splitLevel = !sameAxis ? level - 1 : level;
                if (splitLevel >= sml)
                    return true;
            }

            return false;
        },

        // Add separator in the specified zone
        // Z: (Zone) The zone to add separator
        // sepOrient: (String) Orientation of separator : 'VERTICAL' | 'HORIZONTAL'
        // checkOnly: (Boolean) If true, check if can add separator without adding done
        // Returns : {
        //      selectableZone: (Zone) The selectable zone after seprarator adding.
        //      hasTooShortDimErr: (Boolean) If true, the zone has too short dimension to add separator.
        // }
        addSeparator: function (Z, sepOrient, checkOnly, noUpdate) {
            var result = { selectableZone: Z, hasTooShortDimErr: false };
            var me = this;

            var node = Z.relNode;
            var C = node.getCloset();
            var ZB = C.getDriver().getZoneBounds(node);


            // Axis according separator orientation
            var axis = $ez.getAxis(sepOrient);
            var sameAxis = (axis === node.axis);

            var thick = C.getMainThickness();

            // Verify if zone can be splitted
            var splittedDim = 0;
            var PN = node.getParentNode();
            if (PN === null || sameAxis) { // No parent or same-axis
                splittedDim = axis === $ez.X_AXIS ? (Z.xdim - thick) / 2 : (Z.ydim - thick) / 2;
            }
            else if (axis === $ez.X_AXIS && !Z.hasXDimAuto) { // Not auto-zone for width
                splittedDim = (Z.xdim - thick) / 2;
            }
            else if (axis === $ez.Y_AXIS && !Z.hasYDimAuto) { // Not auto-zone for height
                splittedDim = (Z.ydim - thick) / 2;
            }
            else { // Auto-zone with parent
                var mins = ezMinMaxResolver.findMins(PN);
                mins.w -= ZB.width.min;
                mins.h -= ZB.height.min;
                splittedDim = axis === $ez.X_AXIS ? ((PN.innerZone.xdim - mins.w - thick) / 2) : ((PN.innerZone.ydim - mins.h - thick) / 2);
            }
            splittedDim = Math.round(splittedDim * 100) / 100;

            if (axis === $ez.X_AXIS && splittedDim < ZB.width.min) {
                result.hasTooShortDimErr = true;
            }
            else if (axis === $ez.Y_AXIS && splittedDim < ZB.height.min) {
                result.hasTooShortDimErr = true;
            }

            // Check only : stop here
            if (checkOnly) return result;

            // Split preparation
            var splitNode = node.prepareSeparatorAdd(axis);

            // Add separator
            var parentNode = splitNode.getParentNode();
            parentNode.addSeparator(splitNode); // Parent has necessarily the same axis. Force auto-size.

            // Update
            if (!noUpdate) {
                var P = C.getDriver().plan;
                P.hide();
                P.update();
                C.getSwingDoorAssoc().invalidate();
                C.getSwingDoorAssoc().updateDoors();
                C.getValidator().checkAndFix(C.root);
                P.display();
            }

            result.selectableZone = splitNode.innerZone;
            return result;
        },

        // Remove separator from zone according to the specified separator side.
        // Z : (Zone) The selected zone
        // sepSide : (String) 'left', 'right', 'bottom' or 'top'.
        // checkOnly: (Boolean) If true, check if can remove separator without removal done
        // Returns: (Object) {
        //      selectableZone: (Zone) The selectable zone after removal.
        //      hasNotEmptyErr: (Boolean) If true, the not empty error was detected.
        //  }
        removeSeparator: function (Z, sepSide, checkOnly, noUpdate) {
            var result = { selectableZone: Z, hasNotEmptyErr: false };
            var SN = Z.relNode;
            var C = SN.getCloset();

            var axOfRemoved, sepLoc;
            if (sepSide === 'left' || sepSide === 'right') {
                axOfRemoved = $ez.X_AXIS;
                sepLoc = (sepSide === 'left') ? 'BEFORE' : 'AFTER';
            }
            else if (sepSide === 'bottom' || sepSide === 'top') {
                axOfRemoved = $ez.Y_AXIS;
                sepLoc = (sepSide === 'bottom') ? 'BEFORE' : 'AFTER';
            }
            else
                throw $ez.THROW("Unsupported separator side '" + sepSide + "'.");

            // Place SN on good node
            if (axOfRemoved === SN.axis) {
                /* In case of :
                ...
                + XAxis : Sep = 1 x [|]
                    + YAxis : Sep = 1 x [--] << REMOVED (i.e SN become this node)
                        + XAxis << SELECTED : delete top
                        + XAxis 
                    + YAxis
                */
                SN = SN.getParentNode();
            }

            // get basis element
            var parentNode = SN.getParentNode();
            var SNIdx = parentNode.subNodes.indexOf(SN);
            var adjSN = parentNode.subNodes.get((sepLoc === 'BEFORE') ? SNIdx - 1 : SNIdx + 1);

            // Nodes are not empty : cannot delete.
            if (!SN.isEmpty() && !adjSN.isEmpty())
                result.hasNotEmptyErr = true;

            if (checkOnly) // No removal required
                return result;

            if (result.hasNotEmptyErr)
                $ez.THROW("Cannot remove separator cause 2 zones are not empty.");

            // Determine future selected node
            var selectedNode = null;
            if (parentNode.subNodes.getLength() === 2 && adjSN.isSplit()) {
                selectedNode = adjSN.get1stSelectable();
            }
            else if (parentNode.subNodes.getLength() > 2) // not the last separator : adjacent zone is next selected
                selectedNode = adjSN.get1stSelectable();
            else { // Last separator
                if (parentNode.parent && parentNode.parent.subNodes.getLength() === 1) // ex. X->Y[1-]->[adjSN]
                    selectedNode = adjSN.subNodes.getLength() === 0 ? parentNode.parent : parentNode.parent.get1stSelectable();
                else // ex. X[1|]->[adjSN]
                    selectedNode = adjSN.parent; // select X
            }

            // Remove separator located on side of SN
            // var updatedNode = parentNode.removeSeparator(SN, sepLoc);
            parentNode.removeSeparator(SN, sepLoc);

            if (!noUpdate) {
                var P = C.getDriver().plan;
                P.hide();
                P.update();
                C.getSwingDoorAssoc().invalidate();
                C.getSwingDoorAssoc().updateDoors();
                C.getValidator().checkAndFix(C.root);
                P.display();
            }

            result.selectableZone = selectedNode.innerZone;
            return result;
        },

        // Get node that allow height dimension change
        // Z: (Zone) The zone from height node was retrieved (in general the selected node).
        getHeightNode: function (Z) {
            return ezCTreeHelper.getHeightNode(Z.relNode);
        },

        // Get node that allow width dimension change
        // Z: (Zone) The zone from height node was retrieved (in general the selected node).
        getWidthNode: function (Z) {
            return ezCTreeHelper.getWidthNode(Z.relNode);
        },

        // Set zone height. If zone height has auto-dimension status, it will become fixed automatically.
        // Z: (Zone) The selected zone.
        // dim: (Number) The height of zone.
        // Returns: true if status become fixed, false otherwise
        setHeight: function (Z, dim) {
            if (typeof dim !== "number") $ez.THROW("Height must be a Number. Cannot change height of zone.");

            var SN = this.getHeightNode(Z);
            SN.outerZone.ydim = dim;
            var C = SN.getCloset();

            C.root.hide();
            if (SN.outerZone.hasYDimAuto) { // If is auto, must become fixed
                SN.parent.setZoneAutoSize(SN, $ez.Y_AXIS, false);

                C.getSwingDoorAssoc().invalidate();
                //(SN.parent ? SN.parent : SN).update(); // Cannot go higher than root
                C.root.update();
                C.root.display();
                C.getSwingDoorAssoc().updateDoors();
                C.getValidator().checkAndFix(C.root);

                return true;
            }
            else {
                C.getSwingDoorAssoc().invalidate();
                //(SN.parent ? SN.parent : SN).update(); // Cannot go higher than root
                C.root.update();
                C.root.display();
                C.getSwingDoorAssoc().updateDoors();
                C.getValidator().checkAndFix(C.root);

                return false;
            }
        },

        // Set zone width. If zone width has auto-dimension status, it will become fixed automatically.
        // Z: (Zone) The selected zone.
        // dim: (Number) The width of zone.
        // Returns: true if status become fixed, false otherwise
        setWidth: function (Z, dim) {
            if (typeof dim !== "number") $ez.THROW("Width must be a Number. Cannot change width of zone.");

            var SN = this.getWidthNode(Z);
            SN.outerZone.xdim = dim;
            var C = SN.getCloset();
            //var updNode = C.root;
            var P = C.getDriver().plan;

            P.hide();
            if (SN.outerZone.hasXDimAuto) { // If is auto, must become fixed
                SN.parent.setZoneAutoSize(SN, $ez.X_AXIS, false);

                C.getSwingDoorAssoc().invalidate();
                P.update(); 
                P.display();
                C.getSwingDoorAssoc().updateDoors();
                C.getValidator().checkAndFix(C.root);

                return true;
            }
            else {
                C.getSwingDoorAssoc().invalidate();
                P.update();
                P.display();
                C.getSwingDoorAssoc().updateDoors();
                C.getValidator().checkAndFix(C.root);

                return false;
            }
        },

        // Switch dimension status
        // Z: (Zone) The zone will have status switched
        // dimName: (String) The switched dimension : 'width' or 'height'
        switchDimStatus: function (Z, dimName) {
            var me = this;
            var dimNode, isAuto, axis;

            switch (dimName) {
                case 'width':
                    dimNode = me.getWidthNode(Z);
                    isAuto = dimNode.outerZone.hasXDimAuto;
                    axis = $ez.X_AXIS;
                    break;

                case 'height':
                    dimNode = me.getHeightNode(Z);
                    isAuto = dimNode.outerZone.hasYDimAuto;
                    axis = $ez.Y_AXIS;
                    break;

                default:
                    $ez.THROW("Not supported dimension name '" + dimName + "'.");
            }

            // Autosize is alway setted from the parent of node
            var C = Z.closet;
            dimNode.parent.setZoneAutoSize(dimNode, axis, !isAuto);
            C.getSwingDoorAssoc().invalidate();
            C.root.hide();
            C.root.update();
            C.root.display();
            //dimNode.parent.update();
            C.getSwingDoorAssoc().updateDoors();
            C.getValidator().checkAndFix(C.root);
        }
    }

}

////// EXPORT
export default {
    $init: _init
}
