﻿///// Init method for injection
function _init(dcies, $ez, ez3D, ezSchema, ezThick, ezCTree, ezSwingDoor, ezValidator) {

    var $log = $ez.getLogger();

    var CUID_COUNTER = 0;

    /////// Class Baseboard
    // Brief : Definition of baseboard to install closet without spacing between closet and wall.
    // Constructor
    var Baseboard = function (closet) {
        var me = this;
        me._ext = 10; // Extension of baseboard at extremities
        me._heightRatio = 0.9; // The max percentage of closet height allowed for the baseboard height (90%)
        me._depthRatio = 0.25; // The max percentage of closet depth allowed for the baseboard depth (25%)

        me.CUID = closet.getCUID();
        me._closet = closet;
        me._width = 0;
        me._depth = 0;
        me._height = 0;

        me.x = 0;
        me.y = 0;
        me.z = 0;

        me._mesh = null;
    };
    (function (_P) {

        // Gets or sets depth of baseboard (Z of closet)
        _P.getDepth = function () { return this._depth; }
        _P.setDepth = function (d) { this._depth = d; }

        // Gets or sets height of baseboard (Y of closet)
        _P.getHeight = function () { return this._height; }
        _P.setHeight = function (d) { this._height = d; }

        // Return true if baseborad is defined, i.e has defined dimensions.
        _P.isDefined = function () { return this._depth > 0 && this._height > 0; }

        // Set depth and height to 0
        _P.undefine = function () { this._depth = 0; this._height = 0; }

        // Return true if box collide with baseboard
        // y: (Number) The Y position of box
        // z: (Number) The Z position of box
        // ydim: (Number) The Y dimension of box
        // zdim: (Number) The Z dimension of box
        _P.hasCollision = function (y, z, ydim, zdim) {
            var me = this;

            var bbYmin = -me._closet.getHeight() / 2;
            var bbYmax = bbYmin + me._height;
            if (y - (ydim / 2) >= bbYmax) return false;

            var bbZmin = -me._closet.getDepth() / 2;
            var bbZmax = bbZmin + me._depth;
            if (z - (zdim / 2) > bbZmax) return false;

            return true;
        }

        // Compute shape of baseboard
        _P.compute = function () {
            var me = this;
            me._width = me._closet.getWidth() + (me._ext + 2);

            me.x = 0;
            me.y = (me._height / 2) - (me._closet.getHeight() / 2);
            me.z = (me._depth / 2) - (me._closet.getDepth() / 2);
        }

        // Compute 3D
        _P.compute3D = function () {
            var me = this;

            var g = ez3D.createBox(me._width, me._height, me._depth);
            if (!me._mesh) {
                me._mesh = ez3D.createMesh(g, ez3D.createMaterial({ color: 0xFF0000, opacity: 0.3 }));
            }
            else {
                if (me._mesh.geometry) me._mesh.geometry.dispose();
                me._mesh.geometry = g;
            }

            me._mesh.position.set(me.x, me.y, me.z);
        }

        // Update
        _P.update = function () {
            var me = this;
            me.compute();
            if (ez3D.has3D()) me.compute3D();
        }

        // Get the max height of baseboard according height of closet
        _P.getBaseboardMaxHeight = function () { return this._closet.getHeight() * this._heightRatio; }

        // Get the max depth of baseboard according depth of closet and by considering min of contents (ex. drawers).
        _P.getBaseboardMaxDepth = function () {
            var me = this;
            //if (!me.isDefined()) return $ez.MAX;

            var closetModel = this._closet.getClosetModel();
            if (closetModel.Extensions.BaseboardMaxDepth > 0)
                return closetModel.Extensions.BaseboardMaxDepth;
            else {
                var maxDepth = $ez.MAX;
                me._closet.root.exploreDown(function (n) {
                    if (n.hasItems() && me.hasCollision(n.innerZone.y, n.innerZone.z, n.innerZone.ydim, n.innerZone.zdim)) { // Has items and zone has collision with baseboard
                        var IB = n.getItemBounds();
                        if (maxDepth > me._closet.getDepth() - IB.depth.min) maxDepth = me._closet.getDepth() - IB.depth.min;
                    }

                    return true;
                });
            
                return maxDepth > me._closet.getDepth() * me._depthRatio ? me._closet.getDepth() * me._depthRatio : maxDepth;
            }
        }

        // Get the min height of closet according height of baseboard
        _P.getClosetMinHeight = function () { return this._height / this._heightRatio; }

        // Get the min depth of closet according depth of baseboard and by considering min of contents (ex. drawers).
        _P.getClosetMinDepth = function () {
            var me = this;
            if (!me.isDefined()) return 0;

            var minDepth = 0;
            me._closet.root.exploreDown(function (n) {
                if (n.hasItems() && me.hasCollision(n.innerZone.y, n.innerZone.z, n.innerZone.ydim, n.innerZone.zdim)) { // Has items and zone has collision with baseboard
                    var IB = n.getItemBounds();
                    if (minDepth < IB.depth.min + me._depth) minDepth = IB.depth.min + me._depth;
                }

                return true;
            });

            return minDepth < me._depth / me._depthRatio ? me._depth / me._depthRatio : minDepth;
        }

        // Get local box
        _P.getBox = function () {
            var me = this;

            return {
                x: me.x,
                y: me.y,
                z: me.z,
                xdim: me._width,
                ydim: me._height,
                zdim: me._depth
            };
        }

        // Get price of baseboard. If baseboard is not defined, return 0.
        _P.getPrice = function () {
            if (this.isDefined())
                return this._closet.getClosetModel().BaseboardPrice;
            else
                return 0;
        }

        // Display and hide
        _P.display = function () { if (this._mesh) ez3D.add(this._mesh); }
        _P.hide = function () { if (this._mesh) ez3D.remove(this._mesh); }

        // Dispose
        _P.dispose = function () {
            if (this._mesh && this._mesh.geometry)
                this._mesh.geometry.dispose();
        }


    })(Baseboard.prototype);

    /////// Class CNMargins
    // Brief : Margin values to compute side margins of parts
    // Constructor
    // CM : (ezcClosetModel) The closet model.
    var CNMargins = function (CM) {
        var me = this;
        me._closetModel = CM;

        // Front closet margins
        me.FrontTopMargin = CM.FrontTopMargin;
        me.FrontBottomMargin = CM.FrontBottomMargin;
        me.FrontLeftMargin = CM.FrontLeftMargin;
        me.FrontRightMargin = CM.FrontRightMargin;
        me.FrontShelfMargin = CM.FrontShelfMargin;
        me.FrontSeparator0Margin = CM.FrontSeparator0Margin;
        me.FrontSeparatorMargin = CM.FrontSeparatorMargin;

        // Top closet margins
        //me.TopLeftMargin = CM.TopLeftMargin;
        //me.TopRightMargin = CM.TopRightMargin;

        // Back closet margins
        me.BackLeftMargin = CM.BackLeftMargin;
        me.BackRightMargin = CM.BackRightMargin;
        me.BackBottomMargin = CM.BackBottomMargin;
        me.BackTopMargin = CM.BackTopMargin;
        me.BackShelfMargin = CM.BackShelfMargin;
        me.BackSeparator0Margin = CM.BackSeparator0Margin;
        me.BackSeparatorMargin = CM.BackSeparatorMargin;

        // Left closet margins
        me.LeftTopMargin = CM.LeftTopMargin;

        // Right closet margins
        me.RightTopMargin = CM.RightTopMargin;

        // The user margins
        me._topLeftMargin = 0;
        me._topRightMargin = 0;
    };
    // Methods
    (function (_P) {

        // Gets or set user top-left margin (useful for closet with different column height)
        _P.setUserTopLeftMargin = function (val) { this._topLeftMargin = val; }
        _P.getUserTopLeftMargin = function () { return this._topLeftMargin; }

        // Gets or set user top-right margin (useful for closet with different column height)
        _P.setUserTopRightMargin = function (val) { this._topRightMargin = val; }
        _P.getUserTopRightMargin = function () { return this._topRightMargin; }

        // Gets left-part top margin according current closet-model and user left-part top margin
        _P.getTopLeftMargin = function () { return this._topLeftMargin + this._closetModel.TopLeftMargin; }

        // Gets right-part top margin according current closet-model and user right-part top margin
        _P.getTopRightMargin = function () { return this._topRightMargin + this._closetModel.TopRightMargin; }

    })(CNMargins.prototype);

    /////// Class Closet
    // Brief : Responsible of panel thickness providing to CNode.
    // Constructor
    // Brief : This is the closet itself with the root of CNode.
    var Closet = function (dataRepo) {
        var me = this;
        //me._idCounter = 0;

        me.root = null; // CNode class
        me.schemaProvider = null; // CNSchemaProvider class
        me.thickProvider = null; // CNThickProvider class
        me.model = null;
        // me._emptyPattern = null;
        me._pattern = null;
        me.mainColour = null;
        me.mainPanelModel = null;
        me.defaultBackPanelModel = null;
        me.doorNode = null;
        me.swingDoorAssoc = null;
        me.EZCVer = "";
        me.validator = ezValidator.create(dataRepo.bestHandleHeight);
        me._GPos = { x: 0, y: 0, z: 0 };
        me._GAng = 0;
        me._sceneMode = 'rendering';
        me._driver = null; // IClosetDriver. See ezPlan.
        me._hasVisiblePanel = true;
        me._baseboard = new Baseboard(me);
        
        me.dataRepo = dataRepo;
        me.frontColour = null; // IColour of all swing-door
    };
    // Methods
    (function (_P) {

        _P.isUIEasy = function() { return this._driver.plan.isUIEasy }

        // Get a new unique id for item of this closet.
        _P.getCUID = function () { return ++CUID_COUNTER; }

        // Gets or sets closet driver
        _P.getDriver = function () { return this._driver; }
        _P.setDriver = function (drv) { return this._driver = drv; }

        // Gets or set closet scene mode
        _P.getSceneMode = function (mode) { return this._sceneMode; }
        _P.setSceneMode = function (mode) { this._sceneMode = mode; }

        // Gets or sets panel visibility
        _P.hasVisiblePanel = function (mode) { return this._hasVisiblePanel; }
        _P.setPanelVisibility = function (visible) { this._hasVisiblePanel = visible; }

        // Set and get schema provider
        _P.setSchemaProvider = function (sp) { this.schemaProvider = sp; }
        _P.getSchemaProvider = function () { return this.schemaProvider; }

        // Return the outer-zone of closet, i.e the outer zone of CNode root
        _P.getOuterZone = function () { return this.root.outerZone; }

        // Return main panel model (frame panel, separators and shelfs)
        _P.getMainThickness = function () { return this.mainPanelModel.thick.Value; }
        _P.getMainPanelModel = function () { return this.mainPanelModel; }

        // Return default panel model (frame panel, separators and shelfs)
        _P.getDefaultBackThickness = function () { return this.defaultBackPanelModel ? this.defaultBackPanelModel.thick.Value : 0; }
        _P.getDefaultBackPanelModel = function () { return this.defaultBackPanelModel; }

        // Return thick provider
        _P.getThickProvider = function () {
            var me = this;
            if (!me.thickProvider) {
                me.thickProvider = ezThick.$new(me.getMainThickness());
                me.thickProvider.setBack(me.getDefaultBackThickness());
                me.thickProvider.setFront(me.getMainThickness());

                if (!me.model.TopBandHeight) me.model.TopBandHeight = 0;
                if (me.model.TopBandHeight > 0 && me.model.TopBandMaterialId > 0) {
                    const bandMat = me.dataRepo.getPanelModel(me.model.TopBandMaterialId);
                    me.thickProvider.setTopBand(bandMat.thick.Value);
                    me.thickProvider.setTopBandMatModel(bandMat);
                }

                if (!me.model.BottomBandHeight) me.model.BottomBandHeight = 0;
                if (me.model.BottomBandHeight > 0 && me.model.BottomBandMaterialId > 0) {
                    const bandMat = me.dataRepo.getPanelModel(me.model.BottomBandMaterialId);
                    me.thickProvider.setBottomBand(bandMat.thick.Value);
                    me.thickProvider.setBottomBandMatModel(bandMat);
                }
            }

            return me.thickProvider;
        }

        // Get the swing-door association manager.
        _P.getSwingDoorAssoc = function () {
            var me = this;
            if (!me.swingDoorAssoc)
                me.swingDoorAssoc = ezSwingDoor.createSwingDoorAssoc(me.getThickProvider());

            return me.swingDoorAssoc;
        }

        // Get and set main colour of closet
        _P.getMainColour = function () { return this.mainColour }
        _P.setMainColour = function (colour) {
            var me = this;
            if (me.mainColour.Id === colour.Id) return false;

            me.mainColour = colour;
            me.root.setColour(colour);

            return true;
        }

        // Get or set current closet model
        _P.getClosetModel = function () { return this.model }
        _P.setClosetModel = function (m) {
            this.model = m;
            this.validator.setClosetModel(m);
        }

        // Gets or sets closet pattern. To restore empty pattern, set null pattern.
        _P.getPattern = function () { return this._pattern }
        _P.setPattern = function (p) { this._pattern = p || null; }

        // Get the current pattern of this closet. If no selected pattern, the associate empty pattern is returned.
        _P.getCurrentPattern = function () { 
            return this._pattern ? this._pattern : this.model.emptyPattern; 
        }

        // Get current closet validator
        _P.getValidator = function () { return this.validator; }

        // Get dimensions
        _P.getWidth = function () { return this.root.outerZone.xdim }
        _P.getHeight = function () { return this.root.outerZone.ydim }
        _P.getDepth = function () { return this.root.outerZone.zdim }

        // Set dimensions. Be careful ! These methodes change dimension of this closet only. Other closet of plan are not impacted.
        _P.setWidth = function (w) {
            var me = this;
            me.root.outerZone.xdim = w;
            if (me.doorNode) me.doorNode.outerZone.xdim = w;
        }
        _P.setHeight = function (h) {
            var me = this;
            me.root.outerZone.ydim = h;
            if (me.doorNode) me.doorNode.outerZone.ydim = h;
        }
        _P.setDepth = function (d) {
            var me = this;
            me.root.outerZone.zdim = d;
            if (me.doorNode) me.doorNode.outerZone.zdim = d;
        }

        // Get or set ground position of closet.
        _P.setGPos = function (x,y,z) {
            var me = this;
            me._GPos.x = x;
            me._GPos.y = y;
            me._GPos.z = z;
        }
        _P.getGPos = function () { return this._GPos; }

        // Get or set ground angle. Default is 0 : front of closet is Z+ oriented.
        _P.setGAng = function (a) { this._GAng = a; }
        _P.getGAng = function () { return this._GAng; }

        // Get global bounds of closet, i.e the bounds defined in model and sliding door bounds.
        _P.getGlobalBounds = function () {
            var me = this;
            var B = {
                width: { min: me.model.MinClosetWidth, max: me.model.MaxClosetWidth },
                height: { min: me.model.MinClosetHeight, max: me.model.MaxClosetHeight },
                depth: { min: me.model.MinClosetDepth, max: me.model.MaxClosetDepth }
            }

            if (me.doorNode && me.doorNode.door) {
                var sch = me.schemaProvider.getSchema01();
                var thickProv = me.thickProvider;

                var minW = me.doorNode.door.getMinWidth() + sch.LeftMargin + sch.RightMargin;
                if (sch.HasLeft) minW += thickProv.left;
                if (sch.HasRight) minW += thickProv.right;

                if (B.width.min < minW) B.width.min = minW;

                var minH = me.doorNode.door.getMinHeight() + sch.TopMargin + sch.BottomMargin;
                if (sch.HasTop) minH += thickProv.top;
                if (sch.HasBottom) minH += thickProv.bottom;
                if (B.height.min < minH) B.height.min = minH;
            }

            if (me.getBaseboard().isDefined()) {
                var minH = me.getBaseboard().getClosetMinHeight();
                if (B.height.min < minH) B.height.min = minH;

                var minD = me.getBaseboard().getClosetMinDepth();
                if (B.depth.min < minD) B.depth.min = minD;
            }

            return B;
        };

        // Get or create door-node. Use this method before access with getSlidingDoor() or setSlidingDoor().
        _P.getOrCreateDoorNode = function () {
            var me = this;
            if (!me.doorNode) {
                var SP = me.getSchemaProvider();
                var sch01N = SP.getSchema01N();

                // Door node
                var schema0 = ezSchema.$new();
                schema0.key = '0';
                schema0.LeftMargin = sch01N.HasLeft ? me.thickProvider.getLeft() : 0;
                schema0.RightMargin = sch01N.HasRight ? me.thickProvider.getRight() : 0;
                schema0.BottomMargin = sch01N.BottomMargin;
                schema0.BottomMargin += sch01N.HasBottom ? me.thickProvider.getBottom() : 0;
                schema0.TopMargin = sch01N.TopMargin;
                schema0.TopMargin += sch01N.HasTop ? me.thickProvider.getTop() : 0;
                me.doorNode = ezCTree.$newCNode({
                    closet: me,
                    schemaProvider: ezSchema.createProvider(schema0, null, null),
                    thickProvider: ezThick.$new(0),
                    parent: null,
                    axis: $ez.X_AXIS
                });

                var Z = me.doorNode.outerZone;
                Z.hasXDimAuto = true;
                Z.hasYDimAuto = true;
                Z.hasZDimAuto = true;
                Z.xdim = me.root.outerZone.xdim;
                Z.ydim = me.root.outerZone.ydim;
                Z.zdim = me.root.outerZone.zdim;
                me.doorNode.update(); // Compute inner-zone
            }
            return me.doorNode;
        }

        // Gets wall baseboard
        _P.getBaseboard = function () { return this._baseboard; }

        // Return true if has sliding-door
        _P.hasSlidingDoor = function () { return this.doorNode && this.doorNode.door; }

        // Get sliding-door or null in does not exists.
        _P.getSlidingDoor = function () {
            if (!this.doorNode) $ez.THROW("Cannot get sliding-door because door-node was not created. Call Closet.getOrCreateDoorNode() before.");
            return this.doorNode.door;
        }

        // Set sliding-door.
        // You are responsible to dispose the current door if any.
        _P.setSlidingDoor = function (SD) {
            if (!this.doorNode) $ez.THROW("Cannot set sliding-door because door-node was not created. Call Closet.getOrCreateDoorNode() before.");
            this.doorNode.door = SD;
        }

        // Closet update
        _P.update = function () {
            var me = this;
            var model = me.model;

            // Plan paddings
            var planPads = me._driver.getWidthPaddings();

            // Set padding with closet-model's global-margins
            me.root.setPaddings({
                left: planPads.left, 
                right: planPads.right,
                back: model.BackMargin || 0,
                front: model.FrontMargin || 0,
                bottom: model.BottomMargin || 0,
                top: model.TopMargin || 0
            });

            me.root.update();
            if (me.doorNode) me.doorNode.update();
            if (me._baseboard.isDefined()) me._baseboard.update();
        }

        // Closet hide
        _P.hide = function () {
            var me = this;
            me.root.hide();
            if (me.doorNode) me.doorNode.hide();
            if (me._baseboard.isDefined()) me._baseboard.hide();
        }

        // Closet display
        _P.display = function () {
            var me = this;
            me.root.display();
            if (me.doorNode) me.doorNode.display();
            if (me._baseboard.isDefined()) me._baseboard.display();
        }

        // BOM Filling 
        _P.fillBOM = function (bom) {
            var me = this;

            // fill bom from root
            me.root.fillBOM(bom);

            // Door node ?
            if (me.doorNode) me.doorNode.fillBOM(bom);

            // Additional price of project if any
            bom.addPrice(me.model.AddPrice);

            // Price of baseboard if any
            bom.addPrice(me.getBaseboard().getPrice());
        }

        // Empty the closet. useful to load another closet.
        _P.empty = function () {
            var me = this;
            me.root.hide();
            me.root.empty(); // empties and disposes the root node.

            if (me.doorNode) {
                me.doorNode.hide();
                me.doorNode.empty(); // empties and disposes the root node.
            }
            me._sceneMode = 'rendering';
            me._pattern = null;
            me._driver = null;

            me._baseboard.hide();
            me._baseboard.dispose();
        }

    })(Closet.prototype);


    ////// ezCloset service
    return {
        $name: 'ezCloset',

        // Reset CUID counter
        resetCUIDCounter: function () { CUID_COUNTER = 0; },
        
        // Create new closet according to specified model.
        // model: (ezcClosetModel) the model of closet.
        createCloset: function (model, w, h, d, colour, panelModel, backPanelModel, dataRepo) {

            var C = this.createClosetFromSchemas(ezSchema.getProvider(model.ModelClass, model), w, h, d, colour, panelModel, backPanelModel, model, dataRepo);
            // C.setClosetModel(model);

            return C;
        },

        // Create new closet from schemas provider.
        createClosetFromSchemas: function (schemaProvider, w, h, d, colour, panelModel, backPanelModel, model, dataRepo) {

            var C = new Closet(dataRepo);
            C.setClosetModel(model); 
            C.setSchemaProvider(schemaProvider);
            C.mainColour = colour;
            C.mainPanelModel = panelModel;
            C.defaultBackPanelModel = backPanelModel || null;

            C.root = ezCTree.$newCNode({
                closet: C,
                schemaProvider: C.getSchemaProvider(),
                thickProvider: C.getThickProvider(),
                marginProvider: new CNMargins(model), 
                parent: null,
                axis: schemaProvider.axis0
            });

            // Prepared the first zone of EZCloset world !
            var Z = C.root.outerZone;
            Z.hasXDimAuto = true;
            Z.hasYDimAuto = true;
            Z.hasZDimAuto = true;
            Z.xdim = w;
            Z.ydim = h;
            Z.zdim = d;            

            return C;
        }

    };
}


////// EXPORT
export default {
    $init: _init
}