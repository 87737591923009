import { ISmartGroup } from "../../engine/DataModels/ISmartPattern";
import biblio_200_220_4_1 from "../../assets/patterns/biblio-200-220-4.1.png";
import biblio_221_250_4_1 from "../../assets/patterns/biblio-221-250-4.1.png";

const GKEY = "4.1";
export const BiblioGroup4_1: ISmartGroup = {
    key: GKEY,
    modulesByInterval: [
        {
            groupKey: GKEY,
            itvalIdx: 0,
            icon: biblio_200_220_4_1,
            zones: [
            { Ac:1 },
            { Ac:1 },
            { Ac:1 },
            { Ac:1.5 },
            { Ac:1 },
            { Ac:1.5 },
            { Ac:1 },
            { Ac:1 }
        ]},
        {
            groupKey: GKEY,
            itvalIdx: 1,
            icon: biblio_221_250_4_1,
            zones: [
            { Ac:1.5 },
            { Ac:1.5 },
            { Ac:1 },
            { Ac:1 },
            { Ac:1.5 },
            { Ac:1 },
            { Ac:1.5 },
            { Ac:1 }
        ]}
    ]
}