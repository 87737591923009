import { ISmartGroup } from "../../engine/DataModels/ISmartPattern";
import biblio_200_220_1_1 from "../../assets/patterns/biblio-200-220-1.1.png";
import biblio_221_250_1_1 from "../../assets/patterns/biblio-221-250-1.1.png";

const GKEY = "1.1";
export const BiblioGroup1_1: ISmartGroup = { 
    key: GKEY, 
    modulesByInterval: [
        {
            groupKey: GKEY,
            itvalIdx: 0,
            icon: biblio_200_220_1_1,
            zones: [
                { Ac:1 },
                { Ac:1 }, 
                { Ac:1 }, 
                { Ac:1 }, 
                { Ac:1 }, 
                { Ac:1 }, 
                { Ac:1 },
                { Ac:1 },
                { Ac:1 }
            ]
        }, 
    
        {
            icon: biblio_221_250_1_1,
            groupKey: GKEY,
            itvalIdx: 1,
            zones: [
                { Ac:1 },
                { Ac:1 }, 
                { Ac:1 }, 
                { Ac:1 }, 
                { Ac:1 }, 
                { Ac:1 }, 
                { Ac:1 },
                { Ac:1 },
                { Ac:1 },
                { Ac:1 }
            ]
        }
    ] 
}