﻿///// Init method for injection
function _init(dcies, $ez, ez3D, ezPrice, ezSchema, ezCTree, ezThick, ezBOM) {

    var $log = $ez.getLogger();

    
    /////// Class Drawer
    // Brief : Drawer item added in closet zone.
    // Constructor
    var Drawer = function () {
        var me = this;

        me.zone = null;
        me.closet = null;
        me.model = null;
        me.count = 0;
        me.bounds = $ez.createBounds();
        me.price = 0;

        me.facadeModel = null;
        me.facadeColour = null;
        me.handleModel = null;
        me.handles = null;

        me.schema0 = ezSchema.$new();
        me.schema0.HasTop = true;
        me.schema0.HasBottom = true;
        me.schema0.HasLeft = true;
        me.schema0.HasRight = true;
        me.schema0.HasTopPriority = true;
        me.schema0.HasBottomPriority = true;

        me.schema1 = ezSchema.$new();
        me.schema1.HasFront = true;
        //me.schema1.FrontMargin = 0.5;

        me.thickProvider = ezThick.$new(1.6);
        me.thickProvider.setSeparator(0.5);

        me._group = "";
    };
    // Methods
    (function (_P) {

        // Set and set model of drawer
        _P.getModel = function () { return this.model }
        _P.setModel = function (model) {
            var me = this;
            var oldCount = me.count;

            me.model = model;
            me.bounds.width.min = model.MinWidth;
            me.bounds.width.max = model.MaxWidth;
            me.bounds.height.min = model.MinHeight;
            me.bounds.height.max = model.MaxHeight;
            me.bounds.depth.min = model.MinDepth;
            me.bounds.depth.max = model.MaxDepth;
            me.count = model.DrawerCount;

            if (me.root) {
                if (oldCount !== model.DrawerCount) {
                    me.root.removeSubNodes();

                    // Split according drawer count
                    if (me.count > 1) {
                        var sn = me.root.prepareSeparatorAdd($ez.Y_AXIS);
                        //sn.schOpts.hasFrontActive = true;
                        for (var i = 0; i < me.count - 1; i++) me.root.addSeparator(sn);
                        for (var i = 0; i < me.count; i++) me.root.subNodes.get(i).schOpts.hasFrontActive = true;
                    }
                    else
                        me.root.schOpts.hasFrontActive = true;

                    // Clear handles
                    me._removeHandles();
                }
            }
        }

        // Get ezType
        _P.getEzType = function () { return 'DrawerGroup' }

        // Gets or set group
        _P.getGroup = function () { return this._group; }
        _P.setGroup = function (g) { this._group = g; }

        // Gets or sets issue status of this drawer
        _P.setIssueStatus = function (status, node) {
            var me = this;
            var N = node || me.root;

            if (N) {
                if (N.parts) {
                    if (N.parts.left) N.parts.left.setIssueStatus(status);
                    if (N.parts.right) N.parts.right.setIssueStatus(status);
                    if (N.parts.top) N.parts.top.setIssueStatus(status);
                    if (N.parts.bottom) N.parts.bottom.setIssueStatus(status);
                    if (N.parts.front) N.parts.front.setIssueStatus(status);
                }

                for (var i = 0; i < N.subNodes.getLength(); i++)
                    me.setIssueStatus(status, N.subNodes.get(i));

                N.updateThreeMat();
            }
        }

        // Get bounds
        _P.getBounds = function () { return this.bounds }

        // Set colours of drawer
        _P.setColour = function (clr) { this.root.setColour(clr) }

        // Attach to node, i.e set zone with parentNode.innerZone
        _P.attachTo = function (parentNode) { this.zone = parentNode.innerZone; this.closet = this.zone.closet; }

        // Compute drawer
        _P.compute = function () {
            var me = this;

            var Z = me.zone;
            var B = me.bounds;
            var FSM = me.closet.getClosetModel().FrontSeparatorMargin;

            // Thickness of box
            if (me.model.DefaultBoxThickness > 0) {
                me.thickProvider.left =
                    me.thickProvider.right =
                    me.thickProvider.bottom =
                    me.thickProvider.top = me.model.DefaultBoxThickness;
                me.schema0.HasTop = true;
                me.schema0.HasBottom = true;
                me.schema0.HasLeft = true;
                me.schema0.HasRight = true;
            }
            else {
                me.schema0.HasTop = false;
                me.schema0.HasBottom = false;
                me.schema0.HasLeft = false;
                me.schema0.HasRight = false;
            }

            var OZ;
            if (!me.root) { // Root must be be created

                me.root = ezCTree.$newCNode({
                    closet: me.closet,
                    schemaProvider: ezSchema.createProvider(me.schema0, me.schema1, null),
                    thickProvider: me.thickProvider,
                    parent: null,
                    axis: $ez.Y_AXIS
                });
                me.root.schOpts.defaultFrontClr = me.facadeColour;
                me.root.schemaProvider.zoneShown = false;

                // Init outer zone
                OZ = me.root.outerZone;
                me.count = me.model.DrawerCount;

                // Split according drawer count
                me.root.splitWithBounds = false;
                if (me.count > 1) {
                    var sn = me.root.prepareSeparatorAdd($ez.Y_AXIS);
                    for (var i = 0; i < me.count - 1; i++) me.root.addSeparator(sn);
                    for (var i = 0; i < me.count; i++) {
                        me.root.subNodes.get(i).schOpts.hasFrontActive = true;
                        me.root.subNodes.get(i).schOpts.defaultFrontClr = me.facadeColour;
                    }
                }
                else {
                    me.root.schOpts.hasFrontActive = true;
                    me.root.schOpts.frontMat = me.facadeModel;
                }
            }
            else
                OZ = me.root.outerZone;

            //// Update outer-zone of root
            if (Z.xdim > B.width.max) {
                OZ.xdim = B.width.max;
                OZ.x = Z.x - ((Z.xdim - B.width.max) / 2); // On left side of zone
            }
            else {
                OZ.x = Z.x;
                OZ.xdim = Z.xdim;
            }

            if (Z.ydim > B.height.max) {
                OZ.ydim = B.height.max;
                OZ.y = Z.y - ((Z.ydim - B.height.max) / 2); // On bottom side of zone
            }
            else {
                OZ.y = Z.y;
                OZ.ydim = Z.ydim;
            }

            if (Z.zdim - FSM > B.depth.max) {
                OZ.zdim = B.depth.max;
                OZ.z = Z.z + ((Z.zdim - B.depth.max) / 2) - FSM; // On front side of zone
            }
            else {

                OZ.z = Z.z - FSM / 2;
                OZ.zdim = Z.zdim - FSM;
            }
            //// Update outer-zone of root 

            me.root.setVisualMargins({ front: 0.2 }); // Margin around facade
            me.root.update();

            // Compute handles if any
            // (TODO)
            if (me.handleModel && me.model.canHasHandle)
                me._updateHandles(me.handleModel);

            me.root.innerZone.canDisplay = false;
        }

        // Compute 3D drawer object
        _P.compute3D = function () { }

        // Update
        _P.update = function () {
            var me = this;
            me.compute();
            if (ez3D.has3D()) me.compute3D();
        }

        // Set handle model.
        // handleModel : (ezcAccessory) The new handle model. If null remove handles from drawer group.
        _P.setHandleModel = function (handleModel) {
            var me = this;

            if (me.handleModel === handleModel) return;

            if (handleModel) { // Set new handle model
                me.handleModel = handleModel;
                me._updateHandles(handleModel);
            }
            else { // Unset current handle model
                me.handleModel = null;
                me._removeHandles();
            }
        }

        // Get handle model
        _P.getHandleModel = function () { return this.handleModel; }

        // Get facade model
        _P.getFacadeModel = function () { return this.facadeModel; }

        // Set facade colour
        _P.setFacadeColour = function (colour) {
            var me = this;
            var clr;

            if (colour) { // Set a colour
                clr = colour;
                me.facadeColour = colour;
            }
            else { // Remove colour -> set with closet colour
                clr = me.closet.getMainColour(); // me.root.parts.left.colour;
                me.facadeColour = null;
            }

            if (me.root) {
                var P;
                if (me.root.isLeaf()) { // 1 drawer in group
                    me.root.schOpts.defaultFrontClr = me.facadeColour;
                }
                else { // Many drawer in group
                    var n = me.root.subNodes.getLength();
                    for (var i = 0; i < n; i++) {
                        me.root.subNodes.get(i).schOpts.defaultFrontClr = me.facadeColour;
                    }
                }

                me.root.update();
            }
        }

        // Get custom facade colour. Return null if facades have same color than closet.
        _P.getFacadeColour = function () { return this.facadeColour; }

        // Get current facade colour. If has no custom colour, returns colour of closet.
        // Useful in ezRW.
        _P.getCurrentFacadeColour = function () {
            var me = this;
            if (me.facadeColour)
                return me.facadeColour;
            else
                return me.closet.getMainColour();
        }

        // Remove all handles if any
        _P._removeHandles = function () {
            var me = this;

            if (me.handles) {
                var n, i;
                n = me.handles.length;
                for (i = 0; i < n; i++) {
                    me.handles[i].hide();
                }

                me.handles = null;
            }
        }

        // Set handle model
        _P._updateHandles = function (handleModel) {
            var me = this;
            var H, i, n;

            if (!me.handles) { // Collection of handles mustt be created
                me.handles = [];
                if (me.root.isLeaf()) { // 1 drawer in group
                    H = ezArticle.createArticle(me.handleModel, me.closet);
                    H.positioner = ezcPositionerFactory.createPanelPositioner('front', 0.5, me.root.outerZone, H);
                    me.handles.push(H);
                    H.setModel(handleModel);
                    H.update();
                }
                else { // Many drawer in group
                    n = me.root.subNodes.getLength();
                    for (i = 0; i < n; i++) {
                        H = ezArticle.createArticle(me.handleModel, me.closet);
                        H.positioner = ezcPositionerFactory.createPanelPositioner('front', 0.5, me.root.subNodes.get(i).outerZone, H);
                        me.handles.push(H);
                        H.setModel(handleModel);
                        H.update();
                    }
                }
            }
            else { // Just update each handles
                n = me.handles.length;
                for (i = 0; i < n; i++) {
                    if (handleModel !== me.handles[i].model) me.handles[i].setModel(handleModel);
                    me.handles[i].hide();
                    me.handles[i].update();
                    me.handles[i].display();
                }
            }
        }

        // Get weight in kg
        _P.getWeight = function () {
            var me = this;
            var OZ = me.root.outerZone;
            var vol = (OZ.xdim / 100) * (OZ.ydim / 100) * (OZ.zdim / 100);
            return me.model.Density * vol;
        }

        // Display and hide handles
        _P.displayHandles = function () {
            var me = this;
            if (!me.handles) return;
            for (var i = 0; i < me.handles.length; i++) me.handles[i].display();
        }
        _P.hideHandles = function () {
            var me = this;
            if (!me.handles) return;
            for (var i = 0; i < me.handles.length; i++) me.handles[i].hide();
        }

        // Display
        _P.display = function () {
            var me = this;
            if (me.root) me.root.display();
            me.displayHandles();
        }

        // Hide
        _P.hide = function () {
            var me = this;
            if (me.root) me.root.hide();
            me.hideHandles();
        }

        // Dispose
        _P.dispose = function () { this.root.dispose() }

        // Update three material
        _P.updateThreeMat = function () { this.root.updateThreeMat() }

        // BOM filling.
        // bom: (Bom) The BOM to fill.
        _P.fillBOM = function (bom) {
            var me = this;
            var OZ = me.root.outerZone;
            var bi = ezBOM.$newItem();

            bi.price = me.model.Price;
            me.price = bi.price;

            if (me.model.AddDrawerFacadePrice) {
                if (me.model.DefaultBoxThickness > 0) {
                    var facBom = ezBOM.$new();
                    me.root.fillBOM(facBom);
                    bi.price += facBom.getTotalPrice();
                }
                else {
                    var S_m2 = (me.root.outerZone.xdim / 100) * (me.root.outerZone.ydim / 100);
                    bi.price += ezPrice.getPriceOfSurf(me.facadeModel, me.facadeColour ? me.facadeColour : me.closet.getMainColour(), S_m2);
                }
            }

            bi.price = ezPrice.getSalePrice(bi.price);

            bi.cuid = me.cuid;
            bi.title = me.facadeColour ? me.model.Title + " (façade " + me.facadeColour.Title + ")" : me.model.Title;
            bi.dims = [OZ.xdim, OZ.ydim, OZ.zdim];
            bi.dimsLabel = '(l x h x p)';
            bi.surf = 0;
            bi.weight = me.getWeight();
            bi.ecoTax = ezPrice.getEcoTaxByWeight(bi.weight).Value;

            bom.add(bi);

            // Handles if any
            if (me.handles) {
                for (var i = 0; i < me.handles.length; i++)
                    me.handles[i].fillBOM(bom);
            }
        }

        // Get box of Drawer
        _P.getBox = function () {
            if (!this.root) this.compute();

            var bx = this.root.outerZone;
            return {
                x: bx.x,
                y: bx.y,
                z: bx.z,
                xdim: bx.xdim,
                ydim: bx.ydim,
                zdim: bx.zdim
            };
        }
        
    })(Drawer.prototype);



    ////// ezDrawer service
    return {
        $name: 'ezDrawer',

        // Create drawer
        $new: function (drawerModel, facadeModel, handleModel) {
            var drw = new Drawer();
            if (drawerModel) drw.setModel(drawerModel);
            if (facadeModel) drw.facadeModel = facadeModel;
            if (handleModel) drw.handleModel = handleModel;
            return drw;
        }
    };
}


////// EXPORT
export default {
    $init: _init
}