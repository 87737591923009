﻿///// Init method for injection
function _init(dcies, $ez) {

    var $log = $ez.getLogger();

    /////// Class Trigger
    // Brief : Simple trigger for listen/subscribe mecanism (Observer pattern)
    // Constructor
    // thick : (Number) Thickess value for all type of parts.
    var Trigger = function (name) {
        var me = this;
        me.name = name;
        me._handlers = [];
    };
    // Methods
    (function (_P) {

        // Subscribe to this trigger.
        // f: (Function) The listening function that will be notified by this trigger with this signature : function(a) { ... }
        // k: (String) The key of handler. Useful to remove handler, see unsubscribe()
        _P.subscribe = function (f, k) {
            if (!f) $ez.THROW("Handler must be a defined function.");
            this._handlers.push({ func: f, key: k });
            $log.log("ezObs : Handler for event name '" + this.name + "' with key'" + (k ? k : 'null') + "' was subscribed.");
        }

        _P.unsubscribe = function (key) {
            for (var i = 0; i < this._handlers.length; i++) {
                if (this._handlers[i].key === key) {
                    this._handlers.splice(i, 1);
                    $log.log("ezObs : Handler for event name '" + this.name + "' with key'" + key + "' was unsubscribed.");
                    return;
                }
            }

            $log.warn("ezObs : Handler for event name '" + this.name + "' with key'" + key + "' not found. Cannot unsubscribe.");
        }

        // Run all handlers with arguments a.
        _P.run = function (a) {
            for (var i = 0; i < this._handlers.length; i++)
                this._handlers[i].func(a);
        }

    })(Trigger.prototype);


    /////// Observer store
    var _ObsStore = {};

    ////// ezObs service
    return {
        $name: 'ezObs',

        // Subscribe handler to event name
        // evname: (String) The event name.
        // f : (Function) The function handler as : function(a) { ... }
        // key: (String) The optional key of handler. Useful to remove handler, see unsubscribe()
        subscribe: function (evname, f, key) {
            var trig = _ObsStore[evname];
            if (!trig) {
                trig = new Trigger(evname);
                _ObsStore[evname] = trig;
            }

            trig.subscribe(f, key || null);
        },

        // unsubscribe handler with the specified key from event name
        unsubscribe: function (evname, key) {
            var trig = _ObsStore[evname];
            if (trig)
                trig.unsubscribe(key);
            else
                $log.warn("ezObs : Handler for event name'" + evname + "' not found. Cannot unsubscribe.");
        },

        // Run all handlers associate with event name. Each handler will be executed with optional argument a
        run: function (evname, a) {
            var trig = _ObsStore[evname];
            if (trig) trig.run(a);
        }
    };
}


////// EXPORT
export default {
    $init: _init
}